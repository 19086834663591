import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IUser } from "src/features/account/data/models/user";
import { Pagination } from "src/shared/model/pagination";
import { IPatientRepository } from "../../data/repositories/patientRepository";

export class FetchPatientDetails extends UseCase<FetchPatientDetailsParams, IUser | undefined>{
    private repository: IPatientRepository
    constructor(repository: IPatientRepository) {
        super()
        this.repository = repository
    }

    call(params: FetchPatientDetailsParams): Promise<Either<Failure, IUser | undefined>> {
        return this.repository.fetchPatientDetails(params.patientId)
    }

}

export class FetchPatientDetailsParams {
    patientId: any
    constructor(patientId: any) {
        this.patientId = patientId
    }
}