import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { match } from "src/base/Either";
import { Strings } from "src/constants";
import { history, Routes } from "src/helpers";
import { handleError } from "src/helpers/errorHandler";
import { DeleteNotification } from "../../domain/usecases/deleteNotification";
import { FetchNotificationDetail } from "../../domain/usecases/fetchNotificationDetail";
import { FetchNotification } from "../../domain/usecases/fetchNotifications";
import { SaveNotification } from "../../domain/usecases/saveNotification";
import {
  chooseNotification,
  initialNotification,
  INotification,
  notificationFetchData,
  notificationStateChanged,
} from "../store/notification";

export class NotificationContainer {
  fetchNotification: FetchNotification;
  fetchNotificationDetail: FetchNotificationDetail
  saveNotification: SaveNotification;
  deleteNotification: DeleteNotification;

  constructor(
    fetchNotification: FetchNotification,
    fetchNotificationDetail: FetchNotificationDetail,
    saveNotification: SaveNotification,
    deleteNotification: DeleteNotification
  ) {
    this.fetchNotification = fetchNotification;
    this.fetchNotificationDetail = fetchNotificationDetail
    this.saveNotification = saveNotification;
    this.deleteNotification = deleteNotification;
  }

  async getNotification(dispatch: Dispatch, page: number) {
    dispatch(notificationStateChanged(true));

    let either = await this.fetchNotification.repository.fetchNotification(
      page
    );
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (data) => {
        dispatch(notificationFetchData(data));
      }
    );

    dispatch(notificationStateChanged(false));
  }

  async onFetchNotificationDetail(dispatch: Dispatch, notificationId: string) {
    dispatch(notificationStateChanged(true));
    let either = await this.fetchNotification.repository.fetchNotificationDetail(notificationId);
    match(
      either,
      (failure) => {
        dispatch(chooseNotification({ ...initialNotification, isNotFound: true }))
      },
      (data) => {
        if (data)
          dispatch(chooseNotification(data));
        else
          dispatch(chooseNotification({ ...initialNotification, isNotFound: true }))
      }
    );
    dispatch(notificationStateChanged(false));
  }

  async saveNotificationHandle(
    dispatch: Dispatch,
    notification: INotification
  ) {
    dispatch(notificationStateChanged(true));
    let either = await this.fetchNotification.repository.saveNotification(
      notification
    );
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (data) => {
        toast.success(
          notification.id == -1
            ? Strings.ADD_NOTIFICATION_SUCCESS
            : Strings.UPDATE_NOTIFICATION_SUCCESS,
          { position: toast.POSITION.BOTTOM_LEFT }
        );
        history.replace(Routes.NOTIFICATION_MANAGEMENT)
      }
    );
    dispatch(notificationStateChanged(false));
  }

  async deleteNotificationHandle(
    dispatch: Dispatch,
    notificationId: number,
    page: number
  ) {
    dispatch(notificationStateChanged(true));

    let either = await this.deleteNotification.repository.deleteNotification(
      notificationId
    );
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (data) => {
        if (data) {
          this.getNotification(dispatch, page);
          toast.success(Strings.DELETE_NOTIFICATION_SUCCESS, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
        //history.replace(Routes.NOICE_OPERATOR)
      }
    );

    dispatch(notificationStateChanged(false));
  }
}
