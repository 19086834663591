import axios from "axios";
import { Endpoints } from "src/base/Endpoints";
import { Injectable } from "src/base/Injector";
import { Endpoint, HttpMethod } from "src/helpers";
import { IApiService } from "src/services/api/ApiService";

export interface IAuthRemoteDataSource {
    login(id: string, password: string): Promise<void>;
    forgetPassword(email: string): Promise<boolean>;
    verifyOTP(email: string, otp: string): Promise<boolean>;
    changePassword(password: string, passwordCF: string, token: string): Promise<boolean>;
}

export const TOKEN = "TOKEN";

export class AuthRemoteDataSource implements IAuthRemoteDataSource {

    apiService: IApiService
    constructor(apiService: IApiService) {
        this.apiService = apiService
    }

    async login(id: string, password: string): Promise<void> {
        var response = await this.apiService.request({ method: HttpMethod.POST, endpoint: Endpoints.loginClinic, body: { data: { id, password } } })
        if (response.data.access_token) {
            localStorage.setItem(TOKEN, response.data.access_token)
        }
    }

    async forgetPassword(email: string): Promise<boolean> {
        var response = await this.apiService.request({ method: HttpMethod.POST, endpoint: Endpoints.forgetPassword, body: { data: { email } } })
        if (response.data === email) {
            return true;
        }
        return false;
    }

    async verifyOTP(email: string, otp: string): Promise<boolean> {
        var response = await this.apiService.request({ method: HttpMethod.POST, endpoint: Endpoints.verifyOTP, body: { data: { email, otp } } })
        if (response.data.data.token && response.data.data.token.length > 0) {
            localStorage.setItem("resetPassToken", response.data.data.token);
            return true;
        }
        return false;
    }

    async changePassword(password: string, passwordCF: string, token: string): Promise<boolean> {
        var response = await this.apiService.request({
            method: HttpMethod.POST, endpoint: Endpoints.changePassword, body: {
                data: {
                    password: password,
                    password_confirmation: passwordCF,
                    token: token
                }
            }
        })
        if (response.data.data) {
            return true;
        }
        return false;
    }
}