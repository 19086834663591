import { AuthState } from "src/base/Enums";
import { ActionType } from "typesafe-actions";
import * as actions from './actions';

export interface IAuthState {
    authState: AuthState
}

export enum AuthActionType {
    AUTH_STATE_CHANGED = "AUTH_STATE_CHANGED"
}

export type AuthActions = ActionType<typeof actions>;