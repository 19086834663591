import moment from "moment";
import { Endpoints } from "src/base/Endpoints";
import { Endpoint, HttpMethod } from "src/helpers";
import { IApiService } from "src/services/api/ApiService";
import { Pagination, paginationInit } from "src/shared/model/pagination";
import {
  defaultPatientAnalysisDetail as defaultPatientAnalysisDetail,
  IAnalyticDate,
  IPatientAnalytic,
  IPatientAnalyticDetail,
} from "../models/patientAnalytic";

export interface IAnalyticRemoteDataSource {
  fetchPatientAnalytics(
    code: string,
    fullName: string,
    limit: number,
    page: number
  ): Promise<Pagination<IPatientAnalytic>>;
  fetchPatientAnalyticDetail(
    id: string,
    fromDate?: Date,
    toDate?: Date,
    type?: string
  ): Promise<IPatientAnalyticDetail>;
}

export class AnalyticRemoteDataSource implements IAnalyticRemoteDataSource {
  apiService: IApiService;
  constructor(apiService: IApiService) {
    this.apiService = apiService;
  }

  async fetchPatientAnalytics(
    code: string,
    fullName: string,
    limit: number,
    page: number
  ): Promise<Pagination<IPatientAnalytic>> {
    let params: any = {
      code: code,
      full_name: fullName,
      limit,
      page,
    };
    var response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.fetchPatientAnalytic,
      params: params,
      shouldAuthorize: true,
    });
    if (!response.data || !response.data.data) return paginationInit;
    return {
      ...response.data.data,
      data: (response.data.data.data as Array<any>).map((analysic) => analysic),
    };
  }

  async fetchPatientAnalyticDetail(
    id: string,
    fromDate?: Date,
    toDate?: Date,
    type?: string
  ): Promise<IPatientAnalyticDetail> {
    var params: any = {
      ...(type
        ? {
            type,
          }
        : {}),
    };
    if (fromDate) {
      params.from_date = moment(fromDate).format("YYYY-MM-DD");
    }
    if (toDate) {
      params.to_date = moment(toDate).format("YYYY-MM-DD");
    }
    var response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.fetchAnalyticDetail(id),
      params: params,
      shouldAuthorize: true,
    });
    if (
      !response.data ||
      !response.data.data ||
      (response.data.data as Array<any>).length === 0 
    )
      return {...defaultPatientAnalysisDetail, isNotFound: true};
    return {
      ...response.data.data,
      analysis: response.data.data.analysis
        ? {
            ...response.data.data.analysis,
            dates: [
              ...(response.data.data.analysis.dates as IAnalyticDate[]).map(
                (date) => {
                  date.shouldShowAnalytic = true;
                  date.color =
                    "#" + Math.floor(Math.random() * 16777215).toString(16);
                  return date;
                }
              ),
            ],
          }
        : defaultPatientAnalysisDetail.analysis,
      comprehensive: response.data.data.comprehensive
        ? response.data.data.comprehensive
        : defaultPatientAnalysisDetail.comprehensive,
      evaluation: response.data.data.evaluation
        ? response.data.data.evaluation
        : defaultPatientAnalysisDetail.evaluation,
    };
  }
}
