import React, { ReactElement } from "react";
import { Assets } from "src/utils/assets";
import { dismissPopupById } from "src/utils/utils";

export interface PopupComponentProps {
    id: string
}

export class PopupComponent extends React.Component<PopupComponentProps> {
    render() {
        return <div className="modal" id={this.props.id}>
            <div className="modal-content">
                <div className="modal-content--ins">
                    <div className="btn_close cancelbox" onClick={() => {
                        dismissPopupById(this.props.id)
                    }}><img src={Assets.close} alt="" /></div>
                    {this.props.children}
                </div>
            </div>
        </div>
    }
}