import { AttributeName, ValidationType } from "src/base/Enums";
import { IValidaion } from "src/features/medicalRecord/data/models/attribute";
import { ToothAttribute } from "src/features/medicalRecord/data/models/medicalRecord";

export const Validation = {
  require: (value: string) => {
    return value.length != 0;
  },

  minLength: (value: string, minLength: number) => {
    return value.length >= minLength;
  },
  
  timeLaterNow: (value: Date) => {
    return value > new Date();
  },
  
  validateEmail:(email: string) => {
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  },

  validatePassword:(password: string) => {
    return Validation.require(password) && Validation.minLength(password, 8);
  },
  
  validateByAttributes:(rules: Array<IValidaion>, value: any) => {
    for(let rule of rules) {
      switch(rule.type) {
        case ValidationType.nullable:
          break;
        case ValidationType.array:
          if(!Array.isArray(value))
            return false;
          break;
        case ValidationType.in:
          if(Array.isArray(value)) {
            for(let item of value) {
              if(typeof rule.validValues?.find(element => element == item) == "undefined")
                return false;
            }
          }
          else if(typeof rule.validValues?.find(element => element == value) == "undefined")
            return false;
      }
    }
    return true;
  },
  
  validateTooth:(tooth: Map<string, ToothAttribute>) => {
    if(tooth.size != 6 || Object.keys(tooth.get(AttributeName.buccal)?.value).length != 3 || Object.keys(tooth.get(AttributeName.lingual)?.value).length != 3) {
      return false;
    }
    return true;
  },
  
}