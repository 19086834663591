// import { stringify } from "querystring";
import React from "react";
import { Input } from "src/components/input";
import { Table } from "src/components/table";
import { Strings } from "src/constants";
import MainLayout from "src/layouts/main/mainLayout";
import "react-datepicker/dist/react-datepicker.css";
import { PasswordInput } from "src/components/input/passwordInput";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IRootState } from "src/store";
import { IUser } from "src/features/account/data/models/user";
import { Injector } from "src/base/Injector";
import { AccountContainer } from "src/features/account/presenter/container/accountContainer";
import { MainButton } from "src/components/button/mainButton";
import { history, Routes } from "src/helpers";
import { AccountState } from "src/base/Enums";
// import { ReactSVG } from "react-svg";
// import { Assets } from "src/utils/assets";
import { BackButton } from "src/shared/components/backButton";

interface EditAccountPageState {
  me: IUser
}

class EditAccountPage extends React.Component<EditAccountPageType, EditAccountPageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      me: { ...this.props.me }
    }
  }

  componentDidMount() {
    this.props.onGetMe((me) => {
      this.setState({
        me
      })
    })
  }

  render() {
    return (
      <MainLayout isLoading={this.props.accountState === AccountState.updatingAccount || this.props.accountState === AccountState.fetchingAccount} className="secondpage navstate_show page-account_management detail animate-item" subClassName="p-account_management detail">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header flex-H">
              <BackButton onBackPressed={() => {
                history.replace(Routes.ACCOUNT_MANAGEMENT)
              }} />
              {Strings.ACCOUNT + Strings.LINE + Strings.EDIT}
            </div>
            <form onSubmit={(e) => {
              e.preventDefault()
              this.props.onUpdateAccount(this.state.me)
            }}>
              <div className="card-body">
                <Table
                  className="dataTable-container"
                  subClassName="table table_detail"
                  rows={[
                    {
                      fields: [
                        { html: Strings.ACCOUNT_NAME },
                        {
                          html: (
                            <Input
                              id="userfullname"
                              className="fsc"
                              inputClassName="form-control"
                              type="text"
                              value={this.state.me.full_name}
                              maxLength={20}
                              onChange={(value) => this.setState({ ...this.state, me: { ...this.state.me, full_name: value } })}
                            ></Input>
                          )
                        }
                      ]
                    },

                    {
                      fields: [
                        { html: Strings.ACCOUNT_ID },
                        {
                          html: (
                            <Input
                              id="username"
                              className="fsc"
                              inputClassName="form-control"
                              type="text"
                              value={this.state.me.username}
                              maxLength={50}
                              onChange={(value) => this.setState({ ...this.state, me: { ...this.state.me, username: value } })}
                            ></Input>
                          )
                        }
                      ]
                    },

                    {
                      fields: [
                        { html: Strings.ACCOUNT_PW },
                        {
                          html: (
                            <PasswordInput
                              id="userpassword"
                              className="fsc boxpassword"
                              inputClassName="form-control pass_log_id"
                              value={this.state.me.password}
                              maxLength={20}
                              onChange={(value) => {
                                this.setState({
                                  ...this.state, me: {
                                    ...this.state.me,
                                    password: value
                                  }
                                })
                              }}
                            ></PasswordInput>
                          )
                        }
                      ]
                    },
                  ]}
                ></Table>

                <div className="fec mt20 btn-group-end">
                  <MainButton
                    title={Strings.REGISTER}
                    submitting={this.props.accountState === AccountState.updatingAccount}
                    enabled={this.state.me.username.length != 0 && this.state.me.full_name.length != 0}
                    className="btn btn-primary mr10"
                    type="button"
                    isSubmitButton
                    loadingSize={20}
                    onSubmit={() => {

                    }}
                  />
                  <a className="btn btn-danger" onClick={() => {
                    history.replace(Routes.ACCOUNT_MANAGEMENT)
                  }}>{Strings.RETURN}</a>
                </div>
              </div>
            </form>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ account }: IRootState) => {
  const { me,
    accountState } = account
  return {
    me,
    accountState
  };
};
const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    onGetMe: (onSuccess: (me: IUser) => void) => Injector.get().find<AccountContainer>("AccountContainer").onGetMe(dispatch, onSuccess),
    onUpdateAccount: (newMe: IUser) => Injector.get().find<AccountContainer>("AccountContainer").onUpdateAccount(dispatch, newMe)
  };
};

type EditAccountPageType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(EditAccountPage);
