import { convertUrlEncodedBody } from "src/utils/utils";
import { IClinic } from "./clinic";

export interface IUser {
  id: string;
  code: string;
  user_type: string;
  username: string;
  clinic?: IClinic;
  email: string;
  full_name: string;
  full_name_furigana: string;
  gender: string;
  zipcode: string;
  birthday?: string;
  address: string;
  tel1: string;
  tel2: string;
  password: string;
  loginId: string;
  status: string;
  special_status: string;
  is_abnormalities: number;
  is_pregnancy: number;
  orthodontist_type: number;
  is_block: number;
  user_payment_type: string;
  updateOnly: boolean;
  special_code: string;
  color?: {
    hex?: string;
    shouldDisplayThisColor?: boolean;
  };
  isNotFound?: boolean;
  qr_code?: string;
  user_plan?: string;
  matched_name_images?: any[];
}

export const defaultUser: IUser = {
  id: "",
  code: "",
  user_type: "",
  username: "",
  user_payment_type: "",
  special_status: "",
  clinic: {
    id: "",
    medical_institution: "",
    email: "",
    name: "",
    zipcode: "",
    address: "",
    tel: "",
    representative: "",
  },
  email: "",
  password: "",
  full_name: "",
  full_name_furigana: "",
  gender: "male",
  zipcode: "",
  birthday: "1972-01-01",
  address: "",
  tel1: "",
  tel2: "",
  loginId: "",
  status: "",
  orthodontist_type: 1,
  updateOnly: false,
  is_block: 1,
  is_abnormalities: 0,
  is_pregnancy: 0,
  special_code: "",
  qr_code: "",
  matched_name_images: [],
};

export const formDataUser: any = (user: IUser) => {
  var bodyFormData = new FormData();
  if (user.birthday) {
    bodyFormData.append("birthday[year]", user.birthday?.split("-")[0] ?? "");
    bodyFormData.append("birthday[month]", user.birthday?.split("-")[1] ?? "");
    bodyFormData.append("birthday[day]", user.birthday?.split("-")[2] ?? "");
  }
  if (user.username) {
    bodyFormData.append("username", user.username);
  }

  if (user.email) {
    bodyFormData.append("email", user.email);
  }

  if (user.full_name) {
    bodyFormData.append("full_name", user.full_name);
  }

  if (user.full_name_furigana) {
    bodyFormData.append("full_name_furigana", user.full_name_furigana);
  }

  if (user.zipcode) bodyFormData.append("zipcode", user.zipcode);
  if (user.gender) bodyFormData.append("gender", user.gender);
  if (user.address) bodyFormData.append("address", user.address);
  if (user.tel1) bodyFormData.append("tel1", user.tel1);
  if (user.tel2) bodyFormData.append("tel2", user.tel2);
  if (user.orthodontist_type) bodyFormData.append("orthodontist_type", user.orthodontist_type.toString());
  if (user.status) bodyFormData.append("status", user.status);
  if (user.color) bodyFormData.append("color", JSON.stringify(user.color));
  // if (user.code) bodyFormData.append("code", user.code.slice(-4))
  if (user.special_code) bodyFormData.append("special_code", user.special_code);
  bodyFormData.append("is_abnormalities", user.is_abnormalities.toString());
  bodyFormData.append("is_pregnancy", user.is_pregnancy.toString());
  return bodyFormData;
};

export const urlEncodedUser: any = (user: IUser) => {
  var body: any = {};
  body["birthday[year]"] = user.birthday?.split("-")[0] ?? "";
  body["birthday[month]"] = user.birthday?.split("-")[1] ?? "";
  body["birthday[day]"] = user.birthday?.split("-")[2] ?? "";

  if (user.username) {
    body.username = user.username;
  }

  if (user.email) {
    body.email = user.email;
  }

  if (user.full_name) {
    body.full_name = user.full_name;
  }

  if (user.full_name_furigana) {
    body.full_name_furigana = user.full_name_furigana;
  }

  if (user.zipcode) body.zipcode = user.zipcode;
  if (user.gender) body.gender = user.gender;
  if (user.address) body.address = user.address;
  if (user.tel1) body.tel1 = user.tel1;
  if (user.tel2) body.tel2 = user.tel2;
  if (user.orthodontist_type) body.orthodontist_type = user.orthodontist_type;
  if (user.status) body.status = user.status;
  if (user.color) body.color = JSON.stringify(user.color);
  // if (user.code) body.code = user.code.slice(-4)
  if (user.special_code) body.special_code = user.special_code;
  body.is_abnormalities = user.is_abnormalities;
  body.is_pregnancy = user.is_pregnancy;
  return convertUrlEncodedBody(body);
};

export const userJson: any = (user: IUser) => {
  var json: any = { ...user };
  json["birthday[year]"] = user.birthday?.split("-")[0];
  json["birthday[month]"] = user.birthday?.split("-")[1];
  json["birthday[day]"] = user.birthday?.split("-")[2];
  delete json.birthday;
  return json;
};

export function userFromJson(json: any): IUser {
  let color = undefined;
  if (json.color)
    try {
      color = json.color && JSON.parse(json.color);
    } catch {
      color = undefined;
    }
  return {
    id: json.id ?? "",
    code: json.code ?? "",
    user_type: json.user_type ?? "",
    username: json.username ?? "",
    user_payment_type: json.user_payment_type || "",
    special_status: json.special_status || "",
    clinic: json.clinic
      ? {
          id: json.clinic.id ?? "",
          medical_institution: json.clinic.medical_institution ?? "",
          email: json.clinic.email ?? "",
          name: json.clinic.name ?? "",
          zipcode: json.clinic.zipcode ?? "",
          address: json.clinic.address ?? "",
          tel: json.clinic.tel ?? "",
          representative: json.clinic.representative ?? "",
        }
      : {
          id: "",
          medical_institution: "",
          email: "",
          name: "",
          zipcode: "",
          address: "",
          tel: "",
          representative: "",
        },
    is_block: json.is_block ?? 0,
    email: json.email ?? "",
    password: json.password ?? "",
    full_name: json.full_name ?? "",
    full_name_furigana: json.full_name_furigana ?? "",
    gender: json.gender ?? "",
    zipcode: json.zipcode ?? "",
    birthday: json.birthday ?? "1972-01-01",
    address: json.address ?? "",
    tel1: json.tel1 ?? "",
    tel2: json.tel2 ?? "",
    loginId: json.loginId ?? "",
    status: json.status ?? "",
    is_abnormalities: json.is_abnormalities ?? 0,
    is_pregnancy: json.is_pregnancy ?? 0,
    orthodontist_type: parseInt(json.orthodontist_type ?? "0") ?? 0,
    updateOnly: true,
    color: color,
    special_code: json.special_code ?? "",
    qr_code: json.qr_code ?? "",
    user_plan: json.user_plan,
    matched_name_images: json.matched_name_images ?? [],
  };
}
