import React from "react";
import { connect } from "react-redux";
import { ReactSVG } from "react-svg";
import { Dispatch } from "redux";
import { FileManagementState } from "src/base/Enums";
import { DI, Injector } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import { ConfirmDeletePopup } from "src/components/popup/confirmDeletePopup";
import { Strings } from "src/constants";
import { FileManagementType, IFile } from "src/features/fileManagement/data/models/file";
import { IFolder } from "src/features/fileManagement/data/models/folder";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals, Routes, history } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import { IRootState } from "src/store";
import { Assets } from "src/utils/assets";
import { RemoteFileManagementContainer } from "../../container/RemoteFileManagementContainer";
import DropdownPortal from "../components/DropdownPortal";
import FileComponent from "../components/FileComponent";
import FolderComponent from "../components/FolderComponent";
import StoragePercentComponent from "../components/StoragePercent";
import CreateFolderPopup from "../popup/CreateFolderPopup";
import GetLinkFilePopup from "../popup/GetLinkFilePopup";
import ImportSelectPopup from "../popup/ImportSelectPopup";
import MoveIntoFolderPopup from "../popup/MoveIntoFolderPopup";
import RenamePopup from "../popup/RenamePopup";

export interface DropdownPosition {
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
}

interface FileManagementPageState {
  isShowFolderDropdown: boolean;
  isShowFileDropdown: boolean;
  dropdownPosition: DropdownPosition;
  breadcrumb: string[];
  itemSelected?: IFolder | IFile;
  filesSelected: IFile[];
  searchKey?: string;
}

class FileManagementPage extends React.Component<FileManagementPageType, FileManagementPageState> {
  dropdownRef;

  constructor(props: any) {
    super(props);
    this.state = {
      isShowFolderDropdown: false,
      isShowFileDropdown: false,
      dropdownPosition: { top: 0, right: 0, bottom: 0, left: 0 },
      breadcrumb: [],
      itemSelected: undefined,
      filesSelected: [],
      searchKey: undefined,
    };

    this.dropdownRef = React.createRef<HTMLUListElement>();
  }

  componentDidMount() {
    DI.get().injectRemoteFileManagementDependencies();
    this.props.onFetchFileManagement("");

    document.addEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside = (event: any) => {
    if (this.dropdownRef && !this.dropdownRef.current?.contains(event.target)) {
      this.handleHideDropdown();
    }
  };

  toggleDropdown = (type: string, dropdownPosition: DropdownPosition) => {
    this.setState(
      {
        dropdownPosition,
      },
      () => {
        if (type === "Folder")
          this.setState({
            isShowFolderDropdown: !this.state.isShowFolderDropdown,
          });
        else
          this.setState({
            isShowFileDropdown: !this.state.isShowFileDropdown,
          });
      }
    );
  };

  handleHideDropdown = () => {
    this.setState({
      isShowFolderDropdown: false,
      isShowFileDropdown: false,
    });
  };

  handleResetSelected = () => {
    this.setState({
      itemSelected: undefined,
      filesSelected: [],
    });
  };

  handleSelectItem = (item: IFolder | IFile) => {
    if (item.type === FileManagementType.FOLDER) this.setState({ itemSelected: item, filesSelected: [] });
    if (item.type === FileManagementType.FILE)
      this.setState({
        itemSelected: item,
        filesSelected: [item as IFile],
      });
  };

  handleMoveIntoFolder = (folder: IFolder) => {
    this.setState(
      {
        breadcrumb: folder.path.length === 0 ? [folder.name] : (folder.path + "/" + folder.name).split("/"),
      },
      () => {
        const breadcrumbPath = this.state.breadcrumb.join("/");

        this.props.onFetchFileManagement(breadcrumbPath).then(() => {
          this.handleResetSelected();
        });
      }
    );
  };

  handleSelectBreadcrumb = (idx: number) => {
    if (idx === -1) {
      this.setState({
        breadcrumb: [],
      });
      this.handleResetSelected();
      this.props.onFetchFileManagement("");
    } else {
      const breadcrumbSlice = this.state.breadcrumb.slice(0, idx + 1);
      const breadcrumbPath = breadcrumbSlice.join("/");

      this.setState({ breadcrumb: breadcrumbSlice });
      this.props.onFetchFileManagement(breadcrumbPath).then(() => {
        this.handleResetSelected();
      });
    }
  };

  handleSelectFile = (file: IFile) => {
    this.setState(
      {
        itemSelected: undefined,
      },
      () => {
        if (this.state.filesSelected.includes(file))
          return this.setState((prevState) => ({
            filesSelected: prevState.filesSelected.filter((el) => el !== file),
          }));

        this.setState((prevState) => ({
          filesSelected: [...prevState.filesSelected, file],
        }));
      }
    );
  };

  handleSelectDropdownItem = (modal: Modals) => {
    this.handleHideDropdown();
    this.props.changeModal(modal);
  };

  handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchKey: event.target.value });
  };

  handleSubmitSearch = (event: any) => {
    event.preventDefault();

    this.props.onFetchFileManagement(this.state.breadcrumb.join("/"), this.state.searchKey);
  };

  handleDownloadFiles = () => {
    this.handleHideDropdown();

    this.props.onDownloadFiles(this.state.breadcrumb.join("/"), this.state.filesSelected).then((fileData?: Blob) => {
      const fileUrl = URL.createObjectURL(fileData!);
      const fileName = fileData?.type === "application/zip" ? "Liex Files" : this.state.filesSelected[0].name;
      const fileType = fileData?.type === "application/zip" ? "zip" : this.state.filesSelected[0].extension;

      const a = document.createElement("a");
      a.setAttribute("href", fileUrl);
      a.setAttribute("download", `${fileName}.${fileType}`);

      document.body.appendChild(a);
      a.click();

      document.body.removeChild(a);
      URL.revokeObjectURL(fileUrl);
    });
  };

  handleDownloadFolder = () => {
    this.handleHideDropdown();

    this.props
      .onDownloadFolder(this.state.breadcrumb.join("/"), this.state.itemSelected!)
      .then((folderDownloadSrc?: string) => {
        const link = document.createElement("a");

        link.setAttribute("href", folderDownloadSrc!);
        link.setAttribute("download", this.state.itemSelected?.name!);

        link.click();
      });
  };

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  filesToUploadArr = () => {
    const filesToUploadArr = [];

    for (const [key, value] of this.props.filesToUpload) {
      if (key.indexOf("file") > -1) filesToUploadArr.push(value);
      if (key.indexOf("folder") > -1) {
        (value as File[]).forEach((file: File) => {
          filesToUploadArr.push(file);
        });
      }
    }

    return filesToUploadArr;
  };

  handleImportFile = () => {
    if (this.props.filesToUpload.size === 0) return;

    this.props
      .onImport(this.state.breadcrumb.join("/"), this.filesToUploadArr(), "rewrite")
      .then(() => this.props.onFetchFileManagement(this.state.breadcrumb.join("/")));
  };

  render() {
    return (
      <MainLayout
        isLoading={this.props.fileManagementState === FileManagementState.fetchFileManagement}
        className="secondpage navstate_show file-management animate-item"
        subClassName="file-management"
      >
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <div className="search-bar-container">
                <div className="breadcrumb">
                  {this.state.breadcrumb.length > 0 ? (
                    <div
                      className="breadcrumb-back"
                      onClick={() => this.handleSelectBreadcrumb(this.state.breadcrumb.length - 2)}
                    >
                      <ReactSVG src={Assets.back} fill="white" />
                    </div>
                  ) : null}
                  <span onClick={() => this.handleSelectBreadcrumb(-1)}>{Strings.FILE_MANAGEMENT}</span>
                  {this.state.breadcrumb.map((folder, idx) => {
                    return (
                      <span key={idx} onClick={() => this.handleSelectBreadcrumb(idx)}>
                        {folder}
                      </span>
                    );
                  })}
                </div>
                {this.props.me.user_plan === "master" && (
                  <form className="search-bar" onSubmit={this.handleSubmitSearch} autoComplete="off">
                    <input
                      id="searchInput"
                      className="form-control search-input"
                      value={this.state.searchKey ?? ""}
                      onChange={this.handleSearch}
                    />
                    <label className="search-input-glass" htmlFor="searchInput">
                      <img
                        src={Assets.glass}
                        alt={Strings.FILE_MANAGEMENT}
                        onClick={(event) => (this.state.searchKey ? this.handleSubmitSearch(event) : {})}
                      />
                    </label>
                  </form>
                )}
              </div>
            </div>
            {this.props.me.user_plan !== "master" && (
              <div
                className="card-body"
                style={{
                  height: "75vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <p style={{ fontSize: "1.5rem", letterSpacing: "0.25rem", marginBottom: 0 }}>
                  こちらは有料サービスになります。
                </p>
                <p style={{ fontSize: "1.5rem", letterSpacing: "0.25rem", marginBottom: "3rem" }}>
                  月額+3,300円（税込）/月
                </p>
                <MainButton
                  className="btn btn-primary"
                  onSubmit={() => history.replace(Routes.SUBSCRIPTION_MANAGEMENT)}
                  enabled
                  title="お申込みはこちら"
                  style={{ fontSize: "1.5rem", letterSpacing: "0.25rem" }}
                />
              </div>
            )}
            {this.props.me.user_plan === "master" && (
              <div className="card-body">
                <div className="btn-group2">
                  <MainButton
                    className="btn btn-primary btn-main btn-upload-file"
                    onSubmit={() => this.props.changeModal(Modals.FILE_MANAGEMENT_IMPORT_SELECT)}
                    enabled
                    title="アップロード"
                  />
                  <MainButton
                    className="btn btn-primary btn-main btn-create-folder"
                    onSubmit={() => this.props.changeModal(Modals.FILE_MANAGEMENT_CREATE_FOLDER)}
                    enabled
                    title="フォルダ作成"
                  />
                  <StoragePercentComponent />
                </div>
                <div className="folder-viewer">
                  <h5 className="text_bold">フォルダ</h5>
                  <div className="folder-container">
                    {this.props.fileManagementData.folders.map((folder, idx) => {
                      const isSelected = this.state.itemSelected === folder;

                      return (
                        <FolderComponent
                          key={idx}
                          folder={folder}
                          toggleDropdown={(dropdownPosition: DropdownPosition) =>
                            this.toggleDropdown("Folder", dropdownPosition)
                          }
                          handleSelectItem={this.handleSelectItem}
                          handleMoveIntoFolder={this.handleMoveIntoFolder}
                          isSelected={isSelected}
                        />
                      );
                    })}
                  </div>
                  <DropdownPortal
                    isShowing={this.state.isShowFolderDropdown}
                    dropdownRef={this.dropdownRef}
                    dropdownPosition={this.state.dropdownPosition}
                  >
                    <li onClick={this.handleDownloadFolder}>
                      <img src={Assets.download2} alt="" />
                      ダウンロード
                    </li>
                    <li onClick={() => this.handleSelectDropdownItem(Modals.FILE_MANAGEMENT_MOVE_INTO_FOLDER)}>
                      <img src={Assets.folderMove} alt="" />
                      指定の場所へ移動
                    </li>
                    <li onClick={() => this.handleSelectDropdownItem(Modals.FILE_MANAGEMENT_RENAME)}>
                      <img src={Assets.penRename} alt="" />
                      名前の変更
                    </li>
                    <li onClick={() => this.handleSelectDropdownItem(Modals.CONFIRM_REMOVE)}>
                      <img src={Assets.trashCan} alt="" />
                      削除
                    </li>
                  </DropdownPortal>
                </div>
                <div className="file-viewer">
                  <div className="file-action">
                    <h5 className="text_bold">ファイル</h5>
                    <div className={`file-action-container ${this.state.filesSelected.length > 0 ? "show" : ""}`}>
                      <img src={Assets.download2} onClick={this.handleDownloadFiles} alt="" />
                      <img
                        src={Assets.trashCan}
                        alt=""
                        onClick={() => this.handleSelectDropdownItem(Modals.CONFIRM_REMOVE)}
                      />
                      <img
                        src={Assets.folderMove}
                        alt=""
                        onClick={() => this.handleSelectDropdownItem(Modals.FILE_MANAGEMENT_MOVE_INTO_FOLDER)}
                      />
                    </div>
                  </div>
                  <div className="grid-container mb00 pb00 pt00 mt-4">
                    {this.props.fileManagementData.files.map((file, idx) => {
                      const isSelected = this.state.filesSelected.includes(file);

                      return (
                        <FileComponent
                          key={idx}
                          file={file}
                          toggleDropdown={(dropdownPosition: DropdownPosition) =>
                            this.toggleDropdown("File", dropdownPosition)
                          }
                          handleSelectItem={this.handleSelectItem}
                          handleSelectFile={this.handleSelectFile}
                          isSelected={isSelected}
                        />
                      );
                    })}
                  </div>
                  <DropdownPortal
                    isShowing={this.state.isShowFileDropdown}
                    dropdownRef={this.dropdownRef}
                    dropdownPosition={this.state.dropdownPosition}
                  >
                    <li onClick={() => this.handleSelectDropdownItem(Modals.FILE_MANAGEMENT_GET_FILE_LINK)}>
                      <img src={Assets.getLink} alt="" />
                      リンクを取得
                    </li>
                    <li onClick={this.handleDownloadFiles}>
                      <img src={Assets.download2} alt="" />
                      ダウンロード
                    </li>
                    <li onClick={() => this.handleSelectDropdownItem(Modals.FILE_MANAGEMENT_MOVE_INTO_FOLDER)}>
                      <img src={Assets.folderMove} alt="" />
                      指定の場所へ移動
                    </li>
                    <li onClick={() => this.handleSelectDropdownItem(Modals.FILE_MANAGEMENT_RENAME)}>
                      <img src={Assets.penRename} alt="" />
                      名前の変更
                    </li>
                    <li onClick={() => this.handleSelectDropdownItem(Modals.CONFIRM_REMOVE)}>
                      <img src={Assets.trashCan} alt="" />
                      削除
                    </li>
                  </DropdownPortal>
                </div>
              </div>
            )}
          </div>
        </div>
        <ImportSelectPopup onImportFile={this.handleImportFile} />
        <CreateFolderPopup breadcrumbPath={this.state.breadcrumb.join("/")} />
        <GetLinkFilePopup itemSelected={this.state.itemSelected} />
        {this.props.currentModal === Modals.FILE_MANAGEMENT_MOVE_INTO_FOLDER ? (
          <MoveIntoFolderPopup
            breadcrumbPath={this.state.breadcrumb.join("/")}
            itemSelected={this.state.itemSelected}
            filesSelected={this.state.filesSelected}
            handleResetSelected={this.handleResetSelected}
          />
        ) : null}
        <RenamePopup breadcrumbPath={this.state.breadcrumb.join("/")} itemSelected={this.state.itemSelected} />
        <ConfirmDeletePopup
          changeModal={this.props.changeModal}
          currentModal={this.props.currentModal}
          onDelete={() => {
            const breadcrumbPath = this.state.breadcrumb.join("/");
            const itemToDelete =
              this.state.filesSelected.length === 0 ? this.state.itemSelected : this.state.filesSelected;

            this.props.onDelete(breadcrumbPath, itemToDelete).then(() => {
              this.props.changeModal(Modals.NONE);

              this.props.onFetchFileManagement(breadcrumbPath).then(() => {
                this.handleResetSelected();
              });
            });
          }}
        />
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ app, account, fileManagement }: IRootState) => {
  const { currentModal } = app;
  const { me } = account;
  const { fileManagementState, fileManagementData, filesToUpload } = fileManagement;

  return {
    currentModal,
    me,
    fileManagementState,
    fileManagementData,
    filesToUpload,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
    onFetchFileManagement: (breadcrumbPath: string, search?: string) =>
      Injector.get()
        .find<RemoteFileManagementContainer>("RemoteFileManagementContainer")
        .onFetchFileManagement(dispatch, breadcrumbPath, search),
    onDownloadFiles: (breadcrumbPath: string, files: IFile[]) =>
      Injector.get()
        .find<RemoteFileManagementContainer>("RemoteFileManagementContainer")
        .onDownloadFiles(dispatch, breadcrumbPath, files),
    onDownloadFolder: (breadcrumbPath: string, folder: IFolder) =>
      Injector.get()
        .find<RemoteFileManagementContainer>("RemoteFileManagementContainer")
        .onDownloadFolder(dispatch, breadcrumbPath, folder),
    onDelete: (breadcrumbPath: string, itemToDelete?: IFolder | IFile | IFile[]) =>
      Injector.get()
        .find<RemoteFileManagementContainer>("RemoteFileManagementContainer")
        .onDelete(dispatch, breadcrumbPath, itemToDelete),
    onImport: (breadcrumbPath: string, filesToUpload: any, action?: string) =>
      Injector.get()
        .find<RemoteFileManagementContainer>("RemoteFileManagementContainer")
        .onImport(dispatch, breadcrumbPath, filesToUpload, action),
  };
};

type FileManagementPageType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(FileManagementPage);
