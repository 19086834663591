import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DI } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import Modal from "src/components/modal/modal";
import { IFileToUpload } from "src/features/fileManagement/data/models/file";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers";
import { IRootState } from "src/store";
import { resetFilesToUploadFileManagement, updateFilesToUploadFileManagement } from "../../store";

declare module "react" {
  interface InputHTMLAttributes<T> extends HTMLAttributes<T> {
    directory?: string;
    odirectory?: string;
    msdirectory?: string;
    mozdirectory?: string;
    webkitdirectory?: string;
  }
}

interface ImportSelectPopupProps {
  onImportFile: () => void;
}

interface ImportSelectPopupState {}

class ImportSelectPopup extends React.Component<ImportSelectPopupType, ImportSelectPopupState> {
  componentDidMount() {
    DI.get().injectRemoteFileManagementDependencies();
  }

  handleImportFiles = (event: any, key: string) => {
    if (event.target.files && event.target.files[0]) {
      let filesToUpload: IFileToUpload = { key: "", value: undefined };

      if (key.indexOf("file") > -1)
        filesToUpload = {
          key,
          value: event.target.files[0],
        };
      if (key.indexOf("folder") > -1)
        filesToUpload = {
          key,
          value: Array.from(event.target.files),
        };

      this.props.onUpdateFilesToUpload(filesToUpload);
      this.forceUpdate();
    }
  };

  render() {
    return (
      <Modal
        id="file-management-create-folder-popup"
        dialogExtraClassName="file-management-popup"
        isShow={this.props.currentModal === Modals.FILE_MANAGEMENT_IMPORT_SELECT}
      >
        <h4 className="text_center overflow">
          <strong>アップロード</strong>
        </h4>
        <div className="btn-group2" style={{ flexDirection: "column" }}>
          {[1, 2, 3].map((index) => (
            <div key={index} className="upload-file-container">
              <label className="btn btn-primary btn-upload-file" htmlFor={`btn-upload-file-${index}`}>
                ファイル {index}
              </label>
              <input
                id={`btn-upload-file-${index}`}
                className="btn-upload-file-input"
                type="file"
                value={""}
                onChange={(event) => this.handleImportFiles(event, "file-" + index)}
              />
              <div className="upload-file-path">
                <span>{(this.props.filesToUpload.get("file-" + index) as File)?.name ?? ""}</span>
              </div>
            </div>
          ))}
          {[1, 2, 3].map((index) => {
            return (
              <div key={index} className="upload-file-container">
                <label className="btn btn-primary btn-upload-folder" htmlFor={`btn-upload-folder-${index}`}>
                  フォルダ {index}
                </label>
                <input
                  id={`btn-upload-folder-${index}`}
                  className="btn-upload-folder-input"
                  type="file"
                  directory=""
                  odirectory=""
                  msdirectory=""
                  mozdirectory=""
                  webkitdirectory=""
                  value={""}
                  onChange={(event) => this.handleImportFiles(event, "folder-" + index)}
                />
                <div className="upload-folder-path">
                  <span>
                    {(this.props.filesToUpload.get("folder-" + index) as File[])?.map((folder) => folder.name + ", ")}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        <div className="text_center mt-4 btn-group2" style={{ flexDirection: "row" }}>
          <MainButton
            className="btn btn-secondary"
            title="リセット"
            onSubmit={() => this.props.onResetFilesToUpload()}
            enabled
            style={{ flex: "0.25 1" }}
          />
          <MainButton
            className="btn btn-primary"
            title="更新"
            onSubmit={this.props.onImportFile}
            enabled
            style={{ flex: "1 1" }}
          />
        </div>
        <div className="mt-4">
          <p style={{ color: "red", marginBottom: 0 }}>※同名のファイル・フォルダがある場合、内容のみが更新されます。</p>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ app, fileManagement }: IRootState) => {
  const { currentModal } = app;
  const { filesToUpload } = fileManagement;

  return {
    currentModal,
    filesToUpload,
  };
};
const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
    onUpdateFilesToUpload: (fileToUpload: IFileToUpload) => dispatch(updateFilesToUploadFileManagement(fileToUpload)),
    onResetFilesToUpload: () => dispatch(resetFilesToUploadFileManagement()),
  };
};

type ImportSelectPopupType = ImportSelectPopupProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(ImportSelectPopup);
