import { AttributeName, ValidationType } from "src/base/Enums";

export interface IValidaion {
  type: ValidationType;
  validValues?: Array<any>;
}

export interface MedicalField {
  name: string;
  type?: string;
  label?: string;
  value?: any;
  mutiple?: boolean;
  options?: Array<any>;
}

export interface Attribute {
  id: number;
  type: string;
  name: string;
  displayName: string;
  meta: Array<MedicalField>;
  validation: Array<IValidaion>;
  parentId?: number | null;
}

export function mAttributeFromJson(json: any): Attribute {
  return {
    id: json.id ?? 0,
    type: json.type ?? "",
    name: json.name ?? "",
    displayName: json.display_name ?? "",
    meta: json.meta ? JSON.parse(json.meta) : [],
    validation: json.validation
      ? JSON.parse(json.validation).map((item: string) => {
          {
            const arrDecode = item.split(":");
            if (arrDecode.length > 1) {
              return {
                type: arrDecode[0],
                validValues: arrDecode[1].split(","),
              };
            }
            return {
              type: item,
            };
          }
        })
      : [],
    parentId: json.parentId ?? null,
  };
}

export function getMetaData(
  attributeName: AttributeName,
  attributes: Array<Attribute>
) {
  return attributes.find((item) => item.name == attributeName)?.meta ?? [];
}

export function getAttributeName(
  attributeName: AttributeName,
  attributes: Array<Attribute>
) {
  return (
    attributes.find((item) => item.name == attributeName)?.displayName ?? ""
  );
}
