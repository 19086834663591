import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AccountStatus, OrthodontistState, OrthodontistType, PatientsStatus } from "src/base/Enums";
import { Injector } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import { ColorPlaceHolder } from "src/components/colorPlaceholder";
import { InputGroup } from "src/components/inputGroup";
import { ConfirmDeletePopup } from "src/components/popup/confirmDeletePopup";
import { Table } from "src/components/table";
import { Strings, patientStatus } from "src/constants";
import { IUser } from "src/features/account/data/models/user";
import { OrthodontistContainer } from "src/features/orthodontist/presenter/container/orthodontistContainer";
import {
  orthodontistsPaginationUpdated,
  selectedOrthodontistChanged,
} from "src/features/orthodontist/presenter/store/orthodontist";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals, Routes, history } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import { PaginationComponent } from "src/shared/components/pagination";
import { IRootState } from "src/store";

interface DentistState {
  code: string;
  name: string;
  orthodontistType: OrthodontistType;
  accountStatus: AccountStatus;
  specialStatus: PatientsStatus;
  removeOrthodontistId: string;
  orthodontistId: string;
}

class DentistPage extends React.Component<DentistType, DentistState> {
  constructor(props: any) {
    super(props);
    this.state = {
      code: "",
      name: "",
      accountStatus: AccountStatus.all,
      orthodontistType: OrthodontistType.all,
      specialStatus: PatientsStatus.all,
      removeOrthodontistId: "",
      orthodontistId: "",
    };
  }

  componentDidMount() {
    this.props.paginationUpdated("current_page", 1);
    this.props.fetchOrthodontists(
      this.state.code,
      this.state.name,
      Object.values(OrthodontistType).indexOf(this.state.orthodontistType),
      Object.values(AccountStatus).indexOf(this.state.accountStatus),
      1,
      Object.values(PatientsStatus).indexOf(this.state.specialStatus)
    );
  }

  buildDentistsTable = () => {
    return (
      <Table
        className="dataTable-container-scroll dataTable-container mt-4"
        subClassName="dataTable-table"
        isLoading={this.props.currentState === OrthodontistState.isFetchingOrthodontists}
        headers={[
          {
            fields: [
              { html: Strings.COLOR },
              {
                html: (
                  <div className="select-style select-styleStatus">
                    <select
                      defaultValue="0"
                      className="form-control"
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          specialStatus: Object.values(PatientsStatus)[parseInt(e.target.value) ?? 0],
                        });
                        this.props.fetchOrthodontists(
                          this.state.code,
                          this.state.name,
                          Object.values(OrthodontistType).indexOf(this.state.orthodontistType),
                          Object.values(AccountStatus).indexOf(this.state.accountStatus),
                          1,
                          parseInt(e.target.value) ?? 0
                        );
                      }}
                    >
                      {Object.values(PatientsStatus).map((value, index) => {
                        return (
                          <option
                            key={index}
                            selected={Object.values(PatientsStatus).indexOf(this.state.specialStatus) === index}
                            value={index}
                          >
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ),
              },
              // {
              //   html: (
              //     <InputGroup
              //       className="iprup2 iprup fsc"
              //       inputPlaceHolder={Strings.DENTIST_NUMBER}
              //       buttonClassName="btn-success"
              //       buttonType="button"
              //       value={this.state.code}
              //       buttonText={Strings.SEARCH}
              //       onValueChanged={(e) => {
              //         this.setState({ ...this.state, code: e.target.value })
              //         if (!e.target.value) {
              //           this.props.fetchOrthodontists("", this.state.name, Object.values(OrthodontistType).indexOf(this.state.orthodontistType), Object.values(AccountStatus).indexOf(this.state.accountStatus), this.props.orthodontists.current_page)
              //         }
              //       }}
              //       onButtonClick={() => {
              //         this.props.fetchOrthodontists(this.state.code, this.state.name, Object.values(OrthodontistType).indexOf(this.state.orthodontistType), Object.values(AccountStatus).indexOf(this.state.accountStatus), this.props.orthodontists.current_page)

              //       }}
              //     ></InputGroup>
              //   )
              // },
              {
                html: (
                  <InputGroup
                    className="iprup3 iprup"
                    inputPlaceHolder={Strings.SHARE_FIRST}
                    buttonClassName="btn-success"
                    buttonType="button"
                    value={this.state.name}
                    buttonText={Strings.SEARCH}
                    onValueChanged={(e) => {
                      this.setState({ ...this.state, name: e.target.value });
                      if (!e.target.value) {
                        this.props.fetchOrthodontists(
                          this.state.code,
                          "",
                          Object.values(OrthodontistType).indexOf(this.state.orthodontistType),
                          Object.values(AccountStatus).indexOf(this.state.accountStatus),
                          1,
                          Object.values(PatientsStatus).indexOf(this.state.specialStatus)
                        );
                      }
                    }}
                    onButtonClick={() => {
                      this.props.fetchOrthodontists(
                        this.state.code,
                        this.state.name,
                        Object.values(OrthodontistType).indexOf(this.state.orthodontistType),
                        Object.values(AccountStatus).indexOf(this.state.accountStatus),
                        1,
                        Object.values(PatientsStatus).indexOf(this.state.specialStatus)
                      );
                    }}
                  ></InputGroup>
                ),
              },
              { html: <div className="tdemail">{Strings.EMAIL_ADDRESS}</div> },
              {
                html: (
                  <div className="select-style select-styleCate">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          orthodontistType: Object.values(OrthodontistType)[parseInt(e.target.value) ?? 0],
                        });
                        this.props.fetchOrthodontists(
                          this.state.code,
                          this.state.name,
                          parseInt(e.target.value) ?? 0,
                          Object.values(AccountStatus).indexOf(this.state.accountStatus),
                          1,
                          Object.values(PatientsStatus).indexOf(this.state.specialStatus)
                        );
                      }}
                    >
                      {Object.values(OrthodontistType).map((value, index) => {
                        return (
                          <option
                            key={index}
                            selected={Object.values(OrthodontistType).indexOf(this.state.orthodontistType) === index}
                            value={index}
                          >
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ),
              },
              {
                html: (
                  <div className="select-style select-styleSpecial">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          accountStatus: Object.values(AccountStatus)[parseInt(e.target.value) ?? 0],
                        });
                        this.props.fetchOrthodontists(
                          this.state.code,
                          this.state.name,
                          Object.values(OrthodontistType).indexOf(this.state.orthodontistType),
                          parseInt(e.target.value) ?? 0,
                          1,
                          Object.values(PatientsStatus).indexOf(this.state.specialStatus)
                        );
                      }}
                    >
                      {Object.values(AccountStatus).map((value, index) => {
                        return (
                          <option
                            key={index}
                            selected={Object.values(AccountStatus).indexOf(this.state.accountStatus) === index}
                            value={index}
                          >
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ),
              },
              { html: Strings.ACTION },
            ],
          },
        ]}
        rows={[
          ...this.props.orthodontists.data.map((orthodontist) => {
            return {
              fields: [
                {
                  html: (
                    <div className="color-column">
                      <ColorPlaceHolder
                        color={
                          orthodontist.color && orthodontist.color.shouldDisplayThisColor && orthodontist.color.hex
                        }
                      />
                    </div>
                  ),
                },
                // { html: (orthodontist.clinic && orthodontist.clinic.medical_institution ? orthodontist.clinic.medical_institution : "") + orthodontist.code },
                {
                  html: (
                    <div className={orthodontist.special_status === "valid" ? "text-valid" : "text-invalid"}>
                      {orthodontist.special_status === "valid" ? "ID/PW送信済" : "ID/PW未送信"}
                    </div>
                  ),
                },
                { html: orthodontist.full_name },
                { html: orthodontist.email },
                { html: Object.values(OrthodontistType)[orthodontist.orthodontist_type] },
                {
                  html: (
                    <MainButton
                      title={patientStatus[orthodontist.status]}
                      enabled={true}
                      submitting={
                        this.props.selectedOrthodontist.id === orthodontist.id &&
                        this.props.currentState === OrthodontistState.isUpdatingOrthodontistStatus
                      }
                      className={`btn tag ${orthodontist.status.indexOf("invalid") > -1 ? "orange-tag" : "yellow-tag"}`}
                      type="a"
                      loadingSize={20}
                      onSubmit={() => {
                        this.props.selectOrthodontist(orthodontist);
                        this.props.updateOrthodontistStatus(orthodontist);
                      }}
                      style={{ width: "auto" }}
                    />
                  ),
                },
                {
                  html: (
                    <div className="fcc">
                      <MainButton
                        title="ID/PW送信"
                        enabled={true}
                        className={
                          orthodontist.special_status === "valid" ? "btn btn-dark hide" : "btn btn-dark btnsend"
                        }
                        type="a"
                        onSubmit={() => {
                          this.props.sendOrthodontist(orthodontist);
                        }}
                      />
                      <MainButton
                        className="btn btn-primary"
                        type="a"
                        enabled
                        title={Strings.EDIT}
                        onSubmit={() => {
                          history.replace({
                            pathname: Routes.DENTIST_MANAGEMENT_DETAIL,
                            search: `?orthodontistId=${orthodontist.id}`,
                          });
                        }}
                      />
                      <MainButton
                        title={Strings.DELETE}
                        enabled={true}
                        submitting={
                          this.props.selectedOrthodontist.id === orthodontist.id &&
                          this.props.currentState === OrthodontistState.isDeletingOrthodontist
                        }
                        className="btn btn-danger"
                        type="a"
                        loadingSize={20}
                        onSubmit={() => {
                          this.setState({
                            ...this.state,
                            removeOrthodontistId: orthodontist.id,
                          });
                          this.props.selectOrthodontist(orthodontist);
                          this.props.changeModal(Modals.CONFIRM_REMOVE);
                        }}
                      />
                    </div>
                  ),
                },
              ],
            };
          }),
        ]}
      ></Table>
    );
  };

  render() {
    return (
      <MainLayout
        isLoading={this.props.currentState === OrthodontistState.isFetchingOrthodontists}
        className="secondpage navstate_show page-morthodontist animate-item"
        subClassName="p-morthodontist"
      >
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">{Strings.DENTIST_MANAGEMENT} 1111</div>
            <div className="card-body">
              <div className="btn-group2">
                <MainButton
                  type="a"
                  className="btn btn-primary btn-main"
                  title={Strings.CREATE}
                  enabled
                  onSubmit={() => {
                    history.replace(Routes.DENTIST_MANAGEMENT_CREATE);
                  }}
                />
              </div>
              {this.buildDentistsTable()}
              <div
                className={
                  this.props.orthodontists.data.length === 10 || this.props.orthodontists.last_page > 1
                    ? "showPaginationComponent mt30"
                    : "hide"
                }
              >
                <PaginationComponent
                  className=""
                  currentPage={this.props.orthodontists.current_page}
                  totalPage={this.props.orthodontists.last_page}
                  onClick={(page) => {
                    this.props.fetchOrthodontists(
                      this.state.code,
                      this.state.name,
                      Object.values(OrthodontistType).indexOf(this.state.orthodontistType),
                      Object.values(AccountStatus).indexOf(this.state.accountStatus),
                      page,
                      Object.values(PatientsStatus).indexOf(this.state.specialStatus)
                    );
                    this.props.paginationUpdated("current_page", page);
                  }}
                ></PaginationComponent>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDeletePopup
          changeModal={this.props.changeModal}
          currentModal={this.props.currentModal}
          onDelete={() => {
            this.props.deleteOrthodontist(this.state.removeOrthodontistId, () => {
              this.props.fetchOrthodontists(
                this.state.code,
                this.state.name,
                Object.values(OrthodontistType).indexOf(this.state.orthodontistType),
                Object.values(AccountStatus).indexOf(this.state.accountStatus),
                this.props.orthodontists.current_page,
                Object.values(PatientsStatus).indexOf(this.state.specialStatus)
              );
            });
          }}
        />
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ orthodontist, app }: IRootState) => {
  const { orthodontists, currentState, selectedOrthodontist } = orthodontist;
  const { currentModal } = app;
  return {
    orthodontists,
    currentState,
    currentModal,
    selectedOrthodontist,
  };
};
const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    fetchOrthodontists: (
      code: string,
      fullName: string,
      orthodontist_type: number,
      status: number,
      page: number,
      specialStatus: number,
      limit: number = 10
    ) =>
      Injector.get()
        .find<OrthodontistContainer>("OrthodontistContainer")
        .onFetchOrthodontists(dispatch, code, fullName, orthodontist_type, specialStatus, page, limit, status),
    paginationUpdated: (key: string, value: any) => dispatch(orthodontistsPaginationUpdated(key, value)),
    deleteOrthodontist: (id: string, onSuccess: () => void) =>
      Injector.get().find<OrthodontistContainer>("OrthodontistContainer").onDeleteOrthodontist(dispatch, id, onSuccess),
    updateOrthodontistStatus: (orthodontist: IUser) =>
      Injector.get()
        .find<OrthodontistContainer>("OrthodontistContainer")
        .onUpdateOrthodontistStatus(dispatch, orthodontist),
    selectOrthodontist: (orthodontist: IUser) => dispatch(selectedOrthodontistChanged(orthodontist)),
    sendOrthodontist: (orthodontist: IUser) =>
      Injector.get()
        .find<OrthodontistContainer>("OrthodontistContainer")
        .onSendEmailToOrthodontist(dispatch, orthodontist),
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
  };
};

type DentistType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(DentistPage);
