import React from "react";
import { Strings } from "src/constants";
import { Modals } from "src/helpers";
import { MainButton } from "../button/mainButton";
import Modal from "../modal/modal";

export function ConfirmDeletePopup(props: any) {
  return (
    <Modal
      className={`${props.currentModal === Modals.CONFIRM_REMOVE ? "flexible" : ""}`}
      dialogExtraClassName="confirm-delete"
      isShow={props.currentModal === Modals.CONFIRM_REMOVE}
      footerChildren={
        <React.Fragment>
          <MainButton
            className="btn btn-primary confirmChange close"
            onSubmit={() => props.changeModal(Modals.NONE)}
            title={Strings.NO}
            enabled
          />
          <MainButton
            className="btn btn-danger confirmChange close"
            onSubmit={() => {
              props.onDelete();
              props.changeModal(Modals.NONE);
            }}
            title={Strings.YES}
            enabled
          />
        </React.Fragment>
      }
    >
      <h4 className="text_bold mb20">{Strings.CONFIRM}</h4>
      <h5>{Strings.CONFIRM_DELETE_1}</h5>
    </Modal>
  );
}
