import { AccountState } from "src/base/Enums";
import { action } from "typesafe-actions";
import { IUser } from "../../data/models/user";
import { AccountType } from "./types";

export function accountChanged(account: IUser) {
    return action(AccountType.ACCOUNT_CHANGED, { account })
}

export function accountDataChanged(key: string, value: any) {
    return action(AccountType.ACCOUNT_DATA_CHANGED, { key, value })
}

export function accountStateChanged(accountState: AccountState) {
    return action(AccountType.ACCOUNT_STATE_CHANGED, { accountState })
}