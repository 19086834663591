import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IUser } from "src/features/account/data/models/user";
import { IPatientRepository } from "../../data/repositories/patientRepository";

export class AddPatient extends UseCase<AddPatientParams, boolean>{
    private repository: IPatientRepository
    constructor(repository: IPatientRepository) {
        super()
        this.repository = repository
    }

    call(params: AddPatientParams): Promise<Either<Failure, boolean>> {
        return this.repository.addPatient(params.user)
    }
}

export class AddPatientParams {
    user: IUser
    constructor(user: IUser) {
        this.user = user
    }
}