import { Either } from "src/base/Either"
import { Failure } from "src/base/Failure"
import { UseCase } from "src/base/UseCase"
import { IMedicalRecord } from "../../data/models/medicalRecord"
import { IMedicalRecordRepository } from "../../data/repositories/medicalRecordRepository"

export class DuplicateMedicalRecord extends UseCase<DuplicateMedicalRecordParams, void>{
    private repository: IMedicalRecordRepository
    constructor(repository: IMedicalRecordRepository) {
        super()
        this.repository = repository
    }

    call(params: DuplicateMedicalRecordParams): Promise<Either<Failure, void>> {
        return this.repository.duplicateMedicalRecord(params.id)
    }

}

export class DuplicateMedicalRecordParams {
    id: string
    constructor(id: string) {
        this.id = id
    }
}