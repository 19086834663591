import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { io } from "socket.io-client";
import { isRight, match } from "src/base/Either";
import { PatientState } from "src/base/Enums";
import { Strings } from "src/constants";
import { IUser, defaultUser } from "src/features/account/data/models/user";
import { IAccountState } from "src/features/account/presenter/store";
import { Routes, history } from "src/helpers";
import { handleError } from "src/helpers/errorHandler";
import store from "src/store";
import { AddPatient, AddPatientParams } from "../../domain/usecases/addPatient";
import { DeletePatient, DeletePatientParams } from "../../domain/usecases/deletePatient";
import { FetchDownloadCsvFile } from "../../domain/usecases/fetchDownloadCsvFiles";
import { FetchPatientDetails, FetchPatientDetailsParams } from "../../domain/usecases/fetchPatientDetails";
import { FetchPatients, FetchPatientsParams } from "../../domain/usecases/fetchPatients";
import { ImportCsv, ImportCsvParams } from "../../domain/usecases/importCsv";
import { SendEmailToPatient, SendEmailToPatientParams } from "../../domain/usecases/sendEmail";
import { UpdatePatient, UpdatePatientParams } from "../../domain/usecases/updatePatient";
import { patientStateChanged, patientUpdated, patientsFetched, selectedPatientUpdated } from "../store";

export class PatientContainer {
  addPatient: AddPatient;
  updatePatient: UpdatePatient;
  deletePatient: DeletePatient;
  fetchPatients: FetchPatients;
  fetchPatientDetails: FetchPatientDetails;
  importCsv: ImportCsv;
  sendEmailToPatient: SendEmailToPatient;
  fetchDownloadCsvFile: FetchDownloadCsvFile;

  constructor(
    addPatient: AddPatient,
    updatePatient: UpdatePatient,
    deletePatient: DeletePatient,
    fetchPatients: FetchPatients,
    fetchPatientDetails: FetchPatientDetails,
    importCsv: ImportCsv,
    sendEmailToPatient: SendEmailToPatient,
    fetchDownloadCsvFile: FetchDownloadCsvFile
  ) {
    this.addPatient = addPatient;
    this.updatePatient = updatePatient;
    this.deletePatient = deletePatient;
    this.fetchPatients = fetchPatients;
    this.importCsv = importCsv;
    this.fetchPatientDetails = fetchPatientDetails;
    this.sendEmailToPatient = sendEmailToPatient;
    this.fetchDownloadCsvFile = fetchDownloadCsvFile;
  }

  async onSendEmailToOrthodontist(dispatch: Dispatch, patient: IUser) {
    let either = await this.sendEmailToPatient.call(new SendEmailToPatientParams(patient.id));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        dispatch(
          patientUpdated({
            ...patient,
            status: "valid",
          })
        );
        toast.success("認証メールの送信に成功しました。", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
  }

  async onFetchPatients(
    dispatch: Dispatch,
    code: string,
    fullName: string,
    query: string,
    page: number,
    status: number
  ) {
    dispatch(patientStateChanged(PatientState.fetchingPatients));
    let either = await this.fetchPatients.call(new FetchPatientsParams(code, fullName, query, page, status));
    if (isRight(either)) {
      dispatch(patientsFetched(either.value));
    }
    dispatch(patientStateChanged(PatientState.none));
  }

  async onAddPatient(dispatch: Dispatch, patient: IUser) {
    dispatch(patientStateChanged(PatientState.addingPatient));
    let either = await this.addPatient.call(new AddPatientParams(patient));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        history.replace(Routes.PATIENT_MANAGEMENT);
        toast.success(Strings.ADD_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
    dispatch(patientStateChanged(PatientState.none));
  }

  async onUpdatePatient(dispatch: Dispatch, patient: IUser) {
    dispatch(patientStateChanged(PatientState.updatingPatient));
    let either = await this.updatePatient.call(new UpdatePatientParams(patient));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        history.replace(Routes.PATIENT_MANAGEMENT);
        toast.success(Strings.UPDATE_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        dispatch(patientUpdated(patient));
      }
    );
    dispatch(patientStateChanged(PatientState.none));
  }

  async onDeletePatient(dispatch: Dispatch, id: string, onSuccess: () => void) {
    dispatch(patientStateChanged(PatientState.deletingPatient));
    let either = await this.deletePatient.call(new DeletePatientParams(id));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        toast.success(Strings.DELETE_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        onSuccess();
      }
    );
    dispatch(patientStateChanged(PatientState.none));
  }

  async onImportPatientsCsv(dispatch: Dispatch, csvBase64: string) {
    dispatch(patientStateChanged(PatientState.importingCsv));
    let either = await this.importCsv.call(new ImportCsvParams(csvBase64));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        history.replace(Routes.PATIENT_MANAGEMENT);
        toast.success(Strings.ADD_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
    dispatch(patientStateChanged(PatientState.none));
  }

  async onFetchDownloadFile() {
    let either = await this.fetchDownloadCsvFile.call();
    if (isRight(either)) {
      let url = either.value;
      let fileName = url.split("/")[url.split("/").length - 1];
      var link = document.createElement("a");
      link.download = fileName;
      link.href = url;
      document.body.appendChild(link);
      link.click();
      // document.body.removeChild(link);
    }
  }

  async onFetchPatientDetails(dispatch: Dispatch, patientId: any) {
    dispatch(patientStateChanged(PatientState.fetchingPatientDetails));
    let either = await this.fetchPatientDetails.call(new FetchPatientDetailsParams(patientId));
    match(
      either,
      (_) => {
        dispatch(selectedPatientUpdated({ ...defaultUser, isNotFound: true }));
      },
      (details) => {
        if (details) dispatch(selectedPatientUpdated({ ...details, isNotFound: false }));
        else dispatch(selectedPatientUpdated({ ...defaultUser, isNotFound: true }));
      }
    );
    dispatch(patientStateChanged(PatientState.none));
  }

  onUpdateCSV = async (
    dispatch: Dispatch,
    code: string,
    fullName: string,
    query: string,
    page: number,
    status: number
  ) => {
    dispatch(patientStateChanged(PatientState.fetchingPatients));

    let me = (store.getState().account as IAccountState).me;

    const socket = io(process.env.REACT_APP_API_SOCKET!);

    socket.on("connect", () => {
      socket.emit("clinic-join", me.clinic?.id);

      socket.on("clinic-upload-csv", (args: any) => {
        this.onFetchPatients(dispatch, code, fullName, query, page, status).then(() => {
          socket.disconnect();
          if (args.status === "success") toast.success(args.message);
          if (args.status === "error") toast.error(args.message);
        });
      });
    });
  };
}
