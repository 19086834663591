import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { isRight, match } from "src/base/Either";
import { RemoteFilesState } from "src/base/Enums";
import { Strings } from "src/constants";
import { IUser } from "src/features/account/data/models/user";
import { MFile } from "src/features/medicalRecord/data/models/file";
import { defaultRecord, uploadFileToS3 } from "src/features/medicalRecord/data/models/medicalRecord";
import { selectedMedicalRecordChanged } from "src/features/medicalRecord/presenter/store/medicalRecord";
import { FetchPatients, FetchPatientsParams } from "src/features/patient/domain/usecases/fetchPatients";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals, Routes, history } from "src/helpers";
import { handleError } from "src/helpers/errorHandler";
import { convertPlyFilesToCorrespondingStlFiles, convertToGlbFiles } from "src/utils/utils";
import { DeleteRemoteFile, DeleteRemoteFileParams } from "../../domain/usecases/deleteRemoteFile";
import { FetchRemoteFiles, FetchRemoteFilesParams } from "../../domain/usecases/fetchRemoteFiles";
import { LinkSelectedPatient, LinkSelectedPatientParams } from "../../domain/usecases/linkSelectedPatient";
import {
  clearAllSelectedFiles,
  patientsLoaded,
  patientsValueChanged,
  remoteFileDeleted,
  remoteFilesFetched,
  stateChanged,
} from "../store";

export class RemoteFilesDownloadContainer {
  fetchRemoteFiles: FetchRemoteFiles;
  deleleRemoteFile: DeleteRemoteFile;
  fetchPatients: FetchPatients;
  linkSelectedPatient: LinkSelectedPatient;
  constructor(
    fetchRemoteFiles: FetchRemoteFiles,
    deleteRemoteFiles: DeleteRemoteFile,
    fetchPatients: FetchPatients,
    linkSelectedPatient: LinkSelectedPatient
  ) {
    this.fetchRemoteFiles = fetchRemoteFiles;
    this.deleleRemoteFile = deleteRemoteFiles;
    this.fetchPatients = fetchPatients;
    this.linkSelectedPatient = linkSelectedPatient;
  }

  async onFetchPatients(dispatch: Dispatch, searchString: string, query: string, page: number) {
    if (page > 1) {
      dispatch(patientsValueChanged("isLoadingMore", true));
    } else {
      dispatch(patientsValueChanged("isFetching", true));
      dispatch(patientsValueChanged("data", []));
    }
    let either = await this.fetchPatients.call(new FetchPatientsParams("", searchString, query, page, 1));
    if (isRight(either)) {
      dispatch(patientsLoaded(either.value));
    }
    if (page > 1) {
      dispatch(patientsValueChanged("isLoadingMore", false));
    } else {
      dispatch(patientsValueChanged("isFetching", false));
    }
  }

  async onFetchRemoteFiles(dispatch: Dispatch, page: number) {
    dispatch(stateChanged(RemoteFilesState.fetchingFiles));
    let either = await this.fetchRemoteFiles.call(new FetchRemoteFilesParams(page));
    if (isRight(either)) {
      dispatch(remoteFilesFetched(either.value));
    }
    dispatch(stateChanged(RemoteFilesState.none));
  }

  async onDeleteRemoteFile(dispatch: Dispatch, id: string, page: number) {
    let either = await this.deleleRemoteFile.call(new DeleteRemoteFileParams(id));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        dispatch(remoteFileDeleted(id));
        this.onFetchRemoteFiles(dispatch, page);
        toast.success(Strings.DELETE_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
  }

  async onLinkSelectedPatient(dispatch: Dispatch, patient: IUser, selectedFiles: MFile[]) {
    dispatch(stateChanged(RemoteFilesState.linkingSelectedPatient));
    let originalFileIds = selectedFiles.map((file) => file.id);

    let either = await this.linkSelectedPatient.call(new LinkSelectedPatientParams(patient.id));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (medicalRecord) => {
        let finalMFiles: Map<string, MFile> = new Map();
        dispatch(stateChanged(RemoteFilesState.combiningSelectedPlyFiles));
        //download selected remote files
        this.downloadRemoteFiles(dispatch, selectedFiles).then((downloadedFiles) => {
          //check if download files are not null
          if (!downloadedFiles || downloadedFiles.length === 0 || downloadedFiles.some((file) => !file)) {
            //if they're null, display error text
            handleError("エラーが発生しました。しばらくしてからもう一度お試しください。".toString());
            dispatch(stateChanged(RemoteFilesState.none));
            return;
          }
          let allPlyAndStlFiles: Map<string, File> = new Map();
          let downloadedPlyFiles: File[] = [];
          downloadedFiles!.forEach((file, index) => {
            allPlyAndStlFiles.set(`ply${index + 1}`, file!);
            downloadedPlyFiles.push(file!);
          });
          let stlKeys = Array.from(allPlyAndStlFiles.keys()).map((key) => {
            return key === "ply1" ? "upper" : "lower";
          });

          //if not, continue convert to corresponding STLs progress
          convertPlyFilesToCorrespondingStlFiles(
            downloadedPlyFiles,
            (outputStlFiles) => {
              //check if converted stl files are not null
              if (!outputStlFiles) {
                //if they're null, display error text
                handleError("エラーが発生しました。しばらくしてからもう一度お試しください。".toString());
                dispatch(stateChanged(RemoteFilesState.none));
                return;
              }
              if (!outputStlFiles || Array.from(outputStlFiles.values()).length !== downloadedPlyFiles.length) {
                handleError("エラーが発生しました。しばらくしてからもう一度お試しください。".toString());
                dispatch(stateChanged(RemoteFilesState.none));
                return;
              }

              Array.from(outputStlFiles.keys()).forEach((key) => {
                allPlyAndStlFiles.set(key, outputStlFiles.get(key)!);
              });

              //if not, continue to convert all plys and stls to glbs
              convertToGlbFiles(
                allPlyAndStlFiles,
                async (outputGlbFiles) => {
                  const outputGlbFilesModified: any = await uploadFileToS3(outputGlbFiles);

                  let outputGlbKeys = Array.from(outputGlbFilesModified.keys());
                  if (outputGlbKeys.length === selectedFiles.length * 2) {
                    outputGlbKeys.forEach((key: any) => {
                      finalMFiles.set(key, outputGlbFilesModified.get(key)! as MFile);
                    });
                    /*update current files into new selected medical record
                     */
                    dispatch(
                      selectedMedicalRecordChanged({
                        ...(medicalRecord || defaultRecord()),
                        id: "",
                        files: new Map(finalMFiles),
                        patient: patient,
                        file_ids: originalFileIds,
                        linking_medical_record_id: medicalRecord?.id,
                      })
                    );
                    //go to medical record detail page
                    history.replace({
                      pathname: Routes.MEDICAL_MANAGEMENT_CREATE,
                      search: `?linkingRecordId=${medicalRecord?.id || "none"}`,
                    });
                    dispatch(clearAllSelectedFiles());
                  } else {
                    //if not, display error text
                    handleError("エラーが発生しました。しばらくしてからもう一度お試しください。".toString());
                  }
                  dispatch(changeModal(Modals.NONE));
                  dispatch(stateChanged(RemoteFilesState.none));
                },
                true,
                Array.from(allPlyAndStlFiles.keys())
              );
            },
            stlKeys
          );
        });
      }
    );
  }

  downloadRemoteFiles(dispatch: any, remoteFiles: MFile[]): Promise<(File | undefined)[] | undefined> {
    return Promise.all(
      remoteFiles.map((remoteFile) =>
        fetch(`${process.env.REACT_APP_AWS_S3_URL}/${remoteFile.path}`).then(async (res) => {
          if (!remoteFile.name) return undefined;
          let blob = await res.blob();
          return new File([blob], (remoteFile.name ?? "") + `.${remoteFile.file_type}`);
        })
      )
    ).catch((e) => {
      dispatch(stateChanged(RemoteFilesState.none));
      return Promise.resolve([]);
    });
  }
}
