import React from "react";
import { ArrayFunction } from "src/helpers/arrayFunction";
import { SelectOption } from "../selectOption/selectOption";

interface TimeItem {
  max?: number;
  min?: number;
  current?: number;
}

interface IInputGroupProps {
  className?: string;
  year?: TimeItem;
  month?: TimeItem;
  date?: TimeItem;
  currentDate?: Date;
  changeDate: (date: Date) => void;
}

export class DatePickerCustom extends React.Component<IInputGroupProps> {
  getLastDayOfMonth(month: number, year: number) {
    switch(month + 1) {
      case 4:
      case 6:
      case 9:
      case 11:
        return 30;
      case 2:
        if( (year % 100 == 0 && year % 400 == 0) || (year % 4 == 0))
          return 29;
        return 28;
    }
    return 31;
  }

  changeDate(type: string, value: number) {
    const date = this.props.currentDate ?? new Date();
    switch(type) {
      case "date":
        date?.setDate(value);
        break;
      case "month":
        date?.setMonth(value);
        break;
      case "year":
        date?.setFullYear(value);
    }
    this.props.changeDate(date);
  }
  
  render() {
    const today = new Date();
    
    const yearRange = ArrayFunction.range( this.props.year?.min ?? (today.getFullYear() - 100), this.props.year?.max ?? today.getFullYear());
    const monthRange = ArrayFunction.range( this.props.month?.min ?? 0, this.props.month?.max ?? 11);
    const currentYear = this.props.currentDate?.getFullYear() ?? this.props.year?.current ?? yearRange[0];
    const currentMonth = this.props.currentDate?.getMonth() ?? this.props.month?.current ?? monthRange[0];
    const dayRange = ArrayFunction.range( this.props.date?.min ?? 0, this.props.date?.max ?? this.getLastDayOfMonth( currentMonth, currentYear ));
    const currentDay = this.props.currentDate?.getDate() ?? this.props.date?.current ?? dayRange[0];
    
    return (
      <div className="row time-row">
        <div className="col-4">
          <div className="fsc item">
            <SelectOption
              className="form-control"
              options={yearRange.map(item => { return { value: item } })}
              value={ currentYear }
              onChange={(value) => this.changeDate("year", value)}
            ></SelectOption>
            <span>年</span>
          </div>
        </div>
        <div className="col-4">
          <div className="fsc item">
            <SelectOption
              className="form-control"
              options={monthRange.map(item => { return { value: item,  name: (item + 1).toString()} })}
              value={ currentMonth }
              onChange={(value) => this.changeDate("month", value)}
            ></SelectOption>
            <span>月</span>
          </div>
        </div>
        <div className="col-4">
          <div className="fsc item">
            <SelectOption
              className="form-control"
              options={dayRange.map(item => { return { value: item,  name: (item).toString()} })}
              value={ currentDay }
              onChange={(value) => this.changeDate("date", value)}
            ></SelectOption>
            <span>日</span>
          </div>
        </div>
      </div>
    );
  }
}