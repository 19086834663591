import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { OrthodontistState, OrthodontistType } from "src/base/Enums";
import { Injector } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import { Input } from "src/components/input";
import { Label } from "src/components/label";
import { SelectOption } from "src/components/selectOption";
import { Table } from "src/components/table";
import { colors, Strings } from "src/constants";
import { defaultUser, IUser } from "src/features/account/data/models/user";
import { OrthodontistContainer } from "src/features/orthodontist/presenter/container/orthodontistContainer";
import { selectedOrthodontistChanged, selectedOrthodontistDataChanged } from "src/features/orthodontist/presenter/store/orthodontist";
import { history, Routes } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import { IRootState } from "src/store";
import ColorPicker from "src/components/colorPicker";
import { findParamsFromSearchString, findSubRouteFromPathName, sleep } from "src/utils/utils";
import { BackButton } from "src/shared/components/backButton";
import { NoDataWrapperComponent } from "src/shared/components/noDataWrapperComponent";



class DentistDetailPage extends React.Component<DentistManagementDetailType> {
  constructor(props: any) {
    super(props);

  }

  componentDidMount() {

    let params = findParamsFromSearchString(window.location.search)
    let currentRoute = findSubRouteFromPathName(window.location.pathname)
    if (currentRoute !== Routes.DENTIST_MANAGEMENT_CREATE && params?.has("orthodontistId") && params.get("orthodontistId")) {
      this.props.fetchOrthodontistDetails(params.get("orthodontistId")!)
    } else {
      this.props.orthodontistDetailsChanged({ ...defaultUser, isNotFound: currentRoute !== Routes.DENTIST_MANAGEMENT_CREATE })
    }
    sleep(400).then(() => {
      this.props.changeOrthodontistData("color", {
        ...this.props.selectedOrthodontist.color || {
          shouldDisplayThisColor: true,
          hex: colors[0]
        },

      })
    })
  }

  buildOrthodontistInfoTable = () => {
    return <Table
      className="dataTable-container"
      subClassName="table table_detail"
      rows={[
        {
          fields: !this.props.selectedOrthodontist.updateOnly ? [] : [
            { html: Strings.DENTIST_NUMBER },
            {
              html: (
                <Input
                  className="fsc"
                  inputClassName="form-control"
                  type="text" maxLength={4}
                  pattern="\d*"
                  readonly={this.props.selectedOrthodontist.updateOnly}
                  value={this.props.selectedOrthodontist.clinic?.medical_institution + this.props.selectedOrthodontist.code}
                  inputPlaceHolder={Strings.PATIENT_NUMBER_PLACEHOLDER}
                  onChange={(value) => {
                    this.props.changeOrthodontistData("code", value.toString())
                  }}
                ></Input>
              )
            }
          ]
        },
        {
          fields: [
            { html: Strings.CASE },
            {
              html: (
                <SelectOption
                  className="form-control"
                  options={Object.values(OrthodontistType).filter((type) => type !== OrthodontistType.all).map((type, index) => { return { value: type } })}
                  value={this.props.selectedOrthodontist.orthodontist_type}
                  onChange={(value) => {
                    this.props.changeOrthodontistData("orthodontist_type", Object.values(OrthodontistType).indexOf(value))
                  }}
                ></SelectOption>
              )
            }
          ]
        },
        {
          fields: [
            { html: Strings.NAME },
            {
              html: (
                <Input
                  className="fsc"
                  inputClassName="form-control"
                  type="text"
                  value={this.props.selectedOrthodontist.full_name}
                  inputPlaceHolder={Strings.NAME_PLACEHOLDER}
                  maxLength={20}
                  name="name"
                  onChange={(value) => {
                    this.props.changeOrthodontistData("full_name", value.toString())
                  }}
                ></Input>
              )
            }
          ]
        },


        {
          fields: [
            { html: Strings.TEL },
            {
              html: (
                <Input
                  inputClassName="form-control"
                  className="fsc"
                  type="tel"
                  maxLength={11}
                  inputPlaceHolder={Strings.TEL1_PLACEHOLDER}
                  value={this.props.selectedOrthodontist.tel1}
                  onChange={(value) => this.props.changeOrthodontistData("tel1", value)}
                  name="tel1"
                ></Input>
              )
            }
          ]
        },


        {
          fields: [
            { html: Strings.EMAIL_ADDRESS },
            {
              html: (
                <Input
                  inputClassName="form-control"
                  className="fsc"
                  type="email"
                  inputPlaceHolder={Strings.EMAIL_PLACEHOLDER}
                  value={this.props.selectedOrthodontist.email}
                  name="email"
                  maxLength={50}
                  onChange={(value) => {
                    this.props.changeOrthodontistData("email", value)
                  }}
                ></Input>
              )
            }
          ]
        },
        {
          fields: [
            {
              html: <div>
                {Strings.COLOR_PICK}
                <Label text={Strings.NO_COLOR} className="fsc">
                  <Input
                    type="checkbox"
                    className="fsc"
                    inputClassName="mr05"
                    value={"invalid"}
                    name="gender"
                    checked={!this.props.selectedOrthodontist.color?.shouldDisplayThisColor}
                    onChange={(checked) => {
                      this.props.changeOrthodontistData("color", {
                        ...this.props.selectedOrthodontist.color,
                        shouldDisplayThisColor: !checked
                      })
                    }}
                  ></Input>
                </Label>
              </div>
            },
            {
              html: (
                <ColorPicker pickedColor={this.props.selectedOrthodontist.color?.hex} onChangeColor={(colorHex) => {
                  this.props.changeOrthodontistData("color", {
                    ...this.props.selectedOrthodontist.color,
                    hex: colorHex
                  })
                }} />
              )
            }
          ]
        }
      ]}

    ></Table>
  }

  render() {
    return (
      <MainLayout isLoading={this.props.currentState === OrthodontistState.isFetchingOrthodontistDetails || this.props.currentState === OrthodontistState.isAddingOrthodontist || this.props.currentState === OrthodontistState.isUpdatingOrthodontist} className="secondpage navstate_show page-orthodontist detail animate-item" subClassName="p-orthodontist detail">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header flex-H">
              <BackButton onBackPressed={() => {
                history.replace(Routes.DENTIST_MANAGEMENT)
              }} />
              {Strings.DENTIST + Strings.LINE + (findSubRouteFromPathName(window.location.pathname) !== Routes.DENTIST_MANAGEMENT_CREATE ? Strings.EDIT : Strings.ADD)}
            </div>
            <NoDataWrapperComponent isNoData={this.props.selectedOrthodontist.isNotFound} noDataContent={<h3>{Strings.NO_DATA}</h3>}>
              <form onSubmit={(e) => {
                e.preventDefault()
                if (this.props.selectedOrthodontist.updateOnly) {
                  this.props.onUpdateOrthodontist(this.props.selectedOrthodontist)
                } else {
                  this.props.onAddOrthodontsit(this.props.selectedOrthodontist)
                }
              }}>
                <div className="card-body">
                  {this.buildOrthodontistInfoTable()}
                  <div className="fec mt20 btn-group-end">
                    <MainButton
                      title={Strings.REGISTER}
                      submitting={this.props.selectedOrthodontist.updateOnly ? this.props.currentState === OrthodontistState.isUpdatingOrthodontist : this.props.currentState === OrthodontistState.isAddingOrthodontist}
                      enabled={this.props.selectedOrthodontist.full_name.length !== 0 && this.props.selectedOrthodontist.tel1.length >= 10 && this.props.selectedOrthodontist.email.length !== 0}
                      className="btn btn-primary mr10"
                      type="button"
                      loadingSize={20}
                      isSubmitButton
                      onSubmit={() => {

                      }}
                    />
                    <a className="btn btn-danger" onClick={() => { history.replace(Routes.DENTIST_MANAGEMENT) }}>{Strings.RETURN}</a>
                  </div>
                </div>
              </form>
            </NoDataWrapperComponent>
          </div>
        </div>
      </MainLayout >
    );
  }
}
const mapStateToProps = ({ orthodontist }: IRootState) => {
  const { selectedOrthodontist, currentState } = orthodontist
  return {
    currentState,
    selectedOrthodontist
  };
};
const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    orthodontistDetailsChanged: (orthodontist: IUser) => dispatch(selectedOrthodontistChanged(orthodontist)),
    fetchOrthodontistDetails: (id: any) => Injector.get().find<OrthodontistContainer>("OrthodontistContainer").onFetchOrthondontistDetails(dispatch, id),
    changeOrthodontistData: (key: string, value: any) => dispatch(selectedOrthodontistDataChanged(key, value)),
    onUpdateOrthodontist: (user: IUser) => Injector.get().find<OrthodontistContainer>("OrthodontistContainer").onUpdateOrthodontist(dispatch, user),
    onAddOrthodontsit: (user: IUser) => Injector.get().find<OrthodontistContainer>("OrthodontistContainer").onAddOrthodontist(dispatch, user)
  };
};

type DentistManagementDetailType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(DentistDetailPage);
