import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { Pagination } from "src/shared/model/pagination";
import { IMedicalRecord } from "../../data/models/medicalRecord";
import { IMedicalRecordRepository } from "../../data/repositories/medicalRecordRepository";

export class FetchMedicalRecords extends UseCase<FetchMedicalRecordParams, Pagination<IMedicalRecord>> {
  private repository: IMedicalRecordRepository;
  constructor(repository: IMedicalRecordRepository) {
    super();
    this.repository = repository;
  }

  call(params: FetchMedicalRecordParams): Promise<Either<Failure, Pagination<IMedicalRecord>>> {
    return this.repository.fetchMedicalRecords(
      params.code,
      params.full_name,
      params.page,
      params.fieldRequired,
      params.orthodontistId,
      params.fromDate,
      params.toDate,
      params.date_order
    );
  }
}

export class FetchMedicalRecordParams {
  code: string;
  full_name: string;
  page: number;
  fieldRequired?: Array<any>;
  orthodontistId?: number;
  fromDate?: Date;
  toDate?: Date;
  date_order?: string;
  constructor(
    code: string,
    full_name: string,
    page: number,
    fieldRequired?: Array<any>,
    orthodontistId?: number,
    fromDate?: Date,
    toDate?: Date,
    date_order?: string
  ) {
    this.code = code;
    this.full_name = full_name;
    this.page = page;
    this.fieldRequired = fieldRequired;
    this.orthodontistId = orthodontistId;
    this.fromDate = fromDate;
    this.toDate = toDate;
    this.date_order = date_order;
  }
}
