import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IAuthRepository } from "../../data/repositories/authRepository";

export class ForgetPassword extends UseCase<ForgetPasswordParams, boolean>{
    private repository: IAuthRepository
    constructor(repository: IAuthRepository) {
        super()
        this.repository = repository
    }
    call(params: ForgetPasswordParams): Promise<Either<Failure, boolean>> {
        return this.repository.forgetPassword(params.email)
    }
}

export class ForgetPasswordParams {
    email: string
    constructor(email: string) {
        this.email = email
    }
}