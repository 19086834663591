import axios from "axios";
import moment from "moment";
import { Endpoints } from "src/base/Endpoints";
import { Endpoint, HttpMethod } from "src/helpers";
import { IApiService } from "src/services/api/ApiService";
import { Pagination } from "src/shared/model/pagination";
import { dataURIToBlob } from "src/utils/utils";
import { IAdminNotice, mapNotice } from "../../presenter/store/adminNotice";

export interface IAdminNoticeRemoteDataSource {
    fetchNotices(page: number): Promise<Pagination<IAdminNotice>>;
}

export class AdminNoticeRemoteDatasource implements IAdminNoticeRemoteDataSource {
    apiService: IApiService
    constructor(apiService: IApiService) {
        this.apiService = apiService
    }

    async fetchNotices(page: number): Promise<Pagination<IAdminNotice>> {
        var response = await this.apiService.request({
            method: HttpMethod.GET, endpoint: Endpoints.fetchNews, shouldAuthorize: true,
            params: {
                page: page,
                limit: 10
            }
        })
        return mapNotice(response.data.data);
    }
}