import React from "react";
import { MedicalField } from "src/features/medicalRecord/data/models/attribute";
import { getFilePath, hasData, MFile } from "src/features/medicalRecord/data/models/file";
import { FileTypes } from "src/helpers";
import { Assets } from "src/utils/assets";
import ImgWrapper from "./imgWrapper";

interface IListGalerryProps {
  images?: MFile[];
  listGalerry?: Array<MedicalField>;
  onItemClick?: (image: any, e?: any) => void;
  onItemDoubleClick?: () => void;
  onAddNewDentalType?: () => void;
  onDeleteImage?: (type?: string) => void;
  onChange: (
    type?: string,
    value?: string,
    imageLabel?: string,
    e?: React.ChangeEvent<HTMLInputElement>,
    file?: any,
    url?: string,
    fileList?: any[]
  ) => void;
  isDragAndDrop?: boolean;
  selectedImages?: any[];
  multiple?: boolean;
}

export class ListGalerry extends React.Component<IListGalerryProps> {
  render() {
    const imageItemLabel = (item: any) => item.label;

    return (
      <div className="row list-galerry">
        {this.props.listGalerry
          ?.filter((item) => {
            const image = this.props.images?.filter((image) => item.name.indexOf(image.image_type) > -1)[0];

            return this.props.onAddNewDentalType === undefined ? image && image.path && image.path.length > 0 : true;
          })
          .map((item, index) => {
            const image = this.props.images?.filter((image) => image.image_type === item.name)[0];

            return (
              <ImgWrapper
                key={index}
                index={index}
                item={item}
                imgURL={getFilePath(image)}
                onDelete={() => this.props.onDeleteImage && this.props.onDeleteImage(item.name)}
                hadImage={hasData(image)}
                readonly={this.props.onAddNewDentalType === undefined}
                fileAccept={FileTypes.ALL}
                onClick={(e) => this.props.onItemClick && this.props.onItemClick(image, e)}
                onDoubleClick={this.props.onItemDoubleClick}
                label={imageItemLabel(item)}
                onChange={(_, event, ___, ____, fileList) => {
                  if (fileList?.length === 1)
                    this.props.onChange(
                      item.name ?? "",
                      fileList[0].valueBase64,
                      imageItemLabel(item),
                      event,
                      fileList[0],
                      fileList[0].location
                    );
                  if (fileList?.length! > 1)
                    this.props.onChange(undefined, undefined, undefined, event, undefined, undefined, fileList);
                }}
                isDragAndDrop={this.props.isDragAndDrop}
                multiple={this.props.multiple}
                isFileDroppable
              />
            );
          })}
        {this.props.onAddNewDentalType ? (
          <div
            className="col-lg-3 col-4 img-wrapper"
            onClick={this.props.onAddNewDentalType}
            style={{ height: "150px", display: "flex", justifyContent: "center", alignItems: "center" }}
          >
            <img className="text-center mt30" src={Assets.add} height={80} width={80} alt="" />
          </div>
        ) : undefined}
      </div>
    );
  }
}
