import moment from "moment";
import { paginationInit } from "src/shared/model/pagination";
import { INotificationState, NotificationActions, NotificationActionType } from "./types";

export const initialNotification = {
  id: -1,
  activityDate: {
    value: moment(new Date()).add(1, "days").toDate(),
    error: "",
    validate: true,
  },
  type: "clinic",
  title: {
    value: "",
    error: "",
    validate: false,
  },
  content: {
    value: "",
    error: "",
    validate: false,
  },
};

const initialState: INotificationState = {
  notifications: paginationInit,
  currentNotification: initialNotification,
  state: false,
  validate: false,
};

export function notificationReducer(
  state: INotificationState = initialState,
  action: NotificationActions
): INotificationState {
  switch (action.type) {
    case NotificationActionType.FETCH_NOTIFICATION:
      return {
        ...state,
        notifications: {
          ...action.payload.notifications,
          data: action.payload.notifications.data,
        },
      };

    case NotificationActionType.NOTIFICATION_STATE_CHANGED:
      return {
        ...state,
        state: action.payload.state,
      };
    case NotificationActionType.CHOSSE_NOTIFICATION:
      return {
        ...state,
        currentNotification: action.payload.notification,
        validate: Object.values<any>(action.payload.notification).every((value, _, __) => {
          return value && typeof value.validate != "undefined" ? value.validate : true;
        }),
      };

    case NotificationActionType.UPDATE_NOTIFICATION:
      const newState = {
        ...state,
        currentNotification: {
          ...state.currentNotification,
          [action.payload.field]: {
            value: action.payload.value,
          },
        },
      };
      return {
        ...newState,
        validate: Object.values<any>(newState.currentNotification).every((value, _, __) => {
          return value && typeof value.validate != "undefined" ? value.validate : true;
        }),
      };

    default:
      return state;
  }
}
