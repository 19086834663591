import React, { LegacyRef, ReactNode } from "react";
import { createPortal } from "react-dom";
import { DropdownPosition } from "../pages/fileManagementPage";

interface DropdownPortalProps {
  isShowing: boolean;
  children: ReactNode;
  dropdownRef: LegacyRef<HTMLUListElement>;
  dropdownPosition: DropdownPosition;
}

class DropdownPortal extends React.Component<DropdownPortalProps> {
  render() {
    return this.props.isShowing ? (
      createPortal(
        <ul
          ref={this.props.dropdownRef}
          id="dropdown-action"
          className={`dropdown-action`}
          style={{ ...this.props.dropdownPosition }}
        >
          {this.props.children}
        </ul>,
        document.getElementById("root")!
      )
    ) : (
      <ul
        ref={this.props.dropdownRef}
        id="dropdown-action"
        className={`dropdown-action`}
        style={{ top: 0, left: 0, visibility: "hidden" }}
      >
        {this.props.children}
      </ul>
    );
  }
}

export default DropdownPortal;
