import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IRemoteFileDownloadRepository } from "../../data/repositories/remoteFilesDownloadRepository";

export class DeleteRemoteFile extends UseCase<DeleteRemoteFileParams, boolean>{
    private repository: IRemoteFileDownloadRepository;
    constructor(repository: IRemoteFileDownloadRepository) {
        super();
        this.repository = repository;
    }

    call(params: DeleteRemoteFileParams): Promise<Either<Failure, boolean>> {
        return this.repository.deleteRemoteFile(params.id)
    }

}

export class DeleteRemoteFileParams {
    id: string
    constructor(id: string) {
        this.id = id;
    }
}