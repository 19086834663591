import moment from "moment";
import React from "react";
import { DragDropContext } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { AttributeName, MedicalRecordState } from "src/base/Enums";
import { Injector } from "src/base/Injector";
import QRCodeViewer from "src/components/QRCodeViewer/QRCodeViewer";
import { ListGalerry } from "src/components/listGalerry";
import ImgWrapper from "src/components/listGalerry/imgWrapper";
import { ListGalerry3D } from "src/components/listGalerry/listGalerry3D";
import { MedicalTable } from "src/components/medicalTable";
import ThreeDViewerPopup from "src/components/popup/3DviewerPopup";
import QRCodeViewerPopup from "src/components/popup/QRCodeViewerPopup";
import ImagePopup from "src/components/popup/imagePopup";
import MedicalPopup from "src/components/popup/medicalPopup";
import { TeethSVG } from "src/components/teethSVG/teethSVG";
import { MAX_FILE, Strings } from "src/constants";
import { MedicalField, getMetaData } from "src/features/medicalRecord/data/models/attribute";
import { getFilePath } from "src/features/medicalRecord/data/models/file";
import { IMedicalRecord, defaultRecord } from "src/features/medicalRecord/data/models/medicalRecord";
import { MedicalRecordContainer } from "src/features/medicalRecord/presenter/container/medicalRecordContainer";
import {
  selectedMedicalRecordChanged,
  selectedToothChanged,
} from "src/features/medicalRecord/presenter/store/medicalRecord";
import { changeModal } from "src/features/root/presenter/store/root";
import { FileTypes, Modals, Routes, history } from "src/helpers";
import { TeethIndex } from "src/helpers/teethIndex";
import MainLayout from "src/layouts/main/mainLayout";
import { BackButton } from "src/shared/components/backButton";
import { NoDataWrapperComponent } from "src/shared/components/noDataWrapperComponent";
import { IRootState } from "src/store";
import { Assets } from "src/utils/assets";
import { findParamsFromSearchString } from "src/utils/utils";

interface MedicalConfirmDetailPageState {
  number: string;
  patientId: string;
  dentistId: string;
  patientName: string;
  orthodontistName: string;
  selectedImage: any;
  selectedImageUrl: string;
  selectedImageLabel: string;
  selectedImages: any[];
  selectedModelUrl: string;
  isShow: boolean;
  threeD: {
    threeDKey: string;
    threeDType: string;
    filelenght: number;
  };
  listGallery: MedicalField[];
  threeDPopupTitle: string;
}

class MedicalConfirmDetailPage extends React.Component<MedicalConfirmDetailPageType, MedicalConfirmDetailPageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      number: "",
      patientId: "",
      dentistId: "",
      patientName: "",
      isShow: true,
      threeD: {
        threeDKey: "",
        threeDType: "",
        filelenght: 0,
      },
      orthodontistName: "",
      selectedImage: undefined,
      selectedImageUrl: "",
      selectedImageLabel: "",
      selectedImages: [],
      selectedModelUrl: "",
      listGallery: [],
      threeDPopupTitle: "",
    };
  }

  toggle = () => {
    this.setState({
      isShow: !this.state.isShow,
    });
  };

  _filterAttributes() {
    const allFields = [...getMetaData(AttributeName.imageType, this.props.attributes)];
    const nonDentalFields = [...allFields.filter((field) => field.name.indexOf("dental") !== 0)];
    let dentalFields = [...allFields.filter((field) => field.name.indexOf("dental") > -1)];

    if (this.props.selectedMedicalRecord.images.length > 0) {
      const imageDentalFields: MedicalField[] = [];
      this.props.selectedMedicalRecord.images
        .filter((image) => image.image_type.indexOf("dental") > -1)
        .forEach((image: any, imageIndex: number) => {
          if (image.image_type.indexOf("dental") > -1) {
            imageDentalFields.push({
              name: `dental${imageIndex === 0 ? "" : imageIndex}`,
              type: "file",
              label: image.title,
            });
          }
        });

      if (imageDentalFields.length > 0) dentalFields = [...imageDentalFields];
    }

    this.setState({
      ...this.state,
      listGallery: [...nonDentalFields, ...dentalFields],
    });
  }

  componentDidMount() {
    (this.props.attributes.length === 0 ? this.props.onFetchAttributes() : Promise.resolve()).then(() =>
      this._filterAttributes()
    );
    let params = findParamsFromSearchString(window.location.search);
    if (params?.has("recordId") && params.get("recordId")) {
      this.props.onSelectMedicalRecord(params.get("recordId")).then(() => {
        this._filterAttributes();
      });
    } else {
      this.props.selectedRecordChanged({ ...defaultRecord(), isNotFound: true });
    }
  }

  buildMedicalRecordDetailTable = (plyKeys: string[]) => {
    let patientPdfPaths =
      (this.props.selectedMedicalRecord.pdfs?.patient.file &&
        this.props.selectedMedicalRecord.pdfs?.patient.file.path.split("/")) ??
      [];
    const firstDotIndex = patientPdfPaths.slice(-1)[0]?.indexOf(".") + 1;

    return (
      <div className="infoConfirmation">
        <div className="blockHead d-xl-flex">
          <div className="blockHead-left">
            <div className="blockHead-information">
              <dl className="d-xl-flex">
                <dt>{Strings.DATE}</dt>
                <dd>{moment(this.props.selectedMedicalRecord.created_at).format("YYYY/MM/DD HH:mm:ss")}</dd>
              </dl>
              <dl className="d-xl-flex">
                <dt>{Strings.MEDICAL_NUMBER}</dt>
                <dd>{this.props.selectedMedicalRecord.patient.special_code}</dd>
              </dl>
              <dl className="d-xl-flex">
                <dt>{Strings.PATIENT_NAME}</dt>
                <dd>{this.props.selectedMedicalRecord.patient.full_name}</dd>
              </dl>
              <dl className="d-xl-flex">
                <dt>{Strings.SHARE_FIRST}</dt>
                <dd className="txtMedical">
                  {this.props.selectedMedicalRecord.orthodontists
                    .filter((item) => item.id !== "")
                    .map((item: any, idx: number) => {
                      return <span key={idx}>{item.full_name}</span>;
                    })}
                </dd>
              </dl>
            </div>
            <div className="d-xl-flex space-between">
              <div className="pdffile">
                {this.props.selectedMedicalRecord.pdfs?.patient.file?.path ? (
                  <a
                    target={"_blank"}
                    className="btn btn-default btn-pdf"
                    href={this.props.selectedMedicalRecord.pdfs?.patient.file?.path}
                    rel="noreferrer"
                  >
                    <span>
                      {patientPdfPaths.length > 0
                        ? patientPdfPaths.slice(-1)[0].slice(firstDotIndex).replaceAll(".pdf", "")
                        : ""}
                    </span>
                  </a>
                ) : undefined}
              </div>
              <div className={this.props.selectedMedicalRecord.pdfs?.patient.comment ? "nodeTxt d-xl-flex" : "hide"}>
                <div className="txtNotice">
                  <div className="inner">
                    <p>{this.props.selectedMedicalRecord.pdfs?.patient.comment}</p>
                  </div>
                </div>
                <div className="icon">
                  <img src={Assets.iconuser} alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="blockHead-right flexbox_center">
            {this.props.selectedMedicalRecord.patient.qr_code ? (
              <div className="qrCode-box" onClick={() => this.props.changeModal(Modals.QRCODE)}>
                <QRCodeViewer value={this.props.selectedMedicalRecord.patient.qr_code} size={225} />
              </div>
            ) : null}
          </div>
        </div>
        <div className="blockImage d-xl-flex">
          <div className="blockImage-left">
            <div className="title d-xl-flex">
              <p>{Strings.IMAGE_INSIDE}</p>
              <p className="txtSmoking">
                <span>{Strings.SMOKING}</span>
                <span className="ml10">
                  {this.props.selectedMedicalRecord.is_smoking ? Strings.CAN : Strings.CANNOT}
                </span>
              </p>
            </div>
            <div
              className={
                Array.from(this.props.selectedMedicalRecord.images.keys()).length >= 13 && this.state.isShow
                  ? "listImages hideImages"
                  : "listImages showImages"
              }
            >
              <ListGalerry
                onItemClick={(file) => {
                  this.setState(
                    {
                      selectedImages: [
                        {
                          image: file,
                          imageUrl: getFilePath(file)!,
                          imageLabel: file?.title,
                          imageType: file.image_type,
                        },
                      ],
                    },
                    () => {
                      const fileExtension = file.path.split(".").pop();

                      if (!["mp4", "mov", "ogg", "webm", "avi"].includes(fileExtension))
                        this.props.changeModal(Modals.IMAGE);
                    }
                  );
                }}
                listGalerry={this.state.listGallery}
                images={this.props.selectedMedicalRecord.images}
                onChange={() => {}}
                selectedImages={this.state.selectedImages}
              />
              <div
                className={
                  Array.from(this.props.selectedMedicalRecord.images.keys()).length >= 13 ? "loadmore" : "hide"
                }
                onClick={this.toggle}
              >
                <span>
                  <img src={Assets.iconarrdown} alt="" />
                </span>
              </div>
            </div>

            <div className="blockData3D">
              <dl>
                <dt>3Dデータ</dt>
                <dd>
                  <div
                    className={`list-galerry img-wrapper ${
                      plyKeys.length === MAX_FILE
                        ? "img-wrapper-custom2 d-flex"
                        : plyKeys.length > 0
                        ? "img-wrapper-custom d-flex"
                        : ""
                    }`}
                  >
                    {plyKeys.map((key) => {
                      let item = this.props.selectedMedicalRecord.files.get(key);
                      let lenght = this.props.selectedMedicalRecord.files.size;
                      return (
                        <ImgWrapper
                          key={key}
                          className="list-galerry-item"
                          imgURL={item?.blob || item?.path ? Assets.ply2 : Assets.medicalImage}
                          readonly
                          label={item?.label!}
                          onClick={(e) => {
                            if (item && item.path)
                              this.setState({
                                ...this.state,
                                threeD: {
                                  ...this.state.threeD,
                                  threeDKey: key,
                                  threeDType: "ply",
                                  filelenght: lenght,
                                },
                              });
                            this.props.changeModal(Modals.MODEL_3D);
                          }}
                          onChange={(value, e) => {}}
                          fileAccept={FileTypes.PLY}
                        />
                      );
                    })}
                  </div>
                </dd>
              </dl>
            </div>
            {/* <div className="partner-company">
              <a href="https://r5.dscore.com/#/home">
                <img src={Assets.partnerComp1} alt="" />
              </a>
              <a href="https://portal.3shapecommunicate.com/cases">
                <img src={Assets.partnerComp2} alt="" />
              </a>
              <a href="https://bff.cloud.myitero.com/labs/home">
                <img src={Assets.partnerComp3} alt="" />
              </a>
              <a href="https://www.dental3dcloud.com/u/cases">
                <img src={Assets.partnerComp4} alt="" />
              </a>
              <a href="https://www.meditlink.com/inbox">
                <img src={Assets.partnerComp5} alt="" />
              </a>
            </div> */}
          </div>
          <div className="blockImage-right">
            <div className="TeethSVG">
              <TeethSVG
                teeths={TeethIndex}
                teethDisabled={
                  this.props.selectedMedicalRecord.teeth
                    ? [
                        ...Array.from(this.props.selectedMedicalRecord.teeth?.keys()).filter(
                          (key) =>
                            this.props.selectedMedicalRecord.teeth?.get(key)?.get(AttributeName.pattern)?.value === "4"
                        ),
                      ]
                    : []
                }
                openModal={(toothIndex: number) => {
                  this.props.selectedToothChanged(toothIndex);
                  this.props.changeModal(Modals.MEDICAL);
                }}
              ></TeethSVG>
              <p>PCR・EPP・BOP</p>
            </div>
          </div>
        </div>
        <div className="blockMedicalTable">
          <MedicalTable
            teeth={this.props.selectedMedicalRecord.teeth ?? new Map()}
            attributes={this.props.attributes}
          />
        </div>
        <div className="blockData3D">
          <dl>
            <dt>STL</dt>
            <dd>
              <React.Fragment>
                <ListGalerry3D
                  readonly
                  onItemClick={(item, e) => {
                    if (item.path)
                      this.setState({
                        ...this.state,
                        threeD: {
                          ...this.state.threeD,
                          threeDKey: item.file_type,
                          threeDType: "stl",
                          filelenght: this.props.selectedMedicalRecord.files.size,
                        },
                      });
                    this.props.changeModal(Modals.MODEL_3D);
                  }}
                  listGalerry={getMetaData(AttributeName.file3DType, this.props.attributes).map((item) => {
                    return {
                      ...item,
                      label:
                        (this.props.selectedMedicalRecord.files.get(item.name) &&
                          this.props.selectedMedicalRecord.files.get(item.name)?.name) ||
                        item.label,
                    };
                  })}
                  files={this.props.selectedMedicalRecord.files}
                  onChange={(type: string, value: string, e?: React.ChangeEvent<HTMLInputElement>) => {}}
                />
              </React.Fragment>
            </dd>
          </dl>
        </div>
        <div
          className={
            this.props.selectedMedicalRecord.pdfs?.orthodontist.comment &&
            this.props.selectedMedicalRecord.pdfs?.orthodontist.file?.path
              ? "blockData3D blockOrthodontist"
              : "hide"
          }
        >
          <dl>
            <dt>共有先</dt>
            <dd className="flexbox">
              <div className={this.props.selectedMedicalRecord.pdfs?.orthodontist.comment ? "blockNotice" : "hide"}>
                <div className="txtNotice">
                  <div className="inner">
                    <p>{this.props.selectedMedicalRecord.pdfs?.orthodontist.comment}</p>
                  </div>
                </div>
              </div>
              <div className={this.props.selectedMedicalRecord.pdfs?.orthodontist.file?.path ? "btnpdf" : "hide"}>
                <a
                  target={"_blank"}
                  href={this.props.selectedMedicalRecord.pdfs?.orthodontist.file?.path}
                  className="btn btn-default btn-pdf"
                  rel="noreferrer"
                >
                  <span className="ml20">{"PDFダウンロード"}</span>
                </a>
              </div>
            </dd>
          </dl>
        </div>
      </div>
    );
  };

  render() {
    let plyKeys = Array.from(this.props.selectedMedicalRecord.files.keys()).filter((key) => key.indexOf("ply") > -1);
    let selectedModelTitle = (
      this.props.selectedMedicalRecord.files.get(this.state.threeD.threeDKey)?.label ?? ""
    ).replaceAll(this.state.threeD.threeDType === "ply" ? ".glb" : ".stl", "");
    let selectedModelUrl =
      (this.props.selectedMedicalRecord.files.get(this.state.threeD.threeDKey)?.path &&
        this.props.selectedMedicalRecord.files.get(this.state.threeD.threeDKey)?.path!) ??
      "";

    return (
      <MainLayout
        isLoading={this.props.medicalRecordState === MedicalRecordState.fetchingMedicalRecord}
        className="secondpage navstate_show page-medical_management detail animate-item"
        subClassName="p-medical_management detail"
      >
        <DragDropContext onDragEnd={() => {}}>
          <div className="container-fluid">
            <div className="card">
              <div className="card-header flex-H">
                <BackButton
                  onBackPressed={() => {
                    history.replace(Routes.MEDICAL_MANAGEMENT);
                  }}
                />
                症例相談共有-確認
              </div>
              <div className="card-body medicalConfirm">
                <NoDataWrapperComponent
                  isNoData={this.props.selectedMedicalRecord.isNotFound}
                  noDataContent={<h3>{Strings.NO_DATA}</h3>}
                >
                  {this.buildMedicalRecordDetailTable(plyKeys)}
                  <div className="fec mt20 btn-group-end">
                    <Link className="btn btn-danger" to={Routes.MEDICAL_MANAGEMENT} replace>
                      {Strings.RETURN}
                    </Link>
                  </div>
                </NoDataWrapperComponent>
              </div>
            </div>
          </div>
        </DragDropContext>
        <MedicalPopup readonly></MedicalPopup>
        {this.props.currentModal === Modals.IMAGE && (
          <ImagePopup
            images={this.state.selectedImages}
            onUpdateImageMedicalRecord={this.props.onUpdateImageMedicalRecord}
          />
        )}
        <ThreeDViewerPopup
          currentThreeDKey={this.state.threeD.threeDKey}
          onChangeThreeDModel={(key: any) => {
            this.setState({
              ...this.state,
              threeD: {
                ...this.state.threeD,
                threeDKey: key,
              },
            });
          }}
          threeDType={this.state.threeD.threeDType}
          title={selectedModelTitle}
          modelUrl={selectedModelUrl}
          lenght={this.state.threeD.filelenght}
        />
        <QRCodeViewerPopup
          title={this.props.selectedMedicalRecord.patient.full_name}
          value={this.props.selectedMedicalRecord.patient.qr_code!}
        />
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ app, medicalRecord }: IRootState) => {
  const { currentModal } = app;
  const {
    selectedMedicalRecord,
    searchedPatients,
    searchedOrthodontists,
    isSubmitting,
    attributes,
    medicalRecordState,
  } = medicalRecord;
  return {
    currentModal,
    selectedMedicalRecord,
    searchedPatients,
    searchedOrthodontists,
    isSubmitting,
    attributes,
    medicalRecordState,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    selectedRecordChanged: (newRecord: IMedicalRecord) => dispatch(selectedMedicalRecordChanged(newRecord)),
    onSelectMedicalRecord: (recordId: any) =>
      Injector.get().find<MedicalRecordContainer>("MedicalRecordContainer").onSelectMedicalRecord(dispatch, recordId),
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
    selectedToothChanged: (toothIndex: number) => dispatch(selectedToothChanged(toothIndex)),
    onSearchOthordontists: (keyword: string) =>
      Injector.get().find<MedicalRecordContainer>("MedicalRecordContainer").onSearchOrthodontist(dispatch, keyword),
    onFetchAttributes: () =>
      Injector.get().find<MedicalRecordContainer>("MedicalRecordContainer").onFetchAttributes(dispatch),
    onUpdateImageMedicalRecord: (medical: IMedicalRecord, imageFile: File, imageType: string) =>
      Injector.get()
        .find<MedicalRecordContainer>("MedicalRecordContainer")
        .onUpdateImageMedicalRecord(dispatch, medical, imageFile, imageType),
  };
};

type MedicalConfirmDetailPageType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(MedicalConfirmDetailPage);
