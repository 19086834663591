import { ILoginState, LoginActions, LoginActionType } from "./types";

const initialState: ILoginState = {
    isSubmitting: false,
    
}

export function loginReducer(state: ILoginState = initialState, action: LoginActions): ILoginState {
    switch (action.type) {
        case LoginActionType.LOGIN_STATE_CHANGED:
            return {
                ...state,
                isSubmitting: action.payload.isSubmitting
            }

        default:
            return state;
    }
}