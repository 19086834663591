import { InputValidation } from "src/models/inputValidation/InputValidation";
import { ActionType } from "typesafe-actions";
import * as actions from './actions';

export interface IForgetPasswordState {
    isSubmitting: boolean,
    email: InputValidation<string>,
    otp?: InputValidation<string>;
    password?: InputValidation<string>;
    passwordCF?: InputValidation<string>;
}

export enum ForgetPasswordActionType {
    FORGET_PASSSWORD_STATE_CHANGED = "FORGET_PASSSWORD_STATE_CHANGED",
    FORGET_PASSSWORD_FIELD_CHANGED = "FORGET_PASSSWORD_FIELD_CHANGED",
}

export type ForgetPasswordActions = ActionType<typeof actions>;