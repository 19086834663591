import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DI, Injector } from "src/base/Injector";
import { Strings } from "src/constants";
import { AdminNoticeContainer } from "src/features/adminNotice/presenter/container/adminNoticeContainer";
import MainLayout from "src/layouts/main/mainLayout";
import { PaginationComponent } from "src/shared/components/pagination";
import { IRootState } from "src/store";
import { NoticeCard } from "../components/noticeCard";

class NoticeOperatorPage extends React.Component<AdminNoticeType> {
  componentDidMount() {
    DI.get().provideAdminNoticeDependencies();
    this.props.fetchNotices(1);
  }

  componentWillUnmount() {
    DI.get().removeAdminNoticeDepenencies();
  }

  render() {
    return (
      <MainLayout
        isLoading={this.props.noticeState}
        className="secondpage navstate_show page-notice_operator animate-item"
        subClassName="p-notice_operator"
      >
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">{Strings.NOTICE_OPERATOR}</div>
            <div className="card-body">
              {this.props.notices.data.map((item) => (
                <NoticeCard
                  key={item.id}
                  imgSrc={item.image ? item.image : ""}
                  createdAt={item.activityDate}
                  title={item.title}
                  pdfUrl={item.pdf ? item.pdf : ""}
                  pdfName={item.pdf ? item.pdf.split("/").slice(-1)[0] : "FILE"}
                  content={item.content}
                />
              ))}
              <div
                className={
                  this.props.notices.data.length === 6 || this.props.notices.last_page > 1
                    ? "showPaginationComponent mt30"
                    : "hide"
                }
              >
                <PaginationComponent
                  currentPage={this.props.notices.current_page}
                  totalPage={this.props.notices.last_page}
                  onClick={(page) => {
                    this.props.fetchNotices(page);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ adminNotice }: IRootState) => {
  const { notices, state } = adminNotice;
  return {
    notices,
    noticeState: state,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    fetchNotices: (page: number) =>
      Injector.get().find<AdminNoticeContainer>("AdminNoticeContainer").getNotices(dispatch, page),
  };
};

type AdminNoticeType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(NoticeOperatorPage);
