export enum AuthState {
  unauthenticated,
  authenticated,
}

export enum OrthodontistState {
  none,
  isFetchingOrthodontists,
  isFetchingOrthodontistDetails,
  isAddingOrthodontist,
  isUpdatingOrthodontist,
  isDeletingOrthodontist,
  isUpdatingOrthodontistStatus,
}

export enum MedicalRecordState {
  none,
  fetchingMedicalRecord,
  updatingMedicalRecord,
  convertingFiles,
  addingMedicalRecord,
  deletingMedicalRecord,
  duplicatingMedicalRecord,
}

export enum PatientAnalyticState {
  none,
  fetchingPatientAnalytics,
  fetchingPatientAnalyticDetail,
  exportingAnalyticToPDF,
}

export enum PatientState {
  none,
  fetchingPatients,
  fetchingPatientDetails,
  addingPatient,
  updatingPatient,
  deletingPatient,
  importingCsv,
}

export enum AccountState {
  none,
  fetchingAccount,
  updatingAccount,
}

export enum SubscriptionState {
  none,
  fetchingPlans,
  fetchingCards,
  addingSubscription,
  changingSubscription,
  cancellingSubscription,
  addingCard,
  updatingCard,
  deletingCard,
}

export enum AccountStatus {
  all = "すべて",
  active = "有効",
  inactive = "無効",
}

export enum PatientsStatus {
  all = "すべて",
  valid = "ID/PW送信済",
  invalid = "ID/PW未送信",
}

export enum OrthodontistType {
  all = "すべて",
  prosthodontics = "補綴",
  correct = "矯正",
  perio = "ペリオ",
  implant = "インプラント",
  others = "その他",
}

export enum ValidationType {
  nullable = "nullable",
  array = "array",
  in = "in",
  max = "max",
}

export enum Position {
  left = "left",
  top = "top",
  right = "right",
  bottom = "bottom",
}

export enum AttributeName {
  imageType = "image_type",
  file3DType = "3d_file_type",
  agitation = "agitation",
  lingual = "lingual",
  buccal = "buccal",
  pattern = "pattern",
  bop = "bop",
  pcr = "pcr",
}

export enum AnalyticDetailTab {
  total = "総合評価",
  pcr = "PCR",
  epp = "EPP",
  bop = "BOP",
}

export enum FileRequestState {
  none,
  downloadingRemoteFile,
  convertingPly,
  downloadingResult,
}

export enum RemoteFilesState {
  none,
  fetchingFiles,
  linkingSelectedPatient,
  combiningSelectedPlyFiles,
}

export enum ThreeDViewerState {
  none,
  uploadPlyFile,
}

export enum FileManagementState {
  none,
  submitButton,
  fetchFileManagement,
  importFileManagement,
}
