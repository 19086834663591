import { OrbitControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DI } from "src/base/Injector";
import { Strings } from "src/constants";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers";
import { IRootState } from "src/store";
import * as THREE from "three";
import { threeDViewerUpdateCameraPosition } from "../../../store/threeDViewer";
import "../css.css";
import ThreeDScene from "./ThreeDScene";

interface ThreeDMatchingPageProps {
  onClose: () => void;
  meshes: THREE.Object3D<THREE.Event>[];
  handleOpen3DEditor: (
    editorCameraPosition: [x: number, y: number, z: number]
  ) => void;
  handleSaveScene: () => void;
}

interface ThreeDMatchingPageState {}

class ThreeDMatchingPage extends React.Component<
  ThreeDMatchingPageType,
  ThreeDMatchingPageState
> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    DI.get().injectThreeDViewerDependencies();
  }

  calculatePositionViewer(
    type: string,
    currentType: string,
    position: number[]
  ) {
    switch (type) {
      case "LEFT": // [-50, 0, 0]
        if (currentType === type) return position;
        if (currentType === "CENTER")
          return [position[0] + 50, position[1], position[2] + 50];
        if (currentType === "RIGHT")
          return [position[0] + 100, position[1], position[2]];
        break;
      case "CENTER": // [0, 0, 50]
        if (currentType === type) return position;
        if (currentType === "LEFT")
          return [position[0] - 50, position[1], position[2] - 50];
        if (currentType === "RIGHT")
          return [position[0] + 50, position[1], position[2] - 50];
        break;
      case "RIGHT": // [50, 0, 0]
        if (currentType === type) return position;
        if (currentType === "CENTER")
          return [position[0] - 50, position[1], position[2] + 50];
        if (currentType === "LEFT")
          return [position[0] - 100, position[1], position[2]];
        break;
      default:
        break;
    }
    return position;
  }

  render(): React.ReactNode {
    let leftController: any = React.createRef();
    let rightController: any = React.createRef();
    let centerController: any = React.createRef();
    let currentCamera = "";

    return (
      <div className="threed-viewer-matching-modal">
        <div className="threed-viewer-matching">
          <div className="threed-viewer-matching-container">
            <div className="jaw-viewer-container">
              {/* Jaw Viewer Left */}
              <div className="jaw-column">
                <span className="jaw-title">
                  {Strings.THREED_VIEWER_MATCHING_JAW_TITLE_LEFT}
                </span>
                <div className="jaw-viewer">
                  <Canvas
                    shadows
                    camera={{
                      position: [-50, 0, 0],
                    }}
                  >
                    <ThreeDScene
                      meshes={this.props.meshes}
                      handleSelectMesh={() => undefined}
                    />
                    {
                      // this.props.type == "LEFT" &&
                      <OrbitControls
                        ref={leftController}
                        enabled={true}
                        rotateSpeed={1}
                        dampingFactor={1}
                        enableDamping={true}
                        enableRotate={true}
                        onChange={(e: any) => {
                          if (currentCamera == "LEFT") {
                            const { x, y, z } = e.target.object.position;
                            rightController.current.object.position.set(
                              ...this.calculatePositionViewer("LEFT", "RIGHT", [
                                x,
                                y,
                                z,
                              ])
                            );
                            centerController.current.object.position.set(
                              ...this.calculatePositionViewer(
                                "LEFT",
                                "CENTER",
                                [x, y, z]
                              )
                            );
                          }
                        }}
                        onStart={(e: any) => {
                          currentCamera = "LEFT";
                        }}
                        onEnd={(e: any) => {
                          currentCamera = "";
                        }}
                      />
                    }
                  </Canvas>
                </div>
                <div
                  className="jaw-btn"
                  onClick={() => this.props.handleOpen3DEditor([-50, 25, 0])}
                >
                  <span>{Strings.THREED_VIEWER_MATCHING_JAW_BTN_LEFT}</span>
                </div>
              </div>
              {/* Jaw Viewer Front */}
              <div className="jaw-column">
                <span className="jaw-title">
                  {Strings.THREED_VIEWER_MATCHING_JAW_TITLE_FRONT}
                </span>
                <div className="jaw-viewer">
                  <Canvas
                    shadows
                    camera={{
                      position: [0, 0, 50],
                    }}
                  >
                    <ThreeDScene
                      meshes={this.props.meshes}
                      handleSelectMesh={() => undefined}
                    />
                    <OrbitControls
                      ref={centerController}
                      enabled={true}
                      rotateSpeed={1}
                      dampingFactor={1}
                      enableDamping={true}
                      enableRotate={true}
                      onChange={(e: any) => {
                        if (currentCamera == "CENTER") {
                          const { x, y, z } = e.target.object.position;
                          rightController.current.object.position.set(
                            ...this.calculatePositionViewer("CENTER", "RIGHT", [
                              x,
                              y,
                              z,
                            ])
                          );
                          leftController.current.object.position.set(
                            ...this.calculatePositionViewer("CENTER", "LEFT", [
                              x,
                              y,
                              z,
                            ])
                          );
                        }
                      }}
                      onStart={(e: any) => {
                        currentCamera = "CENTER";
                      }}
                      onEnd={(e: any) => {
                        currentCamera = "";
                      }}
                    />
                  </Canvas>
                </div>
                <div
                  className="jaw-btn"
                  onClick={() => this.props.handleOpen3DEditor([0, 25, 50])}
                >
                  <span>{Strings.THREED_VIEWER_MATCHING_JAW_BTN_FRONT}</span>
                </div>
              </div>
              {/* Jaw Viewer Right */}
              <div className="jaw-column">
                <span className="jaw-title">
                  {Strings.THREED_VIEWER_MATCHING_JAW_TITLE_RIGHT}
                </span>
                <div className="jaw-viewer">
                  <Canvas
                    shadows
                    camera={{
                      position: [50, 0, 0],
                    }}
                  >
                    <ThreeDScene
                      meshes={this.props.meshes}
                      handleSelectMesh={() => undefined}
                    />
                    <OrbitControls
                      ref={rightController}
                      enabled={true}
                      rotateSpeed={1}
                      dampingFactor={1}
                      enableDamping={true}
                      enableRotate={true}
                      onChange={(e: any) => {
                        if (currentCamera == "RIGHT") {
                          const { x, y, z } = e.target.object.position;
                          centerController.current.object.position.set(
                            ...this.calculatePositionViewer("RIGHT", "CENTER", [
                              x,
                              y,
                              z,
                            ])
                          );
                          leftController.current.object.position.set(
                            ...this.calculatePositionViewer("RIGHT", "LEFT", [
                              x,
                              y,
                              z,
                            ])
                          );
                        }
                      }}
                      onStart={(e: any) => {
                        currentCamera = "RIGHT";
                      }}
                      onEnd={(e: any) => {
                        currentCamera = "";
                      }}
                    />
                  </Canvas>
                </div>
                <div
                  className="jaw-btn"
                  onClick={() => this.props.handleOpen3DEditor([50, 25, 0])}
                >
                  <span>{Strings.THREED_VIEWER_MATCHING_JAW_BTN_RIGHT}</span>
                </div>
              </div>
            </div>
            <div className="btn-group2 d-flex">
              <a className={`btn btn-secondary`} onClick={this.props.onClose}>
                戻る
              </a>
              <a
                className={`btn btn-primary`}
                onClick={this.props.handleSaveScene}
              >
                {Strings.THREED_VIEWER_MANAGEMENT_BTN_COMPLETE_EDIT}
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app, threeDViewer }: IRootState) => {
  const { currentModal, position, type } = app;
  const { cameraPosition } = threeDViewer;

  return {
    currentModal,
    position,
    type,
    cameraPosition,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
    openThreeDModal: (position: any, type: string, modal: Modals) => {
      dispatch(threeDViewerUpdateCameraPosition(position, type));
      dispatch(changeModal(modal));
    },
  };
};

type ThreeDMatchingPageType = ThreeDMatchingPageProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(
  mapStateToProps,
  mapDispatcherToProps
)(ThreeDMatchingPage);
