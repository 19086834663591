import { MedicalRecordState } from "src/base/Enums";
import { defaultRecord } from "src/features/medicalRecord/data/models/medicalRecord";
import { paginationInit } from "src/shared/model/pagination";
import { cloneArray } from "src/utils/utils";
import { IMedicalRecordState, MedicalRecordActions, MedicalRecordActionType } from "./types";

const initialState: IMedicalRecordState = {
  medicalRecords: paginationInit,
  selectedMedicalRecord: defaultRecord(),
  searchedOrthodontists: paginationInit,
  searchedPatients: paginationInit,
  medicalRecordState: MedicalRecordState.none,
  isSubmitting: false,
  attributes: [],
  selectedToothIndex: 1,
  tooth: new Map(),
};

export function medicalRecordReducer(state: IMedicalRecordState = initialState, action: MedicalRecordActions) {
  switch (action.type) {
    case MedicalRecordActionType.MEDICAL_RECORDS_FETCHED:
      return {
        ...state,
        medicalRecords: action.payload.records,
      };
    case MedicalRecordActionType.MEDICAL_RECORD_UPDATED:
      let records = { ...state.medicalRecords };
      let updatedIndex = records.data.map((record) => record.id).indexOf(action.payload.record.id);
      if (updatedIndex > -1) {
        records.data[updatedIndex] = { ...action.payload.record };
      }
      return {
        ...state,
        records: {
          ...state.medicalRecords,
          ...records,
        },
      };
    case MedicalRecordActionType.MEDICAL_RECORD_PAGINATION_UPDATED:
      return {
        ...state,
        records: {
          ...state.medicalRecords,
          [action.payload.key]: action.payload.value,
        },
      };
    case MedicalRecordActionType.MEDICAL_RECORD_STATE_CHANGED:
      return {
        ...state,
        medicalRecordState: action.payload.medicalRecordState,
      };
    case MedicalRecordActionType.SELECTED_TOOTH_UPDATED:
      return {
        ...state,
        selectedToothIndex: action.payload.toothIndex,
        tooth: state.selectedMedicalRecord.teeth?.get(action.payload.toothIndex) ?? new Map(),
      };
    case MedicalRecordActionType.SELECTED_MEDICAL_RECORD_UPDATED:
      return {
        ...state,
        selectedMedicalRecord: {
          ...action.payload.record,
        },
      };
    case MedicalRecordActionType.SELECTED_MEDICAL_RECORD_DATA_UPDATED:
      return {
        ...state,
        selectedMedicalRecord: {
          ...state.selectedMedicalRecord,
          [action.payload.key]: action.payload.value,
        },
      };
    case MedicalRecordActionType.MEDICAL_ORTHODONTISTS_SEARCHED:
      let selectedOthodontists = cloneArray(state.selectedMedicalRecord.orthodontists);
      let searchedOrthodontists = { ...action.payload.orthodontists };
      selectedOthodontists.forEach((orthodontist) => {
        let duplicateIndex = searchedOrthodontists.data.map((orthodontist) => orthodontist.id).indexOf(orthodontist.id);
        if (duplicateIndex > -1) {
          searchedOrthodontists.data.splice(duplicateIndex, 1);
        }
      });
      return {
        ...state,
        searchedOrthodontists: {
          ...state.searchedOrthodontists,
          ...searchedOrthodontists,
        },
      };
    case MedicalRecordActionType.MEDICAL_PATIENTS_SEARCHED:
      return {
        ...state,
        searchedPatients: {
          ...state.searchedPatients,
          ...action.payload.patients,
        },
      };
    case MedicalRecordActionType.UPDATE_MEDICAL_SUBMITING:
      return {
        ...state,
        isSubmitting: action.payload.isSubmitting,
      };
    case MedicalRecordActionType.ATTRIBUTES_FETCHED:
      return {
        ...state,
        attributes: action.payload.attributes,
      };
    case MedicalRecordActionType.UPDATE_TOOTH_INFO:
      // const tooth = state.selectedMedicalRecord?.teeth?.get( action.payload.toothIndex ) ?? new Map();
      const tooth = state.tooth ?? new Map();
      tooth.set(action.payload.key, { value: action.payload.value });

      return {
        ...state,
        selectedMedicalRecord: {
          ...state.selectedMedicalRecord,
          tooth: tooth,
        },
      };
    case MedicalRecordActionType.SAVE_TOOTH_INFO:
      const tempTeeth = state.selectedMedicalRecord?.teeth ?? new Map();
      tempTeeth?.set(action.payload.toothIndex, action.payload.tooth);
      return {
        ...state,
        selectedMedicalRecord: {
          ...state.selectedMedicalRecord,
          teeth: tempTeeth,
        },
        tooth: new Map(),
      };
    default:
      return state;
  }
}
