import { Either, LeftSide, RightSide } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { IAccountRemoteDatasource } from "../datasources/accountRemoteDataSource";
import { IUser } from "../models/user";

export interface IAccountRepository {
    getMe(): Promise<Either<Failure, IUser>>
    updateAccount(full_name: string, username: string, password: string): Promise<Either<Failure, void>>
}

export class AccountRepository implements IAccountRepository {
    accountRemoteDatasource: IAccountRemoteDatasource
    constructor(accountRemoteDataSource: IAccountRemoteDatasource) {
        this.accountRemoteDatasource = accountRemoteDataSource
    }


    async getMe(): Promise<Either<Failure, IUser>> {
        try {
            return RightSide(await this.accountRemoteDatasource.getMe())
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e })
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message })
            }
        }
        return LeftSide(new Error(""));
    }

    async updateAccount(full_name: string, username: string, password: string): Promise<Either<Failure, void>> {
        try {
            return RightSide(await this.accountRemoteDatasource.updateAccount(full_name, username, password))
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e })
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message })
            }
        }
        return LeftSide(new Error(""));
    }
}