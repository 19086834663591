import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { Pagination } from "src/shared/model/pagination";
import { INotificationRepository } from "../../data/repositories/notificationRepository";
import { INotification } from "../../presenter/store/notification/types";

export class FetchNotification extends UseCase<NotificationParams, Pagination<INotification>>{
    repository: INotificationRepository
    constructor(repository: INotificationRepository) {
        super()
        this.repository = repository
    }
    call(params: NotificationParams): Promise<Either<Failure, Pagination<INotification>>> {
        return this.repository.fetchNotification(params.page)
    }
}

class NotificationParams {
    page: number
    constructor(page: number) {
        this.page = page
    }
}