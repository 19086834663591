import React from "react";
import { Position } from "src/base/Enums";
import { ArrayFunction } from "src/helpers/arrayFunction";
import { Input } from "../input";

const PCRTemplate = [
  {
    position: Position.left,
    path: "M20.5 20L0 0.5V39.5L20.5 20Z",
  },
  {
    position: Position.top,
    path: "M20.5 20.5L40 0L1 -1.70474e-06L20.5 20.5Z",
  },
  {
    position: Position.right,
    path: "M19 19.5L39.5 39L39.5 0L19 19.5Z",
  },
  {
    position: Position.bottom,
    path: "M20.5 19L1 39.5L40 39.5L20.5 19Z",
  },
];

interface IPCROptionProps {
  selectedPositions: Array<Position>;
  readonly?: boolean;
  onChange: (positions: Array<Position>) => void;
}

export class PCROption extends React.Component<IPCROptionProps> {
  updatePCR(position: Position, value: boolean) {
    return value
      ? ArrayFunction.findOrPush(this.props.selectedPositions, position)
      : ArrayFunction.removeValue(this.props.selectedPositions, position);
  }
  render(): React.ReactNode {
    return (
      <div className="fsc position-custom">
        {PCRTemplate.map((item, idx) => (
          <label
            key={idx}
            className={`image-checkbox image-checkbox1 mr20 ${
              this.props.selectedPositions.indexOf(item.position) !== -1 ? "image-checkbox-checked" : ""
            }`}
            title={item.position}
            htmlFor={this.props.readonly ? undefined : item.position}
          >
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="40" height="40" fill="#E9E8E8"></rect>
              <path d={item.path} fill="white"></path>
            </svg>
            <Input
              type="checkbox"
              name="position"
              readonly={this.props.readonly}
              value={item.position}
              id={item.position}
              onChange={(value) => {
                if (!this.props.readonly) this.props.onChange(this.updatePCR(item.position, value));
              }}
              checked={this.props.selectedPositions.indexOf(item.position) !== -1}
            />
          </label>
        ))}
      </div>
    );
  }
}
