import React from "react";
import QRCode, { QRCodeProps } from "react-qr-code";

class QRCodeViewer extends React.Component<QRCodeProps> {
  render() {
    return <QRCode value={this.props.value ?? ""} size={this.props.size} />;
  }
}

export default QRCodeViewer;
