import React from "react";
import * as THREE from "three";

export interface ThreeDViewerModelEditorProps {
  mesh: THREE.Mesh;
  handleSelectMesh: (mesh: THREE.Mesh) => void;
}

export interface ThreeDViewerModelEditorState {}

class ThreeDModelEditor extends React.Component<
  ThreeDViewerModelEditorProps,
  ThreeDViewerModelEditorState
> {
  private meshRef: React.MutableRefObject<THREE.Mesh>;

  constructor(props: any) {
    super(props);

    this.meshRef = React.createRef() as React.MutableRefObject<THREE.Mesh>;
  }

  componentDidMount(): void {
    this.meshRef.current.position.copy(this.props.mesh.position);
    this.meshRef.current.rotation.copy(this.props.mesh.rotation);
    this.meshRef.current.scale.copy(this.props.mesh.scale);
    this.meshRef.current.updateMatrixWorld();
  }

  render(): React.ReactNode {
    return (
      <mesh
        ref={this.meshRef}
        geometry={this.props.mesh.geometry}
        material={this.props.mesh.material}
        scale={1}
        onClick={() => this.props.handleSelectMesh(this.meshRef.current!)}
      />
    );
  }
}

export default ThreeDModelEditor;
