import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IOrthodontistRepository } from "../../data/repositories/orthodontistRepository";

export class SendEmailToOrthodontist extends UseCase<SendEmailToOrthodontistParams, void>{
  private repository: IOrthodontistRepository
  constructor(repository: IOrthodontistRepository) {
    super()
    this.repository = repository
  }

  async call(params: SendEmailToOrthodontistParams): Promise<Either<Failure, void>> {
    return this.repository.sendMailToOrthodontist(params.orthodontistId)
  }
}

export class SendEmailToOrthodontistParams {
  orthodontistId: string
  constructor(orthodontistId: string) {
    this.orthodontistId = orthodontistId
  }
}