import React from "react";

interface IInputGroupProps {
  children?: React.ReactNode;
  className?: string;
  text?: string;
  htmlFor?: string;
  onClick?: (e: any) => void
}

export class Label extends React.Component<IInputGroupProps> {
  render() {
    return (
      <label onClick={this.props.onClick} className={`${this.props.className ?? ''}`} htmlFor={this.props.htmlFor ?? ''}>
        {this.props.children}
        {this.props.text}
      </label>
    );
  }
}