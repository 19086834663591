import { Either, LeftSide, match, RightSide } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { Pagination } from "src/shared/model/pagination";
import { INotification } from "../../presenter/store/notification/types";
import { INotificationRemoteDataSource } from "../datasources/notificationRemoteDatasource";

export interface INotificationRepository {
    fetchNotification(page: number): Promise<Either<Failure, Pagination<INotification>>>,
    fetchNotificationDetail(notificationId: string): Promise<Either<Failure, INotification | undefined>>,
    saveNotification(notification: INotification): Promise<Either<Failure, INotification>>,
    deleteNotification(notificationId: number): Promise<Either<Failure, boolean>>,
}

export class NotificationRepository implements INotificationRepository {
    notificationRemoteDataSource: INotificationRemoteDataSource
    constructor(notificationRemoteDataSource: INotificationRemoteDataSource) {
        this.notificationRemoteDataSource = notificationRemoteDataSource
    }
  
    async fetchNotification(page: number): Promise<Either<Failure, Pagination<INotification>>> {
        try {
            return RightSide(await this.notificationRemoteDataSource.fetchNotification(page));
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e })
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message })
            }
        }
        return LeftSide(new Error(""));
    }

    async fetchNotificationDetail(notificationId: string): Promise<Either<Failure, INotification | undefined>> {
        try {
            return RightSide(await this.notificationRemoteDataSource.fetchNotificationDetail(notificationId));
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e })
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message })
            }
        }
        return LeftSide(new Error(""));
    }

    async saveNotification(notification: INotification): Promise<Either<Failure, INotification>> {
        try {
            return RightSide(await this.notificationRemoteDataSource.saveNotification(notification));
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e })
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message })
            }
        }
        return LeftSide(new Error(""));
    }

    async deleteNotification(notificationId: number): Promise<Either<Failure, boolean>> {
        try {
            return RightSide(await this.notificationRemoteDataSource.deleteNotification(notificationId));
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e })
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message })
            }
        }
        return LeftSide(new Error(""));
    }
}