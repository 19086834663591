import React from "react";
import { Strings } from "src/constants";
import { ICard } from "src/features/subscription/domain/model/card";
import { LoadingComponent } from "src/shared/components/loadingComponent";
import { pushPopupById } from "src/utils/utils";
import { PopupComponent } from "./popup";

export interface PaymentManagementPopupProps {
    cards: ICard[]
    isFetching: boolean
    isSubmitting: boolean
    onChangePlan: () => void
    onSelectCard: (card: ICard) => void
    onResetDraftCard: () => void
    onSeeCardDetail: (card: ICard) => void
}

export class PaymentManagementPopup extends React.Component<PaymentManagementPopupProps> {

    render() {
        return <PopupComponent id="payment-method">
            <h3>お支払方法選択</h3>
            <div className="scrollable">
                <LoadingComponent isLoading={this.props.isFetching}>
                    <ul className="listmethod">
                        {this.props.cards.map((card) => {
                            return <li className={`item js-item-method mb10 ${card.current ? "active" : ""} flexbox`} onClick={(e) => {
                                this.props.onSelectCard(card)
                            }}><span className="icon ic-check">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                                        <path d="M31 15.5C31 19.6109 29.367 23.5533 26.4602 26.4602C23.5533 29.367 19.6109 31 15.5 31C11.3891 31 7.44666 29.367 4.53984 26.4602C1.63303 23.5533 0 19.6109 0 15.5C0 11.3891 1.63303 7.44666 4.53984 4.53984C7.44666 1.63303 11.3891 0 15.5 0C19.6109 0 23.5533 1.63303 26.4602 4.53984C29.367 7.44666 31 11.3891 31 15.5ZM23.3081 9.62937C23.1697 9.49146 23.0049 9.38287 22.8236 9.3101C22.6423 9.23734 22.4482 9.20188 22.2528 9.20586C22.0575 9.20984 21.8649 9.25317 21.6867 9.33326C21.5085 9.41336 21.3483 9.52856 21.2156 9.672L14.4867 18.2454L10.4315 14.1883C10.156 13.9316 9.7917 13.7919 9.41524 13.7985C9.03878 13.8052 8.67959 13.9577 8.41336 14.2239C8.14712 14.4902 7.99462 14.8493 7.98797 15.2258C7.98133 15.6023 8.12107 15.9666 8.37775 16.2421L13.5044 21.3706C13.6425 21.5085 13.8069 21.6171 13.9879 21.69C14.1689 21.7629 14.3628 21.7987 14.5579 21.795C14.753 21.7914 14.9454 21.7486 15.1235 21.669C15.3017 21.5894 15.462 21.4748 15.5949 21.3319L23.3294 11.6637C23.5931 11.3896 23.7388 11.023 23.7352 10.6426C23.7315 10.2622 23.5789 9.89846 23.3101 9.62937H23.3081Z" fill="#C0C8CD"></path>
                                    </svg></span><span className="icon ic-card">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="56" height="42" viewBox="0 0 56 42" fill="none">
                                        <path d="M7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7L0 35C0 36.8565 0.737498 38.637 2.05025 39.9497C3.36301 41.2625 5.14348 42 7 42H49C50.8565 42 52.637 41.2625 53.9497 39.9497C55.2625 38.637 56 36.8565 56 35V7C56 5.14348 55.2625 3.36301 53.9497 2.05025C52.637 0.737498 50.8565 0 49 0H7ZM52.5 7V24.5H3.5V7C3.5 6.07174 3.86875 5.1815 4.52513 4.52513C5.1815 3.86875 6.07174 3.5 7 3.5H49C49.9283 3.5 50.8185 3.86875 51.4749 4.52513C52.1312 5.1815 52.5 6.07174 52.5 7ZM49 38.5H7C6.07174 38.5 5.1815 38.1312 4.52513 37.4749C3.86875 36.8185 3.5 35.9283 3.5 35V31.5H52.5V35C52.5 35.9283 52.1312 36.8185 51.4749 37.4749C50.8185 38.1312 49.9283 38.5 49 38.5Z" fill="#C0C8CD"></path>
                                    </svg></span><span className="num">{card.number}</span><div className="border-box-card ml10">   <a className="text_underline text_bold" onClick={() => {
                                        this.props.onSeeCardDetail(card)
                                        pushPopupById("payment-infor")
                                    }}>{Strings.CHANGE}</a></div> </li>
                        })}
                    </ul>
                </LoadingComponent>

            </div>
            <a className="add-cart" onClick={() => {
                this.props.onResetDraftCard()
                pushPopupById("payment-infor")
            }}>＋カードを追加する</a>
            <LoadingComponent size={100} isLoading={this.props.isSubmitting}>
                <button onClick={() => {
                    this.props.onChangePlan()
                }} className="btn-payment disable" disabled={!this.props.cards.filter((card) => card.current)} type="submit">登録する</button>
            </LoadingComponent>
        </PopupComponent >
    }
}