import { PatientState, SubscriptionState } from "src/base/Enums";
import { ActionType } from "typesafe-actions";
import * as actions from "./actions";

export interface IUnsharedFilesState {
  unsharedFilesCount: number
}

export enum UnsharedFilesActionType {
  UNSHARED_FILES_COUNT_UPDATED = "UNSHARED_FILES_COUNT_UPDATED"
}

export type UnsharedFilesActions = ActionType<typeof actions>;
