import { makeid } from "src/utils/utils";
import { Editor } from "../Editor";
import { Exporter } from "../exporters/Exporter";

export class ThreeJsModule {
  private static _instance?: ThreeJsModule;
  editors: Map<String, Editor> = new Map();
  convertTimout: any;
  private constructor() {}

  public static getInstance(): ThreeJsModule {
    if (!this._instance) {
      this._instance = new ThreeJsModule();
    }
    return this._instance;
  }

  public loadModelFromFileList(
    files: FileList | File[],
    exporter: Exporter,
    onFilesConverted: (convertedFiles: any[]) => void,
    keys?: string[]
  ) {
    try {
      if (this.convertTimout) {
        clearTimeout(this.convertTimout);
      }
      this.convertTimout = setTimeout(() => {
        onFilesConverted([]);
      }, 10000);
      let convertedFiles: any[] = [];
      for (var i = 0; i < files.length; i++) {
        let key = undefined;
        if (keys && keys.length === files.length) {
          key = keys![i];
          if (keys.length !== files.length) {
            onFilesConverted([]);
            if (this.convertTimout) {
              clearTimeout(this.convertTimout);
            }
          }
          key = keys![i];
        }
        this.importFile(
          files[i],
          exporter,
          (data) => {
            if (data) {
              convertedFiles.push(data);
            } else {
              onFilesConverted([]);
              return;
            }
            if (convertedFiles.length === files.length) {
              onFilesConverted(convertedFiles);
            }
            if (this.convertTimout) {
              clearTimeout(this.convertTimout);
            }
          },
          key
        );
      }
    } catch (e) {
      //TODO catch error here
      onFilesConverted([]);
      if (this.convertTimout) {
        clearTimeout(this.convertTimout);
      }
    }
  }

  private importFile(
    file: any,
    exporter: Exporter,
    onFileConverted: (convertedFile: any) => void,
    withKey?: string
  ) {
    let id = makeid(20) + new Date().getTime().toString();
    let editor = new Editor();
    editor.editorCallback = {
      onObjectReady: () => {
        exporter.exportModel(
          file.name || "",
          Object.create(this.editors.get(id)?.scene!),
          onFileConverted,
          withKey
        );
        this.editors.delete(id);
      },
      onError: (e: any) => {
        onFileConverted(undefined);
      },
    };
    editor.loader.loadFiles([file]);
    this.editors.set(id, editor);
  }
}
