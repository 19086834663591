import { Either, LeftSide, RightSide } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { IRemoteFileManagementRemoteDataSource } from "../datasources/remoteFileManagementRemoteDataSource";
import { IFile } from "../models/file";
import { IFileManagement } from "../models/fileManagement";
import { IFolder } from "../models/folder";

export interface IRemoteFileManagementRepository {
  fetchFileManagement(
    breadcrumbPath: string,
    search?: string,
    only?: string
  ): Promise<Either<Failure, IFileManagement>>;
  createFolderFileManagement(breadcrumbPath: string, name: string): Promise<Either<Failure, void>>;
  importFileManagement(breadcrumbPath: string, files: File[], action?: string): Promise<Either<Failure, void>>;
  downloadFilesFileManagement(breadcrumbPath: string, files: IFile[]): Promise<Either<Failure, Blob>>;
  downloadFolderFileManagement(breadcrumbPath: string, folder: IFolder): Promise<Either<Failure, string>>;
  moveFileManagement(
    oldPath: string,
    newPath: string,
    itemToMove?: IFolder | IFile | IFile[]
  ): Promise<Either<Failure, void>>;
  renameFileManagement(itemSelected: IFolder | IFile, rename: string): Promise<Either<Failure, void>>;
  deleteFileManagement(
    breadcrumbPath: string,
    itemToDelete?: IFolder | IFile | IFile[]
  ): Promise<Either<Failure, void>>;
}

export class RemoteFileManagementRepository implements IRemoteFileManagementRepository {
  fileManagementDataSource: IRemoteFileManagementRemoteDataSource;

  constructor(remoteDataSource: IRemoteFileManagementRemoteDataSource) {
    this.fileManagementDataSource = remoteDataSource;
  }

  async fetchFileManagement(
    breadcrumbPath: string,
    search?: string,
    only?: string
  ): Promise<Either<Failure, IFileManagement>> {
    try {
      return RightSide(await this.fileManagementDataSource.fetchFileManagement(breadcrumbPath, search, only));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }

    return LeftSide(new Error(""));
  }

  async createFolderFileManagement(breadcrumbPath: string, name: string): Promise<Either<Failure, void>> {
    try {
      return RightSide(await this.fileManagementDataSource.createFolderFileManagement(breadcrumbPath, name));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }

    return LeftSide(new Error(""));
  }

  async importFileManagement(breadcrumbPath: string, files: File[], action?: string): Promise<Either<Failure, void>> {
    try {
      return RightSide(await this.fileManagementDataSource.importFileManagement(breadcrumbPath, files, action));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }

    return LeftSide(new Error(""));
  }

  async downloadFilesFileManagement(breadcrumbPath: string, files: IFile[]): Promise<Either<Failure, Blob>> {
    try {
      return RightSide(await this.fileManagementDataSource.downloadFilesFileManagement(breadcrumbPath, files));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }

    return LeftSide(new Error(""));
  }

  async downloadFolderFileManagement(breadcrumbPath: string, folder: IFolder): Promise<Either<Failure, string>> {
    try {
      return RightSide(await this.fileManagementDataSource.downloadFolderFileManagement(breadcrumbPath, folder));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }

    return LeftSide(new Error(""));
  }

  async moveFileManagement(
    oldPath: string,
    newPath: string,
    itemToMove?: IFolder | IFile | IFile[]
  ): Promise<Either<Failure, void>> {
    try {
      return RightSide(await this.fileManagementDataSource.moveFileManagement(oldPath, newPath, itemToMove));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }

    return LeftSide(new Error(""));
  }

  async renameFileManagement(itemSelected: IFolder | IFile, rename: string): Promise<Either<Failure, void>> {
    try {
      return RightSide(await this.fileManagementDataSource.renameFileManagement(itemSelected, rename));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }

    return LeftSide(new Error(""));
  }

  async deleteFileManagement(
    breadcrumbPath: string,
    itemToDelete?: IFolder | IFile | IFile[]
  ): Promise<Either<Failure, void>> {
    try {
      return RightSide(await this.fileManagementDataSource.deleteFileManagement(breadcrumbPath, itemToDelete));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }

    return LeftSide(new Error(""));
  }
}
