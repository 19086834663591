import { Endpoints } from "src/base/Endpoints";
import { MFile, mFileFromJson } from "src/features/medicalRecord/data/models/file";
import { IMedicalRecord, medicalRecordFromJson } from "src/features/medicalRecord/data/models/medicalRecord";
import { HttpMethod } from "src/helpers";
import { IApiService } from "src/services/api/ApiService";
import { Pagination, paginationInit } from "src/shared/model/pagination";
import { sleep } from "src/utils/utils";

export interface IRemoteFileDownloadRemoteDataSource {
    fetchAllRemoteFiles(page: number): Promise<Pagination<MFile>>;
    linkSelectedPatient(patientId: any): Promise<IMedicalRecord | undefined>
    deleteRemoteFile(id: string): Promise<boolean>;
}

export class RemoteFileDownloadRemoteDataSource implements IRemoteFileDownloadRemoteDataSource {
    apiService: IApiService;
    constructor(apiService: IApiService) {
        this.apiService = apiService;
    }

    async fetchAllRemoteFiles(page: number): Promise<Pagination<MFile>> {
        let response = await this.apiService.request({
            method: HttpMethod.GET,
            endpoint: Endpoints.fetchRemoteFiles,
            params: {
                page,
                limit: 8
            },
            shouldAuthorize: true,
        });
        return {
            ...response.data.data,
            data: (response.data.data.data as Array<any>).map((file) => { return { ...mFileFromJson(file) } })
        }
    }

    async deleteRemoteFile(id: string): Promise<boolean> {
        await this.apiService.request({
            method: HttpMethod.DELETE,
            endpoint: Endpoints.deleteRemoteFiles(id),
            shouldAuthorize: true,
        });
        return true
    }

    async linkSelectedPatient(patientId: any): Promise<IMedicalRecord | undefined> {
        let response = await this.apiService.request({
            method: HttpMethod.POST,
            endpoint: Endpoints.linkToPatient(patientId),
            shouldAuthorize: true,
        });
        if (!response.data || !response.data.data || !response.data.data.id) return undefined
        return medicalRecordFromJson(response.data.data)
    }
}