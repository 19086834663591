import { AnalyticDetailTab, PatientAnalyticState } from "src/base/Enums";
import {
  defaultPatientAnalysisDetail as defaultPatientAnalysisDetail,
  defaultPatientAnalytic,
} from "src/features/analytic/data/models/patientAnalytic";
import { paginationInit } from "src/shared/model/pagination";
import {
  IPatientAnalyticState,
  PatientAnalyticActions,
  PatientAnalyticType,
} from "./types";

const intialState: IPatientAnalyticState = {
  patientAnalyticState: PatientAnalyticState.none,
  tab: {
    analysisId: "",
    type: AnalyticDetailTab.total,
  },
  patientAnalyticDetail: defaultPatientAnalytic,
  patientAnalytics: paginationInit,
};

export function patientAnalyticReducer(
  state: IPatientAnalyticState = intialState,
  action: PatientAnalyticActions
) {
  switch (action.type) {
    //Fetch analytics and update its state
    case PatientAnalyticType.PATIENT_ANALYTICS_FETCHED:
      return {
        ...state,
        patientAnalytics: action.payload.analytics,
      };
    //Update UI/UX states
    case PatientAnalyticType.PATIENT_ANALYTIC_STATE_CHANGED:
      return {
        ...state,
        patientAnalyticState: action.payload.patientAnalyticState,
      };
    case PatientAnalyticType.PATIENT_ANALYTIC_DETAILS_FETCHED:
      var analyticDetails = state.patientAnalyticDetail.analytic_details;
      analyticDetails.set(action.payload.key, action.payload.analysis);

      return {
        ...state,
        patientAnalyticDetail: {
          ...state.patientAnalyticDetail,
          analyticDetails: analyticDetails,
        },
      };
    case PatientAnalyticType.PATIENT_ANALYTIC_DETAILS_TAB_CHANGED:
      return {
        ...state,
        tab: {
          ...state.tab,
          analysisId: action.payload.analysisId,
          type: action.payload.type,
        },
      };
    case PatientAnalyticType.PATIENT_ANALYTIC_ON_DATES_SHOWED:
      var analyticDetails = new Map(state.patientAnalyticDetail.analytic_details);
      var selectedAnalyticDetail = {
        ...(analyticDetails.get(action.payload.analysisKey) ??
          defaultPatientAnalysisDetail),
      };
      selectedAnalyticDetail.analysis.dates = [...action.payload.dates];
      analyticDetails.set(action.payload.analysisKey, selectedAnalyticDetail);

      return {
        ...state,
        patientAnalyticDetail: {
          ...state.patientAnalyticDetail,
          analyticDetails: {
            ...state.patientAnalyticDetail.analytic_details,
            ...analyticDetails
          },
        },
      };
      case PatientAnalyticType.PATIENT_ANALYTIC_IS_NOT_FOUND_UPDATED:
        return {
          ...state,
          isNotFound: action.payload.isNotFound
        }
    default:
      return state;
  }
}
