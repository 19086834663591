import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { NoParam } from "src/base/NoParam";
import { UseCase } from "src/base/UseCase";
import { ISubscriptionRepository } from "../../data/repositories/subscriptionRepository";

export class CancelPlan extends UseCase<NoParam, boolean> {
  private repository: ISubscriptionRepository;
  constructor(repository: ISubscriptionRepository) {
    super();
    this.repository = repository;
  }

  call(params: NoParam): Promise<Either<Failure, boolean>> {
    return this.repository.cancelPlan();
  }
}
