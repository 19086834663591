import axios from "axios";
import { Agent } from "https";
import { Endpoints } from "src/base/Endpoints";
import { TOKEN } from "src/features/auth/data/datasources/authRemoteDatasource";
import { HttpMethod } from "src/helpers";
import { IApiService } from "src/services/api/ApiService";
import { Pagination, paginationInit } from "src/shared/model/pagination";
import { formatDate } from "src/utils/utils";
import { Attribute, mAttributeFromJson } from "../models/attribute";
import { IMedicalRecord, defaultRecord, formDataMedicalRecord, medicalRecordFromJson } from "../models/medicalRecord";

export interface IMedialRecordRemoteDataSource {
  fetchMedicalRecords(
    code: string,
    full_name: string,
    page: number,
    fieldRequired?: Array<any>,
    orthodontistId?: number,
    fromDate?: Date,
    toDate?: Date,
    date_order?: string
  ): Promise<Pagination<IMedicalRecord>>;
  addNewMedicalRecord(medicalRecord: IMedicalRecord): Promise<void>;
  updateMedicalRecord(medicalRecord: IMedicalRecord): Promise<void>;
  deleteMedicalRecord(id: string): Promise<void>;
  duplicateMedicalRecord(id: string): Promise<void>;
  fetchAttributes(): Promise<Pagination<Attribute>>;
  fetchMedicalRecordDetail(id: string): Promise<IMedicalRecord>;
}

export class MedicalRecordRemoteDataSource implements IMedialRecordRemoteDataSource {
  apiService: IApiService;
  constructor(apiService: IApiService) {
    this.apiService = apiService;
  }

  async fetchMedicalRecords(
    code: string,
    full_name: string,
    page: number,
    fieldRequired?: Array<any>,
    orthodontistId?: number,
    fromDate?: Date,
    toDate?: Date,
    date_order?: string
  ): Promise<Pagination<IMedicalRecord>> {
    let params: any = {
      code: code,
      date_order: date_order,
      page,
    };
    if (full_name) {
      params.full_name = full_name;
    }
    if (fromDate) {
      fromDate?.setHours(0, 0, 0, 0);
      params.start_time = formatDate(fromDate, true);
    }
    if (toDate) {
      toDate?.setHours(23, 59, 59, 999);
      params.end_time = formatDate(toDate, true);
    }
    if (fieldRequired) {
      for (let field of fieldRequired) {
        switch (field.value) {
          case "pcr":
            params.pcr = true;
            break;
          case "images":
            params.images = true;
            break;
          case "stl":
            params.files = true;
            break;
        }
      }
    }
    if (orthodontistId) {
      params.orthodontist_ids = [orthodontistId];
    }
    var response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.fetchMedicalRecords,
      params: params,
      shouldAuthorize: true,
    });
    if (!response.data || !response.data.data) return paginationInit;
    return {
      ...response.data.data,
      data: (response.data.data.data as Array<any>).map((medicalRecord) => medicalRecordFromJson(medicalRecord)),
    };
  }

  async addNewMedicalRecord(medicalRecord: IMedicalRecord): Promise<void> {
    try {
      const agent = new Agent({
        rejectUnauthorized: false,
      });
      let formData = formDataMedicalRecord("add", medicalRecord);
      await axios({
        method: "POST",
        url: process.env.REACT_APP_API_URL + Endpoints.addMedicalRecord,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        httpsAgent: agent,
        data: formData,
        headers: {
          Authorization: "Bearer " + localStorage.getItem(TOKEN) ?? "",
        },
      });
    } catch (e) {
      this._handleError(e);
    }
  }

  _handleError(error: any) {
    const data: any = {
      error: error,
      status: false,
    };
    if (error.response && error.response.data) {
      data.data = error.response.data;
    }
    let errorText: any = "";
    if (data.data) {
      if (data.data.details) {
        const errors = Object.values(data.data.details[Object.keys(data.data.details)[0]]);
        if (Array.isArray(errors)) {
          errorText = errors[0];
        }
      } else {
        errorText = data.data.message ?? "";
      }
    } else if (data.error && data.error.details) {
      if (data.error.details) {
        const errors = Object.values(data.error.details[Object.keys(data.error.details)[0]]);
        if (Array.isArray(errors)) {
          errorText = errors[0];
        }
      } else {
        errorText = data.error.message ?? "";
      }
    }

    throw errorText;
  }

  async updateMedicalRecord(medicalRecord: IMedicalRecord): Promise<void> {
    try {
      const agent = new Agent({
        rejectUnauthorized: false,
      });
      let formData = formDataMedicalRecord("update", medicalRecord);
      await axios({
        method: "POST",
        url: process.env.REACT_APP_API_URL + Endpoints.updateMedicalRecord(medicalRecord.id),
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        httpsAgent: agent,
        data: formData,
        headers: {
          Authorization: "Bearer " + localStorage.getItem(TOKEN) ?? "",
        },
      });
    } catch (e) {
      this._handleError(e);
    }
  }

  async deleteMedicalRecord(id: string): Promise<void> {
    await this.apiService.request({
      method: HttpMethod.DELETE,
      endpoint: Endpoints.deleteMedicalRecord(id),
      shouldAuthorize: true,
    });
  }

  async duplicateMedicalRecord(id: string): Promise<void> {
    await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: Endpoints.duplicateMedicalRecord(id),
      shouldAuthorize: true,
    });
  }

  async fetchAttributes(): Promise<Pagination<Attribute>> {
    const params = {
      limit: 1000,
    };

    var response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.fetchAttributes,
      params: params,
      shouldAuthorize: true,
    });
    if (!response.data || !response.data.data) return paginationInit;
    return {
      ...response.data.data,
      data: (response.data.data.data as Array<any>).map((medicalRecord) => mAttributeFromJson(medicalRecord)),
    };
  }

  async fetchMedicalRecordDetail(id: string): Promise<IMedicalRecord> {
    var response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.fetchMedicalRecordDetail(id),
      shouldAuthorize: true,
    });
    if (!response.data || !response.data.data || !response.data.data.id || response.data.data.id.toString() !== id)
      return {
        ...defaultRecord(),
        isNotFound: true,
      };
    return { ...medicalRecordFromJson(response.data.data), isNotFound: false };
  }
}
