export const MapFunction = {
  objToMap
};

function objToMap(obj: any) {
  const keys = Object.keys(obj);
  const map = new Map();
  for(let i = 0; i < keys.length; i++){
    //inserting new key value pair inside map
    map.set(keys[i], obj[keys[i]]);
  };
  return map;
}