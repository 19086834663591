import { InputValidation } from "src/models/inputValidation/InputValidation";
import { Pagination } from "src/shared/model/pagination";
import { ActionType } from "typesafe-actions";
import * as actions from "./actions";

export interface INotification {
  id: number;
  activityDate: InputValidation<Date>;
  type: string;
  clinic_id?: number;
  title: InputValidation<string>;
  image?: string;
  content: InputValidation<string>;
  pdf?: InputValidation<string>;
  pdfName?: InputValidation<string>;
  job?: string;
  deletedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
  updateOnly?: boolean;
  isNotFound?: boolean;
}

export interface INotificationState {
  notifications: Pagination<INotification>;
  currentNotification: INotification;

  validate: boolean;
  state: boolean;
}

export enum NotificationActionType {
  FETCH_NOTIFICATION = "FETCH_NOTIFICATION",
  NOTIFICATION_STATE_CHANGED = "NOTIFICATION_STATE_CHANGED",
  UPDATE_NOTIFICATION = "UPDATE_NOTIFICATION",
  CHOSSE_NOTIFICATION = "CHOSSE_NOTIFICATION",
}

export type NotificationActions = ActionType<typeof actions>;

export const notificationFromJson = (json: any) => {
  return {
    ...json,
    activityDate: {
      value: new Date(json.activity_date),
      error: "",
      validate: new Date(json.activity_date).getTime() > new Date().getTime(),
    },
    title: {
      value: json.title,
      error: "",
      validate: json.title && true,
    },
    content: {
      value: json.content,
      error: "",
      validate: json.content && true,
    },
    pdf: {
      value: json.pdf,
      error: "",
      validate: json.pdf && true,
    },
  };
};

export const mapNotification = (notifications: Pagination<any>) => {
  return {
    ...notifications,
    data: notifications.data.map((item) => {
      return notificationFromJson(item);
    }),
  };
};
