import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IUser } from "src/features/account/data/models/user";
import { IOrthodontistRepository } from "../../data/repositories/orthodontistRepository";

export class UpdateOrthodontist extends UseCase<
  UpdateOrthodontistParams,
  boolean
> {
  private repository: IOrthodontistRepository;
  constructor(repository: IOrthodontistRepository) {
    super();
    this.repository = repository;
  }

  call(params: UpdateOrthodontistParams): Promise<Either<Failure, boolean>> {
    return this.repository.updateOrthodontist(params.user);
  }
}

export class UpdateOrthodontistParams {
  user: IUser;
  constructor(user: IUser) {
    this.user = user;
  }
}
