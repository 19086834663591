import { BarChart } from "src/components/chart/bar_chart_component";
import { Strings } from "src/constants";
import { IAnalyticDate } from "src/features/analytic/data/models/patientAnalytic";
import { AnalyticHeader } from "./analyticHeader";

export class EppAnalyticHeader extends AnalyticHeader {
  onDatesUpdated = (date: IAnalyticDate) => {
    var dates = [...this.props.dates];
    var updatedIndex = dates.map((date) => date.value).indexOf(date.value);
    if (updatedIndex > -1) {
      if (date.shouldShowAnalytic) {
        dates.forEach((d) => {
          if (d.value != date.value) d.shouldShowAnalytic = false;
        });
      }
      dates[updatedIndex] = { ...date };
      this.props.onChangeDates([...dates]);
    }
  };
}
