import React from "react";

interface IPaginationComponentProps {
  totalPage: number;
  currentPage: number;
  className?: string;
  onClick: (page: number) => void;
}

export class PaginationComponent extends React.Component<IPaginationComponentProps> {
  render() {
    return (
      <nav aria-label="..." className={this.props.className}>
        <ul className={`pagination pagination-SP justify-content-end ${this.props.className ?? ""}`}>
          {
            this.props.currentPage >= 4 &&
            <li className="page-item">
              <a className="page-link" tabIndex={-1} onClick={() => { this.props.onClick(1) }}>
                &#60;&#60;
              </a>
            </li>
          }

          {
            this.props.currentPage - 2 >= 1 &&
            <li className="page-item">
              <a className="page-link" tabIndex={-1} onClick={() => { this.props.onClick(this.props.currentPage - 2) }}>
                {this.props.currentPage - 2}
              </a>
            </li>
          }

          {
            this.props.currentPage - 1 >= 1 &&
            <li className="page-item">
              <a className="page-link" tabIndex={-1} onClick={() => { this.props.onClick(this.props.currentPage - 1) }}>
                {this.props.currentPage - 1}
              </a>
            </li>
          }

          {
            <li className="page-item disabled">
              <a className="page-link" tabIndex={-1} onClick={() => { this.props.onClick(this.props.currentPage) }}>
                {this.props.currentPage}
              </a>
            </li>
          }

          {
            this.props.currentPage + 1 <= this.props.totalPage &&
            <li className="page-item">
              <a className="page-link" tabIndex={-1} onClick={() => { this.props.onClick(this.props.currentPage + 1) }}>
                {this.props.currentPage + 1}
              </a>
            </li>
          }

          {
            this.props.currentPage + 2 <= this.props.totalPage &&
            <li className="page-item">
              <a className="page-link" tabIndex={-1} onClick={() => { this.props.onClick(this.props.currentPage + 2) }}>
                {this.props.currentPage + 2}
              </a>
            </li>
          }


          {
            this.props.currentPage < this.props.totalPage - 2 &&
            <li className="page-item">
              <a className="page-link" tabIndex={-1} onClick={() => { this.props.onClick(this.props.totalPage) }}>
                &#62;&#62;
              </a>
            </li>
          }
        </ul>
      </nav>
    );
  }
}