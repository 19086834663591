import React from "react";
import { ReactNode } from "react";
import { RadarChartComponent } from "src/components/chart/radar_chart_component";
import { analysisTypes, Strings } from "src/constants";
import { IAnalyticData } from "src/features/analytic/data/models/patientAnalytic";
import { TotalAnalysisBody } from "../body/totalAnalyticBody";
import { TotalAnalyticFooter } from "../footer/totalAnalyticFooter";
import { TotalAnalyticHeader } from "../header/totalAnalyticHeader";
import {
  PatientAnalyticTab,
  PatientAnalyticTabProps,
} from "./patientAnalyticTab";

export class TotalAnalyticTab extends React.Component<PatientAnalyticTabProps> {
  render(): ReactNode {

    return (
      <PatientAnalyticTab
        id={this.props.id}
        onChangeDates={this.props.onChangeDates}
        className={this.props.className}
        header={
          <TotalAnalyticHeader
            id={`${this.props.id}-header`}
            onChangeDates={this.props.onChangeDates}
            totals={
              [
                {
                  label: "平均スコア",
                  value:
                    ((this.props.analyticDetail.analysis.dates.filter((date) => date.shouldShowAnalytic).reduce((sum, date) => sum + (date.average ?? 0), 0) || 0) / (this.props.analyticDetail.analysis.dates.filter((date) => date.shouldShowAnalytic).length || 1)).toFixed(0).toString() +
                    "点",
                },
              ]
            }
            dates={this.props.analyticDetail.analysis.dates}
            title={Strings.ANALYSIS_TOTAL_RESULT}
            chart={
              <RadarChartComponent
                items={this.props.analyticDetail.analysis.dates
                  .filter((date) => date.shouldShowAnalytic)
                  .map((date) => {
                    return {
                      name: date.label,
                      percents: Array.from(analysisTypes.keys()).map((type) => {
                        return (date.result as IAnalyticData[]).filter((result) => type === result.label)[0]?.value
                      }),
                      color: date.color,
                    };
                  })}
                labels={[...Array.from(analysisTypes.values())]}
              />
            }
          />
        }
        body={
          <TotalAnalysisBody
            id={`${this.props.id}-body`}
            comprehensive={this.props.analyticDetail.comprehensive}
          />
        }
        footer={<TotalAnalyticFooter id={`${this.props.id}-footer`} />}
        analyticDetail={this.props.analyticDetail}
      />
    );
  }
}
