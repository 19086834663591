import { AccountRemoteDataSource } from "src/features/account/data/datasources/accountRemoteDataSource";
import { AccountRepository } from "src/features/account/data/repositories/accountRepository";
import { GetMe } from "src/features/account/domain/usecases/getMe";
import { UpdateAccount } from "src/features/account/domain/usecases/updateAccount";
import { AccountContainer } from "src/features/account/presenter/container/accountContainer";
import { AdminNoticeRemoteDatasource } from "src/features/adminNotice/data/datasources/adminNoticeRemoteDatasource";
import { AdminNoticeRepository } from "src/features/adminNotice/data/repositories/notificationRepository";
import { FetchAdminNotice } from "src/features/adminNotice/domain/usecases/fetchAdminNotice";
import { AdminNoticeContainer } from "src/features/adminNotice/presenter/container/adminNoticeContainer";
import { AnalyticRemoteDataSource } from "src/features/analytic/data/datasources/analyticDataSource";
import { AnalyticRepository } from "src/features/analytic/data/repositories/patientAnalyticRepository";
import { FetchPatientAnalyticDetail } from "src/features/analytic/domain/usecases/fetchPatientAnalyticDetail";
import { FetchPatientAnalytics } from "src/features/analytic/domain/usecases/fetchPatientAnalytics";
import { AnalyticContainer } from "src/features/analytic/presenter/container/AnalyticContainer";
import { AuthRemoteDataSource } from "src/features/auth/data/datasources/authRemoteDatasource";
import { AuthRepository } from "src/features/auth/data/repositories/authRepository";
import { ChangePassword } from "src/features/auth/domain/usecases/changePassword";
import { ForgetPassword } from "src/features/auth/domain/usecases/forgetPassword";
import { Login } from "src/features/auth/domain/usecases/login";
import { VerifyOTP } from "src/features/auth/domain/usecases/verifyOTP";
import { AuthContainer } from "src/features/auth/presenter/container/authContainer";
import { RemoteFileManagementRemoteDataSource } from "src/features/fileManagement/data/datasources/remoteFileManagementRemoteDataSource";
import { RemoteFileManagementRepository } from "src/features/fileManagement/data/repositories/remoteFileManagementRepository";
import { CreateFolderFileManagement } from "src/features/fileManagement/domain/usecases/createFolderFileManagement";
import { DeleteFileManagement } from "src/features/fileManagement/domain/usecases/deleteFileManagement";
import { DownloadFilesFileManagement } from "src/features/fileManagement/domain/usecases/downloadFilesFileManagement";
import { DownloadFolderFileManagement } from "src/features/fileManagement/domain/usecases/downloadFolderFileManagement";
import { FetchFileManagement } from "src/features/fileManagement/domain/usecases/fetchFileManagement";
import { ImportFileManagement } from "src/features/fileManagement/domain/usecases/importFileManagement";
import { MoveIntoFolderFileManagement } from "src/features/fileManagement/domain/usecases/moveIntoFolderFileManagement.ts";
import { RenameFileManagement } from "src/features/fileManagement/domain/usecases/renameFileManagement";
import { RemoteFileManagementContainer } from "src/features/fileManagement/presenter/container/RemoteFileManagementContainer";
import { MedicalRecordRemoteDataSource } from "src/features/medicalRecord/data/datasources/medialRecordRemoteDataSource";
import { MedicalRecordRepository } from "src/features/medicalRecord/data/repositories/medicalRecordRepository";
import { AddMedicalRecord } from "src/features/medicalRecord/domain/usecases/addMedicalRecord";
import { DeleteMedicalRecord } from "src/features/medicalRecord/domain/usecases/deleteMedicalRecord";
import { DuplicateMedicalRecord } from "src/features/medicalRecord/domain/usecases/duplicateMedicalRecord";
import { FetchAttributes } from "src/features/medicalRecord/domain/usecases/fetchAttributes";
import { FetchMedicalRecordDetail } from "src/features/medicalRecord/domain/usecases/fetchMedicalRecordDetail";
import { FetchMedicalRecords } from "src/features/medicalRecord/domain/usecases/fetchMedicalRecords";
import { UpdateMedicalRecord } from "src/features/medicalRecord/domain/usecases/updateMedicalRecord";
import { MedicalRecordContainer } from "src/features/medicalRecord/presenter/container/medicalRecordContainer";
import { NotificationRemoteDataSource } from "src/features/notification/data/datasources/notificationRemoteDatasource";
import { NotificationRepository } from "src/features/notification/data/repositories/notificationRepository";
import { DeleteNotification } from "src/features/notification/domain/usecases/deleteNotification";
import { FetchNotificationDetail } from "src/features/notification/domain/usecases/fetchNotificationDetail";
import { FetchNotification } from "src/features/notification/domain/usecases/fetchNotifications";
import { SaveNotification } from "src/features/notification/domain/usecases/saveNotification";
import { NotificationContainer } from "src/features/notification/presenter/container/notificationContainer";
import { OrthodontistRemoteDataSource } from "src/features/orthodontist/data/datasources/orthodontistDataSource";
import { OrthodontistRepository } from "src/features/orthodontist/data/repositories/orthodontistRepository";
import { AddOrthodontist } from "src/features/orthodontist/domain/usecases/addOrthodontist";
import { DeleteOrthodontist } from "src/features/orthodontist/domain/usecases/deleteOrthodontist";
import { FetchOrthodontistDetails } from "src/features/orthodontist/domain/usecases/fetchOrthodontistDetails";
import { FetchOrthodontists } from "src/features/orthodontist/domain/usecases/fetchOrthodontists";
import { SendEmailToOrthodontist } from "src/features/orthodontist/domain/usecases/sendEmail";
import { UpdateOrthodontist } from "src/features/orthodontist/domain/usecases/updateOrthodontist";
import { UpdateOrthodontistStatus } from "src/features/orthodontist/domain/usecases/updateOrthodontistStatus";
import { OrthodontistContainer } from "src/features/orthodontist/presenter/container/orthodontistContainer";
import { PatientRemoteDatasource } from "src/features/patient/data/datasources/patientRemoteDataSource";
import { PatientRepository } from "src/features/patient/data/repositories/patientRepository";
import { AddPatient } from "src/features/patient/domain/usecases/addPatient";
import { DeletePatient } from "src/features/patient/domain/usecases/deletePatient";
import { FetchDownloadCsvFile } from "src/features/patient/domain/usecases/fetchDownloadCsvFiles";
import { FetchListPatients } from "src/features/patient/domain/usecases/fetchListPatients";
import { FetchPatientDetails } from "src/features/patient/domain/usecases/fetchPatientDetails";
import { FetchPatients } from "src/features/patient/domain/usecases/fetchPatients";
import { ImportCsv } from "src/features/patient/domain/usecases/importCsv";
import { SendEmailToPatient } from "src/features/patient/domain/usecases/sendEmail";
import { UpdatePatient } from "src/features/patient/domain/usecases/updatePatient";
import { PatientContainer } from "src/features/patient/presenter/container/patientContainer";
import { RemoteFileDownloadRemoteDataSource } from "src/features/plyDownload/data/datasources/remoteFilesDownloadRemoteDataSource";
import { RemoteFileDownloadRepository } from "src/features/plyDownload/data/repositories/remoteFilesDownloadRepository";
import { DeleteRemoteFile } from "src/features/plyDownload/domain/usecases/deleteRemoteFile";
import { FetchRemoteFiles } from "src/features/plyDownload/domain/usecases/fetchRemoteFiles";
import { LinkSelectedPatient } from "src/features/plyDownload/domain/usecases/linkSelectedPatient";
import { RemoteFilesDownloadContainer } from "src/features/plyDownload/presenter/container/RemoteFilesDownloadContainer";
import { SubscriptionRemoteDataSource } from "src/features/subscription/data/datasources/subscriptionRemoteDataSource";
import { SubscriptionRepository } from "src/features/subscription/data/repositories/subscriptionRepository";
import { AddCard } from "src/features/subscription/domain/usecases/addCard";
import { AddPlan } from "src/features/subscription/domain/usecases/addPlan";
import { CancelPlan } from "src/features/subscription/domain/usecases/cancelPlan";
import { ChangePlan } from "src/features/subscription/domain/usecases/changePlan";
import { DeleteCard } from "src/features/subscription/domain/usecases/deleteCard";
import { GetAllCards } from "src/features/subscription/domain/usecases/getAllCards";
import { GetAllPlans } from "src/features/subscription/domain/usecases/getAllPlans";
import { UpdateCard } from "src/features/subscription/domain/usecases/updateCard";
import { SubscriptionContainer } from "src/features/subscription/presenter/container/subscriptionContainer";
import { ThreeDViewerContainer } from "src/features/threeDViewer/presenter/container/ThreeDViewerContainer";
import { UnsharedFiles } from "src/features/unsharedFiles/data/datasources/unsharedFilesDatasource";
import { UnsharedFilesRepository } from "src/features/unsharedFiles/data/repositories/unsharedFilesRepository";
import { FetchUnsharedFiles } from "src/features/unsharedFiles/domain/usecases/fetchUnsharedFiles";
import { UnsharedFilesContainer } from "src/features/unsharedFiles/presenter/container/unsharedFilesContainer";
import { IApiService } from "src/services/api/ApiService";
import { ReactRestfulService } from "src/services/api/ReactRestfulService";

type InstanceBuilderCallBack<S> = () => S;

export interface InstanceInfo {
  isPermanent?: boolean;
  isSingleton?: boolean;
  isRegistered: boolean;
  isPrepared: boolean;
  isInit?: boolean;
}

export class DI {
  private static instance: DI;
  apiService: IApiService;
  constructor() {
    this.apiService = Injector.get().put(() => new ReactRestfulService(), "ReactRestfulService");
  }

  static get(): DI {
    if (!DI.instance) {
      DI.instance = new DI();
    }
    return new DI();
  }

  injectAuthDependencies() {
    let dataSource = Injector.get().put(() => new AuthRemoteDataSource(this.apiService), "AuthRemoteDataSource");
    let repository = Injector.get().put(() => new AuthRepository(dataSource), "AuthRepository");
    Injector.get().put(
      () =>
        new AuthContainer(
          new Login(repository),
          new ForgetPassword(repository),
          new VerifyOTP(repository),
          new ChangePassword(repository)
        ),
      "AuthContainer"
    );
  }

  removeAuthDepenencies() {
    Injector.get().delete("AuthRemoteDataSource");
    Injector.get().delete("AuthRepository");
    Injector.get().delete("AuthContainer");
  }

  provideAdminNoticeDependencies() {
    let dataSource = Injector.get().put(
      () => new AdminNoticeRemoteDatasource(this.apiService),
      "AdminNoticeRemoteDatasource"
    );
    let repository = Injector.get().put(() => new AdminNoticeRepository(dataSource), "AdminNoticeRepository");
    Injector.get().put(() => new AdminNoticeContainer(new FetchAdminNotice(repository)), "AdminNoticeContainer");
  }

  removeAdminNoticeDepenencies() {
    Injector.get().delete("AdminNoticeRemoteDatasource");
    Injector.get().delete("AdminNoticeRepository");
    Injector.get().delete("AdminNoticeContainer");
  }

  provideNotificationDependencies() {
    let dataSource = Injector.get().put(
      () => new NotificationRemoteDataSource(this.apiService),
      "NotificationRemoteDataSource"
    );
    let repository = Injector.get().put(() => new NotificationRepository(dataSource), "NotificationRepository");
    Injector.get().put(
      () =>
        new NotificationContainer(
          new FetchNotification(repository),
          new FetchNotificationDetail(repository),
          new SaveNotification(repository),
          new DeleteNotification(repository)
        ),
      "NotificationContainer"
    );
  }

  removeNotificationDepenencies() {
    Injector.get().delete("NotificationRemoteDataSource");
    Injector.get().delete("NotificationRepository");
    Injector.get().delete("NotificationContainer");
  }

  injectAccountDependencies() {
    let dataSource = Injector.get().put(() => new AccountRemoteDataSource(this.apiService), "AccountRemoteDataSource");
    let repository = Injector.get().put(() => new AccountRepository(dataSource), "AccountRepository");
    Injector.get().put(
      () => new AccountContainer(new GetMe(repository), new UpdateAccount(repository)),
      "AccountContainer"
    );
  }

  removeAccountDependencies() {
    Injector.get().delete("AccountRemoteDataSource");
    Injector.get().delete("AccountRepository");
    Injector.get().delete("AccountContainer");
  }

  injectOrthodontistDependencies() {
    let dataSource = Injector.get().put(
      () => new OrthodontistRemoteDataSource(this.apiService),
      "OrthodontistRemoteDataSource"
    );
    let repository = Injector.get().put(() => new OrthodontistRepository(dataSource), "OrthodontistRepository");
    Injector.get().put(
      () =>
        new OrthodontistContainer(
          new FetchOrthodontists(repository),
          new FetchOrthodontistDetails(repository),
          new AddOrthodontist(repository),
          new UpdateOrthodontist(repository),
          new UpdateOrthodontistStatus(repository),
          new DeleteOrthodontist(repository),
          new SendEmailToOrthodontist(repository)
        ),
      "OrthodontistContainer"
    );
  }

  deleteOrthodontistDependencies() {
    Injector.get().delete("OrthodontistRemoteDataSource");
    Injector.get().delete("OrthodontistRepository");
    Injector.get().delete("OrthodontistContainer");
  }

  injectPatientDependencies() {
    let dataSource = Injector.get().put(() => new PatientRemoteDatasource(this.apiService), "PatientRemoteDatasource");
    let repository = Injector.get().put(() => new PatientRepository(dataSource), "PatientRepository");
    Injector.get().put(
      () =>
        new PatientContainer(
          new AddPatient(repository),
          new UpdatePatient(repository),
          new DeletePatient(repository),
          new FetchPatients(repository),
          new FetchPatientDetails(repository),
          new ImportCsv(repository),
          new SendEmailToPatient(repository),
          new FetchDownloadCsvFile(repository)
        ),
      "PatientContainer"
    );
  }

  deletePatientDependencies() {
    Injector.get().delete("PatientRemoteDatasource");
    Injector.get().delete("PatientRepository");
    Injector.get().delete("PatientRepository");
  }

  injectMedicalRecordDependencies() {
    let othordontistRepository = Injector.get().find<OrthodontistRepository>("OrthodontistRepository");
    let patientRepository = Injector.get().find<PatientRepository>("PatientRepository");
    let dataSource = Injector.get().put(
      () => new MedicalRecordRemoteDataSource(this.apiService),
      "MedicalRecordRemoteDataSource"
    );
    let repository = Injector.get().put(() => new MedicalRecordRepository(dataSource), "MedicalRecordRepository");
    Injector.get().put(
      () =>
        new MedicalRecordContainer(
          new FetchMedicalRecords(repository),
          new FetchMedicalRecordDetail(repository),
          new AddMedicalRecord(repository),
          new UpdateMedicalRecord(repository),
          new DeleteMedicalRecord(repository),
          new DuplicateMedicalRecord(repository),
          new FetchOrthodontists(othordontistRepository),
          new FetchPatients(patientRepository),
          new FetchListPatients(patientRepository),
          new FetchAttributes(repository)
        ),
      "MedicalRecordContainer"
    );
  }

  deleteMedicalRecordDependencies() {
    Injector.get().delete("MedicalRecordMockDataSource");
    Injector.get().delete("MedicalRecordRepository");
    Injector.get().delete("MedicalRecordContainer");
  }

  injectAnalyticDependencies() {
    let dataSource = Injector.get().put(
      () => new AnalyticRemoteDataSource(this.apiService),
      "AnalyticRemoteDataSource"
    );
    let repository = Injector.get().put(() => new AnalyticRepository(dataSource), "AnalyticRepository");
    Injector.get().put(
      () => new AnalyticContainer(new FetchPatientAnalytics(repository), new FetchPatientAnalyticDetail(repository)),
      "AnalyticContainer"
    );
  }

  deleteAnalyticDependencies() {
    Injector.get().delete("AnalyticRemoteDataSource");
    Injector.get().delete("AnalyticRepository");
    Injector.get().delete("AnalyticContainer");
  }

  injectSubscriptionDependencies() {
    let dataSource = Injector.get().put(
      () => new SubscriptionRemoteDataSource(this.apiService),
      "SubscriptionRemoteDataSource"
    );
    // let dataSource = Injector.get().put(
    //   () => new SubscriptionRemoteDataSource(this.apiService),
    //   "AnalyticRemoteDataSource"
    // );
    let repository = Injector.get().put(() => new SubscriptionRepository(dataSource), "SubscriptionRepository");
    Injector.get().put(
      () =>
        new SubscriptionContainer(
          new ChangePlan(repository),
          new AddPlan(repository),
          new CancelPlan(repository),
          new GetAllPlans(repository),
          new AddCard(repository),
          new GetAllCards(repository),
          new UpdateCard(repository),
          new DeleteCard(repository)
        ),
      "SubscriptionContainer"
    );
  }

  deleteSubscriptionDependencies() {
    Injector.get().delete("SubscriptionMockDataSourc");
    Injector.get().delete("SubscriptionRepository");
    Injector.get().delete("SubscriptionContainer");
  }

  injectRemoteFilesDownloadDependencies() {
    let dataSource = Injector.get().put(
      () => new RemoteFileDownloadRemoteDataSource(this.apiService),
      "RemoteFileDownloadRemoteDataSource"
    );
    // let dataSource = Injector.get().put(
    //   () => new SubscriptionRemoteDataSource(this.apiService),
    //   "AnalyticRemoteDataSource"
    // );
    let repository = Injector.get().put(
      () => new RemoteFileDownloadRepository(dataSource),
      "RemoteFileDownloadRepository"
    );
    Injector.get().put(
      () =>
        new RemoteFilesDownloadContainer(
          new FetchRemoteFiles(repository),
          new DeleteRemoteFile(repository),
          new FetchPatients(Injector.get().find<PatientRepository>("PatientRepository")),
          new LinkSelectedPatient(repository)
        ),
      "RemoteFilesDownloadContainer"
    );
  }

  deleteRemoteFilesDownloadDependencies() {
    Injector.get().delete("RemoteFileDownloadRemoteDataSource");
    Injector.get().delete("RemoteFileDownloadRepository");
    Injector.get().delete("RemoteFilesDownloadContainer");
  }

  injectRemoteFileManagementDependencies() {
    let dataSource = Injector.get().put(
      () => new RemoteFileManagementRemoteDataSource(this.apiService),
      "RemoteFileManagementRemoteDataSource"
    );
    let repository = Injector.get().put(
      () => new RemoteFileManagementRepository(dataSource),
      "RemoteFileManagementRepository"
    );
    Injector.get().put(
      () =>
        new RemoteFileManagementContainer(
          new FetchFileManagement(repository),
          new CreateFolderFileManagement(repository),
          new ImportFileManagement(repository),
          new DownloadFilesFileManagement(repository),
          new DownloadFolderFileManagement(repository),
          new MoveIntoFolderFileManagement(repository),
          new RenameFileManagement(repository),
          new DeleteFileManagement(repository)
        ),
      "RemoteFileManagementContainer"
    );
  }

  deleteRemoteFileManagementDependencies() {
    Injector.get().delete("RemoteFileManagementRemoteDataSource");
    Injector.get().delete("RemoteFileManagementRepository");
    Injector.get().delete("RemoteFileManagementContainer");
  }

  injectUnsharedFilesDependencies() {
    let dataSource = Injector.get().put(() => new UnsharedFiles(this.apiService), "UnsharedFiles");
    let repository = Injector.get().put(() => new UnsharedFilesRepository(dataSource), "UnsharedFilesRepository");
    Injector.get().put(() => new UnsharedFilesContainer(new FetchUnsharedFiles(repository)), "UnsharedFilesContainer");
  }

  deleteUnsharedFilesDependencies() {
    Injector.get().delete("UnsharedFiles");
    Injector.get().delete("UnsharedFilesRepository");
    Injector.get().delete("UnsharedFilesContainer");
  }

  injectThreeDViewerDependencies() {
    Injector.get().put(() => new ThreeDViewerContainer(), "ThreeDViewerContainer");
  }

  deleteThreeDViewerDependencies() {
    Injector.get().delete("ThreeDViewerContainer");
  }
}

export class Injector {
  private static instance: Injector;
  static get(): Injector {
    if (!Injector.instance) {
      Injector.instance = new Injector();
    }
    return Injector.instance;
  }
  _dependencies: Map<string, _InstanceBuilderFactory<any>> = new Map();

  putOrFind<S>(dep: InstanceBuilderCallBack<S>, tag: string) {
    let key = this._getKey(tag);
    if (this._dependencies.has(key)) {
      return this.find(tag);
    } else {
      return this.put(dep, tag);
    }
  }

  //put new dependency to map, Injector will access same instance when you access it again with `Injector.find()`
  put<S>(builder: InstanceBuilderCallBack<S>, tag: string, permanent?: false) {
    this._insert(tag, builder, true, permanent);
    return this.find<S>(tag);
  }

  //access
  find<S>(tag: string): S {
    var key = this._getKey(tag);
    if (this._dependencies.has(key)) {
      let dep = this._dependencies.get(key);
      if (!dep) {
        if (!tag) {
          throw Error('Class "$S" is not registered');
        } else {
          throw Error('Class "$S" with tag "$tag" is not registered');
        }
      }
      return dep.getDependency() as S;
    } else {
      throw Error('"$S" not found. You need to call "Injector.put(()=>$S())"');
    }
  }

  _insert<S>(
    name: string,
    builder: InstanceBuilderCallBack<S>,
    isSingleton?: boolean,
    permanent: boolean = false,
    fenix: boolean = false
  ) {
    var key = this._getKey(name);
    if (this._dependencies.has(key)) {
      let dep = this._dependencies.get(key);
      if (dep) {
        this._dependencies.set(
          key,
          new _InstanceBuilderFactory(
            builder,
            permanent,
            false,
            fenix,
            name,
            isSingleton,
            dep as _InstanceBuilderFactory<S>
          )
        );
      } else {
        this._dependencies.set(key, new _InstanceBuilderFactory(builder, permanent, false, fenix, name, isSingleton));
      }
    } else {
      this._dependencies.set(key, new _InstanceBuilderFactory(builder, permanent, false, fenix, name, isSingleton));
    }
  }

  isRegistered(tag: string): boolean {
    return this._dependencies.has(this._getKey(tag));
  }

  _getKey(name: string) {
    return name;
  }

  delete(tag: string, key?: string) {
    let newKey = key ?? this._getKey(tag);
    if (!this._dependencies.has(newKey)) {
      return false;
    }
    let dep = this._dependencies.get(newKey);
    if (!dep) return false;
    this._dependencies.delete(newKey);
  }

  deleteAll() {
    this._dependencies.clear();
  }

  markAsDirt(tag: string, key?: string) {
    let newKey = key ?? this._getKey(tag);
    if (this._dependencies.has(newKey)) {
      let dep = this._dependencies.get(newKey);
      if (dep != null && !dep.permanent) {
        dep.isDirty = true;
      }
    }
  }
}

class _InstanceBuilderFactory<S> {
  isSingleton?: boolean;
  fenix: boolean;
  dependency?: S;
  builderFunction: InstanceBuilderCallBack<S>;
  permanent = false;
  isInit = false;
  isDirty = false;
  tag: string;
  lateRemove?: _InstanceBuilderFactory<S>;

  constructor(
    builderFunction: InstanceBuilderCallBack<S>,
    permanent: boolean,
    isInit: boolean,
    fenix: boolean,
    tag: string,
    isSingleton?: boolean,
    lateRemove?: _InstanceBuilderFactory<S>
  ) {
    this.isSingleton = isSingleton;
    this.fenix = fenix;
    this.permanent = permanent;
    this.isInit = isInit;
    this.tag = tag;
    this.lateRemove = lateRemove;
    this.builderFunction = builderFunction;
  }

  getDependency(): S {
    if (this.isSingleton) {
      if (!this.dependency) {
        this.dependency = this.builderFunction();
      }
      return this.dependency!;
    } else {
      return this.builderFunction();
    }
  }
}

export abstract class Injectable {
  static key: string;
}
