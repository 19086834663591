export class Endpoints {
  static loginClinic: string = "/auth/clinic/login";
  static me: string = "/profile";
  static updateMe: string = "/profile/me";

  //orthodontist
  static getOrthodontists = "/orthodontists";
  static addOrthodontist = "/orthodontists/store";
  static fetchOrthodontist = (id: string) => `/orthodontists/${id}`;
  static updateOrthodontist = (id: string) => `/orthodontists/${id}`;
  static deleteOrthodontist = (id: string) => `/orthodontists/${id}`;
  static updateOrthodontistStatus = (id: string) => `/orthodontists/status/${id}`;
  static sendOrthodontistEmail = (id: string) => `/orthodontists/${id}/send-email`;

  //patient
  static getPatients = "/patients";
  static addPatient = "/patients/store";
  static patientDetails = (id: string) => `/patients/${id}`;
  static updatePatient = (id: string) => `/patients/${id}`;
  static deletePatient = (id: string) => `/patients/${id}`;
  static importPatientsByCSV = "/patients/csv";
  static sendPariendMail = (id: string) => `/patients/${id}/send-email`;
  // static importPatientsByCSV = (typeofcsv: string) => `/patients/csv`

  //list patient
  static getListPatients = "/medical-records/list-patients";

  static downloadCSV = "/patients/csv/download";

  //notification
  static fetchNotification = "/news/clinic-news";
  static addNotification = "/news/store";
  static fetchNews = "/news";
  static notificationDetail = (id: string) => `/news/${id}`;
  static updateNotification = (notificationId: number) => {
    return "/news/" + notificationId + "?_method=PUT";
  };

  //medical-records
  static fetchMedicalRecords = "/medical-records";
  static fetchMedicalRecordDetail = (id: string) => `/medical-records/show-record/${id}`;
  static fetchAttributes = "/attributes";
  static addMedicalRecord = "/medical-records/store";
  static updateMedicalRecord = (id: string) => `/medical-records/${id}?_method=PUT`;
  static deleteMedicalRecord = (id: string) => `/medical-records/${id}`;
  static duplicateMedicalRecord = (id: string) => `/medical-records/duplicate/${id}`;

  //remote-files
  static fetchRemoteFiles = "/remote-files";
  static countUnsharedFiles = "/remote-files/count";
  static deleteRemoteFiles = (id: string) => `/remote-files/${id}`;
  static linkToPatient = (id: string) => `/remote-files/link-to-patient/${id}`;

  //file-management
  static fetchFileManagement = (breadcrumbPath: string, search?: string, only?: string) =>
    `/files-management?path=${breadcrumbPath}&q=${search}&only=${only}`;
  static createFolderFileManagement = "/files-management/folders/create";
  static importFileManagement = "/files-management/files/import";
  static downloadFilesFileManagement = "/files-management/files/download";
  static downloadFolderFileManagement = "/files-management/folders/download";
  static moveFolderFileManagement = "/files-management/folders/move";
  static moveFileFileManagement = "/files-management/files/move-files";
  static renameFolderFileManagement = "/files-management/folders/rename";
  static renameFileFileManagement = "/files-management/files/rename";
  static deleteFolderFileFileManagement = "/files-management/folders/delete-folder";
  static deleteFileFileFileManagement = "/files-management/files/delete-files";

  //analytic
  static fetchPatientAnalytic = "/medical-records/analysis-records";
  static fetchAnalyticDetail = (id: string) => `/medical-records/analysis-records/${id}`;

  //subscription
  static addPlan = "/stripe/subscription/create";
  static changePlan = "/stripe/plans/change";
  static cancelPlan = "/stripe/subscription/cancel";
  static getAllPlans = "/stripe/plans";
  static getAllCards = "/stripe/paymentMethods";
  static addCard = "/stripe/paymentMethods/create";
  static updateCard = (id: string) => `/stripe/paymentMethods/${id}?_method=PUT`;
  static deleteCard = (id: string) => `/stripe/paymentMethods/${id}`;

  //forget password
  static forgetPassword: string = "/auth/send-forgot-link";
  static verifyOTP: string = "/auth/verify-otp";
  static changePassword: string = "/auth/reset-password";
}
