import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Injector } from "src/base/Injector";
import { Footer } from "src/components/footer";
import { MainHeader } from "src/components/header";
import { Nav } from "src/components/nav";
import { AuthContainer } from "src/features/auth/presenter/container/authContainer";
import { LoadingOverlayComponent } from "src/shared/components/loadingOverlayComponent";
import { MainBody } from "src/shared/layouts/mainBody";
import { MainSection } from "src/shared/layouts/mainSection";
import { IRootState } from "src/store";

interface MainLayoutState {
  isShowNavBar: boolean;
}

interface MainLayoutProps {
  id?: string;
  children: React.ReactNode;
  className: string;
  isLoading?: boolean;
  subClassName: string;
}

class MainLayout extends React.Component<RootType, MainLayoutState> {
  constructor(props: RootType) {
    super(props);
    this.state = {
      isShowNavBar: false,
    };
  }

  render() {
    return (
      <LoadingOverlayComponent isLoading={this.props.isLoading}>
        <MainBody bodyClassname={this.props.className} id={this.props.id}>
          <MainHeader
            onMenuIconClicked={(e) => {
              this.setState({
                isShowNavBar: !this.state.isShowNavBar,
              });
            }}
            isShowNavBar={this.state.isShowNavBar}
            clinicName={this.props.me.clinic ? this.props.me.clinic.name : ""}
          ></MainHeader>
          {/* <Modal
            className="modal-backdrop"
            isShow={this.props.currentModal != Modals.NONE}
            hiddenBody={true}
          ></Modal> */}
          <MainSection className={this.props.subClassName}>
            <div id="layoutSidenav">
              <Nav
                unsharedFilesCount={this.props.unsharedFilesCount}
                isShowNavBar={this.state.isShowNavBar}
                isStripe={this.props.me.user_payment_type === "stripe"}
                isBlock={this.props.me.is_block === 1}
                onLogout={this.props.onLogout}
              ></Nav>
              <div id="layoutSidenav_content">
                <main>{this.props.children}</main>
              </div>
            </div>
          </MainSection>
          <Footer></Footer>
        </MainBody>
      </LoadingOverlayComponent>
    );
  }
}

const mapStateToProps = ({ account, app, unsharedFiles }: IRootState) => {
  const { me } = account;
  const { currentModal } = app;
  const { unsharedFilesCount } = unsharedFiles;
  return {
    me,
    currentModal,
    unsharedFilesCount,
  };
};
const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    onLogout: () => Injector.get().find<AuthContainer>("AuthContainer").loggedOut(dispatch),
  };
};

type RootType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps> & MainLayoutProps;

export default connect(mapStateToProps, mapDispatcherToProps)(MainLayout);
