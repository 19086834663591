import React from "react";
import { IBopEvaluation } from "src/features/analytic/data/models/patientAnalytic";
import { Assets } from "src/utils/assets";

export interface AnalyticFooterProps {
    id?: string
    evaluation?: IBopEvaluation
}

export abstract class AnalyticFooter<P = {}, S = {}, SS = any> extends React.Component<P & AnalyticFooterProps, S, SS>{
    abstract title: string
    abstract content: React.ReactNode

    render(): React.ReactNode {
        return <div className="tab-box-item bg-cover graph-style3" id={this.props.id}>
            <div className="tab-title tab-title-full"><img src={Assets.analysis2} alt={this.title} /><span>{this.title}</span></div>
            <div className="tab-content">
                {this.content}
            </div>
        </div>
    }
}