import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IAccountRepository } from "../../data/repositories/accountRepository";

export class UpdateAccount extends UseCase<UpdateAccountParams, void>{
    private repository: IAccountRepository
    constructor(repository: IAccountRepository) {
        super()
        this.repository = repository
    }
    call(params: UpdateAccountParams): Promise<Either<Failure, void>> {
        return this.repository.updateAccount(params.full_name, params.username, params.password)
    }

}

export class UpdateAccountParams {
    username: string
    full_name: string
    password: string
    constructor(username: string, full_name: string, password: string) {
        this.username = username
        this.full_name = full_name
        this.password = password
    }
}