import { Line } from "react-chartjs-2";
import { BaseChart } from "./chart";
import "chartjs-plugin-datalabels";

export class LineChart extends BaseChart {
  render() {
    var options: any = {
      scales: {
        y: {
          max: 100,
          min: 0,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          align: "end",
          anchor: "end",
          color: function (context: any) {
            return context.dataset.backgroundColor;
          },
          font: function (context: any) {
            var w = context.chart.width;
            return {
              size: w < 512 ? 12 : 14,
              weight: "bold",
            };
          },
        },
      },
    };
    var data: any = {
      labels: this.props.items.map((item) => item.name),
      datasets: [
        {
          data: this.props.items.map((item) => {
            return item.percents;
          }),
          fill: false,
          borderColor: "#B197F9",
        },
      ],
    };
    return <Line data={data} options={options} />;
  }
}
