import * as THREE from "three";

import { TGALoader } from "./loaders/TGALoader";

import { AddObjectCommand } from "./commands/AddObjectCommand.js";

import { LoaderUtils } from "./LoaderUtils.js";

function Loader(editor) {
  const scope = this;

  this.texturePath = "";

  this.loadItemList = function (items) {
    LoaderUtils.getFilesFromItemList(items, function (files, filesMap) {
      scope.loadFiles(files, filesMap);
    });
  };

  this.loadFiles = function (files, filesMap) {
    if (files.length > 0) {
      filesMap = filesMap || LoaderUtils.createFilesMap(files);

      const manager = new THREE.LoadingManager();
      manager.setURLModifier(function (url) {
        url = url.replace(/^(\.?\/)/, ""); // remove './'

        const file = filesMap[url];

        if (file) {
          return URL.createObjectURL(file);
        }

        return url;
      });

      manager.addHandler(/\.tga$/i, new TGALoader());

      for (let i = 0; i < files.length; i++) {
        scope.loadFile(files[i], manager);
      }
    }
  };

  this.loadFile = function (file, manager) {
    const filename = file.name;
    const extension = filename.split(".").pop().toLowerCase();

    const reader = new FileReader();
    reader.addEventListener("progress", function (event) {
      const size = "(" + Math.floor(event.total / 1000) + " KB)";
      const progress = Math.floor((event.loaded / event.total) * 100) + "%";
    });

    switch (extension) {
      case "ply": {
        reader.addEventListener(
          "load",
          async function (event) {
            const contents = event.target.result;

            const { PLYLoader } = await import("./loaders/PLYLoader");

            const geometry = new PLYLoader().parse(contents);
            let object;

            if (geometry.index !== null) {
              const material = new THREE.MeshStandardMaterial();

              object = new THREE.Mesh(geometry, material);
              object.name = filename;
            } else {
              const material = new THREE.PointsMaterial({
                size: 0.01,
              });
              material.vertexColors = geometry.hasAttribute("color");

              object = new THREE.Points(geometry, material);
              object.name = filename;
            }

            editor.execute(new AddObjectCommand(editor, object));
          },
          false
        );
        reader.readAsArrayBuffer(file);

        break;
      }

      case "stl": {
        reader.addEventListener(
          "load",
          async function (event) {
            const contents = event.target.result;

            const { STLLoader } = await import("./loaders/STLLoader");

            const geometry = new STLLoader().parse(contents);
            const material = new THREE.MeshStandardMaterial();

            const mesh = new THREE.Mesh(geometry, material);
            mesh.name = filename;

            editor.execute(new AddObjectCommand(editor, mesh));
          },
          false
        );

        if (reader.readAsBinaryString !== undefined) {
          reader.readAsBinaryString(file);
        } else {
          reader.readAsArrayBuffer(file);
        }

        break;
      }

      default:
        if (editor.editorCallback && editor.editorCallback.onError)
          editor.editorCallback.onError("Unsupported file format (" + extension + ").");

        break;
    }
  };
}

export { Loader };
