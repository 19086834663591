import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { INotificationRepository } from "../../data/repositories/notificationRepository";
import { INotification } from "../../presenter/store/notification/types";

export class FetchNotificationDetail extends UseCase<FetchNotificationDetailParams, INotification | undefined>{
    repository: INotificationRepository
    constructor(repository: INotificationRepository) {
        super()
        this.repository = repository
    }

    call(params: FetchNotificationDetailParams): Promise<Either<Failure, INotification | undefined>> {
       return this.repository.fetchNotificationDetail(params.notificationId)
    }
}

class FetchNotificationDetailParams {
    notificationId: string
    constructor(notificationId: string) {
        this.notificationId = notificationId
    }
}