import { Either } from "src/base/Either"
import { Failure } from "src/base/Failure"
import { UseCase } from "src/base/UseCase"
import { Pagination } from "src/shared/model/pagination"
import { Attribute } from "../../data/models/attribute"
import { IMedicalRecordRepository } from "../../data/repositories/medicalRecordRepository"


export class FetchAttributes extends UseCase<any, Pagination<Attribute>>{
    private repository: IMedicalRecordRepository
    constructor(repository: IMedicalRecordRepository) {
        super()
        this.repository = repository
    }

    call(): Promise<Either<Failure, Pagination<Attribute>>> {
        return this.repository.fetchAttributes()
    }
}