import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { RemoteFilesState } from "src/base/Enums";
import { DI, Injector } from "src/base/Injector";
import { ConfirmDeletePopup } from "src/components/popup/confirmDeletePopup";
import SelectPatientPopup from "src/components/popup/selectPatientPopup";
import { Strings } from "src/constants";
import { IUser } from "src/features/account/data/models/user";
import { MFile } from "src/features/medicalRecord/data/models/file";
import { RemoteFilesDownloadContainer } from "src/features/plyDownload/presenter/container/RemoteFilesDownloadContainer";
import { clearAllSelectedFiles, selectRemoteFile } from "src/features/plyDownload/presenter/store";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import { PaginationComponent } from "src/shared/components/pagination";
import { IRootState } from "src/store";
import { Assets } from "src/utils/assets";

export interface RemoteFilesPageState {
  removePlyId: any;
}

class RemoteFilesPage extends React.Component<RemoteFilesPageType, RemoteFilesPageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      removePlyId: "",
    };
  }

  componentDidMount() {
    DI.get().injectRemoteFilesDownloadDependencies();
    this.props.onFetchRemoteFiles(1);
  }

  componentWillUnmount() {
    this.props.clearAllSelectedFiles();
  }

  render(): React.ReactNode {
    return (
      <MainLayout
        isLoading={this.props.state === RemoteFilesState.fetchingFiles}
        className="secondpage navstate_show p-ply-download animate-item"
        subClassName="p-ply-download"
      >
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">{Strings.PLY_DOWNLOAD_MANAGEMENT}</div>
            <div className="card-body">
              <div className="btn-group2">
                <a
                  className={`btn btn-primary btn-main ${
                    this.props.selectedRemoteFiles.length > 0 ? "" : "disabled gray-btn"
                  }`}
                  onClick={() => {
                    this.props.changeModal(Modals.LINK_PATIENT);
                    this.props.onFetchPatients("", "", 1);
                  }}
                >
                  {Strings.LINK_PATIENT}
                </a>
              </div>
              <div className="grid-container mb00 pb00 pt00 mt-6">
                {this.props.remoteFiles.data.map((file) => {
                  let fileSelected: boolean =
                    this.props.selectedRemoteFiles.findIndex((mfile) => mfile.id === file.id) > -1;
                  return (
                    <div className="grid-item">
                      <div className="grid-head">
                        <img
                          className={`head-item select-box ${
                            this.props.selectedRemoteFiles.length < 2 ? "" : fileSelected ? "" : "hide"
                          }`}
                          src={fileSelected ? Assets.boxActive : Assets.boxInActive}
                          alt=""
                          onClick={() => {
                            this.props.selectRemoteFile(file);
                          }}
                        />
                        <img
                          className="head-item close-btn"
                          src={Assets.closePly}
                          alt=""
                          onClick={() => {
                            this.setState({
                              removePlyId: file.id,
                            });
                            this.props.changeModal(Modals.CONFIRM_REMOVE);
                          }}
                        />
                      </div>
                      <img className="mb10" src={Assets.ply} alt="" />
                      <span className="text_bold fs16 file_name">{file.name}</span>
                      <span className="fs14 datetime">{moment(file.created_at).format("YYYY年MM月DD日 HH:mm")}</span>
                    </div>
                  );
                })}
              </div>
            </div>
            <div
              className={
                this.props.remoteFiles.data.length === 8 || this.props.remoteFiles.last_page > 1
                  ? "showPaginationComponent mb20"
                  : "hide"
              }
            >
              <PaginationComponent
                className="justify-content-center"
                currentPage={this.props.remoteFiles.current_page}
                totalPage={this.props.remoteFiles.last_page}
                onClick={(page) => {
                  this.props.onFetchRemoteFiles(page);
                }}
              ></PaginationComponent>
            </div>
          </div>
          <ConfirmDeletePopup
            changeModal={this.props.changeModal}
            currentModal={this.props.currentModal}
            onDelete={() => {
              this.props.onDeleteRemoteFile(this.state.removePlyId.toString(), this.props.remoteFiles.current_page);
            }}
          />
          <SelectPatientPopup
            onSubmitting={
              this.props.state === RemoteFilesState.linkingSelectedPatient ||
              this.props.state === RemoteFilesState.combiningSelectedPlyFiles
            }
            onLoadmorePatients={(name, page) => {
              this.props.onFetchPatients(name, "", page);
            }}
            onSearchPatients={(name) => {
              this.props.onFetchPatients(name, "", 1);
            }}
            patients={this.props.patients}
            onLink={(selectedPatientId) => {
              this.props.onLinkSelectedPatient(selectedPatientId, this.props.selectedRemoteFiles);
            }}
            isShow={this.props.currentModal === Modals.LINK_PATIENT}
          />
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ remoteFilesDownload, app }: IRootState) => {
  const { remoteFiles, selectedRemoteFiles, state, patients } = remoteFilesDownload;
  const { currentModal } = app;

  return {
    remoteFiles,
    selectedRemoteFiles,
    state,
    patients,
    currentModal,
  };
};
const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    onFetchRemoteFiles: (page: number) =>
      Injector.get()
        .find<RemoteFilesDownloadContainer>("RemoteFilesDownloadContainer")
        .onFetchRemoteFiles(dispatch, page),
    onFetchPatients: (searchString: string, query: string, page: number) =>
      Injector.get()
        .find<RemoteFilesDownloadContainer>("RemoteFilesDownloadContainer")
        .onFetchPatients(dispatch, searchString, query, page),
    selectRemoteFile: (file: MFile) => dispatch(selectRemoteFile(file)),
    onDeleteRemoteFile: (id: string, page: number) =>
      Injector.get()
        .find<RemoteFilesDownloadContainer>("RemoteFilesDownloadContainer")
        .onDeleteRemoteFile(dispatch, id, page),
    onLinkSelectedPatient: (patient: IUser, selectedFiles: MFile[]) =>
      Injector.get()
        .find<RemoteFilesDownloadContainer>("RemoteFilesDownloadContainer")
        .onLinkSelectedPatient(dispatch, patient, selectedFiles),
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
    clearAllSelectedFiles: () => dispatch(clearAllSelectedFiles()),
  };
};

type RemoteFilesPageType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(RemoteFilesPage);
