import React from "react";
import { Link } from "react-router-dom";
import { history, Routes } from "src/helpers";
import { Strings } from "src/constants";
import { Modals } from "src/helpers";
import Modal from "../modal/modal";
import { MainButton } from "src/components/button/mainButton";

// export interface TypeOfCsv {
//     typyofcsv: number
// }

export function CSVOption(props: any) {
    return <Modal
        id="CSVOption"
        className={`${props.currentModal == Modals.CSVOPTION ? "flexible" : ""}`}
        dialogExtraClassName=""
        isShow={props.currentModal == Modals.CSVOPTION}
        footerChildren={
            (
                <React.Fragment>
                    <div>
                        <MainButton
                            title='モリタ'
                            enabled={true}
                            className="btn btn-white close"
                            type="a"
                            onSubmit={() => {
                                history.replace({
                                    pathname: Routes.PATIENT_MANAGEMENT_CSV_IMPORT,
                                    search: `?type=1`
                                })
                                props.changeModal(Modals.NONE);
                            }}
                        />
                    </div>
                    <div>
                        <MainButton
                            title='プラネット'
                            enabled={true}
                            className="btn btn-white close"
                            type="a"
                            onSubmit={() => {
                                history.replace({
                                    pathname: Routes.PATIENT_MANAGEMENT_CSV_IMPORT,
                                    search: `?type=2`
                                })
                                props.changeModal(Modals.NONE);
                            }}
                        />
                    </div>
                    <div>
                        <MainButton
                            title='Mic'
                            enabled={true}
                            className="btn btn-white close"
                            type="a"
                            onSubmit={() => {
                                history.replace({
                                    pathname: Routes.PATIENT_MANAGEMENT_CSV_IMPORT,
                                    search: `?type=3`
                                })
                                props.changeModal(Modals.NONE);
                            }}
                        />
                    </div>
                </React.Fragment>
            )
        }
    >
        <h4 className="text_bold mb20">CSVフォーマット選択</h4>
    </Modal>
}