import React from "react";
import { PopupComponent } from "./popup";

export class PaymentInfoPopup extends React.Component {
    render() {
        return <PopupComponent id="note-box">
            <h3>CVVとは</h3>
            <p>CVVとは、セキュリティコードと呼ばれる番号のことです。クレジットカードやデビットカード裏面のサインパネルに記載されている数字の末尾3~4桁のこと指していて、本人確認をするためや不正利用を防ぐ役割があります。その他にも、インターネットショッピングでカード決済をする際に、カードが利用者自身お手元にあることを確認するために使われることが多いです。</p>
        </PopupComponent>
    }
}