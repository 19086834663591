import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IPatientRepository } from "../../data/repositories/patientRepository";

export class DeletePatient extends UseCase<DeletePatientParams, boolean>{
    private repository: IPatientRepository
    constructor(repository: IPatientRepository) {
        super()
        this.repository = repository
    }

    call(params: DeletePatientParams): Promise<Either<Failure, boolean>> {
        return this.repository.deletePatient(params.id)
    }

}

export class DeletePatientParams {
    id: string
    constructor(id: string) {
        this.id = id
    }
}