import { Radar } from "react-chartjs-2";
import { BaseChart } from "./chart";

export interface RadarChartProps {
  labels: string[];
}

export class RadarChartComponent extends BaseChart<RadarChartProps> {
  render() {
    return (
      <Radar
        data={{
          labels: this.props.labels,
          datasets: this.props.items.map((item) => {
            return {
              label: "",
              data: item.percents as number[],
              backgroundColor: "rgba(0,0,0,0.0)",
              borderColor: item.color,
              borderWidth: 2,
            };
          }),
        }}
        width={this.props.width}
        height={this.props.height}
        options={{
          scales: {
            r: {
              angleLines: {
                display: false,
              },
              max: 20,
              min: 0,
              pointLabels: {
                display: true,
                font: {
                  size: 20,
                  style: "normal",
                },
              },
              ticks: {
                display: false,
                maxTicksLimit: 20,
                stepSize: 4,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
              labels: {
                color: "rgb(255, 99, 132)",
              },
            },
            datalabels: {
              display: false,
            },
          },
        }}
      />
    );
  }
}
