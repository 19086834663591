import { Strings } from "src/constants";
import { AnalyticFooter } from "./analyticFooter";

export class TotalAnalyticFooter extends AnalyticFooter {
    title = Strings.ANALYSIS_TOTAL_EVAL
    content = <div className="comprehensive-box">
        <div className="comprehensive-box--ins shadow-box">
            <table>
                <thead>
                    <tr>
                        <th className="green-txt" style={{ width: "30%" }}>BOP</th>
                        <th style={{ width: "10%" }}></th>
                        <th style={{ width: "60%" }}>
                            <div className="bar green-bar">
                                <div className="bar-percent" style={{ width: "18%" }}></div><span>18％</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>最悪値</td>
                        <td>45％</td>
                        <td>
                            <div className="bar purple-bar">
                                <div className="bar-percent" style={{ width: "60%" }}></div><span>60％</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>最善値</td>
                        <td>18％</td>
                        <td>
                            <div className="bar yellow-bar">
                                <div className="bar-percent" style={{ width: "0%" }}></div><span>0.0％</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="comprehensive-box--ins shadow-box">
            <table>
                <thead>
                    <tr>
                        <th className="green-txt" style={{ width: "30%" }}>
                            EPP<span>4mm以上</span></th>
                        <th style={{ width: "10%" }}> </th>
                        <th style={{ width: "60%" }}>
                            <div className="bar green-bar">
                                <div className="bar-percent" style={{ width: "14%" }}></div><span>14％</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>最悪値</td>
                        <td>45％</td>
                        <td>
                            <div className="bar purple-bar">
                                <div className="bar-percent" style={{ width: "72.5D%" }}></div><span>72.5％</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>最善値</td>
                        <td>18％</td>
                        <td>
                            <div className="bar orange-bar">
                                <div className="bar-percent" style={{ width: "14.3%" }}></div><span>14.3％</span>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div className="comprehensive-box--ins shadow-box">
            <table>
                <thead>
                    <tr>
                        <th className="green-txt" style={{ width: "30%" }}>
                            PCR</th>
                        <th style={{ width: "10%" }}> </th>
                        <th style={{ width: "60%" }}>
                            <div className="bar green-bar">
                                <div className="bar-percent" style={{ width: "30%" }}></div><span>30％</span>
                            </div>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>最悪値</td>
                        <td>45％</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td>最善値</td>
                        <td>18％</td>
                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
}