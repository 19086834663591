import React from "react";
import { BarChart } from "src/components/chart/bar_chart_component";
import { Strings } from "src/constants";
import { EppAnalyticBody } from "../body/eppAnalyticBody";
import { EppAnalyticFooter } from "../footer/eppAnalyticFooter";
import { EppAnalyticHeader } from "../header/eppAnalyticHeader";
import {
  PatientAnalyticTab,
  PatientAnalyticTabProps,
} from "./patientAnalyticTab";

export class EPPAnalyticTab extends React.Component<PatientAnalyticTabProps> {
  render(): React.ReactNode {
    let showingDates = this.props.analyticDetail.analysis.dates.filter(
      (date) => date.shouldShowAnalytic
    );
    return (
      <PatientAnalyticTab
        id={this.props.id}
        onChangeDates={this.props.onChangeDates}
        className={this.props.className}
        analyticDetail={this.props.analyticDetail}
        header={
          <EppAnalyticHeader
            id={`${this.props.id}-header`}
            onChangeDates={this.props.onChangeDates}
            totals={[
              {
                label: "4mm以上の割合",
                value: (showingDates && showingDates.length !== 0 ? (showingDates[0]?.result as number ? (showingDates[0]?.result as number).toFixed(2) : "0") : "0") + "%"
              }, {
                label: "ポケットの平均",
                value: (showingDates && showingDates.length !== 0 ? parseInt((this.props.analyticDetail.analysis.total ?? {})[showingDates[0].value]?.value ?? 0)?.toFixed(2) : "0") + "mm",
              }
            ]
              // (this.props.analyticDetail.analysis.total ?? []).map(
              //   (total) => {
              //     return {
              //       label: total.label,
              //       value:
              //         parseFloat(total.value).toFixed(2).toString() +
              //         (total.label === "ポケットの平均" ? "mm" : "%"),
              //     };
              //   }
              // )
            }
            dates={this.props.analyticDetail.analysis.dates}
            title={Strings.ANALYSIS_EPP_RESULT}
            chart={
              <BarChart
                items={
                  this.props.analyticDetail.analysis.chart &&
                    this.props.analyticDetail.analysis.dates.filter(
                      (date) => date.shouldShowAnalytic
                    ) &&
                    this.props.analyticDetail.analysis.dates.filter(
                      (date) => date.shouldShowAnalytic
                    )[0] &&
                    this.props.analyticDetail.analysis.chart[
                    this.props.analyticDetail.analysis.dates.filter(
                      (date) => date.shouldShowAnalytic
                    )[0].value
                    ]
                    ? this.props.analyticDetail.analysis.chart[
                      this.props.analyticDetail.analysis.dates.filter(
                        (date) => date.shouldShowAnalytic
                      )[0].value
                    ].map((date) => {
                      return {
                        name: date.label,
                        percents: date.value,
                        color:
                          "#" +
                          Math.floor(Math.random() * 16777215).toString(16),
                      };
                    })
                    : []
                }
              />
            }
          />
        }
        body={
          <EppAnalyticBody
            id={`${this.props.id}-body`}
            comprehensive={this.props.analyticDetail.comprehensive}
          />
        }
        footer={<EppAnalyticFooter id={`${this.props.id}-footer`} />}
      />
    );
  }
}
