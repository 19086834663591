import { MedicalRecordState } from "src/base/Enums";
import { IUser } from "src/features/account/data/models/user";
import { Attribute } from "src/features/medicalRecord/data/models/attribute";
import { IMedicalRecord, ToothAttribute } from "src/features/medicalRecord/data/models/medicalRecord";
import { Pagination } from "src/shared/model/pagination";
import { action } from "typesafe-actions";
import { MedicalRecordActionType } from "./types";

export function medicalRecordStateChanged(medicalRecordState: MedicalRecordState) {
    return action(MedicalRecordActionType.MEDICAL_RECORD_STATE_CHANGED, { medicalRecordState })
}

export function medicalRecordsFetched(records: Pagination<IMedicalRecord>) {
    return action(MedicalRecordActionType.MEDICAL_RECORDS_FETCHED, { records })
}

export function medicalRecordUpdated(record: IMedicalRecord) {
    return action(MedicalRecordActionType.MEDICAL_RECORD_UPDATED, { record })
}

export function selectedMedicalRecordChanged(record: IMedicalRecord) {
    return action(MedicalRecordActionType.SELECTED_MEDICAL_RECORD_UPDATED, { record })
}

export function selectedToothChanged(toothIndex: number) {
    return action(MedicalRecordActionType.SELECTED_TOOTH_UPDATED, { toothIndex })
}

export function selectedMedicalRecordDataUpdated(key: string, value: any) {
    return action(MedicalRecordActionType.SELECTED_MEDICAL_RECORD_DATA_UPDATED, { key, value })
}

export function medicalRecordsPaginationUpdated(key: string, value: any) {
    return action(MedicalRecordActionType.MEDICAL_RECORD_PAGINATION_UPDATED, { key, value })
}

export function medicalOrthodontistsSearched(orthodontists: Pagination<IUser>) {
    return action(MedicalRecordActionType.MEDICAL_ORTHODONTISTS_SEARCHED, { orthodontists })
}

export function medicalPatientsSearched(patients: Pagination<IUser>) {
    return action(MedicalRecordActionType.MEDICAL_PATIENTS_SEARCHED, { patients })
}

export function updateMedicalSubmiting(isSubmitting: boolean) {
    return action(MedicalRecordActionType.UPDATE_MEDICAL_SUBMITING, { isSubmitting })
}

export function attributesFetched(attributes: Array<Attribute>) {
    return action(MedicalRecordActionType.ATTRIBUTES_FETCHED, { attributes })
}

export function updateToothInfo(key: string, value: any) {
    return action(MedicalRecordActionType.UPDATE_TOOTH_INFO, { key, value})
}

export function saveToothInfo(toothIndex: number, tooth: Map<string, ToothAttribute>) {
    return action(MedicalRecordActionType.SAVE_TOOTH_INFO, { toothIndex, tooth})
}