import { TrackballControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React from "react";
import * as THREE from "three";

interface ModelViewerProps {
  isLoading: boolean;
  progressBar: string;
  progressBarColor: string;
  mesh: THREE.Mesh;
  meshType: string;
}

class ModelViewer extends React.Component<ModelViewerProps> {
  render() {
    return (
      <div id={`model-viewer`}>
        <div className={`model-viewer-progress-bar ${this.props.isLoading ? "" : "hide"}`}>
          <div
            className="model-viewer-progress-bar progress"
            style={{ width: this.props.progressBar, backgroundColor: this.props.progressBarColor }}
          />
        </div>
        <Canvas camera={{ position: [0, 10, 60] }} style={{ minHeight: "78vh", maxHeight: "78vh" }}>
          <mesh geometry={this.props.mesh.geometry}>
            {this.props.meshType === "stl" ? (
              <meshMatcapMaterial side={THREE.DoubleSide} />
            ) : (
              <meshBasicMaterial vertexColors side={THREE.DoubleSide} />
            )}
          </mesh>
          <TrackballControls rotateSpeed={5} />
        </Canvas>
      </div>
    );
  }
}

export default ModelViewer;
