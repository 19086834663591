import heic2any from "heic2any";
import moment from "moment";
import ReactS3Client from "react-aws-s3-typescript";
import { IAccountState } from "src/features/account/presenter/store";
import { MFile, mFileFromJson } from "src/features/medicalRecord/data/models/file";
import store from "src/store";
import { GLB3DExporter } from "src/threeJs/exporters/GLB3DExporter";
import { STL3DExporter } from "src/threeJs/exporters/STL3DExporter";
import { ThreeJsModule } from "src/threeJs/modules/threeModule";

const awsS3Config = {
  bucketName: process.env.REACT_APP_AWS_S3_BITBUCKET!,
  dirName: process.env.REACT_APP_AWS_S3_DIRNAME!,
  region: process.env.REACT_APP_AWS_S3_REGION!,
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID!,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY!,
  s3Url: process.env.REACT_APP_AWS_S3_URL!,
};

export async function onSelectFile(file: File, callback: (value: any, name?: string, file?: any) => void) {
  // called each time file input changes
  if (file) {
    const name = file.name;
    if ((file.name.indexOf(".heic") > -1 || file.name.indexOf(".heif") > -1) && file instanceof Blob) {
      file = (await heic2any({
        blob: file,
        toType: "image/jpeg",
        quality: 0.2,
      })) as File;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      var url = event.target?.result;
      callback(url, name, file);
    };
  }
}

//convert a file to a blob (formdata format for file)
export function dataURIToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(",");
  const byteString = splitDataURI[0].indexOf("base64") >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}

function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export function formatDate(date: Date, shouldHasTime?: boolean) {
  return (
    [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join("-") +
    (shouldHasTime
      ? " " +
        [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(":")
      : "")
  );
}

export function convertUrlEncodedBody(rawBody: any) {
  const formBody = Object.entries(rawBody)
    .map(([key, value]) => encodeURIComponent(key) + "=" + encodeURIComponent(value as string | number | boolean))
    .join("&");
  return formBody;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function showPopupById(id: string) {
  dismissAllPopups();
  pushPopupById(id);
}

export function pushPopupById(id: string) {
  let element = document.getElementById(id);
  if (element?.classList.contains("modal")) {
    element?.classList.add("showthis");
  }
}

export function dismissAllPopups() {
  let collection = document.getElementsByClassName("modal");
  for (let i = 0; i < collection.length; i++) {
    let element = collection.item(i);
    if (element?.classList.contains("modal") && element?.classList.contains("showthis")) {
      element?.classList.remove("showthis");
    }
  }
}

export function dismissPopupById(id: string) {
  let element = document.getElementById(id);
  if (element?.classList.contains("modal") && element?.classList.contains("showthis")) {
    element?.classList.remove("showthis");
  }
}

export function centuryFromYear(year: number) {
  return Math.floor((year - 1) / 100) + 1;
}

export function cloneArray<T>(input: T[]) {
  return JSON.parse(JSON.stringify(input)) as T[];
}

export function compareBetween(object1: any, object2: any, propNames: string[]): boolean {
  return propNames.every((prop) => object1[prop] && object2[prop] && object1[prop] === object2[prop]);
}

export function makeid(length: number) {
  var result = "";
  var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export function convertPlyFilesToCorrespondingStlFiles(
  inputPlyFiles: File[],
  onComplete: (outputStlFiles?: Map<string, File>) => void,
  fileKeys?: string[]
) {
  ThreeJsModule.getInstance().loadModelFromFileList(
    inputPlyFiles,
    new STL3DExporter(),
    (convertedMFiles) => {
      let outputFileMap = new Map();
      convertedMFiles.forEach((mfile) => {
        outputFileMap.set(mfile.key, new File([mfile.blob], mfile.name));
      });
      onComplete(outputFileMap);
    },
    fileKeys
  );
}

export function convertToGlbFiles(
  inputFiles: Map<string, any>,
  onComplete: (outputGlbFiles: Map<string, File | MFile>) => void,
  useMFileOutput?: boolean,
  keys?: string[]
) {
  ThreeJsModule.getInstance().loadModelFromFileList(
    Array.from(inputFiles.values()),
    new GLB3DExporter(),
    (convertedMFiles) => {
      let outputFileMap = new Map();

      convertedMFiles.forEach((mfile) => {
        mfile.file = inputFiles.get(mfile.key);

        outputFileMap.set(mfile.key, useMFileOutput ? mFileFromJson(mfile) : new File([mfile.blob], mfile.name));
      });
      onComplete(outputFileMap);
    },
    keys
  );
}

export function findParamsFromSearchString(searchString: string) {
  if (!searchString) return;
  let search = searchString.replaceAll("?", "");
  let paramStrings = search.split("&");
  let params: Map<string, string> = new Map();
  paramStrings.forEach((pstring) => {
    if (!pstring.split("=")) {
      return;
    }
    let key = pstring.split("=")[0];
    let value = pstring.split("=")[1];
    params.set(key, value);
  });
  return params;
}

export function findSubRouteFromPathName(routeName: string, baseName?: string) {
  return routeName.replaceAll(`${baseName || process.env.REACT_APP_PATH_NAME}/`, "/");
}

export const uploadFileToS3 = async (file: File) => {
  let me = (store.getState().account as IAccountState).me;
  const fileType = file.name.split(".").pop();

  awsS3Config.dirName = `upload/clinic_${me.clinic?.id}/temp/${fileType}`;

  const awsS3 = new ReactS3Client(awsS3Config);
  const timestamp = moment().unix();

  return awsS3.uploadFile(file, `${timestamp}.${file?.name?.split(".")[0]}`);
};
