import { LoadingOverlayComponent } from "src/shared/components/loadingOverlayComponent";
import { MainBody } from "src/shared/layouts/mainBody";
import { MainSection } from "src/shared/layouts/mainSection";

export function AuthLayout(props: any) {
    return <LoadingOverlayComponent isLoading={props.isLoading}>
        <MainBody className={"secondpage navstate_show page-login animate-item " + (props.bodyClassname || "")}>
            <MainSection className={"main_body " + (props.sectionClassname || "")}>
                <div id="layoutSidenav">
                    {props.children}
                </div>
            </MainSection>
        </MainBody>
    </LoadingOverlayComponent>
}