import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { isRight, match } from "src/base/Either";
import { OrthodontistState } from "src/base/Enums";
import { Strings, patientStatus } from "src/constants";
import { defaultUser, IUser } from "src/features/account/data/models/user";
import { history, Routes } from "src/helpers";
import { handleError } from "src/helpers/errorHandler";
import { AddOrthodontist, AddOrthodontistParams } from "../../domain/usecases/addOrthodontist";
import { DeleteOrthodontist, DeleteOrthodontistParams } from "../../domain/usecases/deleteOrthodontist";
import {
  FetchOrthodontistDetails,
  FetchOrthodontistDetailsParams,
} from "../../domain/usecases/fetchOrthodontistDetails";
import { FetchOrthodontists, FetchOrthodontistsParams } from "../../domain/usecases/fetchOrthodontists";
import { SendEmailToOrthodontist, SendEmailToOrthodontistParams } from "../../domain/usecases/sendEmail";
import { UpdateOrthodontist, UpdateOrthodontistParams } from "../../domain/usecases/updateOrthodontist";
import {
  UpdateOrthodontistStatus,
  UpdateOrthodontistStatusParams,
} from "../../domain/usecases/updateOrthodontistStatus";
import {
  orthodontistsFetched,
  orthodontistStateChanged,
  orthodontistUpdated,
  selectedOrthodontistChanged,
} from "../store/orthodontist";

export class OrthodontistContainer {
  fetchOrthodontists: FetchOrthodontists;
  addOrthodontist: AddOrthodontist;
  updateOrthodontist: UpdateOrthodontist;
  updateOrthodontistStatus: UpdateOrthodontistStatus;
  deleteOrthodontist: DeleteOrthodontist;
  fetchOrthodontistDetails: FetchOrthodontistDetails;
  sendEmailToOrthodontist: SendEmailToOrthodontist;

  constructor(
    fetchOrthodontists: FetchOrthodontists,
    fetchOrthodontistDetails: FetchOrthodontistDetails,
    addOrthodontist: AddOrthodontist,
    updateOrthodontist: UpdateOrthodontist,
    updateOrthodontistStatus: UpdateOrthodontistStatus,
    deleteOrthodontist: DeleteOrthodontist,
    sendEmailToOrthodontist: SendEmailToOrthodontist
  ) {
    this.fetchOrthodontists = fetchOrthodontists;
    this.fetchOrthodontistDetails = fetchOrthodontistDetails;
    this.addOrthodontist = addOrthodontist;
    this.updateOrthodontist = updateOrthodontist;
    this.updateOrthodontistStatus = updateOrthodontistStatus;
    this.deleteOrthodontist = deleteOrthodontist;
    this.sendEmailToOrthodontist = sendEmailToOrthodontist;
  }

  async onSendEmailToOrthodontist(dispatch: Dispatch, orthodontist: IUser) {
    let either = await this.sendEmailToOrthodontist.call(new SendEmailToOrthodontistParams(orthodontist.id));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        dispatch(
          orthodontistUpdated({
            ...orthodontist,
            special_status: "valid",
          })
        );
        toast.success("認証メールの送信に成功しました。", {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
  }

  async onFetchOrthodontists(
    dispatch: Dispatch,
    code: string,
    fullName: string,
    orthodontist_type: number,
    specialStatus: number,
    page: number,
    limit: number,
    status: number
  ) {
    dispatch(orthodontistStateChanged(OrthodontistState.isFetchingOrthodontists));
    let either = await this.fetchOrthodontists.call(
      new FetchOrthodontistsParams(code, fullName, orthodontist_type, specialStatus, page, status, limit)
    );
    if (isRight(either)) {
      dispatch(orthodontistsFetched(either.value));
    }
    dispatch(orthodontistStateChanged(OrthodontistState.none));
  }

  async onFetchOrthondontistDetails(dispatch: Dispatch, id: any) {
    dispatch(orthodontistStateChanged(OrthodontistState.isFetchingOrthodontistDetails));
    let either = await this.fetchOrthodontistDetails.call(new FetchOrthodontistDetailsParams(id));
    if (isRight(either)) {
      if (either.value) dispatch(selectedOrthodontistChanged(either.value));
      else dispatch(selectedOrthodontistChanged({ ...defaultUser, isNotFound: true }));
    } else {
      dispatch(selectedOrthodontistChanged({ ...defaultUser, isNotFound: true }));
    }
    dispatch(orthodontistStateChanged(OrthodontistState.none));
  }

  async onAddOrthodontist(dispatch: Dispatch, orthodontist: IUser) {
    dispatch(orthodontistStateChanged(OrthodontistState.isAddingOrthodontist));
    let either = await this.addOrthodontist.call(new AddOrthodontistParams(orthodontist));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (result) => {
        history.replace(Routes.DENTIST_MANAGEMENT);
        toast.success(Strings.ADD_SUCCESS_CREATE_ORTHO, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
    dispatch(orthodontistStateChanged(OrthodontistState.none));
  }

  async onUpdateOrthodontist(dispatch: Dispatch, orthodontist: IUser) {
    dispatch(orthodontistStateChanged(OrthodontistState.isUpdatingOrthodontist));
    let either = await this.updateOrthodontist.call(new UpdateOrthodontistParams(orthodontist));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (result) => {
        toast.success(Strings.UPDATE_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        dispatch(orthodontistUpdated(orthodontist));
        history.replace(Routes.DENTIST_MANAGEMENT);
      }
    );
    dispatch(orthodontistStateChanged(OrthodontistState.none));
  }

  async onUpdateOrthodontistStatus(dispatch: Dispatch, orthodontist: IUser) {
    dispatch(orthodontistStateChanged(OrthodontistState.isUpdatingOrthodontistStatus));
    let either = await this.updateOrthodontistStatus.call(new UpdateOrthodontistStatusParams(orthodontist.id));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (result) => {
        toast.success(Strings.UPDATE_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });

        const patientStatusKeys = Object.keys(patientStatus);
        const patientStatusKey =
          patientStatusKeys.indexOf(orthodontist.status) === patientStatusKeys.length - 1
            ? 0
            : patientStatusKeys.indexOf(orthodontist.status) + 1;

        dispatch(
          orthodontistUpdated({
            ...orthodontist,

            status: patientStatusKeys[patientStatusKey],
          })
        );
      }
    );
    dispatch(orthodontistStateChanged(OrthodontistState.none));
  }

  async onDeleteOrthodontist(dispatch: Dispatch, id: string, onSuccess: () => void) {
    dispatch(orthodontistStateChanged(OrthodontistState.isDeletingOrthodontist));
    let either = await this.deleteOrthodontist.call(new DeleteOrthodontistParams(id));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (result) => {
        toast.success(Strings.DELETE_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });

        onSuccess();
      }
    );
    dispatch(orthodontistStateChanged(OrthodontistState.none));
  }
}
