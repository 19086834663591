import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { PatientState, PatientsStatus } from "src/base/Enums";
import { Injector } from "src/base/Injector";
import QRCodeViewer from "src/components/QRCodeViewer/QRCodeViewer";
import { MainButton } from "src/components/button/mainButton";
import { InputGroup } from "src/components/inputGroup";
import QRCodeViewerPopup from "src/components/popup/QRCodeViewerPopup";
import { ConfirmDeletePopup } from "src/components/popup/confirmDeletePopup";
import { CSVOption } from "src/components/popup/csvoption";
import { RedirectLinkPopup } from "src/components/popup/medicalRedirectLink";
import { Table } from "src/components/table";
import { Strings } from "src/constants";
import { IUser, defaultUser } from "src/features/account/data/models/user";
import { IMedicalRecord, defaultRecord } from "src/features/medicalRecord/data/models/medicalRecord";
import { selectedMedicalRecordChanged } from "src/features/medicalRecord/presenter/store/medicalRecord";
import { PatientContainer } from "src/features/patient/presenter/container/patientContainer";
import { patientPaginationUpdated, selectedPatientUpdated } from "src/features/patient/presenter/store";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals, Routes, history } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import { PaginationComponent } from "src/shared/components/pagination";
import { IRootState } from "src/store";

interface PatientManagementState {
  code: string;
  name: string;
  query: string;
  patientsStatus: PatientsStatus;
  removePatientId: string;
  selectedPatient: IUser;
}

class PatientManagementPage extends React.Component<PatientManagementType, PatientManagementState> {
  constructor(props: any) {
    super(props);
    this.state = {
      code: "",
      name: "",
      query: "",
      patientsStatus: PatientsStatus.all,
      removePatientId: "",
      selectedPatient: defaultUser,
    };
  }

  componentDidMount() {
    this.props.onFetchPatients(
      this.state.code,
      this.state.name,
      this.state.query,
      this.props.patients.current_page,
      Object.values(PatientsStatus).indexOf(this.state.patientsStatus)
    );
  }

  buildPatientTable = () => {
    return (
      <Table
        className="dataTable-container mt-4 dataTable-container-scroll dataTable-container-patient"
        subClassName="dataTable-table"
        isLoading={this.props.patientState === PatientState.fetchingPatients}
        headers={[
          {
            fields: [
              {
                html: (
                  <div className="select-style select-styleStatus">
                    <select
                      className="form-control"
                      onChange={(e) => {
                        this.setState({
                          ...this.state,
                          patientsStatus: Object.values(PatientsStatus)[parseInt(e.target.value) ?? 0],
                        });
                        this.props.onFetchPatients(
                          this.state.code,
                          this.state.name,
                          this.state.query,
                          1,
                          parseInt(e.target.value) ?? 0
                        );
                      }}
                    >
                      {Object.values(PatientsStatus).map((value, index) => {
                        return (
                          <option
                            selected={Object.values(PatientsStatus).indexOf(this.state.patientsStatus) === index}
                            value={index}
                          >
                            {value}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                ),
              },
              {
                html: (
                  <InputGroup
                    className="iprup1 iprup"
                    inputPlaceHolder={Strings.PATIENT_SPECIALCODE}
                    buttonClassName="btn-success"
                    buttonType="button"
                    value={this.state.code}
                    onValueChanged={(e) => {
                      this.setState({
                        ...this.state,
                        code: e.target.value,
                      });
                      if (!e.target.value) {
                        this.props.onFetchPatients(
                          "",
                          this.state.name,
                          this.state.query,
                          1,
                          Object.values(PatientsStatus).indexOf(this.state.patientsStatus)
                        );
                      }
                    }}
                    buttonText={Strings.SEARCH}
                    onButtonClick={() => {
                      this.props.onFetchPatients(
                        this.state.code,
                        this.state.name,
                        this.state.query,
                        1,
                        Object.values(PatientsStatus).indexOf(this.state.patientsStatus)
                      );
                    }}
                  ></InputGroup>
                ),
              },
              {
                html: (
                  <InputGroup
                    className="iprup2 iprup"
                    inputPlaceHolder={Strings.PATIENT_NAME}
                    buttonClassName="btn-success"
                    buttonType="button"
                    value={this.state.name}
                    onValueChanged={(e) => {
                      this.setState({
                        ...this.state,
                        name: e.target.value,
                      });
                      if (!e.target.value) {
                        this.props.onFetchPatients(
                          this.state.code,
                          "",
                          this.state.query,
                          1,
                          Object.values(PatientsStatus).indexOf(this.state.patientsStatus)
                        );
                      }
                    }}
                    onButtonClick={() => {
                      this.props.onFetchPatients(
                        this.state.code,
                        this.state.name,
                        this.state.query,
                        1,
                        Object.values(PatientsStatus).indexOf(this.state.patientsStatus)
                      );
                    }}
                    buttonText={Strings.SEARCH}
                  ></InputGroup>
                ),
              },
              { html: <div className="tdemail">{Strings.EMAIL_ADDRESS}</div> },
              {
                html: <div className="w100px">症例登録</div>,
              },
              {
                html: <div className="w100px">QRコード</div>,
              },
              { html: Strings.ACTION },
            ],
          },
        ]}
        rows={[
          ...this.props.patients.data.map((patient) => {
            return {
              fields: [
                {
                  html: (
                    <div className={patient.status === "valid" ? "text-valid" : "text-invalid"}>
                      {patient.status === "valid" ? "ID/PW送信済" : "ID/PW未送信"}
                    </div>
                  ),
                },
                // { html: (patient.clinic && patient.clinic.medical_institution ? patient.clinic.medical_institution : "") + patient.code },
                { html: patient.special_code ? patient.special_code : "" },
                { html: patient.full_name },
                { html: patient.email },
                {
                  html: (
                    <div className="fcc">
                      <MainButton
                        title="新規作成"
                        enabled={true}
                        className={`btn btn-white btn-fixwidth ${patient.status === "invalid" ? "hide" : ""}`}
                        type="a"
                        onSubmit={() => {
                          this.setState({
                            selectedPatient: patient,
                          });
                          this.props.changeModal(Modals.REDIRECT_LINK);
                        }}
                      />
                    </div>
                  ),
                },
                {
                  html: patient.qr_code ? (
                    <div
                      className="qrCode-box"
                      onClick={() => {
                        this.props.onSelectPatient(patient);
                        this.props.changeModal(Modals.QRCODE);
                      }}
                    >
                      <QRCodeViewer size={50} value={patient.qr_code} />
                    </div>
                  ) : null,
                },
                {
                  html: (
                    <div className="fcc">
                      <MainButton
                        title="ID/PW送信"
                        enabled={true}
                        className={patient.email === "" || patient.status === "valid" ? "hide" : "btn btn-dark btnsend"}
                        type="a"
                        onSubmit={() => {
                          this.props.sendPatient(patient);
                        }}
                      />
                      <MainButton
                        title={Strings.EDIT}
                        enabled={true}
                        className="btn btn-primary"
                        type="a"
                        onSubmit={() => {
                          history.replace({
                            pathname: Routes.PATIENT_MANAGEMENT_DETAIL,
                            search: `?patientId=${patient.id}`,
                          });
                        }}
                      />
                      <MainButton
                        title={Strings.DELETE}
                        enabled={true}
                        submitting={
                          this.props.selectedPatient.id === patient.id &&
                          this.props.patientState === PatientState.deletingPatient
                        }
                        className="btn btn-danger"
                        type="a"
                        loadingSize={20}
                        onSubmit={() => {
                          this.setState({
                            ...this.state,
                            removePatientId: patient.id,
                          });
                          this.props.onSelectPatient(patient);
                          this.props.changeModal(Modals.CONFIRM_REMOVE);
                        }}
                      />
                    </div>
                  ),
                },
              ],
            };
          }),
        ]}
      ></Table>
    );
  };

  render() {
    return (
      <MainLayout
        isLoading={this.props.patientState === PatientState.fetchingPatients}
        className="secondpage navstate_show page-notice_operator animate-item"
        subClassName="p-notice_operator"
      >
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">{Strings.PATIENT_MANAGEMENT}</div>
            <div className="card-body">
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <div className="btn-group2">
                  <MainButton
                    className="btn btn-primary btn-main"
                    type="a"
                    title={Strings.CREATE}
                    enabled={true}
                    onSubmit={() => {
                      history.replace(Routes.PATIENT_MANAGEMENT_CREATE);
                    }}
                  />
                  <MainButton
                    className="btn btn-primary btn-main"
                    type="a"
                    title={Strings.CSV_BULK_CREATE}
                    enabled={true}
                    onSubmit={() => {
                      history.replace(Routes.PATIENT_MANAGEMENT_CSV_IMPORT);
                      // this.props.changeModal(Modals.CSVOPTION);
                    }}
                  />
                </div>
                <MainButton
                  className="btn btn-primary btn-main"
                  type="a"
                  title="CSV更新"
                  enabled={true}
                  onSubmit={() =>
                    this.props.onUpdateCSV(
                      this.state.code,
                      this.state.name,
                      this.state.query,
                      this.props.patients.current_page,
                      Object.values(PatientsStatus).indexOf(this.state.patientsStatus)
                    )
                  }
                />
              </div>
              {this.buildPatientTable()}
              <div
                className={
                  this.props.patients.data.length === 10 || this.props.patients.last_page > 1
                    ? "showPaginationComponent mt30"
                    : "hide"
                }
              >
                <PaginationComponent
                  className=""
                  currentPage={this.props.patients.current_page}
                  totalPage={this.props.patients.last_page}
                  onClick={(page) => {
                    this.props.onFetchPatients(
                      this.state.code,
                      this.state.name,
                      this.state.query,
                      page,
                      Object.values(PatientsStatus).indexOf(this.state.patientsStatus)
                    );
                    this.props.paginationUpdated("current_page", page);
                  }}
                ></PaginationComponent>
              </div>
            </div>
          </div>
        </div>
        <ConfirmDeletePopup
          changeModal={this.props.changeModal}
          currentModal={this.props.currentModal}
          onDelete={() => {
            this.props.onDeletePatients(this.state.removePatientId, () => {
              this.props.onFetchPatients(
                this.state.code,
                this.state.name,
                this.state.query,
                this.props.patients.current_page,
                Object.values(PatientsStatus).indexOf(this.state.patientsStatus)
              );
            });
            this.props.changeModal(Modals.NONE);
          }}
        />
        <RedirectLinkPopup
          selectedPatient={this.state.selectedPatient}
          onSelectPatient={(patient: IUser) => {
            this.props.onChangeMedicalRecord({
              ...defaultRecord(),
              patient: patient,
            });
          }}
          changeModal={this.props.changeModal}
          currentModal={this.props.currentModal}
        />
        <CSVOption
          // typeofcsv={this.state.typeofcsv}
          changeModal={this.props.changeModal}
          currentModal={this.props.currentModal}
        />
        <QRCodeViewerPopup title={this.props.selectedPatient.full_name} value={this.props.selectedPatient.qr_code!} />
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ patient, app }: IRootState) => {
  const { patients, patientState, selectedPatient } = patient;
  const { currentModal } = app;

  return {
    patients,
    patientState,
    currentModal,
    selectedPatient,
  };
};
const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    onFetchPatients: (code: string, full_name: string, query: string, page: number, status: number) =>
      Injector.get()
        .find<PatientContainer>("PatientContainer")
        .onFetchPatients(dispatch, code, full_name, query, page, status),
    onDeletePatients: (id: string, onSuccess: () => void) =>
      Injector.get().find<PatientContainer>("PatientContainer").onDeletePatient(dispatch, id, onSuccess),
    onSelectPatient: (patient: IUser) => dispatch(selectedPatientUpdated(patient)),
    paginationUpdated: (key: string, value: any) => dispatch(patientPaginationUpdated(key, value)),
    onChangeMedicalRecord: (record: IMedicalRecord) => dispatch(selectedMedicalRecordChanged(record)),
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
    sendPatient: (patient: IUser) =>
      Injector.get().find<PatientContainer>("PatientContainer").onSendEmailToOrthodontist(dispatch, patient),
    onImportCsv: (csvBase64: string) =>
      Injector.get().find<PatientContainer>("PatientContainer").onImportPatientsCsv(dispatch, csvBase64),
    onUpdateCSV: (code: string, fullName: string, query: string, page: number, status: number) =>
      Injector.get()
        .find<PatientContainer>("PatientContainer")
        .onUpdateCSV(dispatch, code, fullName, query, page, status),
  };
};

type PatientManagementType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(PatientManagementPage);
