import { Strings } from "src/constants";

export const TeethIndex = [
  {
    index: 1,
    clipPath: "clip0_92_2",
  },
  {
    index: 2,
    clipPath: "clip1_92_2",
  },
  {
    index: 3,
    clipPath: "clip2_92_2",
  },
  {
    index: 4,
    clipPath: "clip4_92_2",
  },
  {
    index: 5,
    clipPath: "clip5_92_2",
  },
  {
    index: 6,
    clipPath: "clip6_92_2",
  },
  {
    index: 7,
    clipPath: "clip7_92_2",
  },
  {
    index: 8,
    clipPath: "clip8_92_2",
  },
  {
    index: 16,
    clipPath: "clip9_92_2",
  },
  {
    index: 15,
    clipPath: "clip10_92_2",
  },
  {
    index: 14,
    clipPath: "clip11_92_2",
  },
  {
    index: 13,
    clipPath: "clip13_92_2",
  },
  {
    index: 12,
    clipPath: "clip14_92_2",
  },
  {
    index: 11,
    clipPath: "clip15_92_2",
  },
  {
    index: 10,
    clipPath: "clip16_92_2",
  },
  {
    index: 9,
    clipPath: "clip17_92_2",
  },
  {
    index: 17,
    clipPath: "clip18_92_2",
  },
  {
    index: 32,
    clipPath: "clip20_92_2",
  },
  {
    index: 18,
    clipPath: "clip22_92_2",
  },
  {
    index: 31,
    clipPath: "clip23_92_2",
  },
  {
    index: 19,
    clipPath: "clip24_92_2",
  },
  {
    index: 30,
    clipPath: "clip25_92_2",
  },
  {
    index: 20,
    clipPath: "clip26_92_2",
  },
  {
    index: 29,
    clipPath: "clip27_92_2",
  },
  {
    index: 21,
    clipPath: "clip28_92_2",
  },
  {
    index: 28,
    clipPath: "clip29_92_2",
  },
  {
    index: 22,
    clipPath: "clip30_92_2",
  },
  {
    index: 27,
    clipPath: "clip31_92_2",
  },
  {
    index: 23,
    clipPath: "clip32_92_2",
  },
  {
    index: 26,
    clipPath: "clip33_92_2",
  },
  {
    index: 24,
    clipPath: "clip34_92_2",
  },
  {
    index: 25,
    clipPath: "clip35_92_2",
  },
];

export const UpperTeethIndex = [
  {
    index: 1,
    clipPath: "clip0_92_2",
  },
  {
    index: 2,
    clipPath: "clip1_92_2",
  },
  {
    index: 3,
    clipPath: "clip2_92_2",
  },
  {
    index: 4,
    clipPath: "clip4_92_2",
  },
  {
    index: 5,
    clipPath: "clip5_92_2",
  },
  {
    index: 6,
    clipPath: "clip6_92_2",
  },
  {
    index: 7,
    clipPath: "clip7_92_2",
  },
  {
    index: 8,
    clipPath: "clip8_92_2",
  },
  {
    index: 16,
    clipPath: "clip9_92_2",
  },
  {
    index: 15,
    clipPath: "clip10_92_2",
  },
  {
    index: 14,
    clipPath: "clip11_92_2",
  },
  {
    index: 13,
    clipPath: "clip13_92_2",
  },
  {
    index: 12,
    clipPath: "clip14_92_2",
  },
  {
    index: 11,
    clipPath: "clip15_92_2",
  },
  {
    index: 10,
    clipPath: "clip16_92_2",
  },
  {
    index: 9,
    clipPath: "clip17_92_2",
  },
];

export const LowerTeethIndex = [
  {
    index: 17,
    clipPath: "clip18_92_2",
  },
  {
    index: 32,
    clipPath: "clip20_92_2",
  },
  {
    index: 18,
    clipPath: "clip22_92_2",
  },
  {
    index: 31,
    clipPath: "clip23_92_2",
  },
  {
    index: 19,
    clipPath: "clip24_92_2",
  },
  {
    index: 30,
    clipPath: "clip25_92_2",
  },
  {
    index: 20,
    clipPath: "clip26_92_2",
  },
  {
    index: 29,
    clipPath: "clip27_92_2",
  },
  {
    index: 21,
    clipPath: "clip28_92_2",
  },
  {
    index: 28,
    clipPath: "clip29_92_2",
  },
  {
    index: 22,
    clipPath: "clip30_92_2",
  },
  {
    index: 27,
    clipPath: "clip31_92_2",
  },
  {
    index: 23,
    clipPath: "clip32_92_2",
  },
  {
    index: 26,
    clipPath: "clip33_92_2",
  },
  {
    index: 24,
    clipPath: "clip34_92_2",
  },
  {
    index: 25,
    clipPath: "clip35_92_2",
  },
];
