import axios, { AxiosResponse } from "axios";
import { HttpMethod } from "src/helpers";
import { EventDispatcher } from "src/helpers/eventHandler";
import { IApiService, ReponseHandler, Request, Response } from "./ApiService";

export class ReactRestfulService implements IApiService {
  public readonly cookies: Map<string, any> = new Map();
  private _callBacks = new EventDispatcher<{ response?: Response, error?: { text: string, statusCode?: number } }>();


  async request(request: Request): Promise<Response> {
    let url: string = request.url ?? (`${process.env.REACT_APP_API_URL}` + request.endpoint);
    try {
      const time = (new Date()).toISOString();

      if (request.params) {
        url += "?";
        for (var key in Object.keys(request.params)) {
          url =
            Array.isArray(Object.values(request.params)[key]) ?
              url + Object.values(request.params)[key].map((item: any) => {
                return `${Object.keys(request.params ?? {})[key]}[]=${item}&`
              }).join() :
              url +
              (Object.keys(request.params)[key] +
                "=" +
                Object.values(request.params)[key] +
                "&");
        }
      }
      var response: AxiosResponse
      if (request.requireCookies && this.cookies) {
        request.headers = { ...request.headers, cookie: "" };
        this.cookies.forEach((value, key) => {
          request.headers!.cookie += `${key} = ${value}` + ";";
        });
      }

      if (request.shouldAuthorize) {
        if (localStorage.getItem("TOKEN"))
          axios.defaults.headers.common.Authorization = "Bearer " + localStorage.getItem("TOKEN")
      }
      switch (request.method) {
        case HttpMethod.GET:
          response = await axios
            .get(url, {
              headers: request.headers,
            });
          break;
        case HttpMethod.POST:
          response = await axios
            .post(url, request.body?.data, {
              headers: request.headers,
            })
          break;
        case HttpMethod.PUT:
          response = await axios
            .put(url, request.body?.data, {
              headers: request.headers,
            })
          break;
        case HttpMethod.DELETE:
          response = await axios
            .delete(url, {
              data: request.body?.data,
              headers: request.headers ?? {},
            })
          break;
        default:
          response = await axios
            .get(url, {
              headers: request.headers,
            });
      }
      return this.handleReponse(response)
    } catch (e) {
      return this.handleError(url, e, request.allowedStatusCodes)
    }
  }

  handleReponse(res: AxiosResponse): Response {
    if (!res)
      throw "データエラーが発生しました。しばらくしてからもう一度お試しください";
    if (
      res.data &&
      res.status &&
      res.status < 400 &&
      res.data
    ) {
      const resData = {
        data: res.data,
        message: res.data.message ?? res.statusText,
        status_code: res.status,
      };
      return resData
    } else {
      throw res.data.message
        ? res.data.message
        : "データエラーが発生しました。しばらくしてからもう一度お試しください";
    }
  }

  handleError(url: string, e: any, allowedStatusCodes?: number[]): Response {
    if ((allowedStatusCodes?.indexOf(e.response.status) ?? -1) > -1) {
      const resData = {
        data: e.response.data,
        message: e.response.data.message ?? e.response.statusText,
        status_code: e.response.status,
      };

      return resData;
    }
    if (e.response && e.response.data) {
      let errorText =
        e.response.data.message.length > 0
          ? e.response.data.message
          : e.response.statusText;
      if (e.response.data.details) {
        const errors = Object.values(e.response.data.details[Object.keys(e.response.data.details)[0]]);
        if (Array.isArray(errors)) {
          errorText = errors[0];
        }
      }
      this._callBacks.fire(url, {
        error: { text: errorText, statusCode: e.response.status }
      });
      throw errorText
    }

    if (typeof e === "string") {
      throw e
    } else if (e instanceof Error) {
      throw e.message
    }
    return { data: {}, message: "", status_code: 500 }
  }
}
