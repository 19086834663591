export const Strings = {
  //common
  LINE: "-",
  LIST: "一覧",
  CREATE: "新規登録",
  CSV_BULK_CREATE: "CSV一括登録",
  SEND: "送信",
  SEARCH: "検索",
  EMAIL_ADDRESS: "メールアドレス",
  ACTION: "アクション",
  EDIT: "編集",
  ADD: "追加",
  DELETE: "削除",
  ACCOUNT_STATUS: "アカウントステータス",
  ACTIVE: "有効",
  IN_ACTIVE: "無効",
  CREATE_NEW: "新規作成",
  CREATED_AT: "作成日時",
  ATTACH_FILE: "添付ファイル名",
  LOGOUT: "ログアウト",
  LOGIN_SUCCESS: "ログインしました。",
  DELETE_SUCCESS: "削除に成功しました。",
  CANCELL_SUCCESS: "解約に成功しました。",
  ADD_SUCCESS: "追加が完了しました。",
  ADD_SUCCESS_CREATE_ORTHO: "追加が完了しました。",
  ADD_SUCCESS_CREATE: "症例の作成に成功しました。",
  NO_DATA: "データがありません。",
  UPDATE_SUCCESS: "更新が完了しました。",
  DUPLICATE_SUCCESS: "複製が完了しました。",

  SAVE_LOGIN_INFO: "ログイン情報を保存する",
  NAME: "お名前",
  NAME_PLACEHOLDER: "お名前　太郎",
  CASE: "診療分野",
  FURIGANA: "フリガナ",
  FURIGINA_PLACEHOLDER: "オナマエ　タロウ",
  BIRTHDAY: "生年月日",
  GENDER: "性別",
  MALE: "男性",
  FEMALE: "女性",
  ZIP_CODE: "郵便番号",
  ZIP_CODE_PLACEHOLDER: "0000000",
  ZIP_CODE_PLACEHOLDER_PATIENT: "例：0000000（ハイフンなし）",
  ADDRESS: "住所",
  NO_COLOR: "設定しない",
  ADDRESS_PALCEHOLDER: "東京都千代田区丸の内●-●-●●",
  TEL: "電話番号",
  TEL1: "連絡先1",
  TEL1_PLACEHOLDER: "09022833715",
  TEL1_PLACEHOLDER_PATIENT: "例：00000000000（ハイフンなし）",
  TEL2: "連絡先2",
  COLOR: "カラー",
  COLOR_PICK: "カラー選択",
  EMAIL_PLACEHOLDER: "onamae@gmail.com",
  YES: "はい",
  NO: "いいえ",
  CAN: "あり",
  CANNOT: "なし",
  REGISTER: "登録",
  RETURN: "戻る",
  CSV: "CSVファイル",
  CSV_SELECTION: "CSV選択",
  SELECT_FILE: "ファイルを選択する",
  DUPLICATE: "複製",
  TEETH_SVG: "PCR・EPP・BOP",
  DATE_TIME: "日時",
  DATE: "日付",
  TITLE: "タイトル",
  CONTENT: "詳細",
  CONFIRM: "確認",
  ATTACHMENT_PDF: "添付ファイル*PDFのみ",
  ATTACHMENT: "添付ファイル",
  PDF_ONLY: "*PDFのみ",
  ID: "ログインID",
  PASSWORD: "パスワード",
  LOGIN: "ログイン",
  FORGOT_PASSWORD: "パスワードをお忘れの方",
  ALL: "すべて",
  SELECT: "選択してください",
  STL_ONLY: "STLファイルのみ選択可能です",

  //Forget-password
  UPDATE_PASSWORD: "パスワードリセット",
  INPUT_OTP: "認証コードを入力してください",
  INPUT_OTP_IN_EMAIL: "メールアドレスに送信した認証コードを入力してください",
  RESENT_OTP: "メールを再送する",
  SEND_OTP_SUCCESS: "Send otp successful, please check your mailbox",
  VERIFY_OTP_SUCCESS: "Verify otp successful, please type your new password",
  WRONG_OTP: "OTP was wrong, please check your mail box and retype otp",
  RESET_PASSWORD_SUCCESS: "Reset password success",

  //Notice-operator
  NOTICE_OPERATOR: "運営者からのお知らせ",

  //Patient-managent
  PATIENT_MANAGEMENT: "患者登録・編集",
  PATIENT: "患者管理",
  PATIENT_NUMBER: "患者番号",
  PATIENT_NUMBER_PLACEHOLDER: "0000000",
  PATIENT_NAME: "患者名",
  SMOKING: "喫煙",
  HAS_SMOKING: "喫煙する",
  HAS_NOT_SMOKING: "喫煙しない",
  CAN_ANESTHESIA: "麻酔による異常",
  PREGNANCY: "妊娠の有無",
  PATIENT_SPECIALCODE: "カルテ番号",

  //Dentist
  DENTIST_MANAGEMENT: "症例相談・共有",
  DENTIST: "症例相談共有",
  DENTIST_NUMBER: "矯正歯科医番号",
  DENTIST_NAME: "矯正歯科医名",
  SHARE_FIRST: "共有先",

  //Medical
  MEDICAL_MANAGEMENT: "症例登録・編集",
  MEDICAL: "症例管理",
  MEDICAL_NUMBER: "カルテ番号",
  MEDICAL_DENTIST: "担当矯正歯科",
  IMAGE_INSIDE: "口腔内画像",
  HEIGHT: "縦",
  WIDTH: "横",
  ADD_MEDICAL_SUCCESS: "症例の作成に成功しました。",
  UPDATE_MEDICAL_SUCCESS: "症例のアップデートに成功しました。",
  DELETE_MEDICAL_SUCCESS: "症例の削除に成功しました。",
  PDF: "添付ファイル",

  //ply-download
  PLY_DOWNLOAD_MANAGEMENT: "3Dファイル一覧",
  PLY_DOWNLOAD_LIST: "一覧",
  LINK_PATIENT: "患者リンク",
  LINK_NOW: "リンクする",

  //file-management
  FILE_MANAGEMENT: "ファイルアップロード",

  //3d-viewer
  THREED_VIEWER_MANAGEMENT: "噛み合わせマッチング",
  THREED_VIEWER_MANAGEMENT_TITLE: "噛み合わせマッチングには2つのPLYファイルが必要です",
  THREED_VIEWER_MANAGEMENT_BTN_START_EDIT: "噛み合わせマッチングを開始する",
  THREED_VIEWER_MANAGEMENT_BTN_COMPLETE_EDIT: "噛み合わせマッチングを完了する",
  THREED_VIEWER_MANAGEMENT_BTN_LINKED: "症例作成（患者とのリンク）",
  THREED_VIEWER_MANAGEMENT_BTN_EXPORT: "データダウンロード",
  THREED_VIEWER_MATCHING_JAW_TITLE_LEFT: "Left",
  THREED_VIEWER_MATCHING_JAW_TITLE_FRONT: "Front",
  THREED_VIEWER_MATCHING_JAW_TITLE_RIGHT: "Right",
  THREED_VIEWER_MATCHING_JAW_BTN_LEFT: "右",
  THREED_VIEWER_MATCHING_JAW_BTN_FRONT: "正面",
  THREED_VIEWER_MATCHING_JAW_BTN_RIGHT: "左",
  THREED_VIEWER_MATCHING_JAW_BTN_SAVE: "セーブ",

  //Notification
  NOTIFICATION_MANAGEMENT: "全患者への一斉案内",
  NOTIFICATION: "全患者への一斉案内管理",
  NOTIFICATION_TITLE: "お知らせタイトル",
  ADD_NOTIFICATION_SUCCESS: "お知らせの作成に成功しました。",
  UPDATE_NOTIFICATION_SUCCESS: "お知らせのアップデートにに成功しました。",
  DELETE_NOTIFICATION_SUCCESS: "お知らせの削除に成功しました。",

  //Account
  ACCOUNT_MANAGEMENT: "Myアカウント管理",
  ACCOUNT: "アカウント管理",
  ACCOUNT_NAME: "アカウント名",
  ACCOUNT_ID: "ID",
  ACCOUNT_PW: "PW",

  //analysic
  MEDICAL_RECORD_ANALYSIS: "分析結果閲覧",
  TOTAL_MEDICAL_RECORD: "カルテ数",
  ANALYSIS_TOTAL_RESULT: "分析結果-総合評価",
  ANALYSIS_PCR_RESULT: "分析結果-PCR",
  ANALYSIS_EPP_RESULT: "分析結果-EPP",
  ANALYSIS_BOP_RESULT: "分析結果-BOP",
  ANALYSIS_TOTAL_COMPE: "総合評価推移",
  ANALYSIS_PCR_COMPE: "PCR評価推移",
  ANALYSIS_EPP_COMPE: "EPP評価推移",
  ANALYSIS_BOP_COMPE: "分析結果-BOP",
  ANALYSIS_TOTAL_EVAL: "総合評価推移",
  ANALYSIS_EPP_EVAL: "前回からの変化",
  ANALYSIS_BOP_EVAL: "前回との比較",

  //subscription
  SUBSCRIPTION_MANAGEMENT: "プラン変更",
  SUBSCRIPTION: "プラン変更",
  CURRENT_PLAN: "現在のプラン",
  CHANGE_PLAN: "プランを変更する",
  CHANGE: "変更する",
  PLAN_DESCRIPTION: "プラン変更が適用されるのは次回のお支払後になります",
  CANCEL_PLAN_HEADER: "解約手続き",
  CLICK_HERE_TO_CANCEL_PLAN: "解約手続きはこちら",
  CANCEL_PLAN_TITLE: "解約と同時に全データをリセットします",
  CANCEL_PLAN_CONTENT:
    "解約すると、患者様のデータ、症例のデータ等Liex上で管理しているすべてのデータがリセットされます。\n一度リセットされたデータは、再契約いただいても復旧はできませんので、ご了承ください。",
  CANCEL_PLAN: "解約する",
  ARE_YOU_SURE_WANT_TO_CANCEL: "本当に解約しますか。",
  PLAN_VALID_TO: "次回のお支払",
  CANCEL_AT: "このプランの有効期限は、",
  ULTIL: "までです。",

  //errors
  ACCESS_TOKEN_IS_EXPIRED: "アクセストークンの有効期限が切れました。",

  //confirm
  CONFIRM_DELETE: `削除しますか？
  削除するとデータを元に戻せなくなります。`,
  CONFIRM_DELETE_1: `削除してもよろしいでしょうか？`,

  //medical-confirm
  MEDICAL_CONFIRM: "症例確認",

  //image type
  TITLE_INPUT: "表示名入力",
  IMAGE_TYPE_PLACEHOLDER: "デンタルデータ",
};

export const ParseError = (error: string) => {
  if (error == "The account is suspended") localStorage.removeItem("token");
  const errors = {
    "Email or password is incorrect": "メールアドレスまたはパスワードが正しくありません。",
    "Your card's security code is invalid.": "セキュリティコードは正しくありません。",
  };

  for (const [errorName, errorJp] of Object.entries(errors)) {
    if (errorName == error) {
      return errorJp;
    }
  }

  return error;
};

export const analysisTypes: Map<string, string> = new Map([
  ["pcr", "PCR"],
  ["bop", "BOP"],
  ["pattern", "欠損歯数"],
  ["smoking", "喫煙"],
  ["epp", "EPP"],
]);

export const colors = [
  "#EB4444",
  "#F5D31D",
  "#42AF6E",
  "#1583D8",
  "#EA7AAC",
  "#FFE69A",
  "#7AEA93",
  "#7ACFEA",
  "#7F57D5",
  "#954F39",
  "#ED7BAE",
  "#F0682D",
  "#CBA0AE",
  "#BF9742",
  "#A62349",
  "#2C3333",
];

export const patientStatus: any = {
  valid: "有効",
  validTwoWeeks: "2週間有効",
  validOneMonth: "1ヶ月有効",
  validSixMonths: "半年有効",
  invalid: "無効",
};
