import { Dispatch } from "redux";
import { isRight, match } from "src/base/Either";
import { FetchUnsharedFiles } from "../../domain/usecases/fetchUnsharedFiles";
import { unsharedFilesCountUpdated } from "../store";

export class UnsharedFilesContainer {
  fetchUnsharedFiles: FetchUnsharedFiles
  constructor(fetchUnsharedFiles: FetchUnsharedFiles) {
    this.fetchUnsharedFiles = fetchUnsharedFiles
  }

  async onFetchUnsharedFiles(dispatch: Dispatch) {
    let either = await this.fetchUnsharedFiles.call()
    if (isRight(either)) {
      dispatch(unsharedFilesCountUpdated(either.value))
    }
  }
}