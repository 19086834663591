import { Either, LeftSide, match, RightSide } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { Pagination } from "src/shared/model/pagination";
import { IAdminNotice } from "../../presenter/store/adminNotice/types";
import { IAdminNoticeRemoteDataSource } from "../datasources/adminNoticeRemoteDatasource";

export interface IAdminNoticeRepository {
    fetchNotices(page: number): Promise<Either<Failure, Pagination<IAdminNotice>>>
}

export class AdminNoticeRepository implements IAdminNoticeRepository {
    adminNoticeRemoteDataSource: IAdminNoticeRemoteDataSource
    constructor(adminNoticeRemoteDataSource: IAdminNoticeRemoteDataSource) {
        this.adminNoticeRemoteDataSource = adminNoticeRemoteDataSource
    }

    async fetchNotices(page: number): Promise<Either<Failure, Pagination<IAdminNotice>>> {
        try {
            return RightSide(await this.adminNoticeRemoteDataSource.fetchNotices(page));
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e })
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message })
            }
        }
        return LeftSide(new Error(""));
    }
}