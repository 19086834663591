import { PatientState, SubscriptionState } from "src/base/Enums";
import { action } from "typesafe-actions";
import { ICard } from "../../domain/model/card";
import { IPlan } from "../../domain/model/plan";
import { SubscriptionActionType } from "./types";

//trigger when state changed (use for loading state, submitting state,...)
export function subscriptionStateChanged(subscriptionState: SubscriptionState) {
  return action(SubscriptionActionType.SUBSCRIPTION_STATE_CHANGED, {
    subscriptionState,
  });
}

//trigger when plan list changed
export function allPlansUpdated(plans: IPlan[]) {
  return action(SubscriptionActionType.SUBSCRIPTION_ALL_PLANS_UPDATED, {
    plans,
  });
}

//trigger when user change to new plan
export function currentPlanChanged() {
  return action(SubscriptionActionType.SUBSCRIPTION_CURRENT_PLAN_CHANGED);
}

//trigger when user cancel current plan
export function planCancelled() {
  return action(SubscriptionActionType.SUBSCRIPTION_PLAN_CANCELLED);
}

//trigger when user select plan but not save yet
export function planSelected(index: number) {
  return action(SubscriptionActionType.SUBSCRIPTION_PLAN_SELECTED, { index });
}

//trigger when all cards are fetched
export function cardsFetched(cards: ICard[]) {
  return action(SubscriptionActionType.SUBSCRIPTION_CARDS_FETCHED, { cards });
}

//trigger when new card is added
export function cardAdded(card: ICard) {
  return action(SubscriptionActionType.SUBSCRIPTION_CARD_ADDED, { card });
}

//select card on card management popup
export function cardSelected(card: ICard) {
  return action(SubscriptionActionType.SUBSCRIPTION_CARD_SELECTED, { card });
}

//trigger when draft card props changed (use for add, detail popup)
export function draftCardPropsChanged(key: string, value: any) {
  return action(SubscriptionActionType.SUBSCRIPTION_DRAFT_CARD_PROPS_CHANGED, {
    key,
    value,
  });
}

//trigger when draft card changed
export function draftCardChanged(isUpdate: boolean, card: ICard) {
  return action(SubscriptionActionType.SUBSCRIPTION_DRAFT_CARD_CHANGED, {
    isUpdate,
    card,
  });
}

