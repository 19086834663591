import moment from "moment";
import ReactS3Client from "react-aws-s3-typescript";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { isRight, match } from "src/base/Either";
import { MedicalRecordState } from "src/base/Enums";
import { Strings } from "src/constants";
import { IUser } from "src/features/account/data/models/user";
import { IAccountState } from "src/features/account/presenter/store";
import {
  FetchOrthodontists,
  FetchOrthodontistsParams,
} from "src/features/orthodontist/domain/usecases/fetchOrthodontists";
import { FetchListPatients, FetchListPatientsParams } from "src/features/patient/domain/usecases/fetchListPatients";
import { FetchPatients, FetchPatientsParams } from "src/features/patient/domain/usecases/fetchPatients";
import { Routes, history } from "src/helpers";
import { handleError } from "src/helpers/errorHandler";
import { Pagination } from "src/shared/model/pagination";
import store from "src/store";
import { convertPlyFilesToCorrespondingStlFiles, convertToGlbFiles } from "src/utils/utils";
import { MFile } from "../../data/models/file";
import { IMedicalRecord, defaultRecord, uploadFileToS3 } from "../../data/models/medicalRecord";
import { AddMedicalRecord, AddMedicalRecordParams } from "../../domain/usecases/addMedicalRecord";
import { DeleteMedicalRecord, DeleteMedicalRecordParams } from "../../domain/usecases/deleteMedicalRecord";
import { DuplicateMedicalRecord, DuplicateMedicalRecordParams } from "../../domain/usecases/duplicateMedicalRecord";
import { FetchAttributes } from "../../domain/usecases/fetchAttributes";
import {
  FetchMedicalRecordDetail,
  FetchMedicalRecordDetailParams,
} from "../../domain/usecases/fetchMedicalRecordDetail";
import { FetchMedicalRecordParams, FetchMedicalRecords } from "../../domain/usecases/fetchMedicalRecords";
import { UpdateMedicalRecord } from "../../domain/usecases/updateMedicalRecord";
import {
  IMedicalRecordState,
  attributesFetched,
  medicalOrthodontistsSearched,
  medicalPatientsSearched,
  medicalRecordStateChanged,
  medicalRecordsFetched,
  selectedMedicalRecordChanged,
  selectedMedicalRecordDataUpdated,
} from "../store/medicalRecord";

// function deepCopy(object: any) {
//   if (typeof object !== "object" || object === null) {
//     return object;
//   }

//   if (object instanceof Date) {
//     return new Date(object.getTime());
//   }

//   if (object instanceof Array) {
//     return object.reduce((arr, item, i) => {
//       arr[i] = deepCopy(item);
//       return arr;
//     }, []);
//   }

//   if (object instanceof Map) {
//     return new Map(JSON.parse(JSON.stringify(Array.from(object))));
//   }

//   if (object instanceof Object) {
//     return Object.keys(object).reduce((newObj: any, key) => {
//       newObj[key] = deepCopy(object[key]);
//       return newObj;
//     }, {});
//   }
// }

export class MedicalRecordContainer {
  fetchMedicalRecords: FetchMedicalRecords;
  fetchMedicalRecordDetail: FetchMedicalRecordDetail;
  addMedicalRecord: AddMedicalRecord;
  updateMedicalRecord: UpdateMedicalRecord;
  deleteMedicalRecord: DeleteMedicalRecord;
  duplicateMedicalRecord: DuplicateMedicalRecord;
  fetchOrthodontists: FetchOrthodontists;
  fetchPatients: FetchPatients;
  fetchListPatients: FetchListPatients;
  fetchAttributes: FetchAttributes;

  constructor(
    fetchMedicalRecords: FetchMedicalRecords,
    fetchMedicalRecordDetail: FetchMedicalRecordDetail,
    addMedicalRecord: AddMedicalRecord,
    updateMedicalRecord: UpdateMedicalRecord,
    deleteMedicalRecord: DeleteMedicalRecord,
    duplicateMedicalRecord: DuplicateMedicalRecord,
    fetchOrthodontists: FetchOrthodontists,
    fetchPatients: FetchPatients,
    fetchListPatients: FetchListPatients,
    fetchAttributes: FetchAttributes
  ) {
    this.fetchMedicalRecords = fetchMedicalRecords;
    this.fetchMedicalRecordDetail = fetchMedicalRecordDetail;
    this.addMedicalRecord = addMedicalRecord;
    this.updateMedicalRecord = updateMedicalRecord;
    this.deleteMedicalRecord = deleteMedicalRecord;
    this.duplicateMedicalRecord = duplicateMedicalRecord;
    this.fetchOrthodontists = fetchOrthodontists;
    this.fetchPatients = fetchPatients;
    this.fetchListPatients = fetchListPatients;
    this.fetchAttributes = fetchAttributes;
  }

  async onFetchAttributes(dispatch: Dispatch) {
    dispatch(medicalRecordStateChanged(MedicalRecordState.fetchingMedicalRecord));
    let either = await this.fetchAttributes.call();
    if (isRight(either)) {
      dispatch(attributesFetched(either.value.data));
    }
    dispatch(medicalRecordStateChanged(MedicalRecordState.none));
  }

  async onFetchMedicalRecords(
    dispatch: Dispatch,
    code: string,
    fullName: string,
    page: number,
    fieldRequired?: Array<any>,
    orthodontistId?: number,
    fromDate?: Date,
    toDate?: Date,
    date_order?: string
  ) {
    dispatch(medicalRecordStateChanged(MedicalRecordState.fetchingMedicalRecord));
    let either = await this.fetchMedicalRecords.call(
      new FetchMedicalRecordParams(code, fullName, page, fieldRequired, orthodontistId, fromDate, toDate, date_order)
    );
    if (isRight(either)) {
      dispatch(medicalRecordsFetched(either.value));
    }
    dispatch(medicalRecordStateChanged(MedicalRecordState.none));
  }

  async onSelectMedicalRecord(dispatch: Dispatch, recordId?: any) {
    dispatch(medicalRecordStateChanged(MedicalRecordState.fetchingMedicalRecord));
    if (!recordId) {
      dispatch(selectedMedicalRecordChanged(defaultRecord()));
    } else {
      let either = await this.fetchMedicalRecordDetail.call(new FetchMedicalRecordDetailParams(recordId));
      if (isRight(either)) {
        dispatch(selectedMedicalRecordChanged({ ...either.value }));
      } else {
        dispatch(
          selectedMedicalRecordChanged({
            ...defaultRecord(),
            isNotFound: true,
          })
        );
      }
    }
    dispatch(medicalRecordStateChanged(MedicalRecordState.none));
  }

  async onAddMedicalRecord(dispatch: Dispatch, record: IMedicalRecord) {
    dispatch(medicalRecordStateChanged(MedicalRecordState.addingMedicalRecord));

    let either = await this.addMedicalRecord.call(new AddMedicalRecordParams(record));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        history.replace(Routes.MEDICAL_MANAGEMENT);
        toast.success(Strings.ADD_SUCCESS_CREATE, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
    dispatch(medicalRecordStateChanged(MedicalRecordState.none));
  }

  async onUpdateMedicalRecord(dispatch: Dispatch, record: IMedicalRecord) {
    dispatch(medicalRecordStateChanged(MedicalRecordState.updatingMedicalRecord));
    let either = await this.updateMedicalRecord.call(new AddMedicalRecordParams(record));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        history.replace(Routes.MEDICAL_MANAGEMENT);
        toast.success(Strings.UPDATE_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
    dispatch(medicalRecordStateChanged(MedicalRecordState.none));
  }

  async onDeleteMedicalRecord(dispatch: Dispatch, id: string, onSuccess: () => void) {
    dispatch(medicalRecordStateChanged(MedicalRecordState.deletingMedicalRecord));
    let either = await this.deleteMedicalRecord.call(new DeleteMedicalRecordParams(id));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        toast.success(Strings.DELETE_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        onSuccess();
      }
    );
    dispatch(medicalRecordStateChanged(MedicalRecordState.none));
  }

  async onDuplicateMedicalRecord(dispatch: Dispatch, id: string, onSuccess: () => void) {
    dispatch(medicalRecordStateChanged(MedicalRecordState.duplicatingMedicalRecord));
    let either = await this.duplicateMedicalRecord.call(new DuplicateMedicalRecordParams(id));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        toast.success(Strings.DUPLICATE_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        onSuccess();
      }
    );
    dispatch(medicalRecordStateChanged(MedicalRecordState.none));
  }

  async onSearchPatients(dispatch: Dispatch, keyword: string) {
    let either = await this.fetchPatients.call(new FetchPatientsParams("", "", keyword, 1, 1, "get"));
    if (isRight(either)) {
      dispatch(medicalPatientsSearched(either.value));
    }
  }

  async onSearchListPatients(dispatch: Dispatch, keyword: string) {
    let either = await this.fetchListPatients.call(new FetchListPatientsParams("", "", keyword, 1, 1, "get"));
    if (isRight(either)) {
      dispatch(medicalPatientsSearched(either.value));
    }
  }

  async onSelectPatients(dispatch: Dispatch, keyword: string, specialCode: string) {
    dispatch(medicalRecordStateChanged(MedicalRecordState.updatingMedicalRecord));
    let either: any = await this.fetchListPatients.call(new FetchListPatientsParams("", "", keyword, 1, 1, "get"));
    const patientData = either.value.data.filter((patient: IUser) => patient?.special_code === specialCode)[0];

    dispatch(medicalRecordStateChanged(MedicalRecordState.none));
    return patientData;
  }

  async onSearchOrthodontist(dispatch: Dispatch, keyword: string, limit?: number) {
    let either = await this.fetchOrthodontists.call(
      new FetchOrthodontistsParams("", keyword, 0, 0, 0, 1, limit, "get")
    );
    if (isRight(either)) {
      dispatch(medicalOrthodontistsSearched(either.value));
    }
  }

  async onLoadmoreSearchPatients(dispatch: Dispatch, keyword: string, patients: Pagination<IUser>) {
    dispatch(medicalPatientsSearched({ ...patients, isLoadingMore: true }));
    let either = await this.fetchPatients.call(new FetchPatientsParams("", keyword, "", patients.current_page, 1));
    if (isRight(either)) {
      patients = {
        ...either.value,
        data: [...patients.data, ...either.value.data],
      };
      dispatch(medicalPatientsSearched({ ...patients }));
    }
  }

  convertPlyFilesToGlbFromFileInput(dispatch: Dispatch, inputPlyFiles: Map<string, any>) {
    let finalMFiles: Map<string, MFile> = new Map(
      (store.getState().medicalRecord as IMedicalRecordState).selectedMedicalRecord.files
    );
    let allPlyAndStlFiles = new Map(inputPlyFiles);
    let plyKeys = Array.from(inputPlyFiles.keys());
    let stlKeys = plyKeys.map((key) => {
      if (key === "ply3") return "whole";
      return key === "ply1" ? "upper" : "lower";
    });
    dispatch(medicalRecordStateChanged(MedicalRecordState.convertingFiles));
    convertPlyFilesToCorrespondingStlFiles(
      Array.from(allPlyAndStlFiles.values()),
      (outputStlFiles) => {
        //check if converted stl files are not null
        if (!outputStlFiles) {
          //if they're null, display error text
          handleError("エラーが発生しました。しばらくしてからもう一度お試しください。".toString());
          dispatch(medicalRecordStateChanged(MedicalRecordState.none));
          return;
        }
        if (!outputStlFiles || Array.from(outputStlFiles.values()).length !== plyKeys.length) {
          handleError("エラーが発生しました。しばらくしてからもう一度お試しください。".toString());
          dispatch(medicalRecordStateChanged(MedicalRecordState.none));
          return;
        }
        Array.from(outputStlFiles.keys()).forEach((key) => {
          allPlyAndStlFiles.set(key, outputStlFiles.get(key)!);
        });
        convertToGlbFiles(
          allPlyAndStlFiles,
          async (outputGlbFiles) => {
            const outputGlbFilesModified: any = await uploadFileToS3(outputGlbFiles);

            let outputGlbKeys = Array.from(outputGlbFilesModified.keys());
            if (outputGlbKeys.length === plyKeys.length * 2) {
              outputGlbKeys.forEach((key: any) => {
                finalMFiles.set(key, outputGlbFilesModified.get(key)! as MFile);
              });

              dispatch(selectedMedicalRecordDataUpdated("files", finalMFiles));
            } else {
              //if not, display error text
              handleError("エラーが発生しました。しばらくしてからもう一度お試しください。".toString());
            }
            dispatch(medicalRecordStateChanged(MedicalRecordState.none));
          },
          true,
          Array.from(allPlyAndStlFiles.keys())
        );
      },
      stlKeys
    );
  }

  async onUpdateImageMedicalRecord(dispatch: Dispatch, record: IMedicalRecord, imageFile: File, imageType: string) {
    dispatch(medicalRecordStateChanged(MedicalRecordState.fetchingMedicalRecord));

    const awsS3Config = {
      bucketName: process.env.REACT_APP_AWS_S3_BITBUCKET!,
      dirName: process.env.REACT_APP_AWS_S3_DIRNAME!,
      region: process.env.REACT_APP_AWS_S3_REGION!,
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID!,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY!,
      s3Url: process.env.REACT_APP_AWS_S3_URL!,
    };

    let me = (store.getState().account as IAccountState).me;

    awsS3Config.dirName = `upload/clinic_${me.clinic?.id}/temp/png`;

    const awsS3 = new ReactS3Client(awsS3Config);
    const timestamp = moment().unix();

    awsS3.uploadFile(imageFile, `${timestamp}.${imageFile?.name?.split(".")[0]}`).then(async (result: any) => {
      record.images.filter((imageUpdate) => {
        if (imageUpdate.image_type === imageType) {
          imageUpdate.isUpdate = true;
          imageUpdate.path = result.location;
        }

        return imageUpdate;
      });

      let either = await this.updateMedicalRecord.call(new AddMedicalRecordParams(record));
      match(
        either,
        (failure) => {
          handleError(failure.message);
          dispatch(medicalRecordStateChanged(MedicalRecordState.none));
        },
        (_) => {
          this.onSelectMedicalRecord(dispatch, record.id);
        }
      );
    });
  }
}
