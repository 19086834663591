import { strictEqual } from "assert";
import { Endpoints } from "src/base/Endpoints";
import { HttpMethod } from "src/helpers";
import { IApiService } from "src/services/api/ApiService";
import { centuryFromYear, sleep } from "src/utils/utils";
import { ICard } from "../../domain/model/card";
import { IPlan } from "../../domain/model/plan";

export interface ISubscriptionRemoteDataSource {
  getAllPlans(): Promise<Array<any>>;
  changePlan(id: string, card_id: string): Promise<boolean>;
  addPlan(id: string, card_id: string): Promise<boolean>;
  cancelPlan(): Promise<boolean>;
  getAllCards(): Promise<ICard[]>;
  addCard(card: ICard): Promise<string>;
  updateCard(card: ICard): Promise<string>;
  deleteCard(cardId: string): Promise<boolean>;
}

export class SubscriptionMockDataSource
  implements ISubscriptionRemoteDataSource {
  async deleteCard(cardId: string): Promise<boolean> {
    return true
  }
  async updateCard(card: ICard): Promise<string> {
    return "";
  }
  async getAllPlans(): Promise<IPlan[]> {
    await sleep(500);
    return [
      {
        id: "0",
        name: "スタンダードプラン",
        description: "1ヶ月10症例までのアップロード",
        price: 9500,
        current_plan: true,
      },
      {
        id: "1",
        name: "プレミアムプラン",
        description: "​症例アップロード無制限",
        price: 13500,
      },
      {
        id: "2",
        name: "CEREC割引\nスタンダードプラン",
        description: "​1ヶ月10症例までのアップロード",
        price: 8500,
      },
      {
        id: "3",
        name: "CEREC割引\nプレミアムプラン",
        description: "症例アップロード無制限",
        price: 12500,
      },
    ];
  }

  async addPlan(id: string, card_id: string): Promise<boolean> {
    await sleep(500);
    return true;
  }

  async changePlan(id: string, card_id: string): Promise<boolean> {
    await sleep(500);
    return true;
  }

  async cancelPlan(): Promise<boolean> {
    await sleep(500);
    return true;
  }

  async getAllCards(): Promise<ICard[]> {
    await sleep(500);
    return [
      {
        id: "card_1L1MmaCKQ8PNSXxQvxB4YtiS",
        number: "************0341",
        brand: "visa",
        current: false,
      },
      {
        id: "card_1L1Mm7CKQ8PNSXxQ8KTHb5u8",
        number: "************4242",
        brand: "visa",
        current: false,
      },
      {
        id: "card_1L1MlsCKQ8PNSXxQBsiwjPTW",
        number: "************4242",
        brand: "visa",
        current: true,
      },
    ];
  }

  async addCard(card: ICard): Promise<string> {
    await sleep(500);
    return "card_1L1Mm7CKQ8PNSXxQ8KTHb5u8";
  }
}

export class SubscriptionRemoteDataSource
  implements ISubscriptionRemoteDataSource {
  apiService: IApiService;
  constructor(apiService: IApiService) {
    this.apiService = apiService;
  }




  async addPlan(id: string, card_id: string): Promise<boolean> {
    await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: Endpoints.addPlan,
      params: {
        plan: id,
        paymentMethod: card_id,
      },
      shouldAuthorize: true,
    });
    return true;
  }

  async updateCard(card: ICard): Promise<string> {
    let cardData: any = {
      expire_date: card.expire_date
    }
    if ((card.number?.indexOf("*") ?? 0) === -1) {
      cardData["number"] = card.number
      cardData["cvc"] = card.cvc
    }
    let response = await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: Endpoints.updateCard(card.id),
      body: {
        data: cardData,
      },
      shouldAuthorize: true,
    });
    return response.data.data ?? "";
  }

  async deleteCard(cardId: string): Promise<boolean> {
    await this.apiService.request({
      method: HttpMethod.DELETE,
      endpoint: Endpoints.deleteCard(cardId),
      shouldAuthorize: true,
    });
    return true;
  }

  async changePlan(id: string, card_id: string): Promise<boolean> {
    await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: Endpoints.changePlan,
      params: {
        plan: id,
        paymentMethod: card_id,
      },
      shouldAuthorize: true,
    });
    return true;
  }

  async cancelPlan(): Promise<boolean> {
    await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: Endpoints.cancelPlan,
      shouldAuthorize: true,
    });
    return true;
  }

  async getAllPlans(): Promise<IPlan[]> {
    let response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.getAllPlans,
      shouldAuthorize: true,
    });
    if (!response.data || !response.data.data) return [];
    return response.data.data;
  }

  async getAllCards(): Promise<ICard[]> {
    let response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.getAllCards,
      shouldAuthorize: true,
    });
    if (!response.data || !response.data.data) return [];
    return (response.data.data as []).map((card: any) => {
      var expireDate = card.expire_date || card.exp_date
      if (expireDate && typeof expireDate === "string") {
        expireDate = expireDate.length === 4 ? expireDate.split("/").map((str, index) => {
          switch (index) {
            case 0:
              return str.padStart(2, "0")
            case 1:
              let currentYear = (new Date()).getFullYear()
              let currentCentury = centuryFromYear(currentYear)
              return (currentCentury - 1) + str
          }
          return ""
        }).join("/") : expireDate
      }
      return {
        ...card,
        number: card.last4 ? "************" + card.last4 : "",
        expire_date: expireDate
      };
    });
  }

  async addCard(card: ICard): Promise<string> {
    let response = await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: Endpoints.addCard,
      body: {
        data: {
          ...card,
        },
      },
      shouldAuthorize: true,
    });
    return response.data.data ?? "";
  }
}
