import React from "react";

interface ToothPathProps {
  index: number;
  clipPath: string;
  isHover?: boolean;
  isSelected?: boolean;
  onClick: () => void;
}

export class ToothPath extends React.Component<ToothPathProps> {
  render() {
    return (
      <g
        className={`tooth ${this.props.isHover ? "hover" : ""} ${this.props.isSelected ? "selected" : ""}`}
        id={this.props.index.toString()}
        clipPath={`url(#${this.props.clipPath})`}
        data-bs-toggle="modal"
        data-bs-target="#detailBox"
        onClick={() => {
          this.props.onClick();
        }}
      >
        {(() => {
          switch (this.props.index) {
            case 1:
              return (
                <React.Fragment>
                  <path
                    id="cl"
                    d="M32.3947 231.654C27.2329 230.77 21.8931 229.885 16.9092 230.77C11.9254 231.654 6.58561 233.955 3.91571 238.556C2.31377 241.387 1.77979 244.926 1.77979 248.288C1.77979 255.012 3.73771 262.268 9.25551 266.161C11.3914 267.753 13.8833 268.638 16.5532 269.169C28.6568 272.177 43.2523 268.815 50.194 258.374C51.974 255.897 53.2199 253.066 53.2199 250.058C53.3979 244.926 50.016 240.325 45.7442 237.494C41.4723 234.663 36.8445 232.716 32.0387 231.3"
                    fill="white"
                  ></path>
                  <path
                    d="M32.7508 229.885C19.2232 227.761 2.13587 228.292 0.17794 245.28C-1.424 259.082 5.87373 269.7 19.7572 271.646C32.2168 273.416 47.5242 268.815 53.22 256.782C60.1618 242.272 44.1423 233.247 32.3948 229.708C30.2588 229.177 29.3689 232.539 31.5048 233.247C43.9643 236.963 59.4498 247.934 45.7443 261.029C39.3365 267.045 30.2588 269.169 21.7152 268.284C10.1456 267.045 3.55982 259.967 3.55982 248.288C3.73781 231.831 19.4012 231.3 31.8608 233.247C34.1747 233.601 35.0647 230.239 32.7508 229.885Z"
                    fill="black"
                  ></path>
                  <path
                    d="M26.343 247.226C24.5631 246.165 22.9611 244.749 21.7152 243.156C21.1812 242.449 19.7572 242.449 19.2233 243.156C18.5113 243.864 18.6893 244.926 19.2233 245.634C20.6472 247.403 22.4271 248.996 24.5631 250.235C26.521 251.473 28.3009 248.465 26.343 247.226Z"
                    fill="black"
                  ></path>
                  <path
                    d="M26.6989 250.765C29.3688 249.881 32.0387 248.642 34.7086 247.403C36.8446 246.342 34.8866 243.333 32.9287 244.395C30.6148 245.457 28.3009 246.519 25.809 247.403C23.495 248.111 24.563 251.65 26.6989 250.765Z"
                    fill="black"
                  ></path>
                  <path
                    d="M25.275 247.934C22.7831 249.173 20.4692 250.942 18.3333 252.889C16.7313 254.481 19.2232 256.959 20.8252 255.366C22.6051 253.597 24.741 252.181 27.0549 250.942C29.0129 250.058 27.2329 246.872 25.275 247.934Z"
                    fill="black"
                  ></path>
                  <path
                    d="M25.453 251.119C27.233 252.712 28.8349 254.305 30.6148 255.897C32.2168 257.49 34.8867 255.012 33.1067 253.42C31.3268 251.827 29.7249 250.235 27.9449 248.642C26.343 247.049 23.6731 249.527 25.453 251.119Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 2:
              return (
                <React.Fragment>
                  <path
                    d="M36.137 184.427C33.0654 184.065 30.1744 182.979 27.2834 182.075C22.4049 180.809 16.9844 180.085 12.2866 181.894C7.58874 183.703 3.43297 188.406 3.97503 193.471C4.15571 196.365 5.78188 199.078 5.6012 201.973C5.42051 205.59 2.71023 208.485 1.98749 211.921C1.26475 215.72 3.43297 219.881 6.32394 222.232C9.39559 224.764 13.19 226.031 16.9844 226.935C23.8504 228.744 30.8971 229.829 37.9439 229.648C41.919 229.648 46.4361 229.106 49.1464 226.031C50.5919 224.403 51.4953 222.232 52.218 220.061C52.9408 217.71 53.8442 215.539 54.5669 213.188C55.651 209.932 56.9158 206.314 56.3738 202.877C55.651 198.717 52.7601 195.461 49.6884 192.747C44.6292 188.406 38.4859 184.969 32.1619 182.799"
                    fill="white"
                  ></path>
                  <path
                    d="M36.6791 182.618C28.3676 180.99 19.6947 176.829 11.3832 180.266C5.23987 182.799 2.52959 187.683 2.16822 194.014C1.98753 198.355 3.07165 202.696 1.80685 207.399C1.44548 208.846 0.542051 210.113 0.18068 211.56C-0.180691 214.092 0.18068 216.444 1.26479 218.614C4.69781 226.212 13.7321 228.382 20.9595 230.01C28.5483 231.638 38.3053 233.447 45.7134 230.734C52.5794 228.02 54.2056 220.604 56.1931 214.092C58.3614 207.038 59.0841 201.43 54.2056 195.461C48.785 188.768 40.2928 184.427 32.3427 181.532C30.1745 180.809 29.271 184.246 31.4393 184.969C37.4019 187.14 43.3645 190.215 48.243 194.556C54.0249 199.802 55.109 206.133 52.7601 213.188C52.0374 215.539 51.3146 217.71 50.4112 220.061C48.6044 224.764 44.0872 227.478 39.2087 228.201C33.7882 229.106 27.1028 227.659 21.8629 226.573C16.2617 225.488 7.40809 223.679 4.69781 217.891C1.44548 211.198 6.86604 207.761 7.22741 202.154C7.40809 200.526 6.50467 198.898 6.50467 197.451C6.68535 189.853 9.93769 183.16 18.2492 182.618C24.2118 182.256 29.9938 184.969 35.7757 186.055C37.9439 186.597 39.028 182.98 36.6791 182.618Z"
                    fill="black"
                  ></path>
                  <path
                    d="M19.3334 194.556C21.321 197.993 23.4892 201.249 25.8381 204.505C27.2836 206.314 30.3552 204.505 28.9098 202.696C26.5608 199.44 24.3926 196.184 22.4051 192.747C21.321 190.758 18.2493 192.567 19.3334 194.556Z"
                    fill="black"
                  ></path>
                  <path
                    d="M27.1028 203.601C23.8505 207.038 20.2368 210.113 16.4424 213.007C14.6355 214.454 17.1651 216.986 18.972 215.539C22.7664 212.645 26.1994 209.57 29.6324 206.133C31.2586 204.505 28.729 201.973 27.1028 203.601Z"
                    fill="black"
                  ></path>
                  <path
                    d="M27.2834 206.314C29.813 210.293 32.3426 214.092 35.2335 217.71C36.679 219.519 39.7507 217.71 38.3052 215.901C35.5949 212.102 32.8846 208.304 30.355 204.505C29.0902 202.515 26.0186 204.324 27.2834 206.314Z"
                    fill="black"
                  ></path>
                  <path
                    d="M29.8131 206.133C32.8847 203.42 37.2212 203.058 41.1963 203.058C43.5452 203.058 43.5452 199.44 41.1963 199.44C36.1371 199.44 31.0779 199.983 27.1028 203.42C25.6573 205.048 28.1869 207.761 29.8131 206.133Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 3:
              return (
                <React.Fragment>
                  <g clipPath="url(#clip3_92_2)">
                    <path
                      d="M43.6564 127.167C38.6285 124.494 32.8824 123.069 27.3158 124.138C21.7492 125.207 16.7214 129.839 16.0031 135.362C15.644 137.678 16.0031 139.994 16.0031 142.31C15.644 150.149 9.71827 157.098 10.9752 164.759C11.6935 169.213 14.7461 172.954 18.3375 175.626C21.9288 178.299 26.418 179.546 30.9071 180.615C35.3963 181.684 39.8855 182.575 44.5542 182.04C49.2229 181.506 53.5325 179.19 55.8669 175.27C57.483 172.42 58.0217 169.034 59.6378 166.362C60.7152 164.759 62.1517 163.511 63.2291 162.086C65.2043 159.414 66.1022 156.029 65.743 152.644C65.3839 149.793 64.1269 146.943 62.6904 144.448C58.2012 136.966 51.3777 130.908 43.4768 127.345"
                      fill="white"
                    ></path>
                    <path
                      d="M44.5543 125.563C37.7308 122.356 30.0094 120.397 23.0063 123.782C14.5667 128.057 14.9258 134.293 14.2075 142.31C13.3097 151.04 6.66575 158.879 9.89794 167.787C12.4119 175.092 19.5945 179.011 26.4181 181.149C34.1394 183.644 43.2973 185.603 50.8391 181.684C53.5326 180.259 55.6874 178.477 57.3035 175.805C59.997 171.529 62.3314 166.718 64.6657 162.443C73.1054 146.943 57.6627 131.977 44.3747 125.385C42.3995 124.316 40.4243 127.523 42.5791 128.414C47.6069 130.908 52.0961 133.937 55.867 138.034C62.8701 145.517 66.1023 153.356 60.1766 162.443C58.5605 164.937 57.124 167.075 56.0466 169.925C51.737 180.08 43.2973 181.149 33.062 179.011C26.9568 177.764 19.9537 175.626 16.0032 170.46C10.4366 163.333 13.6688 157.276 16.1828 149.971C17.6193 145.874 17.4397 142.667 17.9784 138.391C19.5945 124.672 31.9846 122.891 43.1178 128.236C44.9134 129.661 46.7091 126.632 44.5543 125.563Z"
                      fill="black"
                    ></path>
                    <path
                      d="M32.3437 141.598C35.3963 144.27 37.0124 147.833 37.192 151.931C37.192 154.247 40.7833 154.247 40.7833 151.931C40.6037 146.943 38.6285 142.31 34.8576 139.103C33.2415 137.5 30.7276 139.994 32.3437 141.598Z"
                      fill="black"
                    ></path>
                    <path
                      d="M36.4737 150.862C37.0124 153.356 35.935 155.851 33.7802 157.098C31.805 158.167 33.6007 161.374 35.5759 160.126C39.1672 157.988 40.9629 153.891 40.0651 149.793C39.3468 147.833 35.935 148.724 36.4737 150.862Z"
                      fill="black"
                    ></path>
                    <path
                      d="M34.1395 157.098C32.703 157.989 31.2665 158.879 29.6504 159.77C28.3934 160.483 26.9569 161.908 25.5203 162.086C26.059 162.086 26.4182 162.264 26.9569 162.264C26.7773 162.264 26.7773 162.086 26.5977 162.086C24.4429 161.195 22.6473 164.224 24.8021 165.115C26.5977 166.006 28.0343 164.937 29.6504 163.868C31.8052 162.621 33.7804 161.374 35.9352 160.126C37.9104 159.057 36.1148 156.029 34.1395 157.098Z"
                      fill="black"
                    ></path>
                    <path
                      d="M34.4986 159.948C35.576 163.155 37.0126 166.362 38.2695 169.569C39.1674 171.707 42.22 169.925 41.3222 167.787C40.0652 164.937 38.9878 161.908 37.9104 159.057C37.3717 156.92 33.7804 157.81 34.4986 159.948Z"
                      fill="black"
                    ></path>
                    <path
                      d="M39.8855 152.287C41.8607 152.109 43.8359 151.931 45.8112 151.397C46.709 151.218 47.7864 150.862 48.6842 150.506C49.4025 150.328 50.4799 149.437 51.3777 149.615C53.7121 149.971 54.6099 146.586 52.2756 146.23C50.3003 145.874 48.6842 146.764 46.8886 147.299C44.5542 148.012 42.2198 148.368 39.7059 148.546C37.5511 148.902 37.5511 152.466 39.8855 152.287Z"
                      fill="black"
                    ></path>
                  </g>
                </React.Fragment>
              );
            case 4:
              return (
                <React.Fragment>
                  <path
                    d="M58.8926 92.7045C54.7404 89.2197 48.685 88.6969 43.6677 90.7878C38.6504 92.8787 34.8442 97.2348 32.941 102.288C31.903 105.076 31.2109 108.038 31.73 111C32.595 116.053 36.5743 119.886 40.5535 122.848C47.3009 127.901 55.2594 131.561 63.7369 132.083C67.3701 132.432 71.1763 132.083 74.1175 129.818C77.5777 127.205 78.7888 122.326 78.0968 117.97C77.4047 113.614 74.9826 109.78 72.3874 106.295C68.5812 101.417 64.6019 97.0606 59.9307 93.2272"
                    fill="white"
                  ></path>
                  <path
                    d="M59.7578 91.1363C49.8962 84.3409 37.7854 89.0454 32.4221 99.3257C25.3287 112.917 35.3633 122.848 46.782 128.773C55.4325 133.303 72.3875 138.53 78.2699 127.205C85.0173 114.136 69.7924 99.5 61.1419 92.0075C59.4117 90.6136 56.9896 93.053 58.7197 94.4469C65.1211 100.197 73.0796 107.341 75.6747 115.879C77.7508 122.848 75.5017 128.773 66.5052 130.341C62.3529 131.038 57.1626 129.295 53.3564 127.727C44.5329 124.417 31.3841 116.924 33.6332 105.424C35.8823 94.2727 48.3391 87.4772 58.0277 94.0985C59.9308 95.4924 61.6609 92.5303 59.7578 91.1363Z"
                    fill="black"
                  ></path>
                  <path
                    d="M46.7819 115.705C49.3771 115.879 51.7992 115.705 54.3944 115.356C56.6435 115.008 55.6055 111.523 53.5294 112.045C51.2802 112.394 49.0311 112.394 46.7819 112.22C44.7058 112.045 44.7058 115.53 46.7819 115.705Z"
                    fill="black"
                  ></path>
                  <path
                    d="M53.7025 102.636C54.2215 105.076 54.7405 107.341 55.2596 109.78C55.7786 112.045 59.0658 111 58.5468 108.909C58.0277 106.47 57.5087 104.205 56.9897 101.765C56.6437 99.5 53.3565 100.371 53.7025 102.636Z"
                    fill="black"
                  ></path>
                  <path
                    d="M55.6056 108.561C54.9136 109.954 54.0485 111.174 53.0105 112.22C51.4534 113.788 53.8755 116.401 55.4326 114.659C56.6437 113.265 57.6817 111.871 58.5468 110.303C59.7579 108.386 56.6437 106.644 55.6056 108.561Z"
                    fill="black"
                  ></path>
                  <path
                    d="M53.5295 114.311C53.8755 116.227 54.2215 118.318 54.5676 120.235C54.9136 122.5 58.2008 121.455 57.8548 119.364C57.5087 117.447 57.1627 115.356 56.8167 113.439C56.4707 111.174 53.0105 112.045 53.5295 114.311Z"
                    fill="black"
                  ></path>
                  <path
                    d="M57.8548 112.22C59.4119 112.394 60.969 112.742 62.5261 113.265C64.6022 113.962 65.6402 110.477 63.3911 109.954C61.488 109.432 59.5849 109.083 57.6818 108.735C56.8167 108.561 55.9517 109.606 55.9517 110.477C56.1247 111.523 56.9897 112.22 57.8548 112.22Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 5:
              return (
                <React.Fragment>
                  <path
                    d="M82.0629 60.939C79.8896 58.561 76.6298 57.6464 73.3699 57.6464C70.1101 57.6464 67.2125 58.9268 64.4959 60.7561C58.5196 64.5976 54.5353 71.1829 53.6298 78.3171C53.4487 80.5122 53.4487 82.8903 54.3542 85.0854C55.2597 87.2805 56.8896 88.9268 58.7007 90.5732C63.0471 94.4147 68.118 97.5244 73.7322 98.8049C79.3463 100.085 85.5038 99.3537 90.3936 96.2439C92.0235 95.1464 93.4723 93.8659 94.3778 92.2195C96.9133 87.8293 95.8266 82.3415 94.0156 77.5854C91.2991 71 86.9526 64.9634 81.3385 60.3903"
                    fill="white"
                  ></path>
                  <path
                    d="M83.3306 59.4756C73.7322 51.7927 61.5983 57.8293 55.6219 67.3415C48.3778 79.2317 52.3621 89.6586 63.7715 96.4269C72.8267 101.915 87.3148 103.744 94.9212 94.4147C103.976 83.2561 90.9369 66.2439 82.6062 58.9269C80.7952 57.4634 78.2597 60.0244 80.0708 61.4878C86.0471 66.7927 92.7479 74.4756 93.8345 82.7073C95.4645 95.8781 81.8818 99.3537 71.9211 96.2439C63.4093 93.5 53.8109 86.5488 55.9841 76.4878C58.1574 66.6098 70.4723 53.8049 80.9763 62.2195C82.6062 63.683 85.1416 60.9391 83.3306 59.4756Z"
                    fill="black"
                  ></path>
                  <path
                    d="M73.1891 65.6951C70.6536 66.4268 68.8426 68.622 67.0316 70.4512C65.0395 72.6464 63.2284 75.0244 61.5985 77.5854C60.3308 79.5976 63.4095 81.4268 64.6773 79.4146C65.945 77.4025 67.3938 75.3903 69.0237 73.7439C70.2914 72.2805 72.1025 69.9025 74.0946 69.3537C76.4489 68.622 75.5434 65.1464 73.1891 65.6951Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 6:
              return (
                <React.Fragment>
                  <path
                    d="M109.558 35.7059C108.488 32.8824 104.922 32.1765 102.07 32C96.5426 31.4706 89.2326 29 84.9535 32.5294C81.9225 35.1765 80.8527 41.5294 80.1395 45.4118C79.6047 49.2941 80.3178 53.5294 82.1008 57.0588C83.7054 60.4118 86.3799 63.0588 89.2326 65.5294C93.6899 69.5882 98.6822 73.4706 104.566 74.8824C110.45 76.2941 117.581 74.353 120.434 69.0588C121.682 66.5882 122.039 63.9412 121.86 61.1177C121.504 51.4118 116.512 42.4118 109.558 35.353"
                    fill="white"
                  ></path>
                  <path
                    d="M111.163 34.8235C107.597 29.8824 100.643 29.7059 94.9379 29.1765C90.124 28.8235 85.4883 28.8235 82.4573 33.0588C73.186 45.0588 79.7829 60.0588 89.9457 68.5294C97.9689 75.2353 111.876 81.9412 120.434 71.8824C129.884 60.7647 118.651 42.5883 110.806 34.1177C109.201 32.5294 106.705 35 108.31 36.7647C114.55 43.4706 119.899 51.7647 120.077 61.1177C120.434 77.7059 103.139 74.1765 94.403 67.4706C86.0232 60.9412 80.3178 53.1765 82.6356 42.0588C84.062 35.1765 89.5891 32 96.5426 32.8824C100.287 33.4118 105.636 33.2353 108.132 36.5882C109.38 38.353 112.411 36.5882 111.163 34.8235Z"
                    fill="black"
                  ></path>
                  <path
                    d="M91.5505 50.8823C92.0854 46.8235 96.721 42.5882 100.822 42.2353C103.14 42.0588 103.14 38.5294 100.822 38.7059C95.1164 39.2353 88.876 44.3529 87.9846 50C87.8063 52.2941 91.1939 53.1765 91.5505 50.8823Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 7:
              return (
                <React.Fragment>
                  <path
                    d="M144.26 24.3914C143.712 22.2054 142.981 20.0193 142.433 17.8333C141.885 16.1938 141.337 14.3721 140.058 13.279C138.962 12.3682 137.317 12.186 135.856 12.0038C128.366 11.8217 121.606 16.0116 115.942 20.748C113.019 23.2984 109.914 26.3953 110.096 30.4031C110.279 32.9534 111.741 35.1395 113.202 37.3255C117.769 44.0659 122.885 50.8062 130.375 54.0852C133.116 55.3604 136.587 55.9069 139.327 54.2674C141.702 52.81 142.798 49.7131 143.529 46.9806C145.356 39.3294 145.539 31.3139 143.894 23.4806"
                    fill="white"
                  ></path>
                  <path
                    d="M145.904 23.8449C144.808 20.2015 144.26 14.0077 140.789 11.4573C136.587 8.36044 129.827 10.3643 125.442 12.3682C114.664 17.1046 103.885 26.3953 111.558 38.0542C116.49 45.7054 126.721 59.3682 137.683 56.6356C149.558 53.5387 147 31.3139 145.539 22.7519C145.173 20.3837 141.702 21.4767 142.067 23.6628C143.346 30.7674 144.625 41.5155 141.154 48.2558C134.76 60.6434 121.606 45.3411 117.587 40.0581C116.308 38.4186 114.115 36.0504 113.385 33.8643C110.644 26.031 118.135 20.5659 123.615 17.469C126.904 15.6473 131.837 12.9147 135.673 13.8256C140.789 15.1008 141.337 20.9302 142.433 24.938C142.981 27.124 146.635 26.2132 145.904 23.8449Z"
                    fill="black"
                  ></path>
                  <path
                    d="M129.827 18.7442C125.077 20.5659 120.692 23.2985 117.039 26.9419C115.394 28.5814 117.952 31.1318 119.596 29.4923C122.885 26.3954 126.721 23.845 130.923 22.2054C132.933 21.4768 132.019 17.8333 129.827 18.7442Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 8:
              return (
                <React.Fragment>
                  <path
                    d="M183.201 16.4607C182.841 13.2048 182.301 9.9488 180.502 7.41638C177.983 3.79863 173.485 1.98976 169.167 1.80887C164.849 1.62798 160.531 2.53242 156.213 3.61774C153.695 4.16041 150.996 4.88395 148.657 6.33105C146.318 7.77815 144.339 9.76791 143.799 12.4812C143.439 14.2901 143.799 16.2799 144.339 18.0887C146.498 26.4096 149.736 34.3686 154.414 41.785C157.293 46.3072 161.251 51.0102 166.649 51.372C170.427 51.5529 174.025 49.3823 176.364 46.4881C178.703 43.5939 179.962 39.9761 180.862 36.3584C182.481 30.3891 183.201 24.058 183.021 17.9078"
                    fill="white"
                  ></path>
                  <path
                    d="M185 16.4607C183.561 3.79862 174.925 -1.62801 162.331 0.361754C156.033 1.44708 147.218 2.89418 143.619 8.68257C140.201 13.9283 143.079 20.9829 145.059 26.2287C148.477 35.9966 156.213 55.5324 169.707 52.6382C183.561 49.744 184.82 28.7611 184.82 17.7269C184.82 15.3754 181.222 15.3754 181.222 17.7269C181.222 25.1433 180.862 34.0068 177.443 40.6996C167.368 59.8737 155.854 41.6041 151.356 32.1979C147.397 23.8771 141.64 10.1297 154.234 5.96926C165.209 2.35152 179.602 1.26619 181.402 16.4607C181.582 18.8123 185.18 18.8123 185 16.4607Z"
                    fill="black"
                  ></path>
                  <path
                    d="M151.535 16.8225C154.954 14.1092 159.092 13.7475 163.23 13.5666C165.929 13.3857 170.427 12.4813 172.946 13.5666C175.105 14.471 176.904 11.3959 174.745 10.4915C170.607 8.50173 164.849 9.58705 160.351 10.1297C156.213 10.6724 152.255 11.5768 148.837 14.2901C147.038 15.7372 149.736 18.2696 151.535 16.8225Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 9:
              return (
                <React.Fragment>
                  <path
                    d="M186.799 16.4607C187.159 13.2048 187.699 9.9488 189.498 7.41638C192.017 3.79863 196.515 1.98976 200.833 1.80887C205.151 1.62798 209.469 2.53242 213.787 3.61774C216.305 4.16041 219.004 4.88395 221.343 6.33105C223.682 7.77815 225.661 9.76791 226.201 12.4812C226.561 14.2901 226.201 16.2799 225.661 18.0887C223.502 26.4096 220.264 34.3686 215.586 41.785C212.707 46.3072 208.749 51.0102 203.351 51.372C199.573 51.5529 195.975 49.3823 193.636 46.4881C191.297 43.5939 190.038 39.9761 189.138 36.3584C187.519 30.3891 186.799 24.058 186.979 17.9078"
                    fill="white"
                  ></path>
                  <path
                    d="M185 16.4607C186.439 3.79862 195.075 -1.62801 207.669 0.361754C213.967 1.44708 222.782 2.89418 226.381 8.68257C229.799 13.9283 226.921 20.9829 224.941 26.2287C221.523 35.9966 213.787 55.5324 200.293 52.6382C186.439 49.744 185.18 28.7611 185.18 17.7269C185.18 15.3754 188.778 15.3754 188.778 17.7269C188.778 25.1433 189.138 34.0068 192.557 40.6996C202.632 59.8737 214.146 41.6041 218.644 32.1979C222.603 23.8771 228.36 10.1297 215.766 5.96926C204.791 2.35152 190.398 1.26619 188.598 16.4607C188.418 18.8123 184.82 18.8123 185 16.4607Z"
                    fill="black"
                  ></path>
                  <path
                    d="M218.465 16.8225C215.046 14.1092 210.908 13.7475 206.77 13.5666C204.071 13.3857 199.573 12.4813 197.054 13.5666C194.895 14.471 193.096 11.3959 195.255 10.4915C199.393 8.50173 205.151 9.58705 209.649 10.1297C213.787 10.6724 217.745 11.5768 221.163 14.2901C222.962 15.7372 220.264 18.2696 218.465 16.8225Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 10:
              return (
                <React.Fragment>
                  <path
                    d="M225.74 24.3914C226.288 22.2054 227.019 20.0193 227.567 17.8333C228.115 16.1938 228.663 14.3721 229.942 13.279C231.038 12.3682 232.683 12.186 234.144 12.0038C241.634 11.8217 248.394 16.0116 254.058 20.748C256.981 23.2984 260.086 26.3953 259.904 30.4031C259.721 32.9534 258.259 35.1395 256.798 37.3255C252.231 44.0659 247.115 50.8062 239.625 54.0852C236.884 55.3604 233.413 55.9069 230.673 54.2674C228.298 52.81 227.202 49.7131 226.471 46.9806C224.644 39.3294 224.461 31.3139 226.106 23.4806"
                    fill="white"
                  ></path>
                  <path
                    d="M224.096 23.8449C225.192 20.2015 225.74 14.0077 229.211 11.4573C233.413 8.36044 240.173 10.3643 244.558 12.3682C255.336 17.1046 266.115 26.3953 258.442 38.0542C253.51 45.7054 243.279 59.3682 232.317 56.6356C220.442 53.5387 223 31.3139 224.461 22.7519C224.827 20.3837 228.298 21.4767 227.933 23.6628C226.654 30.7674 225.375 41.5155 228.846 48.2558C235.24 60.6434 248.394 45.3411 252.413 40.0581C253.692 38.4186 255.885 36.0504 256.615 33.8643C259.356 26.031 251.865 20.5659 246.385 17.469C243.096 15.6473 238.163 12.9147 234.327 13.8256C229.211 15.1008 228.663 20.9302 227.567 24.938C227.019 27.124 223.365 26.2132 224.096 23.8449Z"
                    fill="black"
                  ></path>
                  <path
                    d="M240.173 18.7442C244.923 20.5659 249.308 23.2985 252.961 26.9419C254.606 28.5814 252.048 31.1318 250.404 29.4923C247.115 26.3954 243.279 23.845 239.077 22.2054C237.067 21.4768 237.981 17.8333 240.173 18.7442Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 11:
              return (
                <React.Fragment>
                  <path
                    d="M260.442 35.7059C261.512 32.8824 265.078 32.1765 267.93 32C273.457 31.4706 280.767 29 285.047 32.5294C288.078 35.1765 289.147 41.5294 289.86 45.4118C290.395 49.2941 289.682 53.5294 287.899 57.0588C286.295 60.4118 283.62 63.0588 280.767 65.5294C276.31 69.5882 271.318 73.4706 265.434 74.8824C259.55 76.2941 252.419 74.353 249.566 69.0588C248.318 66.5882 247.961 63.9412 248.14 61.1177C248.496 51.4118 253.488 42.4118 260.442 35.353"
                    fill="white"
                  ></path>
                  <path
                    d="M258.837 34.8235C262.403 29.8824 269.357 29.7059 275.062 29.1765C279.876 28.8235 284.512 28.8235 287.543 33.0588C296.814 45.0588 290.217 60.0588 280.054 68.5294C272.031 75.2353 258.124 81.9412 249.566 71.8824C240.116 60.7647 251.349 42.5883 259.194 34.1177C260.799 32.5294 263.295 35 261.69 36.7647C255.45 43.4706 250.101 51.7647 249.923 61.1177C249.566 77.7059 266.861 74.1765 275.597 67.4706C283.977 60.9412 289.682 53.1765 287.364 42.0588C285.938 35.1765 280.411 32 273.457 32.8824C269.713 33.4118 264.364 33.2353 261.868 36.5882C260.62 38.353 257.589 36.5882 258.837 34.8235Z"
                    fill="black"
                  ></path>
                  <path
                    d="M278.45 50.8823C277.915 46.8235 273.279 42.5882 269.178 42.2353C266.86 42.0588 266.86 38.5294 269.178 38.7059C274.884 39.2353 281.124 44.3529 282.015 50C282.194 52.2941 278.806 53.1765 278.45 50.8823Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 12:
              return (
                <React.Fragment>
                  <path
                    d="M287.937 60.939C290.11 58.561 293.37 57.6464 296.63 57.6464C299.89 57.6464 302.788 58.9268 305.504 60.7561C311.48 64.5976 315.465 71.1829 316.37 78.3171C316.551 80.5122 316.551 82.8903 315.646 85.0854C314.74 87.2805 313.11 88.9268 311.299 90.5732C306.953 94.4147 301.882 97.5244 296.268 98.8049C290.654 100.085 284.496 99.3537 279.606 96.2439C277.977 95.1464 276.528 93.8659 275.622 92.2195C273.087 87.8293 274.173 82.3415 275.984 77.5854C278.701 71 283.047 64.9634 288.662 60.3903"
                    fill="white"
                  ></path>
                  <path
                    d="M286.669 59.4756C296.268 51.7927 308.402 57.8293 314.378 67.3415C321.622 79.2317 317.638 89.6586 306.228 96.4269C297.173 101.915 282.685 103.744 275.079 94.4147C266.024 83.2561 279.063 66.2439 287.394 58.9269C289.205 57.4634 291.74 60.0244 289.929 61.4878C283.953 66.7927 277.252 74.4756 276.165 82.7073C274.536 95.8781 288.118 99.3537 298.079 96.2439C306.591 93.5 316.189 86.5488 314.016 76.4878C311.843 66.6098 299.528 53.8049 289.024 62.2195C287.394 63.683 284.858 60.9391 286.669 59.4756Z"
                    fill="black"
                  ></path>
                  <path
                    d="M296.811 65.6951C299.346 66.4268 301.157 68.622 302.968 70.4512C304.961 72.6464 306.772 75.0244 308.401 77.5854C309.669 79.5976 306.59 81.4268 305.323 79.4146C304.055 77.4025 302.606 75.3903 300.976 73.7439C299.709 72.2805 297.898 69.9025 295.905 69.3537C293.551 68.622 294.457 65.1464 296.811 65.6951Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 13:
              return (
                <React.Fragment>
                  <path
                    d="M311.107 92.7045C315.26 89.2197 321.315 88.6969 326.332 90.7878C331.35 92.8787 335.156 97.2348 337.059 102.288C338.097 105.076 338.789 108.038 338.27 111C337.405 116.053 333.426 119.886 329.446 122.848C322.699 127.901 314.741 131.561 306.263 132.083C302.63 132.432 298.824 132.083 295.882 129.818C292.422 127.205 291.211 122.326 291.903 117.97C292.595 113.614 295.017 109.78 297.613 106.295C301.419 101.417 305.398 97.0606 310.069 93.2272"
                    fill="white"
                  ></path>
                  <path
                    d="M310.242 91.1363C320.104 84.3409 332.215 89.0454 337.578 99.3257C344.671 112.917 334.637 122.848 323.218 128.773C314.567 133.303 297.612 138.53 291.73 127.205C284.983 114.136 300.208 99.5 308.858 92.0075C310.588 90.6136 313.01 93.053 311.28 94.4469C304.879 100.197 296.92 107.341 294.325 115.879C292.249 122.848 294.498 128.773 303.495 130.341C307.647 131.038 312.837 129.295 316.644 127.727C325.467 124.417 338.616 116.924 336.367 105.424C334.118 94.2727 321.661 87.4772 311.972 94.0985C310.069 95.4924 308.339 92.5303 310.242 91.1363Z"
                    fill="black"
                  ></path>
                  <path
                    d="M323.218 115.705C320.623 115.879 318.201 115.705 315.606 115.356C313.356 115.008 314.395 111.523 316.471 112.045C318.72 112.394 320.969 112.394 323.218 112.22C325.294 112.045 325.294 115.53 323.218 115.705Z"
                    fill="black"
                  ></path>
                  <path
                    d="M316.298 102.636C315.778 105.076 315.259 107.341 314.74 109.78C314.221 112.045 310.934 111 311.453 108.909C311.972 106.47 312.491 104.205 313.01 101.765C313.356 99.5 316.644 100.371 316.298 102.636Z"
                    fill="black"
                  ></path>
                  <path
                    d="M314.394 108.561C315.086 109.954 315.951 111.174 316.99 112.22C318.547 113.788 316.124 116.401 314.567 114.659C313.356 113.265 312.318 111.871 311.453 110.303C310.242 108.386 313.356 106.644 314.394 108.561Z"
                    fill="black"
                  ></path>
                  <path
                    d="M316.471 114.311C316.124 116.227 315.778 118.318 315.432 120.235C315.086 122.5 311.799 121.455 312.145 119.364C312.491 117.447 312.837 115.356 313.183 113.439C313.529 111.174 316.99 112.045 316.471 114.311Z"
                    fill="black"
                  ></path>
                  <path
                    d="M312.145 112.22C310.588 112.394 309.031 112.742 307.474 113.265C305.398 113.962 304.36 110.477 306.609 109.954C308.512 109.432 310.415 109.083 312.318 108.735C313.183 108.561 314.048 109.606 314.048 110.477C313.875 111.523 313.01 112.22 312.145 112.22Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 14:
              return (
                <React.Fragment>
                  <g clipPath="url(#clip12_92_2)">
                    <path
                      d="M326.344 127.167C331.372 124.494 337.118 123.069 342.684 124.138C348.251 125.207 353.279 129.839 353.997 135.362C354.356 137.678 353.997 139.994 353.997 142.31C354.356 150.149 360.282 157.098 359.025 164.759C358.306 169.213 355.254 172.954 351.663 175.626C348.071 178.299 343.582 179.546 339.093 180.615C334.604 181.684 330.115 182.575 325.446 182.04C320.777 181.506 316.467 179.19 314.133 175.27C312.517 172.42 311.978 169.034 310.362 166.362C309.285 164.759 307.848 163.511 306.771 162.086C304.796 159.414 303.898 156.029 304.257 152.644C304.616 149.793 305.873 146.943 307.31 144.448C311.799 136.966 318.622 130.908 326.523 127.345"
                      fill="white"
                    ></path>
                    <path
                      d="M325.446 125.563C332.269 122.356 339.991 120.397 346.994 123.782C355.433 128.057 355.074 134.293 355.792 142.31C356.69 151.04 363.334 158.879 360.102 167.787C357.588 175.092 350.405 179.011 343.582 181.149C335.861 183.644 326.703 185.603 319.161 181.684C316.467 180.259 314.313 178.477 312.696 175.805C310.003 171.529 307.669 166.718 305.334 162.443C296.895 146.943 312.337 131.977 325.625 125.385C327.601 124.316 329.576 127.523 327.421 128.414C322.393 130.908 317.904 133.937 314.133 138.034C307.13 145.517 303.898 153.356 309.823 162.443C311.44 164.937 312.876 167.075 313.953 169.925C318.263 180.08 326.703 181.149 336.938 179.011C343.043 177.764 350.046 175.626 353.997 170.46C359.563 163.333 356.331 157.276 353.817 149.971C352.381 145.874 352.56 142.667 352.022 138.391C350.405 124.672 338.015 122.891 326.882 128.236C325.087 129.661 323.291 126.632 325.446 125.563Z"
                      fill="black"
                    ></path>
                    <path
                      d="M337.656 141.598C334.604 144.27 332.988 147.833 332.808 151.931C332.808 154.247 329.217 154.247 329.217 151.931C329.396 146.943 331.371 142.31 335.142 139.103C336.758 137.5 339.272 139.994 337.656 141.598Z"
                      fill="black"
                    ></path>
                    <path
                      d="M333.526 150.862C332.988 153.356 334.065 155.851 336.22 157.098C338.195 158.167 336.399 161.374 334.424 160.126C330.833 157.988 329.037 153.891 329.935 149.793C330.653 147.833 334.065 148.724 333.526 150.862Z"
                      fill="black"
                    ></path>
                    <path
                      d="M335.86 157.098C337.297 157.989 338.734 158.879 340.35 159.77C341.607 160.483 343.043 161.908 344.48 162.086C343.941 162.086 343.582 162.264 343.043 162.264C343.223 162.264 343.223 162.086 343.402 162.086C345.557 161.195 347.353 164.224 345.198 165.115C343.402 166.006 341.966 164.937 340.35 163.868C338.195 162.621 336.22 161.374 334.065 160.126C332.09 159.057 333.885 156.029 335.86 157.098Z"
                      fill="black"
                    ></path>
                    <path
                      d="M335.501 159.948C334.424 163.155 332.987 166.362 331.73 169.569C330.833 171.707 327.78 169.925 328.678 167.787C329.935 164.937 331.012 161.908 332.09 159.057C332.628 156.92 336.22 157.81 335.501 159.948Z"
                      fill="black"
                    ></path>
                    <path
                      d="M330.115 152.287C328.139 152.109 326.164 151.931 324.189 151.397C323.291 151.218 322.214 150.862 321.316 150.506C320.598 150.328 319.52 149.437 318.622 149.615C316.288 149.971 315.39 146.586 317.724 146.23C319.7 145.874 321.316 146.764 323.111 147.299C325.446 148.012 327.78 148.368 330.294 148.546C332.449 148.902 332.449 152.466 330.115 152.287Z"
                      fill="black"
                    ></path>
                  </g>
                </React.Fragment>
              );
            case 15:
              return (
                <React.Fragment>
                  <path
                    d="M333.863 184.427C336.935 184.065 339.826 182.979 342.717 182.075C347.595 180.809 353.016 180.085 357.713 181.894C362.411 183.703 366.567 188.406 366.025 193.471C365.844 196.365 364.218 199.078 364.399 201.973C364.579 205.59 367.29 208.485 368.013 211.921C368.735 215.72 366.567 219.881 363.676 222.232C360.604 224.764 356.81 226.031 353.016 226.935C346.15 228.744 339.103 229.829 332.056 229.648C328.081 229.648 323.564 229.106 320.854 226.031C319.408 224.403 318.505 222.232 317.782 220.061C317.059 217.71 316.156 215.539 315.433 213.188C314.349 209.932 313.084 206.314 313.626 202.877C314.349 198.717 317.24 195.461 320.312 192.747C325.371 188.406 331.514 184.969 337.838 182.799"
                    fill="white"
                  ></path>
                  <path
                    d="M333.321 182.618C341.632 180.99 350.305 176.829 358.617 180.266C364.76 182.799 367.47 187.683 367.832 194.014C368.012 198.355 366.928 202.696 368.193 207.399C368.555 208.846 369.458 210.113 369.819 211.56C370.181 214.092 369.819 216.444 368.735 218.614C365.302 226.212 356.268 228.382 349.041 230.01C341.452 231.638 331.695 233.447 324.287 230.734C317.421 228.02 315.794 220.604 313.807 214.092C311.639 207.038 310.916 201.43 315.794 195.461C321.215 188.768 329.707 184.427 337.657 181.532C339.826 180.809 340.729 184.246 338.561 184.969C332.598 187.14 326.636 190.215 321.757 194.556C315.975 199.802 314.891 206.133 317.24 213.188C317.963 215.539 318.685 217.71 319.589 220.061C321.396 224.764 325.913 227.478 330.791 228.201C336.212 229.106 342.897 227.659 348.137 226.573C353.738 225.488 362.592 223.679 365.302 217.891C368.555 211.198 363.134 207.761 362.773 202.154C362.592 200.526 363.495 198.898 363.495 197.451C363.315 189.853 360.062 183.16 351.751 182.618C345.788 182.256 340.006 184.969 334.224 186.055C332.056 186.597 330.972 182.98 333.321 182.618Z"
                    fill="black"
                  ></path>
                  <path
                    d="M350.667 194.556C348.679 197.993 346.511 201.249 344.162 204.505C342.716 206.314 339.645 204.505 341.09 202.696C343.439 199.44 345.607 196.184 347.595 192.747C348.679 190.758 351.751 192.567 350.667 194.556Z"
                    fill="black"
                  ></path>
                  <path
                    d="M342.897 203.601C346.149 207.038 349.763 210.113 353.558 213.007C355.364 214.454 352.835 216.986 351.028 215.539C347.234 212.645 343.801 209.57 340.368 206.133C338.741 204.505 341.271 201.973 342.897 203.601Z"
                    fill="black"
                  ></path>
                  <path
                    d="M342.717 206.314C340.187 210.293 337.657 214.092 334.766 217.71C333.321 219.519 330.249 217.71 331.695 215.901C334.405 212.102 337.115 208.304 339.645 204.505C340.91 202.515 343.981 204.324 342.717 206.314Z"
                    fill="black"
                  ></path>
                  <path
                    d="M340.187 206.133C337.115 203.42 332.779 203.058 328.804 203.058C326.455 203.058 326.455 199.44 328.804 199.44C333.863 199.44 338.922 199.983 342.897 203.42C344.343 205.048 341.813 207.761 340.187 206.133Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 16:
              return (
                <React.Fragment>
                  <path
                    d="M337.605 231.654C342.767 230.77 348.107 229.885 353.091 230.77C358.075 231.654 363.414 233.955 366.084 238.556C367.686 241.387 368.22 244.926 368.22 248.288C368.22 255.012 366.262 262.268 360.744 266.161C358.609 267.753 356.117 268.638 353.447 269.169C341.343 272.177 326.748 268.815 319.806 258.374C318.026 255.897 316.78 253.066 316.78 250.058C316.602 244.926 319.984 240.325 324.256 237.494C328.528 234.663 333.155 232.716 337.961 231.3"
                    fill="white"
                  ></path>
                  <path
                    d="M337.249 229.885C350.777 227.761 367.864 228.292 369.822 245.28C371.424 259.082 364.126 269.7 350.243 271.646C337.783 273.416 322.476 268.815 316.78 256.782C309.838 242.272 325.858 233.247 337.605 229.708C339.741 229.177 340.631 232.539 338.495 233.247C326.036 236.963 310.55 247.934 324.256 261.029C330.663 267.045 339.741 269.169 348.285 268.284C359.854 267.045 366.44 259.967 366.44 248.288C366.262 231.831 350.599 231.3 338.139 233.247C335.825 233.601 334.935 230.239 337.249 229.885Z"
                    fill="black"
                  ></path>
                  <path
                    d="M343.657 247.226C345.437 246.165 347.039 244.749 348.285 243.156C348.819 242.449 350.243 242.449 350.777 243.156C351.489 243.864 351.311 244.926 350.777 245.634C349.353 247.403 347.573 248.996 345.437 250.235C343.479 251.473 341.699 248.465 343.657 247.226Z"
                    fill="black"
                  ></path>
                  <path
                    d="M343.301 250.765C340.631 249.881 337.961 248.642 335.291 247.403C333.155 246.342 335.113 243.333 337.071 244.395C339.385 245.457 341.699 246.519 344.191 247.403C346.505 248.111 345.437 251.65 343.301 250.765Z"
                    fill="black"
                  ></path>
                  <path
                    d="M344.725 247.934C347.217 249.173 349.531 250.942 351.667 252.889C353.269 254.481 350.777 256.959 349.175 255.366C347.395 253.597 345.259 252.181 342.945 250.942C340.987 250.058 342.767 246.872 344.725 247.934Z"
                    fill="black"
                  ></path>
                  <path
                    d="M344.547 251.119C342.767 252.712 341.165 254.305 339.385 255.897C337.783 257.49 335.113 255.012 336.893 253.42C338.673 251.827 340.275 250.235 342.055 248.642C343.657 247.049 346.327 249.527 344.547 251.119Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 17:
              return (
                <React.Fragment>
                  <g clipPath="url(#clip19_92_2)">
                    <path
                      d="M53.7792 323C47.1925 317.96 38.7761 315.26 30.5427 315.98C22.3092 316.7 14.2587 320.48 8.58682 326.42C7.30607 327.68 6.20828 328.94 5.47641 330.38C4.56159 332.18 4.01269 333.98 3.82973 335.96C3.4638 341.9 6.57421 347.66 11.1483 351.44C15.7225 355.22 21.7603 356.84 27.7982 357.2C36.9464 357.74 46.6436 354.68 52.4985 347.84C58.3534 341 59.4512 329.84 53.7792 323Z"
                      fill="white"
                    ></path>
                    <path
                      d="M54.877 321.2C42.2524 312.2 25.6025 311.3 12.795 320.12C1.81705 327.68 -2.02522 340.82 7.8549 351.08C27.6152 371.6 75.0032 349.28 55.4259 321.92C53.9622 319.76 50.3028 321.74 51.9495 323.9C68.7823 347.48 27.4322 365.3 10.7823 348.38C-0.195571 337.04 10.7823 323.54 22.8581 319.58C33.47 316.16 43.8991 318.5 52.6814 324.8C54.877 326.24 56.8896 322.82 54.877 321.2Z"
                      fill="black"
                    ></path>
                    <path
                      d="M23.041 328.76C24.6877 331.64 27.6151 333.98 30.7256 335.06L23.041 328.76Z"
                      fill="white"
                    ></path>
                    <path
                      d="M21.2113 329.66C23.224 333.08 26.3344 335.6 30.1766 336.86C32.7382 337.76 33.653 333.8 31.2744 332.9C28.53 331.82 26.3344 330.02 24.6877 327.5C23.4069 325.52 19.9306 327.5 21.2113 329.66Z"
                      fill="black"
                    ></path>
                    <path
                      d="M30.7255 335.6C28.7129 337.76 27.2492 340.28 26.5173 343.16L30.7255 335.6Z"
                      fill="white"
                    ></path>
                    <path
                      d="M29.2618 334.16C27.0662 336.68 25.4195 339.38 24.6876 342.62C23.9558 345.14 27.981 346.22 28.7129 343.7C29.4447 341.18 30.5425 339.02 32.3722 337.04C33.8359 335.06 30.9085 332.18 29.2618 334.16Z"
                      fill="black"
                    ></path>
                    <path
                      d="M31.0914 336.32C30.7255 335.96 31.2743 335.42 31.8232 335.24C35.4826 334.16 39.3248 333.62 43.1671 333.98"
                      fill="white"
                    ></path>
                    <path
                      d="M33.104 335.78C33.104 335.6 33.104 335.42 32.921 335.42C32.7381 336.14 32.5551 336.68 32.3721 337.4C33.287 336.32 36.3974 336.14 37.8611 336.14C39.6908 335.96 41.3374 335.96 43.1671 336.14C45.7286 336.32 45.7286 332.18 43.1671 332C40.0567 331.82 27.798 331.46 29.0788 336.86C29.8106 339.38 33.6529 338.3 33.104 335.78Z"
                      fill="black"
                    ></path>
                  </g>
                </React.Fragment>
              );
            case 18:
              return (
                <React.Fragment>
                  <path
                    d="M48.1028 358.192C39.7603 357.992 31.0206 358.192 23.274 361.38C15.5275 364.568 8.57541 371.342 7.58225 379.711C7.18499 383.695 7.97952 387.68 8.97267 391.665C9.76719 394.654 10.7603 397.643 12.548 400.233C15.9247 404.816 21.8836 406.808 27.6439 406.808C33.4042 406.808 38.7672 405.015 44.1302 403.222C49.2946 401.628 54.6576 399.635 57.637 395.252C60.0206 391.865 60.4179 387.282 60.2192 383.098C60.0206 373.932 56.6439 364.169 48.8973 359.387"
                    fill="white"
                  ></path>
                  <path
                    d="M48.1027 356C33.0068 355.801 13.7397 357.594 6.78767 373.733C1.62329 386.086 7.78082 404.417 21.2877 408.004C32.6096 410.992 54.2603 405.812 60.2192 394.654C66.774 382.5 60.4178 364.966 50.089 357.395C47.7055 355.801 45.5205 359.586 47.9041 361.18C56.4452 367.357 63.3973 386.286 54.4589 395.252C48.1027 401.628 31.0205 405.613 22.6781 403.62C8.57534 400.233 6.19178 378.316 15.3288 369.15C23.8699 360.583 36.7808 360.383 48.1027 360.583C51.0822 360.583 51.0822 356 48.1027 356Z"
                    fill="black"
                  ></path>
                  <path
                    d="M28.041 371.741C29.8287 374.729 31.815 377.519 34.3972 379.91L28.041 371.741Z"
                    fill="white"
                  ></path>
                  <path
                    d="M26.2534 372.936C28.0411 376.124 30.4247 378.914 33.0069 381.504C34.9932 383.496 38.1712 380.308 36.1849 378.316C33.8014 375.925 31.8151 373.534 30.226 370.545C28.637 368.154 24.6644 370.346 26.2534 372.936Z"
                    fill="black"
                  ></path>
                  <path
                    d="M34.3971 379.711C31.0204 380.906 27.6437 382.5 24.4656 384.293L34.3971 379.711Z"
                    fill="white"
                  ></path>
                  <path
                    d="M33.8013 377.519C30.2259 378.714 26.6506 380.508 23.2739 382.301C20.8903 383.895 23.0753 387.68 25.4588 386.286C28.4383 384.493 31.6164 383.098 34.7944 381.902C37.5753 380.906 36.3835 376.523 33.8013 377.519Z"
                    fill="black"
                  ></path>
                  <path
                    d="M37.1782 379.511C40.3563 379.511 43.5344 379.711 46.7125 379.711L37.1782 379.511Z"
                    fill="white"
                  ></path>
                  <path
                    d="M37.1782 381.703C40.3563 381.703 43.5344 381.902 46.7124 381.902C49.4933 381.902 49.4933 377.519 46.7124 377.32C43.5344 377.32 40.3563 377.12 37.1782 377.12C34.1987 377.12 34.1987 381.703 37.1782 381.703Z"
                    fill="black"
                  ></path>
                  <path
                    d="M34.5959 381.703C33.4041 385.289 33.6027 389.274 34.7945 392.662L34.5959 381.703Z"
                    fill="white"
                  ></path>
                  <path
                    d="M32.411 381.105C31.2192 385.09 31.2192 389.274 32.6096 393.259C33.6027 396.049 37.774 394.853 36.9795 392.064C35.9863 388.876 35.7877 385.489 36.7808 382.301C37.5754 379.511 33.2055 378.316 32.411 381.105Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 19:
              return (
                <React.Fragment>
                  <path
                    d="M66.7835 406.723C56.6315 404.908 45.9356 406.36 36.69 410.718C33.6081 412.17 30.3449 414.349 28.8947 417.617C27.9882 420.34 28.3508 423.426 28.1695 426.331C27.9882 430.507 26.7192 434.683 26.1754 438.859C25.6315 443.035 26.1754 447.573 28.8947 450.841C31.7952 454.291 36.8713 455.38 40.3157 458.467C41.5847 459.556 42.8537 461.009 44.1227 462.098C47.9297 465.184 53.3683 465.911 58.2631 464.64C63.1578 463.369 67.5087 460.827 71.6783 458.104C76.3917 455.017 81.2865 451.386 82.918 446.121C83.8245 443.398 83.8245 440.674 83.4619 437.951C82.3742 426.513 76.7543 415.62 68.0526 407.994"
                    fill="white"
                  ></path>
                  <path
                    d="M67.3274 404.726C55 402.729 28.8947 403.818 26.3567 420.34C25.4502 426.331 24.7251 432.504 24.1812 438.496C23.8187 442.127 23.9999 445.939 25.4502 449.207C26.538 451.568 28.1695 453.202 30.345 454.654C34.5146 457.559 38.5029 460.282 42.6725 463.369C54.8187 472.447 73.8538 462.643 82.0116 451.931C92.5263 437.951 80.1988 416.527 69.5029 406.542C67.5087 404.726 64.6081 407.631 66.6023 409.447C75.304 417.617 86.5438 435.046 80.0175 447.573C76.9356 453.383 68.7777 457.922 62.9766 460.646C59.8947 462.098 56.6315 463.187 53.1871 463.187C47.0233 463.006 45.7543 460.101 41.5847 457.014C39.0467 455.017 35.7836 453.928 33.2456 451.931C25.269 445.758 28.7134 437.769 29.8011 429.418C30.345 424.879 30.345 422.882 32.7017 418.524C35.6023 413.259 37.4152 412.17 42.8538 410.354C50.4678 407.813 58.2631 407.45 66.0584 408.539C68.959 408.902 70.0467 405.089 67.3274 404.726Z"
                    fill="black"
                  ></path>
                  <path
                    d="M39.228 426.513C43.9415 426.694 48.6549 426.876 53.3684 427.058L39.228 426.513Z"
                    fill="white"
                  ></path>
                  <path
                    d="M39.228 428.51C43.9414 428.692 48.6549 428.873 53.3683 429.055C55.9063 429.055 55.9063 425.061 53.3683 424.879C48.6549 424.879 43.9414 424.697 39.228 424.334C36.5087 424.334 36.5087 428.329 39.228 428.51Z"
                    fill="black"
                  ></path>
                  <path
                    d="M59.5321 418.524C57.3566 420.885 55.1812 423.245 53.0058 425.605C51.918 426.876 50.8303 427.784 51.0116 429.418C51.1929 431.415 53.1871 434.138 54.0935 435.954C55.5438 438.677 57.1754 441.219 58.9882 443.579C60.4385 445.758 64.0642 443.761 62.4327 441.582C60.2572 438.496 57.1754 434.683 56.0876 431.233C55.3625 428.692 56.0876 428.147 57.9005 426.15C59.3508 424.516 60.8011 423.063 62.2514 421.429C64.0642 419.432 61.3449 416.709 59.5321 418.524Z"
                    fill="black"
                  ></path>
                  <path
                    d="M61.1635 443.398C58.8068 447.21 56.6314 451.023 54.2747 454.836L61.1635 443.398Z"
                    fill="white"
                  ></path>
                  <path
                    d="M59.532 442.49C57.1753 446.303 54.9998 450.115 52.6431 453.928C51.3741 456.107 54.8185 458.285 56.0876 455.925C58.4443 452.112 60.6197 448.3 62.9764 444.487C64.2454 442.127 60.801 440.13 59.532 442.49Z"
                    fill="black"
                  ></path>
                  <path
                    d="M62.4329 443.579C65.8773 443.579 69.3217 443.942 72.7662 444.669L62.4329 443.579Z"
                    fill="white"
                  ></path>
                  <path
                    d="M62.4328 445.758C65.6959 445.758 68.9591 446.121 72.2223 446.666C74.7603 447.21 75.848 443.216 73.31 442.671C69.6842 441.945 66.0585 441.582 62.4328 441.582C59.7135 441.582 59.7135 445.576 62.4328 445.758Z"
                    fill="black"
                  ></path>
                  <path
                    d="M55.3626 435.772C53.5497 437.043 51.5555 438.314 49.7427 439.585L55.3626 435.772Z"
                    fill="white"
                  ></path>
                  <path
                    d="M54.4562 433.957C52.6433 435.228 50.6492 436.499 48.8363 437.769C46.6609 439.222 48.655 442.853 50.8305 441.219C52.6433 439.948 54.6375 438.677 56.4503 437.406C58.6258 436.135 56.6316 432.504 54.4562 433.957Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 20:
              return (
                <React.Fragment>
                  <path
                    d="M92.2379 461.102C90.23 458.915 87.1268 458.004 84.2061 457.822C81.2855 457.822 78.3649 458.551 75.6268 459.644C70.1506 461.648 64.6744 464.564 61.0236 469.119C57.3728 473.674 55.5474 480.233 57.7379 485.699C59.3808 489.89 63.0315 492.987 67.0474 495.356C69.2379 496.631 71.6109 497.725 74.1665 497.725C76.1744 497.725 78.1823 496.996 80.1903 496.267C87.3093 493.352 94.2458 488.797 97.5315 481.873C100.817 474.949 99.5395 465.475 92.968 461.284"
                    fill="white"
                  ></path>
                  <path
                    d="M93.6983 459.644C83.6586 451.263 66.865 458.915 59.5634 467.661C52.2618 476.589 53.7221 488.25 63.0317 495.174C73.8015 503.008 86.3967 498.089 94.7936 488.979C102.46 480.78 103.555 467.297 94.2459 459.826C92.238 458.186 89.3174 461.102 91.3253 462.742C107.206 475.131 85.6666 500.093 69.9682 494.445C60.4761 490.983 55.7301 480.78 61.3888 472.034C66.3174 464.381 82.3809 455.453 90.5951 462.559C92.7856 464.199 95.7063 461.466 93.6983 459.644Z"
                    fill="black"
                  ></path>
                  <path
                    d="M67.0474 475.314C68.6902 476.225 70.1505 477.682 71.2458 479.322C72.8886 481.873 73.4363 485.153 75.6267 487.339"
                    fill="white"
                  ></path>
                  <path
                    d="M66.1348 477.136C70.6982 479.869 70.8808 485.153 74.349 488.797C76.1744 490.619 79.0951 487.886 77.2697 485.881C75.4443 483.877 74.7141 481.326 73.4363 478.958C72.1586 476.771 70.3332 474.949 68.1427 473.674C65.7697 472.216 63.7617 475.678 66.1348 477.136Z"
                    fill="black"
                  ></path>
                  <path
                    d="M73.6189 479.686C75.9919 478.411 78.3649 477.136 80.9205 475.86L73.6189 479.686Z"
                    fill="white"
                  ></path>
                  <path
                    d="M74.5316 481.326C76.9046 480.051 79.2776 478.775 81.8332 477.5C84.2062 476.225 82.0157 472.763 79.8253 474.038C77.4522 475.314 75.0792 476.589 72.5237 477.864C70.1507 479.14 72.1586 482.602 74.5316 481.326Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 21:
              return (
                <React.Fragment>
                  <path
                    d="M111.83 486.126C106.816 484.172 101.032 484.953 96.2109 487.102C91.3903 489.251 87.1481 492.767 83.0988 496.479C80.3993 499.019 77.6997 501.753 76.9284 505.465C76.1571 509.177 77.8925 513.084 80.2064 516.209C81.5562 517.967 83.0988 519.726 85.0271 520.898C86.7625 521.874 88.6907 522.46 90.619 522.851C97.7535 523.828 104.888 519.921 109.323 514.256C113.758 508.591 115.493 500.972 115.493 493.744C115.493 491.009 114.915 487.688 112.408 486.712"
                    fill="white"
                  ></path>
                  <path
                    d="M112.408 483.977C99.4888 480.07 86.5696 488.274 78.4709 498.237C71.5292 506.442 75.5785 518.358 84.8341 523.242C94.4754 528.321 106.045 522.656 111.83 514.256C116.65 507.223 121.857 491.009 113.565 484.758C111.251 483 109.13 486.907 111.444 488.47C117.036 492.767 111.637 506.442 108.937 511.13C104.502 518.358 94.2826 523.828 86.1839 518.944C83.0987 517.186 79.8207 513.084 79.2422 509.372C78.2781 503.316 82.7131 499.605 86.5696 496.284C93.7041 490.033 101.61 485.344 111.251 488.274C113.951 489.056 115.108 484.758 112.408 483.977Z"
                    fill="black"
                  ></path>
                  <path
                    d="M86.5696 507.614C86.9552 510.74 89.0763 513.67 91.9687 514.842L86.5696 507.614Z"
                    fill="white"
                  ></path>
                  <path
                    d="M84.4486 508.2C85.027 511.912 87.5338 515.233 90.8118 516.795C93.3185 517.967 95.6324 514.256 92.9329 513.084C90.4261 511.912 88.8835 509.763 88.4979 507.028C88.3051 505.856 86.9553 505.27 85.7983 505.465C84.8342 505.856 84.2557 507.028 84.4486 508.2Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 22:
              return (
                <React.Fragment>
                  <path
                    d="M137.647 496.988C136.196 496.808 134.926 497.35 133.475 497.711C125.132 501.146 117.515 506.208 111.348 512.896C109.716 514.704 108.265 516.511 107.358 518.861C105.907 523.2 107.176 527.9 108.99 531.877C110.26 534.769 111.892 537.661 114.613 538.927C116.064 539.65 117.515 539.831 118.966 539.831C125.676 540.011 132.75 536.577 135.47 530.431C136.196 528.623 136.74 526.815 137.103 525.008C138.554 517.958 139.461 510.908 139.642 503.858C139.642 501.869 139.642 499.881 138.554 498.254"
                    fill="white"
                  ></path>
                  <path
                    d="M137.647 495C126.946 495.723 114.431 505.123 108.265 513.438C102.642 521.031 104.637 534.769 112.98 540.012C120.417 544.712 132.206 540.373 136.559 533.323C139.823 528.442 140.368 521.573 141.093 515.788C141.819 509.823 143.27 502.773 140.549 496.988C139.461 494.819 136.015 496.808 137.103 499.158C140.005 505.304 137.284 517.958 135.471 524.465C132.931 533.504 124.77 541.458 115.52 535.312C97.0195 523.019 122.956 500.062 137.647 499.158C140.186 498.796 140.186 494.819 137.647 495Z"
                    fill="black"
                  ></path>
                  <path
                    d="M116.97 520.308C117.515 524.646 121.142 528.262 125.495 528.985L116.97 520.308Z"
                    fill="white"
                  ></path>
                  <path
                    d="M114.975 520.85C115.882 525.912 119.873 529.888 124.951 530.973C127.49 531.515 128.578 527.538 126.039 526.996C122.23 526.273 119.691 523.562 118.966 519.765C118.422 517.235 114.613 518.319 114.975 520.85Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 23:
              return (
                <React.Fragment>
                  <path
                    d="M158.762 506.253C154.738 506.048 151.317 509.53 148.902 512.807C142.866 521 138.238 529.807 134.616 539.434C134.213 540.663 133.811 541.892 133.811 543.121C133.811 545.988 135.622 548.446 138.037 549.88C140.451 551.313 143.268 551.723 145.884 552.133C149.305 552.747 153.128 553.157 155.744 551.109C158.159 549.265 159.165 545.988 159.768 542.916C161.378 534.313 162.384 525.711 162.384 517.109C162.384 513.627 162.183 509.94 159.97 507.482"
                    fill="white"
                  ></path>
                  <path
                    d="M158.762 504C147.293 505.229 140.652 520.59 136.226 529.807C132 538.615 128.177 548.241 139.445 552.952C148.299 556.639 158.36 556.024 161.579 545.783C163.793 538.819 164.195 530.627 164.598 523.458C165 517.313 165.604 511.374 162.183 506.048C160.573 503.59 156.549 506.048 158.159 508.506C162.183 514.855 159.97 525.916 159.165 532.88C158.762 535.747 158.963 542.096 157.152 544.554C154.5 550.5 150.5 549.88 146.488 549.88C143.872 549.47 141.457 548.855 139.043 547.831C129.988 540.663 150.311 509.53 158.762 508.711C161.579 508.301 161.579 503.795 158.762 504Z"
                    fill="black"
                  ></path>
                  <path
                    d="M140.25 540.868C142.866 542.506 145.884 543.735 148.701 544.759L140.25 540.868Z"
                    fill="white"
                  ></path>
                  <path
                    d="M139.043 542.711C141.86 544.554 144.878 545.988 148.098 547.012C150.915 548.036 152.122 543.53 149.305 542.506C146.488 541.482 143.872 540.253 141.256 538.819C138.841 537.386 136.628 541.277 139.043 542.711Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 24:
              return (
                <React.Fragment>
                  <path
                    d="M180 513.996C179.655 511.584 176.207 510.378 173.966 511.412C171.724 512.446 170.172 514.685 168.966 516.925C164.655 525.884 163.103 535.876 162.069 545.697C161.897 547.075 161.724 548.453 162.414 549.832C163.448 551.899 166.035 552.416 168.448 552.588C171.035 552.76 173.621 552.588 176.207 552.071C178.448 551.727 180.69 550.865 181.724 548.97C182.241 547.936 182.414 546.73 182.414 545.524C182.931 534.67 182.241 523.817 180.172 513.135"
                    fill="white"
                  ></path>
                  <path
                    d="M181.552 512.963C176.897 503.487 168.448 512.963 166.034 518.648C163.276 525.195 161.897 532.258 160.862 539.322C160.345 543.112 158.793 548.453 161.552 551.899C165.69 556.895 179.828 555.689 183.276 550.348C185.69 546.386 184.655 539.667 184.483 535.36C184.31 527.779 183.448 520.199 182.069 512.79C181.552 510.378 177.931 511.412 178.276 513.824C179.483 520.543 180.345 527.09 180.517 533.809C180.862 540.873 182.069 548.626 173.276 550.521C169.655 551.21 165.172 550.004 163.966 545.869C163.103 543.112 165 537.255 165.517 534.498C166.034 531.569 166.724 528.641 167.586 525.884C167.931 524.85 168.276 523.817 168.621 522.955C169.31 517.787 172.586 515.03 178.276 514.858C179.31 517.097 182.759 515.202 181.552 512.963Z"
                    fill="black"
                  ></path>
                  <path
                    d="M168.276 544.491C170.172 544.663 172.069 544.835 173.966 545.007C174.483 545.007 175.172 545.007 175.517 544.663"
                    fill="white"
                  ></path>
                  <path
                    d="M168.276 546.386C170 546.558 171.724 546.73 173.448 546.73C174.828 546.903 175.862 546.73 177.069 545.869C178.965 544.318 176.207 541.562 174.31 543.112C174.828 542.768 174.483 543.112 174.138 542.94C173.448 542.768 172.759 542.768 172.069 542.768C170.862 542.596 169.655 542.596 168.448 542.423C165.69 542.423 165.862 546.213 168.276 546.386Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 25:
              return (
                <React.Fragment>
                  <path
                    d="M189 513.996C189.345 511.584 192.793 510.378 195.034 511.412C197.276 512.446 198.828 514.685 200.034 516.925C204.345 525.884 205.897 535.876 206.931 545.697C207.103 547.075 207.276 548.453 206.586 549.832C205.552 551.899 202.965 552.416 200.552 552.588C197.965 552.76 195.379 552.588 192.793 552.071C190.552 551.727 188.31 550.865 187.276 548.97C186.759 547.936 186.586 546.73 186.586 545.524C186.069 534.67 186.759 523.817 188.828 513.135"
                    fill="white"
                  ></path>
                  <path
                    d="M187.448 512.963C192.103 503.487 200.552 512.963 202.966 518.648C205.724 525.195 207.103 532.258 208.138 539.322C208.655 543.112 210.207 548.453 207.448 551.899C203.31 556.895 189.172 555.689 185.724 550.348C183.31 546.386 184.345 539.667 184.517 535.36C184.69 527.779 185.552 520.199 186.931 512.79C187.448 510.378 191.069 511.412 190.724 513.824C189.517 520.543 188.655 527.09 188.483 533.809C188.138 540.873 186.931 548.626 195.724 550.521C199.345 551.21 203.828 550.004 205.034 545.869C205.897 543.112 204 537.255 203.483 534.498C202.966 531.569 202.276 528.641 201.414 525.884C201.069 524.85 200.724 523.817 200.379 522.955C199.69 517.787 196.414 515.03 190.724 514.858C189.69 517.097 186.241 515.202 187.448 512.963Z"
                    fill="black"
                  ></path>
                  <path
                    d="M200.724 544.491C198.828 544.663 196.931 544.835 195.034 545.007C194.517 545.007 193.828 545.007 193.483 544.663"
                    fill="white"
                  ></path>
                  <path
                    d="M200.724 546.386C199 546.558 197.276 546.73 195.552 546.73C194.172 546.903 193.138 546.73 191.931 545.869C190.035 544.318 192.793 541.562 194.69 543.112C194.172 542.768 194.517 543.112 194.862 542.94C195.552 542.768 196.241 542.768 196.931 542.768C198.138 542.596 199.345 542.596 200.552 542.423C203.31 542.423 203.138 546.213 200.724 546.386Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 26:
              return (
                <React.Fragment>
                  <path
                    d="M210.238 506.253C214.262 506.048 217.683 509.53 220.098 512.807C226.134 521 230.762 529.807 234.384 539.434C234.787 540.663 235.189 541.892 235.189 543.121C235.189 545.988 233.378 548.446 230.963 549.88C228.549 551.313 225.732 551.723 223.116 552.133C219.695 552.747 215.872 553.157 213.256 551.109C210.841 549.265 209.835 545.988 209.232 542.916C207.622 534.313 206.616 525.711 206.616 517.109C206.616 513.627 206.817 509.94 209.03 507.482"
                    fill="white"
                  ></path>
                  <path
                    d="M210.238 504C221.707 505.229 228.348 520.59 232.774 529.807C237 538.615 240.823 548.241 229.555 552.952C220.701 556.639 210.64 556.024 207.421 545.783C205.207 538.819 204.805 530.627 204.402 523.458C204 517.313 203.396 511.374 206.817 506.048C208.427 503.59 212.451 506.048 210.841 508.506C206.817 514.855 209.03 525.916 209.835 532.88C210.238 535.747 210.037 542.096 211.848 544.554C214.262 547.831 217 550.743 222.512 549.88C225.128 549.47 227.543 548.855 229.957 547.831C239.012 540.663 218.689 509.53 210.238 508.711C207.421 508.301 207.421 503.795 210.238 504Z"
                    fill="black"
                  ></path>
                  <path
                    d="M228.75 540.868C226.134 542.506 223.116 543.735 220.299 544.759L228.75 540.868Z"
                    fill="white"
                  ></path>
                  <path
                    d="M229.957 542.711C227.14 544.554 224.122 545.988 220.902 547.012C218.085 548.036 216.878 543.53 219.695 542.506C222.512 541.482 225.128 540.253 227.744 538.819C230.159 537.386 232.372 541.277 229.957 542.711Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 27:
              return (
                <React.Fragment>
                  <path
                    d="M231.353 496.988C232.804 496.808 234.074 497.35 235.525 497.711C243.868 501.146 251.485 506.208 257.652 512.896C259.284 514.704 260.735 516.511 261.642 518.861C263.093 523.2 261.824 527.9 260.01 531.877C258.74 534.769 257.108 537.661 254.387 538.927C252.936 539.65 251.485 539.831 250.034 539.831C243.324 540.011 236.25 536.577 233.53 530.431C232.804 528.623 232.26 526.815 231.897 525.008C230.446 517.958 229.539 510.908 229.358 503.858C229.358 501.869 229.358 499.881 230.446 498.254"
                    fill="white"
                  ></path>
                  <path
                    d="M231.353 495C242.054 495.723 254.569 505.123 260.735 513.438C266.358 521.031 264.363 534.769 256.02 540.012C248.583 544.712 236.794 540.373 232.441 533.323C229.177 528.442 228.632 521.573 227.907 515.788C227.181 509.823 225.73 502.773 228.451 496.988C229.539 494.819 232.985 496.808 231.897 499.158C228.995 505.304 231.716 517.958 233.529 524.465C236.069 533.504 244.23 541.458 253.48 535.312C271.98 523.019 246.044 500.062 231.353 499.158C228.814 498.796 228.814 494.819 231.353 495Z"
                    fill="black"
                  ></path>
                  <path
                    d="M252.03 520.308C251.485 524.646 247.858 528.262 243.505 528.985L252.03 520.308Z"
                    fill="white"
                  ></path>
                  <path
                    d="M254.025 520.85C253.118 525.912 249.127 529.888 244.049 530.973C241.51 531.515 240.422 527.538 242.961 526.996C246.77 526.273 249.309 523.562 250.034 519.765C250.578 517.235 254.387 518.319 254.025 520.85Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 28:
              return (
                <React.Fragment>
                  <path
                    d="M257.17 486.126C262.184 484.172 267.968 484.953 272.789 487.102C277.61 489.251 281.852 492.767 285.901 496.479C288.601 499.019 291.3 501.753 292.072 505.465C292.843 509.177 291.107 513.084 288.794 516.209C287.444 517.967 285.901 519.726 283.973 520.898C282.238 521.874 280.309 522.46 278.381 522.851C271.246 523.828 264.112 519.921 259.677 514.256C255.242 508.591 253.507 500.972 253.507 493.744C253.507 491.009 254.085 487.688 256.592 486.712"
                    fill="white"
                  ></path>
                  <path
                    d="M256.592 483.977C269.511 480.07 282.43 488.274 290.529 498.237C297.471 506.442 293.421 518.358 284.166 523.242C274.525 528.321 262.955 522.656 257.17 514.256C252.35 507.223 247.143 491.009 255.435 484.758C257.749 483 259.87 486.907 257.556 488.47C251.964 492.767 257.363 506.442 260.063 511.13C264.498 518.358 274.717 523.828 282.816 518.944C285.901 517.186 289.179 513.084 289.758 509.372C290.722 503.316 286.287 499.605 282.43 496.284C275.296 490.033 267.39 485.344 257.749 488.274C255.049 489.056 253.892 484.758 256.592 483.977Z"
                    fill="black"
                  ></path>
                  <path
                    d="M282.43 507.614C282.045 510.74 279.924 513.67 277.031 514.842L282.43 507.614Z"
                    fill="white"
                  ></path>
                  <path
                    d="M284.551 508.2C283.973 511.912 281.466 515.233 278.188 516.795C275.681 517.967 273.368 514.256 276.067 513.084C278.574 511.912 280.116 509.763 280.502 507.028C280.695 505.856 282.045 505.27 283.202 505.465C284.166 505.856 284.744 507.028 284.551 508.2Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 29:
              return (
                <React.Fragment>
                  <path
                    d="M276.762 461.102C278.77 458.915 281.873 458.004 284.794 457.822C287.714 457.822 290.635 458.551 293.373 459.644C298.849 461.648 304.326 464.564 307.976 469.119C311.627 473.674 313.453 480.233 311.262 485.699C309.619 489.89 305.968 492.987 301.953 495.356C299.762 496.631 297.389 497.725 294.834 497.725C292.826 497.725 290.818 496.996 288.81 496.267C281.691 493.352 274.754 488.797 271.468 481.873C268.183 474.949 269.461 465.475 276.032 461.284"
                    fill="white"
                  ></path>
                  <path
                    d="M275.302 459.644C285.341 451.263 302.135 458.915 309.437 467.661C316.738 476.589 315.278 488.25 305.968 495.174C295.199 503.008 282.603 498.089 274.206 488.979C266.54 480.78 265.445 467.297 274.754 459.826C276.762 458.186 279.683 461.102 277.675 462.742C261.794 475.131 283.333 500.093 299.032 494.445C308.524 490.983 313.27 480.78 307.611 472.034C302.683 464.381 286.619 455.453 278.405 462.559C276.214 464.199 273.294 461.466 275.302 459.644Z"
                    fill="black"
                  ></path>
                  <path
                    d="M301.953 475.314C300.31 476.225 298.849 477.682 297.754 479.322C296.111 481.873 295.564 485.153 293.373 487.339"
                    fill="white"
                  ></path>
                  <path
                    d="M302.865 477.136C298.302 479.869 298.119 485.153 294.651 488.797C292.826 490.619 289.905 487.886 291.73 485.881C293.556 483.877 294.286 481.326 295.564 478.958C296.841 476.771 298.667 474.949 300.857 473.674C303.23 472.216 305.238 475.678 302.865 477.136Z"
                    fill="black"
                  ></path>
                  <path
                    d="M295.381 479.686C293.008 478.411 290.635 477.136 288.08 475.86L295.381 479.686Z"
                    fill="white"
                  ></path>
                  <path
                    d="M294.468 481.326C292.095 480.051 289.722 478.775 287.167 477.5C284.794 476.225 286.984 472.763 289.175 474.038C291.548 475.314 293.921 476.589 296.476 477.864C298.849 479.14 296.841 482.602 294.468 481.326Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 30:
              return (
                <React.Fragment>
                  <path
                    d="M302.216 406.723C312.368 404.908 323.064 406.36 332.31 410.718C335.392 412.17 338.655 414.349 340.105 417.617C341.012 420.34 340.649 423.426 340.83 426.331C341.012 430.507 342.281 434.683 342.825 438.859C343.369 443.035 342.825 447.573 340.105 450.841C337.205 454.291 332.129 455.38 328.684 458.467C327.415 459.556 326.146 461.009 324.877 462.098C321.07 465.184 315.632 465.911 310.737 464.64C305.842 463.369 301.491 460.827 297.322 458.104C292.608 455.017 287.714 451.386 286.082 446.121C285.176 443.398 285.176 440.674 285.538 437.951C286.626 426.513 292.246 415.62 300.947 407.994"
                    fill="white"
                  ></path>
                  <path
                    d="M301.673 404.726C314 402.729 340.105 403.818 342.643 420.34C343.55 426.331 344.275 432.504 344.819 438.496C345.181 442.127 345 445.939 343.55 449.207C342.462 451.568 340.83 453.202 338.655 454.654C334.485 457.559 330.497 460.282 326.328 463.369C314.181 472.447 295.146 462.643 286.988 451.931C276.474 437.951 288.801 416.527 299.497 406.542C301.491 404.726 304.392 407.631 302.398 409.447C293.696 417.617 282.456 435.046 288.983 447.573C292.064 453.383 300.222 457.922 306.023 460.646C309.105 462.098 312.368 463.187 315.813 463.187C321.977 463.006 323.246 460.101 327.415 457.014C329.953 455.017 333.216 453.928 335.754 451.931C343.731 445.758 340.287 437.769 339.199 429.418C338.655 424.879 338.655 422.882 336.298 418.524C333.398 413.259 331.585 412.17 326.146 410.354C318.532 407.813 310.737 407.45 302.942 408.539C300.041 408.902 298.953 405.089 301.673 404.726Z"
                    fill="black"
                  ></path>
                  <path
                    d="M329.772 426.513C325.059 426.694 320.345 426.876 315.632 427.058L329.772 426.513Z"
                    fill="white"
                  ></path>
                  <path
                    d="M329.772 428.51C325.059 428.692 320.345 428.873 315.632 429.055C313.094 429.055 313.094 425.061 315.632 424.879C320.345 424.879 325.059 424.697 329.772 424.334C332.491 424.334 332.491 428.329 329.772 428.51Z"
                    fill="black"
                  ></path>
                  <path
                    d="M309.468 418.524C311.643 420.885 313.819 423.245 315.994 425.605C317.082 426.876 318.17 427.784 317.988 429.418C317.807 431.415 315.813 434.138 314.907 435.954C313.456 438.677 311.825 441.219 310.012 443.579C308.561 445.758 304.936 443.761 306.567 441.582C308.743 438.496 311.825 434.683 312.912 431.233C313.638 428.692 312.912 428.147 311.099 426.15C309.649 424.516 308.199 423.063 306.749 421.429C304.936 419.432 307.655 416.709 309.468 418.524Z"
                    fill="black"
                  ></path>
                  <path
                    d="M307.836 443.398C310.193 447.21 312.369 451.023 314.725 454.836L307.836 443.398Z"
                    fill="white"
                  ></path>
                  <path
                    d="M309.468 442.49C311.825 446.303 314 450.115 316.357 453.928C317.626 456.107 314.181 458.285 312.912 455.925C310.556 452.112 308.38 448.3 306.024 444.487C304.755 442.127 308.199 440.13 309.468 442.49Z"
                    fill="black"
                  ></path>
                  <path
                    d="M306.567 443.579C303.123 443.579 299.678 443.942 296.234 444.669L306.567 443.579Z"
                    fill="white"
                  ></path>
                  <path
                    d="M306.567 445.758C303.304 445.758 300.041 446.121 296.778 446.666C294.24 447.21 293.152 443.216 295.69 442.671C299.316 441.945 302.941 441.582 306.567 441.582C309.287 441.582 309.287 445.576 306.567 445.758Z"
                    fill="black"
                  ></path>
                  <path
                    d="M313.637 435.772C315.45 437.043 317.444 438.314 319.257 439.585L313.637 435.772Z"
                    fill="white"
                  ></path>
                  <path
                    d="M314.544 433.957C316.357 435.228 318.351 436.499 320.164 437.769C322.339 439.222 320.345 442.853 318.17 441.219C316.357 439.948 314.363 438.677 312.55 437.406C310.374 436.135 312.368 432.504 314.544 433.957Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 31:
              return (
                <React.Fragment>
                  <path
                    d="M320.897 358.192C329.24 357.992 337.979 358.192 345.726 361.38C353.473 364.568 360.425 371.342 361.418 379.711C361.815 383.695 361.02 387.68 360.027 391.665C359.233 394.654 358.24 397.643 356.452 400.233C353.075 404.816 347.116 406.808 341.356 406.808C335.596 406.808 330.233 405.015 324.87 403.222C319.705 401.628 314.342 399.635 311.363 395.252C308.979 391.865 308.582 387.282 308.781 383.098C308.979 373.932 312.356 364.169 320.103 359.387"
                    fill="white"
                  ></path>
                  <path
                    d="M320.897 356C335.993 355.801 355.26 357.594 362.212 373.733C367.377 386.086 361.219 404.417 347.712 408.004C336.39 410.992 314.74 405.812 308.781 394.654C302.226 382.5 308.582 364.966 318.911 357.395C321.295 355.801 323.479 359.586 321.096 361.18C312.555 367.357 305.603 386.286 314.541 395.252C320.897 401.628 337.979 405.613 346.322 403.62C360.425 400.233 362.808 378.316 353.671 369.15C345.13 360.583 332.219 360.383 320.897 360.583C317.918 360.583 317.918 356 320.897 356Z"
                    fill="black"
                  ></path>
                  <path
                    d="M340.959 371.741C339.171 374.729 337.185 377.519 334.603 379.91L340.959 371.741Z"
                    fill="white"
                  ></path>
                  <path
                    d="M342.747 372.936C340.959 376.124 338.575 378.914 335.993 381.504C334.007 383.496 330.829 380.308 332.815 378.316C335.199 375.925 337.185 373.534 338.774 370.545C340.363 368.154 344.336 370.346 342.747 372.936Z"
                    fill="black"
                  ></path>
                  <path
                    d="M334.603 379.711C337.98 380.906 341.356 382.5 344.534 384.293L334.603 379.711Z"
                    fill="white"
                  ></path>
                  <path
                    d="M335.199 377.519C338.774 378.714 342.349 380.508 345.726 382.301C348.11 383.895 345.925 387.68 343.541 386.286C340.562 384.493 337.384 383.098 334.206 381.902C331.425 380.906 332.617 376.523 335.199 377.519Z"
                    fill="black"
                  ></path>
                  <path
                    d="M331.822 379.511C328.644 379.511 325.466 379.711 322.288 379.711L331.822 379.511Z"
                    fill="white"
                  ></path>
                  <path
                    d="M331.822 381.703C328.644 381.703 325.466 381.902 322.288 381.902C319.507 381.902 319.507 377.519 322.288 377.32C325.466 377.32 328.644 377.12 331.822 377.12C334.801 377.12 334.801 381.703 331.822 381.703Z"
                    fill="black"
                  ></path>
                  <path
                    d="M334.404 381.703C335.596 385.289 335.397 389.274 334.206 392.662L334.404 381.703Z"
                    fill="white"
                  ></path>
                  <path
                    d="M336.589 381.105C337.781 385.09 337.781 389.274 336.39 393.259C335.397 396.049 331.226 394.853 332.021 392.064C333.014 388.876 333.212 385.489 332.219 382.301C331.425 379.511 335.795 378.316 336.589 381.105Z"
                    fill="black"
                  ></path>
                </React.Fragment>
              );
            case 32:
              return (
                <React.Fragment>
                  <g clipPath="url(#clip21_92_2)">
                    <path
                      d="M315.221 323C321.808 317.96 330.224 315.26 338.457 315.98C346.691 316.7 354.741 320.48 360.413 326.42C361.694 327.68 362.792 328.94 363.524 330.38C364.438 332.18 364.987 333.98 365.17 335.96C365.536 341.9 362.426 347.66 357.852 351.44C353.278 355.22 347.24 356.84 341.202 357.2C332.054 357.74 322.356 354.68 316.502 347.84C310.647 341 309.549 329.84 315.221 323Z"
                      fill="white"
                    ></path>
                    <path
                      d="M314.123 321.2C326.748 312.2 343.397 311.3 356.205 320.12C367.183 327.68 371.025 340.82 361.145 351.08C341.385 371.6 293.997 349.28 313.574 321.92C315.038 319.76 318.697 321.74 317.05 323.9C300.218 347.48 341.568 365.3 358.218 348.38C369.196 337.04 358.218 323.54 346.142 319.58C335.53 316.16 325.101 318.5 316.319 324.8C314.123 326.24 312.11 322.82 314.123 321.2Z"
                      fill="black"
                    ></path>
                    <path
                      d="M345.959 328.76C344.312 331.64 341.385 333.98 338.274 335.06L345.959 328.76Z"
                      fill="white"
                    ></path>
                    <path
                      d="M347.789 329.66C345.776 333.08 342.666 335.6 338.823 336.86C336.262 337.76 335.347 333.8 337.726 332.9C340.47 331.82 342.666 330.02 344.312 327.5C345.593 325.52 349.069 327.5 347.789 329.66Z"
                      fill="black"
                    ></path>
                    <path
                      d="M338.274 335.6C340.287 337.76 341.751 340.28 342.483 343.16L338.274 335.6Z"
                      fill="white"
                    ></path>
                    <path
                      d="M339.738 334.16C341.934 336.68 343.58 339.38 344.312 342.62C345.044 345.14 341.019 346.22 340.287 343.7C339.555 341.18 338.457 339.02 336.628 337.04C335.164 335.06 338.092 332.18 339.738 334.16Z"
                      fill="black"
                    ></path>
                    <path
                      d="M337.909 336.32C338.275 335.96 337.726 335.42 337.177 335.24C333.517 334.16 329.675 333.62 325.833 333.98"
                      fill="white"
                    ></path>
                    <path
                      d="M335.896 335.78C335.896 335.6 335.896 335.42 336.079 335.42C336.262 336.14 336.445 336.68 336.628 337.4C335.713 336.32 332.603 336.14 331.139 336.14C329.309 335.96 327.663 335.96 325.833 336.14C323.271 336.32 323.271 332.18 325.833 332C328.943 331.82 341.202 331.46 339.921 336.86C339.189 339.38 335.347 338.3 335.896 335.78Z"
                      fill="black"
                    ></path>
                  </g>
                </React.Fragment>
              );
          }
        })()}
      </g>
    );
  }
}
