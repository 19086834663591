import { Modals } from "src/helpers";
import { action } from "typesafe-actions";
import { RootActionType } from "./types";

export function changeModal(modal: Modals) {
    return action(RootActionType.CHANGE_MODAL, { modal })
}

export function changePosition(position: [x: number, y: number, z: number], type: string) {
    return action(RootActionType.CHANGE_POSITION, { position, type })
}
