import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IUser } from "src/features/account/data/models/user";
import { Pagination } from "src/shared/model/pagination";
import { IOrthodontistRepository } from "../../data/repositories/orthodontistRepository";

export class FetchOrthodontists extends UseCase<FetchOrthodontistsParams, Pagination<IUser>>{
    private repository: IOrthodontistRepository
    constructor(repository: IOrthodontistRepository) {
        super()
        this.repository = repository
    }

    call(params: FetchOrthodontistsParams): Promise<Either<Failure, Pagination<IUser>>> {
        return this.repository.fetchOrthodontists(params.code, params.full_name, params.orthodontist_type, params.specialStatus, params.page, params.status, params.limit, params.action)
    }

}

export class FetchOrthodontistsParams {
    code: string
    full_name: string
    orthodontist_type: number
    specialStatus: number
    page: number
    status: number
    limit?: number
    action?: string
    constructor(
            code: string, 
            full_name: string, 
            orthodontist_type: number,
            specialStatus: number, 
            page: number, 
            status: number,
            limit?: number,
            action?: string, 
        ) {
        this.code = code
        this.full_name = full_name
        this.orthodontist_type = orthodontist_type
        this.specialStatus = specialStatus
        this.page = page
        this.status = status
        this.limit = limit
        this.action = action
    }
}