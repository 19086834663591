import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Route, Router, Switch } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Dispatch } from "redux";
import { Injector } from "./base/Injector";
import { AuthContainer } from "./features/auth/presenter/container/authContainer";
import { Routes } from "./helpers";
import { IRootState } from "./store";

import { ToastContainer } from "react-toastify";
import { AuthState } from "./base/Enums";
import AccountPage from "./features/account/presenter/ui/pages/accountPage";
import EditAccountPage from "./features/account/presenter/ui/pages/details/editAccountPage";
import NoticeOperatorPage from "./features/adminNotice/presenter/ui/pages/noticeOperatorPage";
import AnalysisDetailPage from "./features/analytic/presenter/ui/pages/detail/analysisDetailPage";
import AnalysisPdfViewPage from "./features/analytic/presenter/ui/pages/detail/analysisPdfViewPage";
import TotalAnalysisPage from "./features/analytic/presenter/ui/pages/index/totalAnalysisPage";
import ChangePasswordPage from "./features/auth/presenter/ui/pages/changePasswordPage";
import ForgetPasswordPage from "./features/auth/presenter/ui/pages/forgetPasswordPage";
import LoginPage from "./features/auth/presenter/ui/pages/loginPage";
import VerifyOTPPage from "./features/auth/presenter/ui/pages/verifyOTPPage";
import FileManagementPage from "./features/fileManagement/presenter/ui/pages/fileManagementPage";
import MedicalConfirmDetailPage from "./features/medicalRecord/presenter/ui/pages/details/medicalConfirmDetailPage";
import MedicalManagementDetailPage from "./features/medicalRecord/presenter/ui/pages/details/medicalManagementDetailPage";
import MedicalManagementPage from "./features/medicalRecord/presenter/ui/pages/index/medicalManagementPage";
import NotificationDetailPage from "./features/notification/presenter/ui/pages/details/notificationDetailPage";
import NotificationPage from "./features/notification/presenter/ui/pages/notificationPage";
import DentistDetailPage from "./features/orthodontist/presenter/ui/pages/detail/dentistDetailPage";
import DentistPage from "./features/orthodontist/presenter/ui/pages/index/dentistPage";
import PatientCsvImportPage from "./features/patient/presenter/ui/pages/csv/patientCsvImportPage";
import PatientManagementDetailPage from "./features/patient/presenter/ui/pages/details/patientManagementDetailPage";
import PatientManagementPage from "./features/patient/presenter/ui/pages/patientManagementPage";
import RemoteFilesPage from "./features/plyDownload/presenter/ui/pages/remoteFilesPage";
import CancelPlanPage from "./features/subscription/presenter/ui/pages/cancel/cancelPlanPage";
import DetailPlanPage from "./features/subscription/presenter/ui/pages/detail/detailPlanPage";
import PlanManagementPage from "./features/subscription/presenter/ui/pages/index/planManagementPage";
import ThreeDViewerPage from "./features/threeDViewer/presenter/ui/pages/index/threeDViewerPopup";
import ThreeDMatchingPage2 from "./features/threeDViewer/presenter/ui/pages/matching/threeDMatchingPopup";
import { history } from "./helpers";

class App extends React.Component<AppType> {
  componentDidMount() {
    this.props.start();

    window.onpopstate = (e) => {
      if (this.props.authState === AuthState.authenticated) {
        if (window.location.pathname.indexOf("/home/") === -1) {
          history.goForward();
          return;
        }
      } else {
        if (window.location.pathname.indexOf("/home/") > -1) {
          history.goForward();
          return;
        }
      }
    };
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path={Routes.SPLASH} />
          <Route exact path={Routes.LOGIN} component={LoginPage} />
          <Route exact path={Routes.FORGET_PASSWORD} component={ForgetPasswordPage} />
          <Route exact path={Routes.VERIFY_OTP} component={VerifyOTPPage} />
          <Route exact path={Routes.CHANGE_PASSWORD} component={ChangePasswordPage} />
          <Route path={Routes.HOME}>
            <Route exact path={Routes.NOTICE_OPERATOR} component={NoticeOperatorPage} />

            <Route path={Routes.PATIENT}>
              <Route exact path={Routes.PATIENT_MANAGEMENT} component={PatientManagementPage} />
              <Route exact path={Routes.PATIENT_MANAGEMENT_CREATE} component={PatientManagementDetailPage} />
              <Route exact path={Routes.PATIENT_MANAGEMENT_DETAIL} component={PatientManagementDetailPage} />
              <Route exact path={Routes.PATIENT_MANAGEMENT_CSV_IMPORT} component={PatientCsvImportPage} />
            </Route>

            <Route path={Routes.MEDICAL}>
              <Route exact path={Routes.MEDICAL_MANAGEMENT} component={MedicalManagementPage} />
              <Route exact path={Routes.MEDICAL_MANAGEMENT_CREATE} component={MedicalManagementDetailPage} />
              <Route exact path={Routes.MEDICAL_MANAGEMENT_DETAIL} component={MedicalManagementDetailPage} />
              <Route exact path={Routes.MEDICAL_CONFIRM_DETAIL} component={MedicalConfirmDetailPage} />
            </Route>

            <Route path={Routes.DENTIST}>
              <Route exact path={Routes.DENTIST_MANAGEMENT} component={DentistPage} />
              <Route exact path={Routes.DENTIST_MANAGEMENT_CREATE} component={DentistDetailPage} />
              <Route exact path={Routes.DENTIST_MANAGEMENT_DETAIL} component={DentistDetailPage} />
            </Route>

            <Route exact path={Routes.PLY_DOWNLOAD_MANAGEMENT} component={RemoteFilesPage} />

            <Route exact path={Routes.FILE_MANAGEMENT} component={FileManagementPage} />

            <Route exact path={Routes.THREED_VIEWER_MANAGEMENT} component={ThreeDViewerPage} />

            <Route exact path={Routes.THREED_VIEWER_MATCHING2} component={ThreeDMatchingPage2} />

            <Route path={Routes.NOTIFICATION}>
              <Route exact path={Routes.NOTIFICATION_MANAGEMENT} component={NotificationPage} />
              <Route exact path={Routes.NOTIFICATION_MANAGEMENT_CREATE} component={NotificationDetailPage} />
              <Route exact path={Routes.NOTIFICATION_MANAGEMENT_DETAIL} component={NotificationDetailPage} />
            </Route>

            <Route path={Routes.ACCOUNT}>
              <Route exact path={Routes.ACCOUNT_MANAGEMENT} component={AccountPage} />
              <Route exact path={Routes.ACCOUNT_MANAGEMENT_DETAIL} component={EditAccountPage} />
            </Route>

            <Route path={Routes.PATIENT_ANALYTIC}>
              <Route path={Routes.PATIENT_ANALYTIC_MANAGEMENT} component={TotalAnalysisPage} />
              <Route path={Routes.PATIENT_ANALYTIC_MANAGEMENT_DETAIL} component={AnalysisDetailPage} />
              <Route path={Routes.PATIENT_PDF_REVIEW_PAGE} component={AnalysisPdfViewPage} />
            </Route>

            <Route path={Routes.SUBSCRIPTION}>
              <Route path={Routes.SUBSCRIPTION_MANAGEMENT} component={PlanManagementPage} />
              <Route path={Routes.SUBSCRIPTION_PLAN_CANCEL} component={CancelPlanPage} />
              <Route path={Routes.SUBSCRIPTION_PLAN_DETAIL} component={DetailPlanPage} />
            </Route>
          </Route>
        </Switch>
        <ToastContainer />
      </Router>
    );
  }
}

const mapStateToProps = ({ auth }: IRootState) => {
  const { authState } = auth;
  return { authState };
};
const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    start: () => {
      Injector.get().find<AuthContainer>("AuthContainer").checkForAuthStatus(dispatch);
    },
  };
};

type AppType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(App);
