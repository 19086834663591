import React from "react";
import { Strings } from "src/constants";
import { IMedicalRecord } from "src/features/medicalRecord/data/models/medicalRecord";
import { PLYLoader } from "src/threeJs/loaders/PLYLoader";
import * as THREE from "three";
import "../css.css";
import ThreeDEditor from "../matching/Editor/ThreeDEditor";
import ThreeDMatchingPopup from "../matching/threeDMatchingPopup";

interface ThreeDViewerPageProps {
  selectedMedicalRecord: IMedicalRecord;
  plyKeys: string[];
  threeDScene?: THREE.Scene;
  handleUpdateScene: (scene: THREE.Scene) => void;
}

interface ThreeDViewerPageState {
  scene: THREE.Scene;
  isShow3DMatchingPopup: boolean;
  isShow3DEditor: boolean;
  editorCameraPosition: [x: number, y: number, z: number];
}

class ThreeDViewerPage extends React.Component<
  ThreeDViewerPageProps,
  ThreeDViewerPageState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      scene: new THREE.Scene(),
      isShow3DMatchingPopup: false,
      isShow3DEditor: false,
      editorCameraPosition: [0, 0, 0],
    };
  }

  handleCreateScene() {
    const sceneModified = new THREE.Scene();

    if (this.props.threeDScene) {
      const meshChildren = this.props.threeDScene.children.filter(
        (child) => child.type === "Mesh"
      );
      sceneModified.children = meshChildren;

      this.setState({ scene: sceneModified });
    } else {
      this.props.selectedMedicalRecord.files.forEach((value, key) => {
        if (key.includes("ply1") || key.includes("ply2")) {
          value.file!.arrayBuffer().then((buffer) => {
            const fileBuffer = new PLYLoader().parse(buffer);

            const basicMaterial = new THREE.MeshBasicMaterial({
              vertexColors: true,
              side: THREE.DoubleSide,
            });

            const mesh = new THREE.Mesh(fileBuffer, basicMaterial);

            sceneModified.add(mesh);
          });
        }
      });
    }

    this.setState({ scene: sceneModified });
  }

  handleShow3DMatchingPopup() {
    if (this.state.isShow3DMatchingPopup)
      this.setState({ scene: new THREE.Scene() });
    else this.handleCreateScene();

    this.setState({
      isShow3DMatchingPopup: !this.state.isShow3DMatchingPopup,
    });
  }

  handleShow3DEditor(cameraPosition?: [x: number, y: number, z: number]) {
    this.setState({
      isShow3DEditor: !this.state.isShow3DEditor,
      editorCameraPosition: cameraPosition ?? [0, 0, 50],
    });
  }

  handleUpdateScene(scene: THREE.Scene) {
    const meshChildren = scene.children.filter(
      (child) => child.type === "Mesh"
    );

    const sceneModified = this.state.scene;
    sceneModified.children = meshChildren;

    this.setState({ scene: sceneModified });
    this.handleShow3DEditor();
  }

  handleSaveScene() {
    this.props.handleUpdateScene(this.state.scene);
    this.handleShow3DMatchingPopup();
  }

  render(): React.ReactNode {
    return (
      <>
        <div className="btn-group2 threed-viewer-popup">
          <a
            className={`btn btn-primary`}
            onClick={() => this.handleShow3DMatchingPopup()}
          >
            {Strings.THREED_VIEWER_MANAGEMENT_BTN_START_EDIT}
          </a>
        </div>
        {this.state.isShow3DMatchingPopup ? (
          <ThreeDMatchingPopup
            onClose={() => this.handleShow3DMatchingPopup()}
            meshes={this.state.scene.children}
            handleOpen3DEditor={(
              editorCameraPosition: [x: number, y: number, z: number]
            ) => this.handleShow3DEditor(editorCameraPosition)}
            handleSaveScene={() => this.handleSaveScene()}
          />
        ) : null}
        {this.state.isShow3DEditor ? (
          <ThreeDEditor
            onClose={() => this.handleShow3DEditor()}
            cameraPosition={this.state.editorCameraPosition}
            meshes={this.state.scene.children}
            handleUpdateScene={(scene: THREE.Scene) =>
              this.handleUpdateScene(scene)
            }
          />
        ) : null}
      </>
    );
  }
}

export default ThreeDViewerPage;
