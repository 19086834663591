import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Strings } from "src/constants";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers";
import { IRootState } from "src/store";
import { Input } from "../input";
import Modal from "../modal/modal";

function NewImageNamePopup(props: MedicalPopupType) {
  let [newImageName, setNewImageName] = useState("デンタルデータ");

  useEffect(() => {
    setNewImageName("デンタルデータ");
  }, [props.currentModal]);

  return (
    <Modal
      id="imageType"
      className={`${props.currentModal === Modals.IMAGE_TYPE ? "flexible" : ""}`}
      dialogExtraClassName=""
      isShow={props.currentModal === Modals.IMAGE_TYPE}
      footerChildren={
        <React.Fragment>
          <div className="input">
            <Input
              className="fsc"
              inputClassName="form-control"
              type="text"
              pattern=""
              value={newImageName}
              maxLength={20}
              onChange={(value) => {
                setNewImageName(value);
              }}
            />
            <span>{newImageName.length}/20</span>
          </div>
          <div className="button">
            <button
              className={`btn btn-primary close ${newImageName.length > 0 ? "" : "disabled"}`}
              id=""
              type="button"
              onClick={() => {
                props.changeModal(Modals.NONE);
                props.onSubmitName(newImageName);
              }}
            >
              登録する
            </button>
          </div>
        </React.Fragment>
      }
    >
      <h4 className="text_bold mb20">{Strings.TITLE_INPUT}</h4>
    </Modal>
  );
}

const mapStateToProps = ({ app }: IRootState) => {
  const { currentModal } = app;
  return {
    currentModal,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
  };
};

type MedicalPopupType = any & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(NewImageNamePopup);
