import React from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { FileTypes } from "src/helpers";
import { Assets } from "src/utils/assets";
import { uploadFileToS3 } from "src/utils/utils";
import { Label } from "../label";
import FileManagementPopup from "../popup/FileManagementPopup/FileManagementPopup";
import SelectFileOptionPopup from "../popup/SelectFileOptionPopup";

interface IImgWrapperProps {
  index?: number;
  id?: string;
  inputClassName?: string;
  item?: any;
  hadImage?: boolean;
  imgURL?: string;
  className?: string;
  label: string;
  readonly?: boolean;
  fileAccept?: FileTypes;
  onClick?: (e?: any) => void;
  onDoubleClick?: () => void;
  onDelete?: () => void;
  onChange: (value: any, e?: React.ChangeEvent<HTMLInputElement>, file?: any, url?: any, fileList?: any[]) => void;
  isVideo?: boolean;
  isDragAndDrop?: boolean;
  isSelected?: boolean;
  multiple?: boolean;
  isFileDroppable?: boolean;
}

interface IImgWrapperState {
  isShowSelectFilePopup: boolean;
  isShowFileManagementPopup: boolean;
}

class ImgWrapper extends React.Component<IImgWrapperProps, IImgWrapperState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isShowSelectFilePopup: false,
      isShowFileManagementPopup: false,
    };
  }

  handleImageClick = (event: any) => {
    if (event.detail === 1) this.props.onClick && this.props.onClick();
    if (event.detail === 2) this.props.onDoubleClick && this.props.onDoubleClick();
  };

  handleShowSelectFilePopup = (isShow: boolean) => {
    this.setState({ isShowSelectFilePopup: isShow });
  };

  handleShowFileManagementPopup = (isShow: boolean) => {
    this.setState({ isShowFileManagementPopup: isShow });
  };

  overrideEventDefaults = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  onDrop = async (event: any) => {
    this.overrideEventDefaults(event);

    if (!this.props.isFileDroppable) return;
    if (!event.dataTransfer) return;

    const fileListUpload: any[] = [];

    for await (const file of event.dataTransfer.files) {
      const result = await uploadFileToS3(file);

      file.location = result.location;
      fileListUpload.push(file);
    }

    this.props.onChange(undefined, event, undefined, undefined, fileListUpload);
  };

  render() {
    const fileExtension = this.props.imgURL?.split(".").pop()!;
    const isVideo = ["mp4", "mov", "ogg", "webm", "avi"].includes(fileExtension);

    return (
      <>
        <Droppable droppableId={this.props.item?.name ?? "null"}>
          {(provided) => (
            <div
              ref={provided.innerRef}
              onClick={this.handleImageClick}
              className={this.props.className || "col-lg-3 col-4 img-wrapper"}
              onDragEnter={this.overrideEventDefaults}
              onDragLeave={this.overrideEventDefaults}
              onDragOver={this.overrideEventDefaults}
              onDrop={this.onDrop}
              {...provided.droppableProps}
            >
              <figure
                className="image-container"
                style={{ background: this.props.imgURL ? "" : `url(${Assets.medicalImage}) center no-repeat` }}
              >
                {this.props.isSelected && <img className="select-box" src={Assets.boxActive} alt="" width={20} />}
                {this.props.hadImage && !this.props.readonly ? (
                  <div
                    className="delete-btn"
                    onClick={() => {
                      if (this.props.onDelete) {
                        this.props.onDelete();
                      }
                    }}
                  >
                    <img className="close-icon" src={Assets.close} alt="" />
                  </div>
                ) : undefined}
                {provided.placeholder}
                {this.props.imgURL ? (
                  <Draggable
                    draggableId={this.props.item?.name ?? "null"}
                    index={this.props.index! ?? 0}
                    isDragDisabled={!this.props.isDragAndDrop}
                    disableInteractiveElementBlocking
                  >
                    {(provided) => (
                      <div
                        className="images"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {isVideo ? (
                          <video controls>
                            <source src={this.props.imgURL} />
                          </video>
                        ) : (
                          <img src={this.props.imgURL} alt={this.props.label} />
                        )}
                      </div>
                    )}
                  </Draggable>
                ) : null}
              </figure>
              <Label
                className="btn btn-outline-primary"
                text={this.props.label}
                onClick={() => !this.props.readonly && this.handleShowSelectFilePopup(true)}
              />
            </div>
          )}
        </Droppable>
        {this.state.isShowSelectFilePopup && (
          <SelectFileOptionPopup
            isShow={this.state.isShowSelectFilePopup}
            handleShow={this.handleShowSelectFilePopup}
            handleShowFileManagementPopup={this.handleShowFileManagementPopup}
            id={this.props.id}
            inputClassName={this.props.inputClassName}
            fileAccept={this.props.fileAccept}
            onChange={(_, event, ___, ____, fileList) => {
              this.handleShowSelectFilePopup(false);

              this.props.onChange(undefined, event, undefined, undefined, fileList!);
            }}
            multiple={this.props.multiple}
          />
        )}
        {this.state.isShowFileManagementPopup && (
          <FileManagementPopup
            isShow={this.state.isShowFileManagementPopup}
            handleShow={this.handleShowFileManagementPopup}
            fileAccept={this.props.fileAccept}
            onChange={async (_: any, event: any, ___: any, ____: any, fileList?: any[]) => {
              this.handleShowFileManagementPopup(false);

              const fileListUpload: any[] = [];

              for await (const file of fileList!) {
                file.location = file.url;

                fileListUpload.push(file);
              }

              this.props.onChange && this.props.onChange(undefined, event, undefined, undefined, fileListUpload);
              this.handleShowSelectFilePopup(false);
            }}
            multiple={this.props.multiple}
          />
        )}
      </>
    );
  }
}

export default ImgWrapper;
