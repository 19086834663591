import { Either, LeftSide, RightSide } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { MFile } from "src/features/medicalRecord/data/models/file";
import { IMedicalRecord } from "src/features/medicalRecord/data/models/medicalRecord";
import { Pagination } from "src/shared/model/pagination";
import { IRemoteFileDownloadRemoteDataSource } from "../datasources/remoteFilesDownloadRemoteDataSource";

export interface IRemoteFileDownloadRepository {
    fetchAllRemoteFiles(page: number): Promise<Either<Failure, Pagination<MFile>>>;
    deleteRemoteFile(id: string): Promise<Either<Failure, boolean>>;
    linkSelectedPatient(patientId: any): Promise<Either<Failure, IMedicalRecord | undefined>>
}

export class RemoteFileDownloadRepository implements IRemoteFileDownloadRepository {
    plyDownloadRemoteDataSource: IRemoteFileDownloadRemoteDataSource
    constructor(remoteDataSource: IRemoteFileDownloadRemoteDataSource) {
        this.plyDownloadRemoteDataSource = remoteDataSource
    }

    async fetchAllRemoteFiles(page: number): Promise<Either<Failure, Pagination<MFile>>> {
        try {
            return RightSide(await this.plyDownloadRemoteDataSource.fetchAllRemoteFiles(page));
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e });
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message });
            }
        }
        return LeftSide(new Error(""));
    }

    async deleteRemoteFile(id: string): Promise<Either<Failure, boolean>> {
        try {
            return RightSide(await this.plyDownloadRemoteDataSource.deleteRemoteFile(id));
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e });
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message });
            }
        }
        return LeftSide(new Error(""));
    }

    async linkSelectedPatient(patientId: any): Promise<Either<Failure, IMedicalRecord | undefined>> {
        try {
            return RightSide(await this.plyDownloadRemoteDataSource.linkSelectedPatient(patientId));
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e });
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message });
            }
        }
        return LeftSide(new Error(""));
    }

}