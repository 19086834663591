import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { IMedicalRecord } from "src/features/medicalRecord/data/models/medicalRecord";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers";
import { IRootState } from "src/store";
import { Assets } from "src/utils/assets";
import Modal from "../modal/modal";
import ImageCanvas from "./imageCanvas";

export interface ImagePopupProps {
  images?: any;
  onUpdateImageMedicalRecord: (medical: IMedicalRecord, imageFile: File, imageType: string) => void;
}

interface ImagePopupState {
  imageRotate: number;
  imageScaleY: number;
  imageScaleX: number;
  canvasArr: any[];
}

class ImagePopup extends React.Component<ImagePopupPopupType, ImagePopupState> {
  constructor(props: any) {
    super(props);

    this.state = {
      imageRotate: 0,
      imageScaleY: 1,
      imageScaleX: 1,
      canvasArr: [],
    };
  }

  handleNavImage = (nav: string) => {
    let imageRotate = this.state.imageRotate;
    let imageScaleY = this.state.imageScaleY;
    let imageScaleX = this.state.imageScaleX;

    switch (nav) {
      case "rotateRight90":
        imageRotate = this.state.imageRotate + 90 === 360 ? 0 : this.state.imageRotate + 90;

        this.setState({ imageRotate });
        break;
      case "rotateLeft90":
        imageRotate = this.state.imageRotate - 90 === -360 ? 0 : this.state.imageRotate - 90;

        this.setState({ imageRotate });
        break;
      case "scaleY":
        imageScaleY = this.state.imageScaleY === 1 ? -1 : 1;

        this.setState({ imageScaleY });
        break;
      case "scaleX":
        imageScaleX = this.state.imageScaleX === 1 ? -1 : 1;

        this.setState({ imageScaleX });
        break;
      default:
        this.setState({
          imageRotate: 0,
          imageScaleY: 1,
          imageScaleX: 1,
        });
    }
  };

  dataURLtoFile = (dataurl: string, filename: string) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)![1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  handleClosePopup = async () => {
    if (this.state.imageRotate !== 0 || this.state.imageScaleX !== 1 || this.state.imageScaleY !== 1) {
      for await (const [index, image] of this.props.images.entries()) {
        const dataUrl = this.state.canvasArr[index]?.toDataURL("image/png", 1.0)!;

        const imageFile = this.dataURLtoFile(dataUrl, `${image.imageLabel}.png`);
        this.props.onUpdateImageMedicalRecord(this.props.selectedMedicalRecord, imageFile, image.imageType);
      }
    }

    this.props.changeModal(Modals.NONE);
  };

  render() {
    return (
      <Modal className="ModalImages" isShow onClose={this.handleClosePopup}>
        <h4 className="text_center" style={{ color: "rgb(2, 163, 175)" }}>
          画像を編集する
        </h4>
        <div className="image-container">
          <div className="image-navigation">
            <div className="nav-item" onClick={() => this.handleNavImage("rotateRight90")}>
              <img src={Assets.rotateRight} width={20} style={{ alignSelf: "center" }} alt="" />
              <p>右へ90度回転</p>
            </div>
            <div className="nav-item" onClick={() => this.handleNavImage("rotateLeft90")}>
              <img src={Assets.rotateLeft} width={20} style={{ alignSelf: "center" }} alt="" />
              <p>左へ90度回転</p>
            </div>
            <div
              className="nav-item"
              onClick={() => this.handleNavImage("scaleY")}
              style={{
                color: this.state.imageScaleY === -1 ? "white" : "black",
                backgroundColor: this.state.imageScaleY === -1 ? "#02a3af" : "white",
              }}
            >
              <img src={Assets.flipVertical} width={20} style={{ alignSelf: "center" }} alt="" />
              <p>上下反転</p>
            </div>
            <div
              className="nav-item"
              onClick={() => this.handleNavImage("scaleX")}
              style={{
                color: this.state.imageScaleX === -1 ? "white" : "black",
                backgroundColor: this.state.imageScaleX === -1 ? "#02a3af" : "white",
              }}
            >
              <img src={Assets.flipHorizontal} width={20} style={{ alignSelf: "center" }} alt="" />
              <p>左右反転</p>
            </div>
          </div>
          {this.props.images.map((image: any, index: number) => (
            <ImageCanvas
              key={index}
              image={image}
              imageRotate={this.state.imageRotate}
              imageScaleY={this.state.imageScaleY}
              imageScaleX={this.state.imageScaleX}
              handleMountCanvas={(canvas) => {
                const { canvasArr } = this.state;
                canvasArr[index] = canvas;

                this.setState({ canvasArr });
              }}
            />
          ))}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ app, medicalRecord }: IRootState) => {
  const { currentModal } = app;
  const { selectedMedicalRecord } = medicalRecord;
  return {
    currentModal,
    selectedMedicalRecord,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
  };
};

type ImagePopupPopupType = ImagePopupProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(ImagePopup);
