import React from "react";

interface ITableCeil {
  html: String | React.ReactNode;
  colSpan?: number;
  rowSpan?: number;
  className?: string;
}

interface ITableRow {
  fields: Array<ITableCeil>;
  isHiden?: boolean;
}

interface ITableProps {
  headers?: Array<ITableRow>;
  footers?: Array<ITableRow>;
  rows: Array<ITableRow>;
  className?: string;
  subClassName?: string;
  isLoading?: boolean;
}

export class Table extends React.Component<ITableProps> {
  render() {
    return (
      <div className={this.props.className ?? ""}>
        <table className={this.props.subClassName ?? ""}>
          {this.props.headers && (
            <thead>
              {this.props.headers.map((row, idx) => (
                <tr key={idx}>
                  {row.fields.map((field, idx) => (
                    <td
                      key={idx}
                      colSpan={field.colSpan ?? 1}
                      rowSpan={field.rowSpan ?? 1}
                      className={field.className ?? ""}
                    >
                      {field.html}
                    </td>
                  ))}
                </tr>
              ))}
            </thead>
          )}
          {this.props.isLoading ? null : (
            <tbody className="animated-item">
              {this.props.rows
                .filter((row) => !row.isHiden)
                .map((row, idx) => (
                  <tr key={idx}>
                    {row.fields.map((field, idx) => (
                      <td
                        key={idx}
                        colSpan={field.colSpan ?? 1}
                        rowSpan={field.rowSpan ?? 1}
                        className={field.className ?? ""}
                      >
                        {field.html}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          )}
          {this.props.footers && (
            <tfoot>
              {this.props.footers.map((row, idx) => (
                <tr key={idx}>
                  {row.fields.map((field, idx) => (
                    <td
                      key={idx}
                      colSpan={field.colSpan ?? 1}
                      rowSpan={field.rowSpan ?? 1}
                      className={field.className ?? ""}
                    >
                      {field.html}
                    </td>
                  ))}
                </tr>
              ))}
            </tfoot>
          )}
        </table>
      </div>
    );
  }
}
