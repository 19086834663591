import { PatientState } from "src/base/Enums";
import { defaultUser } from "src/features/account/data/models/user";
import { paginationInit } from "src/shared/model/pagination";
import { IPatientState, PatientActions, PatientActionType } from "./types";

const initialState: IPatientState = {
    patients: paginationInit,
    selectedPatient: defaultUser,
    patientState: PatientState.none
}

export function patientReducer(state: IPatientState = initialState, action: PatientActions) {
    switch (action.type) {
        case PatientActionType.PATIENTS_FETCHED:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    ...action.payload.patients
                }
            }
        case PatientActionType.PATIENT_UPDATED:
            let patients = { ...state.patients }
            let updatedIndex = patients.data.map((patient) => patient.id).indexOf(action.payload.patient.id)
            if (updatedIndex > -1) {
                patients.data[updatedIndex] = { ...action.payload.patient }
            }
            return {
                ...state,
                patients: {
                    ...state.patients,
                    ...patients
                }
            }
        case PatientActionType.PATIENTS_PAGINATION_UPDATED:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.payload.key]: action.payload.value
                }
            }
        case PatientActionType.PATIENT_STATE_CHANGED:
            return {
                ...state,
                patientState: action.payload.patientState
            }
        case PatientActionType.SELECTED_PATIENT_DATA_UPDATED:
            return {
                ...state,
                selectedPatient: {
                    ...state.selectedPatient,
                    [action.payload.key]: action.payload.value
                }
            }
        case PatientActionType.SELECTED_PATIENT_UPDATED:
            return {
                ...state,
                selectedPatient: {
                    ...state.selectedPatient,
                    ...action.payload.patient
                }
            }
        case PatientActionType.SELECTED_PATIENT:
            return {
                ...state,
                selectedPatient: {
                    ...state.selectedPatient,
                    ...action.payload.patient
                }
            }
        default: return state
    }
}