import { Dispatch } from "redux";
import { isRight, match } from "src/base/Either";
import { FileManagementState } from "src/base/Enums";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers";
import { handleError } from "src/helpers/errorHandler";
import { IFile } from "../../data/models/file";
import { IFolder } from "../../data/models/folder";
import {
  CreateFolderFileManagement,
  CreateFolderFileManagementParams,
} from "../../domain/usecases/createFolderFileManagement";
import { DeleteFileManagement, DeleteFileManagementParams } from "../../domain/usecases/deleteFileManagement";
import {
  DownloadFilesFileManagement,
  DownloadFilesFileManagementParams,
} from "../../domain/usecases/downloadFilesFileManagement";
import {
  DownloadFolderFileManagement,
  DownloadFolderFileManagementParams,
} from "../../domain/usecases/downloadFolderFileManagement";
import { FetchFileManagement, FetchFileManagementParams } from "../../domain/usecases/fetchFileManagement";
import { ImportFileManagement, ImportFileManagementParams } from "../../domain/usecases/importFileManagement";
import {
  FetchFolderToMoveFileManagementParams,
  MoveIntoFolderFileManagement,
  MoveIntoFolderFileManagementParams,
} from "../../domain/usecases/moveIntoFolderFileManagement.ts";
import { RenameFileManagement, RenameFileManagementParams } from "../../domain/usecases/renameFileManagement";
import {
  createFolderFileManagement,
  deleteFileManagement,
  fetchFolder,
  fetchFolderToMove,
  importFileManagement,
  importFileManagementCompleted,
  moveFileManagement,
  renameFileManagement,
  stateChanged,
} from "../store";

export class RemoteFileManagementContainer {
  fetchFileManagement: FetchFileManagement;
  createFolderFileManagement: CreateFolderFileManagement;
  importFileManagement: ImportFileManagement;
  downloadFilesFileManagement: DownloadFilesFileManagement;
  downloadFolderFileManagement: DownloadFolderFileManagement;
  moveIntoFolderFileManagement: MoveIntoFolderFileManagement;
  renameFileManagement: RenameFileManagement;
  deleteFileManagement: DeleteFileManagement;

  constructor(
    fetchFileManagement: FetchFileManagement,
    createFolderFileManagement: CreateFolderFileManagement,
    importFileManagement: ImportFileManagement,
    downloadFilesFileManagement: DownloadFilesFileManagement,
    downloadFolderFileManagement: DownloadFolderFileManagement,
    moveIntoFolderFileManagement: MoveIntoFolderFileManagement,
    renameFileManagement: RenameFileManagement,
    deleteFileManagement: DeleteFileManagement
  ) {
    this.fetchFileManagement = fetchFileManagement;
    this.createFolderFileManagement = createFolderFileManagement;
    this.importFileManagement = importFileManagement;
    this.downloadFilesFileManagement = downloadFilesFileManagement;
    this.downloadFolderFileManagement = downloadFolderFileManagement;
    this.moveIntoFolderFileManagement = moveIntoFolderFileManagement;
    this.renameFileManagement = renameFileManagement;
    this.deleteFileManagement = deleteFileManagement;
  }

  async onFetchFileManagement(dispatch: Dispatch, breadcrumbPath: string, search?: string, only?: string) {
    dispatch(stateChanged(FileManagementState.fetchFileManagement));

    let either = await this.fetchFileManagement.call(new FetchFileManagementParams(breadcrumbPath, search, only));

    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (value) => {
        dispatch(fetchFolder(value));
      }
    );

    dispatch(stateChanged(FileManagementState.none));
  }

  async onCreateFolder(dispatch: Dispatch, breadcrumbPath: string, name: string) {
    dispatch(stateChanged(FileManagementState.submitButton));

    let either = await this.createFolderFileManagement.call(new CreateFolderFileManagementParams(breadcrumbPath, name));

    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        dispatch(createFolderFileManagement());
      }
    );

    dispatch(stateChanged(FileManagementState.none));
  }

  async onImport(dispatch: Dispatch, breadcrumbPath: string, files: File[], action?: string) {
    dispatch(stateChanged(FileManagementState.fetchFileManagement));
    dispatch(importFileManagement());

    await this.importFileManagement.call(new ImportFileManagementParams(breadcrumbPath, files, action));

    dispatch(importFileManagementCompleted());
    dispatch(changeModal(Modals.NONE));
    dispatch(stateChanged(FileManagementState.none));
  }

  async onDownloadFiles(dispatch: Dispatch, breadcrumbPath: string, files: IFile[]) {
    dispatch(stateChanged(FileManagementState.fetchFileManagement));

    let either = await this.downloadFilesFileManagement.call(
      new DownloadFilesFileManagementParams(breadcrumbPath, files)
    );

    if (isRight(either)) {
      dispatch(stateChanged(FileManagementState.none));
      return either.value;
    }
  }

  async onDownloadFolder(dispatch: Dispatch, breadcrumbPath: string, folder: IFolder) {
    dispatch(stateChanged(FileManagementState.fetchFileManagement));

    let either = await this.downloadFolderFileManagement.call(
      new DownloadFolderFileManagementParams(breadcrumbPath, folder)
    );

    if (isRight(either)) {
      dispatch(stateChanged(FileManagementState.none));
      return either.value;
    }
  }

  async onFetchFolderToMove(dispatch: Dispatch, breadcrumbPath: string, search?: string, only?: string) {
    dispatch(stateChanged(FileManagementState.fetchFileManagement));

    let either = await this.moveIntoFolderFileManagement.callFetchFolderToMove(
      new FetchFolderToMoveFileManagementParams(breadcrumbPath, search, only)
    );

    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (value) => {
        dispatch(fetchFolderToMove(value));
      }
    );

    dispatch(stateChanged(FileManagementState.none));
  }

  async onMove(dispatch: Dispatch, oldPath: string, newPath: string, itemToMove?: IFolder | IFile | IFile[]) {
    dispatch(stateChanged(FileManagementState.fetchFileManagement));

    let either = await this.moveIntoFolderFileManagement.call(
      new MoveIntoFolderFileManagementParams(oldPath, newPath, itemToMove)
    );

    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        dispatch(moveFileManagement());
      }
    );

    dispatch(stateChanged(FileManagementState.none));
  }

  async onRename(dispatch: Dispatch, itemSelected: IFolder | IFile, rename: string) {
    dispatch(stateChanged(FileManagementState.fetchFileManagement));

    let either = await this.renameFileManagement.call(new RenameFileManagementParams(itemSelected, rename));

    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        dispatch(renameFileManagement());
      }
    );

    dispatch(stateChanged(FileManagementState.none));
  }

  async onDelete(dispatch: Dispatch, breadcrumbPath: string, itemToDelete?: IFolder | IFile | IFile[]) {
    dispatch(stateChanged(FileManagementState.fetchFileManagement));

    let either = await this.deleteFileManagement.call(new DeleteFileManagementParams(breadcrumbPath, itemToDelete));

    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        dispatch(deleteFileManagement());
      }
    );

    dispatch(stateChanged(FileManagementState.none));
  }
}
