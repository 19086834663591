import { Dispatch } from "redux";
import { threeDViewerUpdateCameraPosition, threeDViewerUploadMeshFiles } from "../store/threeDViewer";

export class ThreeDViewerContainer {
  onChangeMeshFiles(dispatch: Dispatch, inputMeshFiles: Map<string, File>) {
    let finalMeshFiles: Map<string, File> = new Map(inputMeshFiles);

    dispatch(threeDViewerUploadMeshFiles(finalMeshFiles))
  }
  
  onUpdateCameraPosition(dispatch: Dispatch, cameraPosition: [x: number, y: number, z: number], type: string) {
    dispatch(threeDViewerUpdateCameraPosition(cameraPosition, type))
  }
}
