import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { match } from "src/base/Either";
import { AccountState } from "src/base/Enums";
import { Injector } from "src/base/Injector";
import { NoParam } from "src/base/NoParam";
import { Strings } from "src/constants";
import { AuthContainer } from "src/features/auth/presenter/container/authContainer";
import { history, Routes } from "src/helpers";
import { handleError } from "src/helpers/errorHandler";
import { IUser } from "../../data/models/user";
import { GetMe } from "../../domain/usecases/getMe";
import {
  UpdateAccount,
  UpdateAccountParams,
} from "../../domain/usecases/updateAccount";
import { accountChanged, accountStateChanged } from "../store";

export class AccountContainer {
  getMe: GetMe;
  updateAccount: UpdateAccount;

  constructor(getMe: GetMe, updateAccount: UpdateAccount) {
    this.getMe = getMe;
    this.updateAccount = updateAccount;
  }

  async onGetMe(dispatch: Dispatch, onSuccess?: (me: IUser) => void) {
    dispatch(accountStateChanged(AccountState.fetchingAccount));
    let either = await this.getMe.call(new NoParam());
    match(
      either,
      (_) => {
        handleError(Strings.ACCESS_TOKEN_IS_EXPIRED);
        Injector.get().find<AuthContainer>("AuthContainer").loggedOut(dispatch);
      },
      (account) => {
        if (onSuccess) {
          onSuccess(account);
        }
        dispatch(accountChanged(account));
      }
    );
    dispatch(accountStateChanged(AccountState.none));
  }

  async onUpdateAccount(dispatch: Dispatch, newMe: IUser) {
    dispatch(accountStateChanged(AccountState.updatingAccount));
    let either = await this.updateAccount.call(
      new UpdateAccountParams(newMe.username, newMe.full_name, newMe.password)
    );
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        newMe.password = "";
        dispatch(accountChanged(newMe));
        toast.success(Strings.UPDATE_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        history.replace(Routes.ACCOUNT_MANAGEMENT)
      }
    );
    dispatch(accountStateChanged(AccountState.none));
  }
}
