import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { isRight, match } from "src/base/Either";
import { SubscriptionState } from "src/base/Enums";
import { NoParam } from "src/base/NoParam";
import { Strings } from "src/constants";
import { accountDataChanged } from "src/features/account/presenter/store";
import { history, Routes } from "src/helpers";
import { handleError } from "src/helpers/errorHandler";
import { dismissPopupById, sleep } from "src/utils/utils";
import { ICard } from "../../domain/model/card";
import { AddCard, AddCardParams } from "../../domain/usecases/addCard";
import { AddPlan, AddPlanParams } from "../../domain/usecases/addPlan";
import { CancelPlan } from "../../domain/usecases/cancelPlan";
import { ChangePlan, ChangePlanParams } from "../../domain/usecases/changePlan";
import { DeleteCard, DeleteCardParams } from "../../domain/usecases/deleteCard";
import { GetAllCards } from "../../domain/usecases/getAllCards";
import { GetAllPlans } from "../../domain/usecases/getAllPlans";
import { UpdateCard, UpdateCardParams } from "../../domain/usecases/updateCard";
import {
  allPlansUpdated,
  cardsFetched,
  currentPlanChanged,
  subscriptionStateChanged,
} from "../store";

export class SubscriptionContainer {
  changePlan: ChangePlan;
  addPlan: AddPlan;
  cancelPlan: CancelPlan;
  fetchAllPlans: GetAllPlans;
  addCard: AddCard;
  getAllCards: GetAllCards;
  updateCard: UpdateCard
  deleteCard: DeleteCard
  constructor(
    changePlan: ChangePlan,
    addPlan: AddPlan,
    cancelPlan: CancelPlan,
    getAllPlans: GetAllPlans,
    addCard: AddCard,
    getAllCards: GetAllCards,
    updateCard: UpdateCard,
    deleteCard: DeleteCard
  ) {
    this.cancelPlan = cancelPlan;
    this.changePlan = changePlan;
    this.addPlan = addPlan;
    this.fetchAllPlans = getAllPlans;
    this.addCard = addCard;
    this.getAllCards = getAllCards;
    this.updateCard = updateCard
    this.deleteCard = deleteCard
  }

  async onFetchAllPlans(dispatch: Dispatch) {
    dispatch(subscriptionStateChanged(SubscriptionState.fetchingPlans));
    let either = await this.fetchAllPlans.call(new NoParam());
    if (isRight(either)) {
      dispatch(allPlansUpdated([...either.value]));
    }
    dispatch(subscriptionStateChanged(SubscriptionState.none));
  }

  async onFetchAllCards(dispatch: Dispatch) {
    dispatch(subscriptionStateChanged(SubscriptionState.fetchingCards));
    let either = await this.getAllCards.call(new NoParam());
    if (isRight(either)) {
      dispatch(cardsFetched([...either.value]));
    }
    dispatch(subscriptionStateChanged(SubscriptionState.none));
  }

  async onAddPlan(dispatch: Dispatch, newPlanId: string, cardId: string) {
    dispatch(subscriptionStateChanged(SubscriptionState.addingSubscription));
    let either = await this.addPlan.call(new AddPlanParams(newPlanId, cardId));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        history.replace(Routes.SUBSCRIPTION_MANAGEMENT)
        dispatch(accountDataChanged("is_block", 0));
        dispatch(currentPlanChanged());
        toast.success(Strings.UPDATE_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
    dispatch(subscriptionStateChanged(SubscriptionState.none));
  }

  async onChangePlan(dispatch: Dispatch, newPlanId: string, cardId: string) {
    dispatch(subscriptionStateChanged(SubscriptionState.changingSubscription));
    let either = await this.changePlan.call(
      new ChangePlanParams(newPlanId, cardId)
    );
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        history.replace(Routes.SUBSCRIPTION_MANAGEMENT)
        dispatch(accountDataChanged("is_block", 0));
        dispatch(currentPlanChanged());
        toast.success(Strings.UPDATE_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
    dispatch(subscriptionStateChanged(SubscriptionState.none));
  }

  async onCancelPlan(dispatch: Dispatch) {
    dispatch(
      subscriptionStateChanged(SubscriptionState.cancellingSubscription)
    );
    let either = await this.cancelPlan.call(new NoParam());
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        toast.success(Strings.CANCELL_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        sleep(1000).then(() => {
          // dispatch(accountDataChanged("is_block", 1));
          history.replace(Routes.SUBSCRIPTION_MANAGEMENT);
        });
      }
    );
    dispatch(subscriptionStateChanged(SubscriptionState.none));
  }

  async onAddCard(dispatch: Dispatch, card: ICard) {
    dispatch(subscriptionStateChanged(SubscriptionState.addingCard));
    let either = await this.addCard.call(new AddCardParams(card));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        this.onFetchAllCards(dispatch);
        dismissPopupById("payment-infor");
        toast.success(Strings.ADD_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
    dispatch(subscriptionStateChanged(SubscriptionState.none));
  }

  async onUpdateCard(dispatch: Dispatch, card: ICard) {
    dispatch(subscriptionStateChanged(SubscriptionState.updatingCard));
    let either = await this.updateCard.call(new UpdateCardParams(card));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        this.onFetchAllCards(dispatch);
        dismissPopupById("payment-infor");
        toast.success(Strings.UPDATE_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
    dispatch(subscriptionStateChanged(SubscriptionState.none));

  }

  async onDeleteCard(dispatch: Dispatch, cardId: string) {
    dispatch(subscriptionStateChanged(SubscriptionState.deletingCard));
    let either = await this.deleteCard.call(new DeleteCardParams(cardId));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        this.onFetchAllCards(dispatch);
        dismissPopupById("payment-infor");
        toast.success(Strings.DELETE_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
      }
    );
    dispatch(subscriptionStateChanged(SubscriptionState.none));
  }
}
