import { Either, LeftSide, RightSide } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { ICard } from "../../domain/model/card";
import { IPlan } from "../../domain/model/plan";
import { ISubscriptionRemoteDataSource } from "../datasources/subscriptionRemoteDataSource";

export interface ISubscriptionRepository {
  getAllPlans(): Promise<Either<Failure, Array<IPlan>>>;
  createPlan(id: string, card_id: string): Promise<Either<Failure, boolean>>;
  changePlan(id: string, card_id: string): Promise<Either<Failure, boolean>>;
  cancelPlan(): Promise<Either<Failure, boolean>>;
  getAllCards(): Promise<Either<Failure, ICard[]>>;
  addCard(card: ICard): Promise<Either<Failure, string>>;
  updateCard(card: ICard): Promise<Either<Failure, string>>;
  deleteCard(cardId: string): Promise<Either<Failure, boolean>>;
}

export class SubscriptionRepository implements ISubscriptionRepository {
  subscriptionRemoteDataSource: ISubscriptionRemoteDataSource;
  constructor(remoteDataSource: ISubscriptionRemoteDataSource) {
    this.subscriptionRemoteDataSource = remoteDataSource;
  }

  async getAllPlans(): Promise<Either<Failure, Array<any>>> {
    try {
      return RightSide(await this.subscriptionRemoteDataSource.getAllPlans());
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async changePlan(
    id: string,
    card_id: string
  ): Promise<Either<Failure, boolean>> {
    try {
      return RightSide(
        await this.subscriptionRemoteDataSource.changePlan(id, card_id)
      );
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async updateCard(card: ICard): Promise<Either<Failure, string>> {
    try {
      return RightSide(await this.subscriptionRemoteDataSource.updateCard(card));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async deleteCard(cardId: string): Promise<Either<Failure, boolean>> {
    try {
      return RightSide(await this.subscriptionRemoteDataSource.deleteCard(cardId));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }


  async cancelPlan(): Promise<Either<Failure, boolean>> {
    try {
      return RightSide(await this.subscriptionRemoteDataSource.cancelPlan());
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async getAllCards(): Promise<Either<Failure, ICard[]>> {
    try {
      return RightSide(await this.subscriptionRemoteDataSource.getAllCards());
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async addCard(card: ICard): Promise<Either<Failure, string>> {
    try {
      return RightSide(await this.subscriptionRemoteDataSource.addCard(card));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async createPlan(
    id: string,
    card_id: string
  ): Promise<Either<Failure, boolean>> {
    try {
      return RightSide(
        await this.subscriptionRemoteDataSource.addPlan(id, card_id)
      );
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }
}
