import { useState } from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Strings } from "src/constants";
import { IUser } from "src/features/account/data/models/user";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers";
import { LoadingComponent } from "src/shared/components/loadingComponent";
import { Pagination } from "src/shared/model/pagination";
import { IRootState } from "src/store";
import { Assets } from "src/utils/assets";
import { InputGroup } from "../inputGroup";
import Modal from "../modal/modal";

export interface SelectPatientPopupProps {
  patients: Pagination<IUser>;
  onLink: (selectedPatient: IUser) => void;
  onSearchPatients: (name: string) => void;
  onLoadmorePatients: (name: string, page: number) => void;
  onSubmitting?: boolean;
  isShow: boolean;
}

export function SelectPatientPopup(props: SelectPatientPopupType) {
  const [selectedPatientId, changeSelectedPatientId] = useState("");
  const [searchedString, changeSearchedString] = useState("");
  const [searchString, changeSearchString] = useState("");

  const loadmoreButton = () => {
    if (props.patients.current_page === props.patients.last_page) return undefined;
    return (
      <LoadingComponent isLoading={props.patients.isFetching || props.patients.isLoadingMore}>
        <a
          className="btn btn-primary btn-main btn-loadmore"
          onClick={() => {
            props.onLoadmorePatients(searchString, props.patients.current_page + 1);
          }}
        >
          <img src={Assets.add} width={20} height={20} alt="" /> もっと読み込む
        </a>
      </LoadingComponent>
    );
  };
  const selectedPatient = props.patients.data.find((patient) => patient.id === selectedPatientId);

  return (
    <Modal
      dialogExtraClassName="modal-patient"
      isShow={props.isShow}
      onClose={() => {
        props.changeModal(Modals.NONE);
      }}
    >
      <div className="p modal-patient-body pt00">
        <h4 className="text_center text_bold">{Strings.LINK_PATIENT}</h4>
        <p className="text-red">
          患者登録が完了していない場合は、3Dデータとの紐づけができませんので、お先に登録をお願いいたします。
        </p>
        <InputGroup
          className="iprup2 iprup fsc"
          inputClassName="patient-input"
          inputPlaceHolder={Strings.PATIENT_NAME}
          buttonClassName={`btn-success ${searchString ? "" : "disabled gray-btn"}`}
          buttonType="submit"
          buttonText={Strings.SEARCH}
          value={searchString}
          onValueChanged={(e) => {
            if (!e.target.value) {
              props.onSearchPatients("");
              changeSearchedString("");
            }
            changeSearchString(e.target.value);
          }}
          onButtonClick={() => {
            props.onSearchPatients(searchString);
            changeSearchedString(searchString);
          }}
        ></InputGroup>
        {searchedString && !props.patients.isFetching && !props.patients.isLoadingMore && (
          <span className="mt10 text_bold">
            「{searchedString}」の検索結果{props.patients.total}件
          </span>
        )}
        <div className="patient-list mt20">
          {props.patients.data.map((patient) => {
            return (
              <div
                className={`item ${patient.id === selectedPatientId ? "active" : ""}`}
                onClick={() => {
                  changeSelectedPatientId(patient.id);
                }}
              >
                <span>{patient.full_name}</span>
              </div>
            );
          })}
          {loadmoreButton()}
        </div>
        <LoadingComponent isLoading={props.onSubmitting}>
          <a
            className={`btn btn-primary btn-main btn-link-patient mt20 ${
              props.patients.data.length > 0 && selectedPatientId && selectedPatient ? "" : "disabled gray-btn"
            }`}
            onClick={() => {
              props.onLink(selectedPatient!);
            }}
          >
            {Strings.LINK_NOW}
          </a>
        </LoadingComponent>
      </div>
    </Modal>
  );
}

const mapStateToProps = ({ app }: IRootState) => {
  const { currentModal } = app;
  return {
    currentModal,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
  };
};

type SelectPatientPopupType = SelectPatientPopupProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(SelectPatientPopup);
