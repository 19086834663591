export type Observer<E> = (event: E) => void;

export class EventDispatcher<E> {
  private observers: Map<string, Observer<E>> = new Map();

  fire(key: string, event: E) {
    if (!this.observers.has(key)) return;
    this.observers.get(key)!(event);
  }

  register(key: string, handler: Observer<E>) {
    this.observers.set(key, handler);
  }

  removeListener(key: string) {
    this.observers.delete(key);
  }

  clear() {
    this.observers.clear();
  }
}
