import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { PatientState } from "src/base/Enums";
import { Injector } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import { DatePickerCustom } from "src/components/datePicker/datePicker";
import { Input } from "src/components/input";
import { Label } from "src/components/label";
import { Table } from "src/components/table";
import { Strings } from "src/constants";
import { defaultUser, IUser } from "src/features/account/data/models/user";
import { PatientContainer } from "src/features/patient/presenter/container/patientContainer";
import { selectedPatientDataChanged, selectedPatientUpdated } from "src/features/patient/presenter/store";
import { history, Routes } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import { BackButton } from "src/shared/components/backButton";
import { NoDataWrapperComponent } from "src/shared/components/noDataWrapperComponent";
import { IRootState } from "src/store";
import { findParamsFromSearchString, findSubRouteFromPathName, formatDate } from "src/utils/utils";
var postal_code = require("japan-postal-code");

class PatientManagementDetailPage extends React.Component<PatientManagementDetailType> {
  componentDidMount() {
    let params = findParamsFromSearchString(window.location.search);
    let currentRoute = findSubRouteFromPathName(window.location.pathname);
    if (currentRoute !== Routes.PATIENT_MANAGEMENT_CREATE && params?.has("patientId") && params.get("patientId")) {
      this.props.fetchPatientDetails(params.get("patientId"));
    } else if (currentRoute === Routes.PATIENT_MANAGEMENT_CREATE) {
      this.props.patientChanged({ ...defaultUser, isNotFound: false, updateOnly: false });
    } else {
      this.props.patientChanged({ ...defaultUser, isNotFound: true });
    }
  }

  getPrefAreaData = (zip: string) => {
    let cleanZip = zip.replace("-", "");
    postal_code.get(cleanZip, (address: any) => {
      this.props.changePatientData("address", address.prefecture + address.city + address.area + address.street);
    });
  };

  buildTableBody = () => {
    return (
      <Table
        className="dataTable-container"
        subClassName="table table_detail"
        headers={[]}
        rows={[
          {
            fields: [
              { html: Strings.PATIENT_SPECIALCODE },
              {
                html: (
                  <Input
                    className="fsc"
                    inputClassName="form-control"
                    type="text"
                    maxLength={20}
                    pattern="\d*"
                    value={this.props.selectedPatient.special_code}
                    inputPlaceHolder={Strings.PATIENT_NUMBER_PLACEHOLDER}
                    onChange={(value) => this.props.changePatientData("special_code", value)}
                  ></Input>
                ),
              },
            ],
          },

          {
            fields: !this.props.selectedPatient.updateOnly
              ? []
              : [
                  { html: Strings.PATIENT_NUMBER },
                  {
                    html: (
                      <Input
                        className="fsc"
                        inputClassName="form-control"
                        type="text"
                        maxLength={4}
                        pattern="\d*"
                        readonly={this.props.selectedPatient.updateOnly}
                        value={this.props.selectedPatient.clinic?.medical_institution + this.props.selectedPatient.code}
                        inputPlaceHolder={Strings.PATIENT_NUMBER_PLACEHOLDER}
                        onChange={(value) => this.props.changePatientData("code", value)}
                      ></Input>
                    ),
                  },
                ],
          },

          {
            fields: [
              { html: Strings.NAME },
              {
                html: (
                  <Input
                    className="fsc"
                    inputClassName="form-control"
                    type="text"
                    value={this.props.selectedPatient.full_name}
                    maxLength={20}
                    inputPlaceHolder={Strings.NAME_PLACEHOLDER}
                    name="name"
                    onChange={(value) => this.props.changePatientData("full_name", value)}
                  ></Input>
                ),
              },
            ],
          },

          {
            fields: [
              { html: Strings.FURIGANA },
              {
                html: (
                  <Input
                    className="fsc"
                    inputClassName="form-control"
                    type="text"
                    maxLength={20}
                    inputPlaceHolder={Strings.FURIGINA_PLACEHOLDER}
                    value={this.props.selectedPatient.full_name_furigana}
                    name="furigana"
                    onChange={(value) => this.props.changePatientData("full_name_furigana", value)}
                  ></Input>
                ),
              },
            ],
          },

          {
            fields: [
              { html: Strings.BIRTHDAY },
              {
                html: (
                  <DatePickerCustom
                    currentDate={
                      new Date(
                        parseInt(this.props.selectedPatient.birthday?.split("-")[0] ?? "1"),
                        parseInt(this.props.selectedPatient.birthday?.split("-")[1] ?? "1") - 1,
                        parseInt(this.props.selectedPatient.birthday?.split("-")[2] ?? "1")
                      )
                    }
                    changeDate={(date) => this.props.changePatientData("birthday", formatDate(date))}
                  ></DatePickerCustom>
                ),
              },
            ],
          },

          {
            fields: [
              { html: Strings.GENDER },
              {
                html: (
                  <Table
                    headers={[]}
                    rows={[
                      {
                        fields: [
                          {
                            html: (
                              <Label htmlFor="inputmale" text={Strings.MALE} className="fsc mr30 cursor-pointer">
                                <Input
                                  id="inputmale"
                                  type="checkbox"
                                  className="fsc"
                                  inputClassName="mr05"
                                  value="male"
                                  name="gender"
                                  checked={this.props.selectedPatient.gender == "male"}
                                  onChange={(checked) => {
                                    if (checked) this.props.changePatientData("gender", "male");
                                  }}
                                ></Input>
                              </Label>
                            ),
                          },
                          {
                            html: (
                              <Label htmlFor="inputfemale" text={Strings.FEMALE} className="fsc cursor-pointer">
                                <Input
                                  id="inputfemale"
                                  type="checkbox"
                                  className="fsc"
                                  inputClassName="mr05"
                                  value="female"
                                  name="gender"
                                  checked={this.props.selectedPatient.gender == "female"}
                                  onChange={(checked) => {
                                    if (checked) this.props.changePatientData("gender", "female");
                                  }}
                                ></Input>
                              </Label>
                            ),
                          },
                        ],
                      },
                    ]}
                  ></Table>
                ),
              },
            ],
          },

          {
            fields: [
              {
                html: (
                  <div className="hassmalltext">
                    {Strings.ZIP_CODE}
                    <span>※ハイフンなしで入力ください</span>
                  </div>
                ),
              },
              {
                html: (
                  <div className="fsc zipcode">
                    {/* <Input
                    inputClassName="form-control"
                    className="inputzip"
                    type="text"
                    inputPlaceHolder={Strings.ZIP_CODE_PLACEHOLDER_PATIENT}
                    name="postcode"
                    maxLength={7}
                    value={this.props.selectedPatient.zipcode}
                    onChange={(value) => this.props.changePatientData("zipcode", value)}
                  ></Input> */}
                    <Input
                      inputClassName="form-control"
                      className="inputzip"
                      type="text"
                      inputPlaceHolder={Strings.ZIP_CODE_PLACEHOLDER_PATIENT}
                      name="postcode"
                      maxLength={7}
                      value={this.props.selectedPatient.zipcode}
                      onChange={(value) => this.props.changePatientData("zipcode", value)}
                    ></Input>
                    <MainButton
                      title="郵便番号検索"
                      enabled={true}
                      className={`btn btn-primary btn-fixwidth}`}
                      type="button"
                      onSubmit={() => {
                        this.getPrefAreaData(this.props.selectedPatient.zipcode);
                      }}
                    />
                  </div>
                ),
              },
            ],
          },

          {
            fields: [
              { html: Strings.ADDRESS },
              {
                html: (
                  <Input
                    inputClassName="form-control"
                    className="fsc"
                    type="text"
                    maxLength={50}
                    inputPlaceHolder={Strings.ADDRESS_PALCEHOLDER}
                    value={this.props.selectedPatient.address}
                    onChange={(value) => this.props.changePatientData("address", value)}
                    name="address"
                  ></Input>
                ),
              },
            ],
          },
          {
            fields: [
              { html: Strings.EMAIL_ADDRESS },
              {
                html: (
                  <Input
                    inputClassName="form-control"
                    className="fsc"
                    type="email"
                    maxLength={50}
                    inputPlaceHolder={Strings.EMAIL_PLACEHOLDER}
                    value={this.props.selectedPatient.email}
                    name="email"
                    onChange={(value) => this.props.changePatientData("email", value)}
                  ></Input>
                ),
              },
            ],
          },

          {
            fields: [
              { html: Strings.CAN_ANESTHESIA },
              {
                html: (
                  <Table
                    headers={[]}
                    rows={[
                      {
                        fields: [
                          {
                            html: (
                              <Label htmlFor="inputcan" text={Strings.CAN} className="fsc mr30 cursor-pointer">
                                <Input
                                  id="inputcan"
                                  type="checkbox"
                                  className="fsc"
                                  inputClassName="mr05"
                                  value={true}
                                  name="canAnesthesia"
                                  checked={this.props.selectedPatient.is_abnormalities === 1}
                                  onChange={(checked) => {
                                    if (checked) this.props.changePatientData("is_abnormalities", checked ? 1 : 0);
                                  }}
                                ></Input>
                              </Label>
                            ),
                          },
                          {
                            html: (
                              <Label htmlFor="inputcannot" text={Strings.CANNOT} className="fsc cursor-pointer">
                                <Input
                                  id="inputcannot"
                                  type="checkbox"
                                  className="fsc"
                                  inputClassName="mr05"
                                  value={false}
                                  name="canAnesthesia"
                                  checked={this.props.selectedPatient.is_abnormalities === 0}
                                  onChange={(checked) => {
                                    if (checked) this.props.changePatientData("is_abnormalities", checked ? 0 : 1);
                                  }}
                                ></Input>
                              </Label>
                            ),
                          },
                        ],
                      },
                    ]}
                  ></Table>
                ),
              },
            ],
          },

          {
            fields: [
              { html: Strings.PREGNANCY },
              {
                html: (
                  <Table
                    headers={[]}
                    rows={[
                      {
                        fields: [
                          {
                            html: (
                              <Label htmlFor="inputyes" text={Strings.YES} className="fsc mr30 cursor-pointer">
                                <Input
                                  id="inputyes"
                                  type="checkbox"
                                  className="fsc"
                                  inputClassName="mr05"
                                  value={true}
                                  name="pregnancy"
                                  checked={this.props.selectedPatient.is_pregnancy === 1}
                                  onChange={(checked) => {
                                    if (checked) this.props.changePatientData("is_pregnancy", checked ? 1 : 0);
                                  }}
                                ></Input>
                              </Label>
                            ),
                          },
                          {
                            html: (
                              <Label htmlFor="inputno" text={Strings.NO} className="fsc cursor-pointer">
                                <Input
                                  id="inputno"
                                  type="checkbox"
                                  className="fsc"
                                  inputClassName="mr05"
                                  value={false}
                                  name="pregnancy"
                                  checked={this.props.selectedPatient.is_pregnancy === 0}
                                  onChange={(checked) => {
                                    if (checked) this.props.changePatientData("is_pregnancy", !checked ? 1 : 0);
                                  }}
                                ></Input>
                              </Label>
                            ),
                          },
                        ],
                      },
                    ]}
                  ></Table>
                ),
              },
            ],
          },
        ]}
      ></Table>
    );
  };

  render() {
    return (
      <MainLayout
        isLoading={
          this.props.patientState === PatientState.addingPatient ||
          this.props.patientState === PatientState.updatingPatient ||
          this.props.patientState === PatientState.fetchingPatientDetails
        }
        className="secondpage navstate_show page-patient_management detail animate-item"
        subClassName="p-patient_management detail"
      >
        <div className="container-fluid">
          <div className="card">
            <div className="card-header flex-H">
              <BackButton
                onBackPressed={() => {
                  history.replace(Routes.PATIENT_MANAGEMENT);
                }}
              />
              {Strings.PATIENT_MANAGEMENT +
                Strings.LINE +
                (findSubRouteFromPathName(window.location.pathname) === Routes.PATIENT_MANAGEMENT_DETAIL
                  ? Strings.EDIT
                  : Strings.CREATE)}
            </div>
            <NoDataWrapperComponent
              isNoData={this.props.selectedPatient.isNotFound}
              noDataContent={<h3>{Strings.NO_DATA}</h3>}
            >
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (this.props.selectedPatient.updateOnly) {
                    this.props.onUpdatePatient(this.props.selectedPatient);
                  } else {
                    this.props.onAddPatient(this.props.selectedPatient);
                  }
                }}
              >
                <div className="card-body">
                  {this.buildTableBody()}
                  <div className="fec mt20 btn-group-end">
                    <MainButton
                      title={Strings.REGISTER}
                      submitting={
                        this.props.patientState === PatientState.addingPatient ||
                        this.props.patientState === PatientState.updatingPatient
                      }
                      enabled={
                        this.props.selectedPatient.special_code.length !== 0 &&
                        this.props.selectedPatient.full_name.length !== 0 &&
                        this.props.selectedPatient.full_name_furigana.length !== 0 &&
                        (this.props.selectedPatient.birthday ?? "").length !== 0 &&
                        this.props.selectedPatient.gender.length !== 0 &&
                        this.props.selectedPatient.address.length !== 0
                      }
                      className="btn btn-primary mr10"
                      type="button"
                      loadingSize={20}
                      isSubmitButton
                      onSubmit={() => {}}
                    />
                    <button
                      className="btn btn-danger"
                      onClick={() => {
                        history.replace(Routes.PATIENT_MANAGEMENT);
                      }}
                    >
                      {Strings.RETURN}
                    </button>
                  </div>
                </div>
              </form>
            </NoDataWrapperComponent>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ patient }: IRootState) => {
  const { patientState, selectedPatient } = patient;

  return {
    patientState,
    selectedPatient,
  };
};
const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    patientChanged: (patient: IUser) => dispatch(selectedPatientUpdated(patient)),
    fetchPatientDetails: (patientId: any) =>
      Injector.get().find<PatientContainer>("PatientContainer").onFetchPatientDetails(dispatch, patientId),
    changePatientData: (key: string, value: any) => dispatch(selectedPatientDataChanged(key, value)),
    onUpdatePatient: (user: IUser) =>
      Injector.get().find<PatientContainer>("PatientContainer").onUpdatePatient(dispatch, user),
    onAddPatient: (user: IUser) =>
      Injector.get().find<PatientContainer>("PatientContainer").onAddPatient(dispatch, user),
  };
};

type PatientManagementDetailType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(PatientManagementDetailPage);
