import { Pagination } from "src/shared/model/pagination";
import { action } from "typesafe-actions";
import { INotification, NotificationActionType } from "./types";

export function notificationFetchData(notifications: Pagination<INotification>) {
    return action(NotificationActionType.FETCH_NOTIFICATION, { notifications })
}

export function notificationStateChanged(state: boolean) {
    return action(NotificationActionType.NOTIFICATION_STATE_CHANGED, { state })
}

export function updateNotification(field: string, value: any) {
    return action(NotificationActionType.UPDATE_NOTIFICATION, { field, value })
}

export function chooseNotification(notification: INotification) {
    return action(NotificationActionType.CHOSSE_NOTIFICATION, { notification })
}

