import moment from "moment";
import { Endpoints } from "src/base/Endpoints";
import { HttpMethod } from "src/helpers";
import { IApiService } from "src/services/api/ApiService";
import { Pagination } from "src/shared/model/pagination";
import { INotification, mapNotification, notificationFromJson } from "../../presenter/store/notification";

export interface INotificationRemoteDataSource {
  fetchNotification(page: number): Promise<Pagination<INotification>>;
  fetchNotificationDetail(notificationId: string): Promise<INotification | undefined>;
  saveNotification(notification: INotification): Promise<INotification>;
  deleteNotification(notificationId: number): Promise<boolean>;
}

export class NotificationRemoteDataSource implements INotificationRemoteDataSource {
  apiService: IApiService;
  constructor(apiService: IApiService) {
    this.apiService = apiService;
  }

  async fetchNotification(page: number): Promise<Pagination<INotification>> {
    var response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.fetchNotification,
      shouldAuthorize: true,
      params: {
        page: page,
        limit: 10,
      },
    });
    return mapNotification(response.data.data);
  }

  async fetchNotificationDetail(notificationId: string): Promise<INotification | undefined> {
    var response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.notificationDetail(notificationId),
      shouldAuthorize: true,
    });
    if (
      !response.data ||
      !response.data.data ||
      !response.data.data.id ||
      response.data.data.id.toString() !== notificationId
    ) {
      return undefined;
    }
    return notificationFromJson(response.data.data);
  }

  async saveNotification(notification: INotification): Promise<INotification> {
    var data = new FormData();
    if (notification.activityDate.value) {
      data.append("activity_date", moment(notification.activityDate.value).format("YYYY/MM/DD HH:mm"));
    }

    if (notification.title) {
      data.append("title", notification.title.value);
    }

    if (notification.content) {
      data.append("content", notification.content.value);
    }

    if (notification.pdfName && notification.pdf) {
      data.append("pdfName", notification.pdfName.value.toString());
      data.append("pdf", notification.pdf.value.toString());
    }

    var response = await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: notification.id !== -1 ? Endpoints.updateNotification(notification.id) : Endpoints.addNotification,
      shouldAuthorize: true,
      body: {
        data: data,
      },
    });
    return response.data.data;
  }

  async deleteNotification(notificationId: number): Promise<boolean> {
    await this.apiService.request({
      method: HttpMethod.DELETE,
      endpoint: Endpoints.updateNotification(notificationId),
      shouldAuthorize: true,
    });
    return true;
  }
}
