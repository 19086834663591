import { Either, LeftSide, RightSide } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { IUser } from "src/features/account/data/models/user";
import { Pagination } from "src/shared/model/pagination";
import { IOrthodontistRemoteDataSource } from "../datasources/orthodontistDataSource";

export interface IOrthodontistRepository {
  fetchOrthodontists(
    code: string,
    full_name: string,
    orthodontist_type: number,
    specialStatus: number,
    page: number,
    status: number,
    limit?: number,
    action?: string
  ): Promise<Either<Failure, Pagination<IUser>>>;
  fetchOrthodontistDetails(id: any): Promise<Either<Failure, IUser | undefined>>;
  addOrthodontists(user: IUser): Promise<Either<Failure, boolean>>;
  updateOrthodontist(user: IUser): Promise<Either<Failure, boolean>>;
  deleteOrthodontist(id: string): Promise<Either<Failure, boolean>>;
  updateOrthodontistStatus(id: string): Promise<Either<Failure, boolean>>;
  sendMailToOrthodontist(id: string): Promise<Either<Failure, void>>
}

export class OrthodontistRepository implements IOrthodontistRepository {
  orthodontistRemoteDataSource: IOrthodontistRemoteDataSource;
  constructor(remoteDataSource: IOrthodontistRemoteDataSource) {
    this.orthodontistRemoteDataSource = remoteDataSource;
  }

  async sendMailToOrthodontist(id: string): Promise<Either<Failure, void>> {
    try {
      return RightSide(
        await this.orthodontistRemoteDataSource.sendMailToOrthodontist(id)
      );
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async fetchOrthodontists(
    code: string,
    full_name: string,
    orthodontist_type: number,
    specialStatus: number,
    page: number,
    status: number,
    limit?: number,
    action?: string
  ): Promise<Either<Failure, Pagination<IUser>>> {
    try {
      return RightSide(
        await this.orthodontistRemoteDataSource.fetchOrthodontists(
          code,
          full_name,
          orthodontist_type,
          specialStatus,
          page,
          status,
          limit,
          action
        )
      );
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async fetchOrthodontistDetails(id: any): Promise<Either<Failure, IUser | undefined>> {
    try {
      return RightSide(
        await this.orthodontistRemoteDataSource.fetchOrthodontistDetails(id)
      );
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async addOrthodontists(user: IUser): Promise<Either<Failure, boolean>> {
    try {
      return RightSide(
        await this.orthodontistRemoteDataSource.addOrthodontists(user)
      );
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async updateOrthodontist(user: IUser): Promise<Either<Failure, boolean>> {
    try {
      return RightSide(
        await this.orthodontistRemoteDataSource.updateOrthodontist(user)
      );
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async deleteOrthodontist(id: string): Promise<Either<Failure, boolean>> {
    try {
      return RightSide(
        await this.orthodontistRemoteDataSource.deleteOrthodontist(id)
      );
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async updateOrthodontistStatus(
    id: string
  ): Promise<Either<Failure, boolean>> {
    try {
      return RightSide(
        await this.orthodontistRemoteDataSource.updateOrthodontistStatus(id)
      );
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }
}
