import React from "react";
import { AverageCalculatorComponent } from "src/components/analytic/averageCalculator";
import { BaseChart } from "src/components/chart/chart";
import {
  IAnalyticDate,
  IAnalyticTotal,
} from "src/features/analytic/data/models/patientAnalytic";
import { Assets } from "src/utils/assets";

export interface AnalyticHeaderProps {
  id?: string
  totals: IAnalyticTotal[];
  dates: IAnalyticDate[];
  chart: React.ReactElement<BaseChart>;
  title: string;
  onChangeDates: (dates: IAnalyticDate[]) => void;
}

export abstract class AnalyticHeader<
  P = {},
  S = {},
  SS = any
  > extends React.Component<P & AnalyticHeaderProps, S, SS> {
  onDatesUpdated = (date: IAnalyticDate): void => {
    var dates = [...this.props.dates] as IAnalyticDate[];
    var updatedIndex = dates.map((date) => date.value).indexOf(date.value);
    if (updatedIndex > -1) {
      dates[updatedIndex] = { ...date };
      this.props.onChangeDates(dates);
    }
  };
  render(): React.ReactNode {
    return (
      <div className="tab-box" id={this.props.id}>
        <div className={`tab-box-item flexbox graph-style1`}>
          <div className="item">
            <div className="tab-title">
              <img src={Assets.analysis4} alt={this.props.title} />
              <span>{this.props.title}</span>
            </div>
            {this.props.chart}
          </div>
          <div className="item">
            <div className="top-box">
              <ul className="box-common desc-box">
                {[...this.props.dates].map((date) => {
                  return (
                    <li>
                      <div className="left">
                        <span
                          className="square"
                          style={{
                            background: date.color,
                          }}
                        ></span>
                        <span>{date.label}</span>
                      </div>
                      <div
                        className={`right btn btn-primary ${!date.shouldShowAnalytic ? "active" : ""
                          }`}
                        onClick={() => {
                          date.shouldShowAnalytic = !date.shouldShowAnalytic;
                          this.onDatesUpdated(date);
                        }}
                      >
                        <span>
                          {!date.shouldShowAnalytic ? "非表示" : "表示"}
                        </span>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="bottom-box">
              {this.props.totals.map((total) => {
                return (
                  <ul className="box-common average-box">
                    <li className="average-box-title">
                      <img src={Assets.analysis1} alt="平均スコア" />
                      <span>{total.label}</span>
                    </li>
                    <li className="average-box-ct">
                      <span>{total.value}</span>
                    </li>
                  </ul>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
