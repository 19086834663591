class Selector {

	constructor( editor ) {


		this.editor = editor;

		// signals


	}

	select( object ) {

		if ( this.editor.selected === object ) return;

		let uuid = null;

		if ( object !== null ) {

			uuid = object.uuid;

		}

		this.editor.selected = object;
		this.editor.config.setKey( 'selected', uuid );


	}

	deselect() {

		this.select( null );

	}

}

export { Selector };
