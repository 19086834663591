export enum AuthPage {
  login,
  register,
}

export enum HttpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  PATCH = "PATCH",
  DELETE = "DELETE",
}

export enum Routes {
  SPLASH = "/",
  LOGIN = "/login",
  FORGET_PASSWORD = "/forget-password",
  VERIFY_OTP = "/verify-otp",
  CHANGE_PASSWORD = "/change-password",
  TERM = "/term",
  CONTACT = "/contact",
  FAQ = "/faq",
  USER_GUIDE = "/userGuide",
  PRIVACY_POLICY = "/privacy-polocy",
  HOME = "/home",
  REGISTER = "/register",

  NOTICE_OPERATOR = "/home/notice",

  PATIENT = "/home/patient",
  PATIENT_MANAGEMENT = "/home/patient/main",
  PATIENT_MANAGEMENT_DETAIL = "/home/patient/edit",
  PATIENT_MANAGEMENT_CREATE = "/home/patient/create",
  PATIENT_MANAGEMENT_CSV_IMPORT = "/home/patient/csv",

  DENTIST = "/home/dentist",
  DENTIST_MANAGEMENT = "/home/dentist/main",
  DENTIST_MANAGEMENT_CREATE = "/home/dentist/add",
  DENTIST_MANAGEMENT_DETAIL = "/home/dentist/edit",

  MEDICAL = "/home/medical",
  MEDICAL_MANAGEMENT = "/home/medical/main",
  MEDICAL_MANAGEMENT_CREATE = "/home/medical/create",
  MEDICAL_MANAGEMENT_DETAIL = "/home/medical/edit",
  MEDICAL_CONFIRM_DETAIL = "/home/medical/confirm",

  PLY_DOWNLOAD_MANAGEMENT = "/home/files",

  FILE_MANAGEMENT = "/home/file-management",

  THREED_VIEWER_MANAGEMENT = "/home/3d-viewer-management",
  THREED_VIEWER_MATCHING = "/home/3d-viewer-matching",
  THREED_VIEWER_MATCHING2 = "/home/3d-viewer-matching2",

  NOTIFICATION = "/home/notification",
  NOTIFICATION_MANAGEMENT = "/home/notification/main",
  NOTIFICATION_MANAGEMENT_CREATE = "/home/notification/create",
  NOTIFICATION_MANAGEMENT_DETAIL = "/home/notification/edit",

  ACCOUNT = "/home/account",
  ACCOUNT_MANAGEMENT = "/home/account/main",
  ACCOUNT_MANAGEMENT_DETAIL = "/home/account/edit",

  PATIENT_ANALYTIC = "/home/analysis",
  PATIENT_ANALYTIC_MANAGEMENT = "/home/analysis/main",
  PATIENT_ANALYTIC_MANAGEMENT_DETAIL = "/home/analysis/detail",
  PATIENT_PDF_REVIEW_PAGE = "/home/analysis/pdf",

  SUBSCRIPTION = "/home/subscription",
  SUBSCRIPTION_MANAGEMENT = "/home/subscription/main",
  SUBSCRIPTION_PLAN_DETAIL = "/home/subscription/edit",
  SUBSCRIPTION_PLAN_CANCEL = "/home/subscription/cancel",
}

export enum Modals {
  NONE = "NONE",
  CSVOPTION = "CSVOPTION",
  REDIRECT_LINK = "REDIRECT_LINK",
  IMAGE_TYPE = "IMAGE_TYPE",
  CONFIRM_REMOVE = "CONFIRM_REMOVE",
  MEDICAL = "MEDICAL",
  CONFIRM_CANCEL = "CONFIRM_CANCEL",
  IMAGE = "IMAGE",
  MODEL_3D = "MODEL_3D",
  LINK_PATIENT = "LINK_PATIENT",
  QRCODE = "QRCODE",
  FILE_MANAGEMENT_IMPORT_SELECT = "FILE_MANAGEMENT_IMPORT_SELECT",
  FILE_MANAGEMENT_CREATE_FOLDER = "FILE_MANAGEMENT_CREATE_FOLDER",
  FILE_MANAGEMENT_GET_FILE_LINK = "FILE_MANAGEMENT_GET_FILE_LINK",
  FILE_MANAGEMENT_MOVE_INTO_FOLDER = "FILE_MANAGEMENT_MOVE_INTO_FOLDER",
  FILE_MANAGEMENT_RENAME = "FILE_MANAGEMENT_RENAME",
  SELECT_FILE_OPTION = "SELECT_FILE_OPTION",
  FILE_MANAGEMENT_IMPORT_REWRITE = "FILE_MANAGEMENT_IMPORT_REWRITE",
}

export enum FileTypes {
  ALL = "*",
  PDF = "application/pdf,application/vnd.ms-ex",
  CSV = ".csv",
  IMAGE = "image/*,.heic,.HEIF",
  STL = ".stl",
  PLY = ".ply",
  VIDEO = "video/*",
}
