import React from "react";
// import DatePicker from "react-datepicker";
import { Input } from "src/components/input";
import { Table } from "src/components/table";
import { Strings } from "src/constants";
import { FileTypes, history, Routes } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import "react-datepicker/dist/react-datepicker.css";
// import ja from "date-fns/locale/ja";
import { Textarea } from "src/components/textarea";
import { IRootState } from "src/store";
import { NotificationContainer } from "src/features/notification/presenter/container/notificationContainer";
import { Dispatch } from "redux";
import { DI, Injector } from "src/base/Injector";
import { connect } from "react-redux";
import { chooseNotification, updateNotification } from "src/features/notification/presenter/store/notification/actions";
import { initialNotification, INotification } from "src/features/notification/presenter/store/notification";
import { findParamsFromSearchString, findSubRouteFromPathName } from "src/utils/utils";
import { MainButton } from "src/components/button/mainButton";
import { NoDataWrapperComponent } from "src/shared/components/noDataWrapperComponent";
import { BackButton } from "src/shared/components/backButton";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
// import moment from 'moment';
import "moment/locale/ja";
import FilePicker from "src/components/filePicker/filePicker";

class NotificationDetailPage extends React.Component<NotificationDetailPageType> {
  componentDidMount() {
    DI.get().provideNotificationDependencies();
    let params = findParamsFromSearchString(window.location.search);
    let currentRoute = findSubRouteFromPathName(window.location.pathname);
    if (
      currentRoute !== Routes.NOTIFICATION_MANAGEMENT_CREATE &&
      params?.has("notificationId") &&
      params.get("notificationId")
    ) {
      this.props.fetchNotificationDetail(params.get("notificationId")!);
    } else {
      this.props.selectNotification({
        ...initialNotification,
        isNotFound: currentRoute !== Routes.NOTIFICATION_MANAGEMENT_CREATE,
      });
    }
  }

  componentWillUnmount() {
    DI.get().removeNotificationDepenencies();
  }

  buildNotificationInfoTable = () => {
    // const ExampleCustomTimeInput = ({ value, onChange }) => (
    //   <input
    //     type="time"
    //     step="0"
    //     value={value}
    //     onChange={e => onChange(e.target.value)}
    //   />
    // );
    return (
      <Table
        className="dataTable-container"
        subClassName="table table_detail"
        rows={[
          {
            fields: [
              { html: Strings.DATE_TIME },
              {
                html: (
                  <React.Fragment>
                    <div className="input-group date date-fix" id="datetime2">
                      <Datetime
                        className="align-center"
                        dateFormat="YYYY/MM/DD"
                        timeFormat="HH:mm a"
                        value={this.props.currentNotification.activityDate.value}
                        onChange={(date) => {
                          this.props.updateNotification("activityDate", date);
                        }}
                      />
                      <span className="input-group-addon">
                        <span className="glyphicon glyphicon-calendar"></span>
                      </span>
                    </div>
                    {this.props.currentNotification.activityDate.error &&
                      this.props.currentNotification.activityDate.error.length > 0 && (
                        <p className="text-error">{this.props.currentNotification.activityDate.error}</p>
                      )}
                  </React.Fragment>
                ),
              },
            ],
          },

          {
            fields: [
              { html: Strings.TITLE },
              {
                html: (
                  <Input
                    inputClassName="form-control"
                    type="text"
                    value={this.props.currentNotification.title.value}
                    onChange={(value) => this.props.updateNotification("title", value)}
                    error={this.props.currentNotification.title.error}
                  />
                ),
              },
            ],
          },

          {
            fields: [
              { html: Strings.CONTENT },
              {
                html: (
                  <Textarea
                    className="form-control h300"
                    value={this.props.currentNotification.content.value}
                    name="content"
                    onChange={(value) => this.props.updateNotification("content", value)}
                    cols={30}
                    rows={3}
                    error={this.props.currentNotification.content.error}
                  />
                ),
              },
            ],
          },

          {
            fields: [
              {
                html: (
                  <React.Fragment>
                    {Strings.ATTACHMENT}
                    <br />
                    {Strings.PDF_ONLY}
                  </React.Fragment>
                ),
              },
              {
                html: (
                  <FilePicker
                    className="btnpdf-wrapper"
                    labelClassName="btn btn-primary btn-main"
                    id="pdf1"
                    labelText={
                      this.props.currentNotification.pdf?.value
                        ? this.props.currentNotification.pdf.value.split("/").slice(-1)[0]
                        : "" || Strings.SELECT_FILE
                    }
                    inputClassName="w300px form-control mr10"
                    onChange={(_: any, __: any, inputfile: any, url?: string) => {
                      this.props.updateNotification("pdfName", inputfile.name);
                      this.props.updateNotification("pdf", url!);
                    }}
                    fileAccept={FileTypes.PDF}
                  />
                ),
              },
            ],
          },
        ]}
      />
    );
  };

  render() {
    return (
      <MainLayout
        isLoading={this.props.notificationState}
        className="secondpage navstate_show page-notification_management detail animate-item"
        subClassName="p-notification_management detail"
      >
        <div className="container-fluid">
          <div className="card">
            <div className="card-header flex-H">
              <BackButton
                onBackPressed={() => {
                  history.replace(Routes.NOTIFICATION_MANAGEMENT);
                }}
              />
              {Strings.NOTIFICATION +
                Strings.LINE +
                (findSubRouteFromPathName(window.location.pathname) !== Routes.NOTIFICATION_MANAGEMENT_CREATE
                  ? Strings.EDIT
                  : Strings.CREATE_NEW)}
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.props.saveNotification(this.props.currentNotification);
              }}
            >
              <div className="card-body">
                <NoDataWrapperComponent
                  isNoData={this.props.currentNotification.isNotFound}
                  noDataContent={<h3>{Strings.NO_DATA}</h3>}
                >
                  {this.buildNotificationInfoTable()}
                  <div className="fec mt20 btn-group-end">
                    <MainButton
                      className="btn btn-primary mr10"
                      enabled={
                        this.props.currentNotification.activityDate &&
                        this.props.currentNotification.title.value !== "" &&
                        this.props.currentNotification.content.value !== ""
                      }
                      isSubmitButton
                      onSubmit={() => {}}
                      title={Strings.REGISTER}
                    />
                    <MainButton
                      className="btn btn-danger"
                      title={Strings.RETURN}
                      enabled
                      onSubmit={() => {
                        history.replace(Routes.NOTIFICATION_MANAGEMENT);
                      }}
                    />
                  </div>
                </NoDataWrapperComponent>
              </div>
            </form>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ notification }: IRootState) => {
  const { currentNotification, state, validate } = notification;
  return {
    currentNotification,
    validate,
    notificationState: state,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    selectNotification: (newNotification: INotification) => dispatch(chooseNotification(newNotification)),
    fetchNotificationDetail: (notificationId: string) =>
      Injector.get()
        .find<NotificationContainer>("NotificationContainer")
        .onFetchNotificationDetail(dispatch, notificationId),
    updateNotification: (field: string, value: any) => dispatch(updateNotification(field, value)),
    saveNotification: (notification: INotification) =>
      Injector.get()
        .find<NotificationContainer>("NotificationContainer")
        .saveNotificationHandle(dispatch, notification),
  };
};

type NotificationDetailPageType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(NotificationDetailPage);
