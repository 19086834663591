import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { NoParam } from "src/base/NoParam";
import { UseCase } from "src/base/UseCase";
import { ISubscriptionRepository } from "../../data/repositories/subscriptionRepository";
import { ICard } from "../model/card";

export class AddCard extends UseCase<AddCardParams, string> {
  private repository: ISubscriptionRepository;
  constructor(repository: ISubscriptionRepository) {
    super();
    this.repository = repository;
  }

  call(params: AddCardParams): Promise<Either<Failure, string>> {
    return this.repository.addCard(params.card);
  }
}

export class AddCardParams {
  card: ICard;
  constructor(card: ICard) {
    this.card = card;
  }
}
