import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers/enums";
import { IRootState } from "src/store";
import QRCodeViewer from "../QRCodeViewer/QRCodeViewer";
import Modal from "../modal/modal";

type QRCodeViewerPopupProps = {
  title?: string;
  value: string;
};

class QRCodeViewerPopup extends React.Component<ThreeDViewerPopupType> {
  render() {
    return (
      <Modal
        dialogExtraClassName="modal-qrcode-viewer"
        isShow={this.props.currentModal == Modals.QRCODE}
        onClose={() => {
          this.props.changeModal(Modals.NONE);
        }}
      >
        <div className="modal-body modal-bodyViewer" data-v-2808a57a="">
          <h4 className="text_center overflow" data-v-2808a57a="" style={{ color: "rgb(2, 163, 175)" }}>
            {this.props.title}
          </h4>
          <div className="qrCode-box">
            <QRCodeViewer value={this.props.value} />
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ app }: IRootState) => {
  const { currentModal } = app;

  return {
    currentModal,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
  };
};

type ThreeDViewerPopupType = QRCodeViewerPopupProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(QRCodeViewerPopup);
