import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AttributeName, Position } from "src/base/Enums";
import { Strings } from "src/constants";
import { getAttributeName, getMetaData } from "src/features/medicalRecord/data/models/attribute";
import { ToothAttribute } from "src/features/medicalRecord/data/models/medicalRecord";
import { saveToothInfo, updateToothInfo } from "src/features/medicalRecord/presenter/store/medicalRecord";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers/enums";
import { MapFunction } from "src/helpers/mapFunction";
import { LowerTeethIndex, UpperTeethIndex } from "src/helpers/teethIndex";
import { Validation } from "src/helpers/validation";
import { IRootState } from "src/store";
import { MainButton } from "../button/mainButton";
import Modal from "../modal/modal";
import { SelectOption } from "../selectOption";
import { TeethSVG } from "../teethSVG";
import { MedicalRadio } from "./medicalRadio";
import { PCROption } from "./pcrOption";

export interface MedicalPopupProps {
  readonly?: boolean;
}

class MedicalPopup extends React.Component<MedicalPopupType> {
  render(): React.ReactNode {
    const tooth = this.props.tooth;
    const bucal = MapFunction.objToMap(tooth?.get(AttributeName.buccal)?.value ?? {});
    const lingual = MapFunction.objToMap(tooth?.get(AttributeName.lingual)?.value ?? {});
    return (
      <Modal
        className=""
        isShow={this.props.currentModal === Modals.MEDICAL}
        onClose={() => {
          this.props.changeModal(Modals.NONE);
        }}
      >
        <div className="modal-body">
          <table className="table table-detailbox">
            <tbody>
              <tr>
                <th>{getAttributeName(AttributeName.pattern, this.props.attributes)}</th>
                <td>
                  <MedicalRadio
                    readonly={this.props.readonly}
                    options={getMetaData(AttributeName.pattern, this.props.attributes).map((item) => ({
                      label: item.label ?? "",
                      value: item.value ?? "",
                    }))}
                    labelClassName="fsc mr30 check-custom mb10"
                    className="fsc flex-wrap"
                    name="style1"
                    selected={tooth?.get(AttributeName.pattern)?.value ?? ""}
                    onChange={(value: any) => {
                      this.props.updateToothInfo(AttributeName.pattern, value);
                    }}
                  ></MedicalRadio>
                </td>
              </tr>
              {tooth?.get(AttributeName.pattern)?.value !== "4" ? (
                <tr>
                  <th>{getAttributeName(AttributeName.pcr, this.props.attributes)}</th>
                  <td>
                    <div className="fsc position-custom">
                      <PCROption
                        readonly={this.props.readonly}
                        selectedPositions={tooth?.get(AttributeName.pcr)?.value ?? []}
                        onChange={(positions: Array<Position>) => {
                          this.props.updateToothInfo(AttributeName.pcr, positions);
                        }}
                      ></PCROption>
                    </div>
                  </td>
                </tr>
              ) : undefined}
              {tooth?.get(AttributeName.pattern)?.value !== "4" ? (
                <tr>
                  <th>{getAttributeName(AttributeName.bop, this.props.attributes)}</th>
                  <td>
                    <MedicalRadio
                      options={getMetaData(AttributeName.bop, this.props.attributes).map((item) => ({
                        label: item.label ?? "",
                        value: item.value ?? "",
                      }))}
                      labelClassName="fsc mr30 check-custom"
                      className="fsc"
                      name="check1"
                      readonly={this.props.readonly}
                      selected={tooth?.get(AttributeName.bop)?.value ?? ""}
                      onChange={(value: any) => {
                        this.props.updateToothInfo(AttributeName.bop, value);
                      }}
                    ></MedicalRadio>
                  </td>
                </tr>
              ) : undefined}
              {tooth?.get(AttributeName.pattern)?.value !== "4" ? (
                <tr>
                  <th>{getAttributeName(AttributeName.agitation, this.props.attributes)}</th>
                  <td>
                    <SelectOption
                      className="form-select select-item form-control"
                      id="select-agitation"
                      name="select-agitation"
                      readonly={this.props.readonly}
                      options={[
                        {
                          name: "-----",
                          value: 0,
                        },
                        ...getMetaData(AttributeName.agitation, this.props.attributes).map((item) => ({
                          name: item.value ?? "",
                          value: item.value ?? "",
                        })),
                      ]}
                      value={tooth?.get(AttributeName.agitation)?.value}
                      onChange={(value) => {
                        this.props.updateToothInfo(AttributeName.agitation, value);
                      }}
                    ></SelectOption>
                  </td>
                </tr>
              ) : undefined}
              {tooth?.get(AttributeName.pattern)?.value !== "4" ? (
                <tr>
                  <th>EPP</th>
                  <td>
                    <div className="fsc jaw-box">
                      <label id="jawtag1">{getAttributeName(AttributeName.buccal, this.props.attributes)}</label>
                      {getMetaData(AttributeName.buccal, this.props.attributes).map((item, index) => (
                        <div key={index} className="select-item-wrapper mr10">
                          <SelectOption
                            readonly={this.props.readonly}
                            className="form-select select-item form-control"
                            id={`select${item}`}
                            name="cheek"
                            options={["-", ...(item.options ?? [])].map((item) => {
                              return { value: item };
                            })}
                            value={bucal.get((index + 1).toString()) ?? "-"}
                            onChange={(value) => {
                              const bucal = {
                                ...tooth?.get(AttributeName.buccal)?.value,
                                [index + 1]: value,
                              };
                              this.props.updateToothInfo(AttributeName.buccal, bucal);
                            }}
                          ></SelectOption>
                        </div>
                      ))}
                    </div>
                    <div className="fsc mt10 jaw-box">
                      <label id="jawtag2">{getAttributeName(AttributeName.lingual, this.props.attributes)}</label>
                      {getMetaData(AttributeName.lingual, this.props.attributes).map((item, index) => (
                        <div key={index} className="select-item-wrapper mr10">
                          <SelectOption
                            readonly={this.props.readonly}
                            className="form-select select-item form-control"
                            id={`select${item}`}
                            name="cheek"
                            options={["-", ...(item.options ?? [])].map((item) => {
                              return { value: item };
                            })}
                            value={lingual.get((index + 1).toString()) ?? "-"}
                            onChange={(value) => {
                              const lingual = {
                                ...tooth?.get(AttributeName.lingual)?.value,
                                [index + 1]: value,
                              };
                              this.props.updateToothInfo(AttributeName.lingual, lingual);
                            }}
                          ></SelectOption>
                        </div>
                      ))}
                    </div>
                  </td>
                </tr>
              ) : undefined}
              <tr>
                <td colSpan={2} className="btn-center">
                  <TeethSVG
                    selectedTeethIndex={this.props.selectedToothIndex}
                    lowerJaw={this.props.selectedToothIndex > 16}
                    teeths={this.props.selectedToothIndex > 16 ? LowerTeethIndex : UpperTeethIndex}
                    teethDisabled={[]}
                    openModal={(index) => {}}
                  />
                </td>
              </tr>
              {!this.props.readonly ? (
                <tr>
                  {" "}
                  <td colSpan={2} className="btn-center">
                    {" "}
                    <MainButton
                      enabled={
                        tooth?.get(AttributeName.pattern)?.value === "4" || Validation.validateTooth(tooth ?? new Map())
                      }
                      title={Strings.REGISTER}
                      className="btn btn-primary"
                      onSubmit={() => {
                        this.props.saveTooth(this.props.selectedToothIndex, tooth ?? new Map());
                        this.props.changeModal(Modals.NONE);
                      }}
                    />
                  </td>
                </tr>
              ) : undefined}
            </tbody>
          </table>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ app, medicalRecord }: IRootState) => {
  const { currentModal } = app;
  const { selectedToothIndex, attributes, selectedMedicalRecord, tooth } = medicalRecord;
  return {
    currentModal,
    attributes,
    selectedToothIndex,
    selectedMedicalRecord,
    tooth,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
    updateToothInfo: (key: string, value: any) => dispatch(updateToothInfo(key, value)),
    saveTooth: (toothIndex: number, tooth: Map<string, ToothAttribute>) => dispatch(saveToothInfo(toothIndex, tooth)),
  };
};

type MedicalPopupType = MedicalPopupProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(MedicalPopup);
