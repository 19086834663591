import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { MedicalRecordState } from "src/base/Enums";
import { medicalRecordStateChanged } from "src/features/medicalRecord/presenter/store/medicalRecord";
import { FileTypes } from "src/helpers";
import { Assets } from "src/utils/assets";
import { uploadFileToS3 } from "src/utils/utils";
import FileManagementPopup from "../popup/FileManagementPopup/FileManagementPopup";
import SelectFileOptionPopup from "../popup/SelectFileOptionPopup";

interface IDropzoneSelectFileProps {
  id?: string;
  inputClassName?: string;
  className?: string;
  fileAccept?: FileTypes;
  readonly?: boolean;
  onChange?: (value: any) => void;
}

interface IDropzoneSelectFileState {
  isShowSelectFilePopup: boolean;
  isShowFileManagementPopup: boolean;
}

class DropzoneSelectFile extends React.Component<DropzoneSelectFileType, IDropzoneSelectFileState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isShowSelectFilePopup: false,
      isShowFileManagementPopup: false,
    };
  }

  handleShowSelectFilePopup = (isShow: boolean) => {
    this.setState({ isShowSelectFilePopup: isShow });
  };

  handleShowFileManagementPopup = (isShow: boolean) => {
    this.setState({ isShowFileManagementPopup: isShow });
  };

  overrideEventDefaults = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
  };

  onDrop = async (event: any) => {
    this.overrideEventDefaults(event);
    this.props.medicalRecordStateChanged(MedicalRecordState.convertingFiles);

    if (!event.dataTransfer) return;

    const fileListUpload: any[] = [];

    for await (const file of event.dataTransfer.files) {
      const result = await uploadFileToS3(file);

      file.location = result.location;
      fileListUpload.push(file);
    }

    this.props.onChange && this.props.onChange(fileListUpload);
  };

  render() {
    return (
      <>
        <div
          className={this.props.className}
          onClick={() => !this.props.readonly && this.handleShowSelectFilePopup(true)}
          onDragEnter={this.overrideEventDefaults}
          onDragLeave={this.overrideEventDefaults}
          onDragOver={this.overrideEventDefaults}
          onDrop={this.onDrop}
        >
          <div className="upload_wrapper_ins">
            <figure>
              <img src={Assets.upload} alt="" />
            </figure>
            <span>ドラッグアンドドロップもしくはファイルを選択</span>
          </div>
        </div>
        {this.state.isShowSelectFilePopup && (
          <SelectFileOptionPopup
            isShow={this.state.isShowSelectFilePopup}
            handleShow={this.handleShowSelectFilePopup}
            handleShowFileManagementPopup={this.handleShowFileManagementPopup}
            id={this.props.id}
            inputClassName={this.props.inputClassName}
            fileAccept={this.props.fileAccept}
            onLoading={() => this.props.medicalRecordStateChanged(MedicalRecordState.convertingFiles)}
            onChange={(_, __, ___, ____, fileList) => {
              this.handleShowSelectFilePopup(false);
              this.props.onChange && this.props.onChange(fileList);
            }}
            multiple
          />
        )}
        {this.state.isShowFileManagementPopup && (
          <FileManagementPopup
            isShow={this.state.isShowFileManagementPopup}
            handleShow={this.handleShowFileManagementPopup}
            fileAccept={this.props.fileAccept}
            onChange={async (_: any, __: any, ___: any, ____: any, fileList?: any[]) => {
              this.props.medicalRecordStateChanged(MedicalRecordState.convertingFiles);

              const fileListUpload: any[] = [];

              for await (const file of fileList!) {
                await fetch(file.url).then(async (res) => {
                  const blob = await res.blob();
                  const newFile: any = new File([blob], `${file.name}.${file.extension}`);

                  newFile.location = file.url;

                  fileListUpload.push(newFile);
                });
              }

              this.props.onChange && this.props.onChange(fileListUpload);
              this.handleShowFileManagementPopup(false);
              this.handleShowSelectFilePopup(false);
            }}
            multiple
            maxFile={2}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = () => {};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    medicalRecordStateChanged: (state: MedicalRecordState) => dispatch(medicalRecordStateChanged(state)),
  };
};

type DropzoneSelectFileType = IDropzoneSelectFileProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(null, mapDispatcherToProps)(DropzoneSelectFile);
