import { AnalyticDetailTab, PatientAnalyticState } from "src/base/Enums";
import {
  IAnalyticDate,
  IPatientAnalytic,
  IPatientAnalyticDetail,
} from "src/features/analytic/data/models/patientAnalytic";
import { Pagination } from "src/shared/model/pagination";
import { action } from "typesafe-actions";
import { PatientAnalyticType } from "./types";

//handle change UI/UX state
export function patientAnalyticStateChanged(
  patientAnalyticState: PatientAnalyticState
) {
  return action(PatientAnalyticType.PATIENT_ANALYTIC_STATE_CHANGED, {
    patientAnalyticState,
  });
}

export function patientAnalyticsPaginationValueChanged(
  key: string,
  value: any
) {
  return action(PatientAnalyticType.PATIENT_ANALYTICS_PAGITION_VALUE_CHANGED, {
    key,
    value,
  });
}

//handle update analytics
export function patientAnalyticsFetched(
  analytics: Pagination<IPatientAnalytic>
) {
  return action(PatientAnalyticType.PATIENT_ANALYTICS_FETCHED, { analytics });
}

export function patientAnalysisDetailFetched(
  key: string,
  analysis: IPatientAnalyticDetail
) {
  return action(PatientAnalyticType.PATIENT_ANALYTIC_DETAILS_FETCHED, {
    key,
    analysis,
  });
}

export function patientAnalyticDetailsTabChanged(
  analysisId: string,
  type: AnalyticDetailTab
) {
  return action(PatientAnalyticType.PATIENT_ANALYTIC_DETAILS_TAB_CHANGED, {
    analysisId,
    type,
  });
}

export function patientAnalyticOnDateShowed(
  analysisKey: AnalyticDetailTab,
  dates: IAnalyticDate[]
) {
  return action(PatientAnalyticType.PATIENT_ANALYTIC_ON_DATES_SHOWED, {
    analysisKey,
    dates,
  });
}

export function isNotFoundUpdated(isNotFound: boolean) {
  return action(PatientAnalyticType.PATIENT_ANALYTIC_IS_NOT_FOUND_UPDATED, { isNotFound })
}
