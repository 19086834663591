import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IUser } from "src/features/account/data/models/user";
import { IOrthodontistRepository } from "../../data/repositories/orthodontistRepository";

export class AddOrthodontist extends UseCase<AddOrthodontistParams, boolean>{
    private repository: IOrthodontistRepository
    constructor(repository: IOrthodontistRepository) {
        super()
        this.repository = repository
    }

    call(params: AddOrthodontistParams): Promise<Either<Failure, boolean>> {
        return this.repository.addOrthodontists(params.user)
    }

}

export class AddOrthodontistParams {
    user: IUser
    constructor(user: IUser) {
        this.user = user
    }
}