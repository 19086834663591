import { FileManagementState } from "src/base/Enums";
import { ActionType } from "typesafe-actions";
import { IFileManagement } from "../../data/models/fileManagement";
import { IFolder } from "../../data/models/folder";
import * as actions from "./actions";

export interface IFileManagementState {
  fileManagementState: FileManagementState;
  fileManagementData: IFileManagement;
  folderToMove: IFolder[];
  filesToUpload: Map<string, File | File[]>;
}

export enum FileManagementActionType {
  FILE_MANAGEMENT_STATE_CHANGED = "FILE_MANAGEMENT_STATE_CHANGED",
  FETCH_FILE_MANAGEMENT = "FETCH_FILE_MANAGEMENT",
  CREATE_FOLDER_FILE_MANAGEMENT = "CREATE_FOLDER_FILE_MANAGEMENT",
  IMPORT_FILE_MANAGEMENT = "IMPORT_FILE_MANAGEMENT",
  IMPORT_FILE_MANAGEMENT_COMPLETED = "IMPORT_FILE_MANAGEMENT_COMPLETED",
  DOWNLOAD_FOLDER_FILE_MANAGEMENT = "DOWNLOAD_FOLDER_FILE_MANAGEMENT",
  FETCH_FOLDER_TO_MOVE_FILE_MANAGEMENT = "FETCH_FOLDER_TO_MOVE_FILE_MANAGEMENT",
  MOVE_FILE_MANAGEMENT = "MOVE_FILE_MANAGEMENT",
  RENAME_FILE_MANAGEMENT = "RENAME_FILE_MANAGEMENT",
  DELETE_FILE_MANAGEMENT = "DELETE_FILE_MANAGEMENT",
  UPDATE_FILE_TO_UPLOAD_FILE_MANAGEMENT = "UPDATE_FILE_TO_UPLOAD_FILE_MANAGEMENT",
  RESET_FILE_TO_UPLOAD_FILE_MANAGEMENT = "RESET_FILE_TO_UPLOAD_FILE_MANAGEMENT",
}

export type FileManagementActions = ActionType<typeof actions>;
