import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { NoParam } from "src/base/NoParam";
import { UseCase } from "src/base/UseCase";
import { MFile } from "src/features/medicalRecord/data/models/file";
import { Pagination } from "src/shared/model/pagination";
import { IRemoteFileDownloadRepository } from "../../data/repositories/remoteFilesDownloadRepository";

export class FetchRemoteFiles extends UseCase<FetchRemoteFilesParams, Pagination<MFile>>{
    private repository: IRemoteFileDownloadRepository;
    constructor(repository: IRemoteFileDownloadRepository) {
        super();
        this.repository = repository;
    }

    call(params: FetchRemoteFilesParams): Promise<Either<Failure, Pagination<MFile>>> {
        return this.repository.fetchAllRemoteFiles(params.page)
    }

}

export class FetchRemoteFilesParams {
    page: number
    constructor(page: number) {
        this.page = page
    }
}