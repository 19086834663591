import { Modals } from "src/helpers";
import { IAppState, RootActions, RootActionType } from "./types";

const initialState: IAppState = {
  currentModal: Modals.NONE,
  position: [0, 0, 50],
  type: "LEFT",
};

export function appReducer(
  state: IAppState = initialState,
  action: RootActions
): IAppState {
  switch (action.type) {
    case RootActionType.CHANGE_MODAL:
      return {
        ...state,
        currentModal: action.payload.modal,
      };
    case RootActionType.CHANGE_POSITION:
      return {
        ...state,
        position: action.payload.position,
        type: action.payload.type,
      };
    default:
      return state;
  }
}
