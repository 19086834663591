import { PatientState, SubscriptionState } from "src/base/Enums";
import { defaultUser } from "src/features/account/data/models/user";
import { paginationInit } from "src/shared/model/pagination";
import { defaultCard } from "../../domain/model/card";
import {
  ISubscriptionState,
  SubscriptionActions,
  SubscriptionActionType,
} from "./types";

const initialState: ISubscriptionState = {
  plans: [],
  selectedPlanIndex: 0,
  isUpdate: false,
  cards: [],
  draftCard: defaultCard,
  subscriptionState: SubscriptionState.none,
};

export function subscriptionReducer(
  state: ISubscriptionState = initialState,
  action: SubscriptionActions
) {
  switch (action.type) {
    case SubscriptionActionType.SUBSCRIPTION_ALL_PLANS_UPDATED:
      return {
        ...state,
        plans: [...action.payload.plans],
        selectedPlanIndex:
          action.payload.plans.filter((plan) => plan.current_plan).length !== 0
            ? action.payload.plans
              .map((plan) => plan.id)
              .indexOf(
                action.payload.plans.filter((plan) => plan.current_plan)[0].id
              )
            : 0,
      };
    case SubscriptionActionType.SUBSCRIPTION_STATE_CHANGED:
      return {
        ...state,
        subscriptionState: action.payload.subscriptionState,
      };
    case SubscriptionActionType.SUBSCRIPTION_CURRENT_PLAN_CHANGED:
      let allPlans = [...state.plans];
      if (state.selectedPlanIndex > -1) {
        allPlans.forEach((plan, index) => {
          allPlans[index] = {
            ...allPlans[index],
            current_plan: state.selectedPlanIndex === index,
          };
        });
      }

      return {
        ...state,
        plans: [...allPlans],
      };
    case SubscriptionActionType.SUBSCRIPTION_PLAN_SELECTED:
      return {
        ...state,
        selectedPlanIndex: action.payload.index,
      };
    case SubscriptionActionType.SUBSCRIPTION_PLAN_CANCELLED:
      let allMPlans = [...state.plans];
      allMPlans.forEach((_, index) => {
        allPlans[index] = {
          ...allPlans[index],
          current_plan: false,
        };
      });
      return {
        ...state,
        allPlans: [...allMPlans],
        selectedPlanIndex: 0,
      };
    case SubscriptionActionType.SUBSCRIPTION_CARDS_FETCHED:
      return {
        ...state,
        cards: [...action.payload.cards],
      };
    case SubscriptionActionType.SUBSCRIPTION_CARD_ADDED:
      return {
        ...state,
        cards: [...state.cards, action.payload.card],
      };
    case SubscriptionActionType.SUBSCRIPTION_DRAFT_CARD_PROPS_CHANGED:
      return {
        ...state,
        draftCard: {
          ...state.draftCard,
          [action.payload.key]: action.payload.value,
        },
      };
    case SubscriptionActionType.SUBSCRIPTION_CARD_SELECTED:
      let cards = [...state.cards];
      cards.forEach((card) => {
        if (card.id === action.payload.card.id) {
          card.current = true;
        } else {
          card.current = false;
        }
      });
      return {
        ...state,
        cards: [...state.cards],
      };
    case SubscriptionActionType.SUBSCRIPTION_DRAFT_CARD_CHANGED:
      return {
        ...state,
        isUpdate: action.payload.isUpdate,
        draftCard: {
          ...action.payload.card,
        },
      };
    default:
      return state;
  }
}
