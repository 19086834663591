import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { ISubscriptionRepository } from "../../data/repositories/subscriptionRepository";

export class ChangePlan extends UseCase<ChangePlanParams, boolean> {
  private repository: ISubscriptionRepository;
  constructor(repository: ISubscriptionRepository) {
    super();
    this.repository = repository;
  }

  call(params: ChangePlanParams): Promise<Either<Failure, boolean>> {
    return this.repository.changePlan(params.id, params.card_id);
  }
}

export class ChangePlanParams {
  id: string;
  card_id: string;
  constructor(id: string, card_id: string) {
    this.id = id;
    this.card_id = card_id;
  }
}
