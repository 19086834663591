import React from "react";
import { IFile } from "src/features/fileManagement/data/models/file";
import { IFolder } from "src/features/fileManagement/data/models/folder";

import imgFolder from "../../../../assets/common_img/folder_2.svg";

interface FolderComponentProps {
  folder: IFolder;
  handleSelectItem: (item: IFolder | IFile) => void;
  handleMoveIntoFolder: (folder: IFolder) => void;
  isSelected: boolean;
}

interface FolderComponentState {}

class FolderComponent extends React.Component<FolderComponentProps, FolderComponentState> {
  handleClickFolder = (event: any) => {
    if (event.detail === 1) this.props.handleSelectItem(this.props.folder);
    else this.props.handleMoveIntoFolder(this.props.folder);
  };

  render(): React.ReactNode {
    return (
      <div
        className={`folder-component folder-component-popup ${this.props.isSelected && "selected"}`}
        onClick={this.handleClickFolder}
      >
        <span className="folder-image">
          <img src={imgFolder} alt="" width="100" />
        </span>
        <span className="folder-name">{this.props.folder.name}</span>
      </div>
    );
  }
}

export default FolderComponent;
