import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IFile } from "../../data/models/file";
import { IRemoteFileManagementRepository } from "../../data/repositories/remoteFileManagementRepository";
import { IFolder } from "../../data/models/folder";

export class DeleteFileManagement extends UseCase<DeleteFileManagementParams, void> {
  private repository: IRemoteFileManagementRepository;

  constructor(repository: IRemoteFileManagementRepository) {
    super();
    this.repository = repository;
  }

  call(params: DeleteFileManagementParams): Promise<Either<Failure, void>> {
    return this.repository.deleteFileManagement(params.breadcrumb, params.itemToDelete);
  }
}

export class DeleteFileManagementParams {
  breadcrumb: string;
  itemToDelete?: IFolder | IFile | IFile[];

  constructor(breadcrumb: string, itemToDelete?: IFolder | IFile | IFile[]) {
    this.breadcrumb = breadcrumb;
    this.itemToDelete = itemToDelete;
  }
}
