import { MedicalRecordState } from "src/base/Enums";
import { IUser } from "src/features/account/data/models/user";
import { Attribute } from "src/features/medicalRecord/data/models/attribute";
import { IMedicalRecord, ToothAttribute } from "src/features/medicalRecord/data/models/medicalRecord";
import { Pagination } from "src/shared/model/pagination";
import { ActionType } from "typesafe-actions";
import * as actions from "./actions";

export interface IMedicalRecordState {
    medicalRecords: Pagination<IMedicalRecord>;
    searchedOrthodontists: Pagination<IUser>;
    searchedPatients: Pagination<IUser>;
    selectedMedicalRecord: IMedicalRecord;
    selectedToothIndex: number;
    medicalRecordState: MedicalRecordState;
    isSubmitting: boolean;
    attributes: Array<Attribute>;
    tooth: Map<string, ToothAttribute>;
}

export enum MedicalRecordActionType {
    MEDICAL_RECORD_STATE_CHANGED = "MEDICAL_RECORD_STATE_CHANGED",
    MEDICAL_RECORDS_FETCHED = "MEDICAL_RECORD_FETCHED",
    MEDICAL_RECORD_PAGINATION_UPDATED = "MEDICAL_RECORD_PAGINATION_UPDATED",
    MEDICAL_RECORD_UPDATED = "MEDICAL_RECORD_UPDATED",
    SELECTED_MEDICAL_RECORD_UPDATED = "SELECTED_MEDICAL_RECORD_UPDATED",
    SELECTED_MEDICAL_RECORD_DATA_UPDATED = "SELECTED_MEDICAL_RECORD_DATA_UPDATED",
    SELECTED_TOOTH_UPDATED = "SELECTED_TOOTH_UPDATED",
    MEDICAL_ORTHODONTISTS_SEARCHED = "MEDICAL_ORTHODONTISTS_SEARCHED",
    MEDICAL_PATIENTS_SEARCHED = "MEDICAL_PATIENTS_SEARCHED",
    UPDATE_MEDICAL_SUBMITING = "UPDATE_MEDICAL_SUBMITING",
    UPDATE_TOOTH_INFO = "UPDATE_TOOTH_INFO",
    ATTRIBUTES_FETCHED = "ATTRIBUTES_FETCHED",
    SAVE_TOOTH_INFO = "SAVE_TOOTH_INFO"
}

export type MedicalRecordActions = ActionType<typeof actions>