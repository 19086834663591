import { Strings } from "src/constants";
import { AnalyticFooter } from "./analyticFooter";



export class BOPAnalyticFooter extends AnalyticFooter {
    title = Strings.ANALYSIS_BOP_EVAL
    content = <div className="comprehensive-box comprehensive-box2 mb20">
        <div className="comprehensive-box--ins shadow-box green mt20">
            <p>消失</p>
            <div className="line-bar">{(typeof this.props.evaluation?.loss === "number" ? (this.props.evaluation?.loss ?? 0) : parseInt(this.props.evaluation?.loss ?? "0")).toFixed(0)}％</div>
        </div>
        <div className="comprehensive-box--ins shadow-box orange mt20">
            <p>発生</p>
            <div className="line-bar">{(typeof this.props.evaluation?.occurrence === "number" ? (this.props.evaluation?.occurrence ?? 0) : parseInt(this.props.evaluation?.occurrence ?? "0")).toFixed(0)}％</div>
        </div>
        <div className="comprehensive-box--ins shadow-box purple mt20">
            <p>悪化</p>
            <div className="line-bar">{(typeof this.props.evaluation?.continuation === "number" ? (this.props.evaluation?.continuation ?? 0) : parseInt(this.props.evaluation?.continuation ?? "0")).toFixed(0)}％</div>
        </div>
    </div>
}