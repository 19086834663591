import React from "react";
import { ReactNode } from "react";
import { PieChart } from "src/components/chart/pie_chart_component";
import { Strings } from "src/constants";
import { IPolishData } from "src/features/analytic/data/models/patientAnalytic";
import { PCRAnalyticBody } from "../body/pcrAnalyticBody";
import { PCRAnalyticHeader } from "../header/pcrAnalyticHeader";
import {
  PatientAnalyticTab,
  PatientAnalyticTabProps,
} from "./patientAnalyticTab";

function instanceOfPolishData(object: any): object is IPolishData {
  return "polish" in object && "unpolish" in object;
}

export class PCRAnalyticTab extends React.Component<PatientAnalyticTabProps> {
  render(): ReactNode {
    let showingDates = this.props.analyticDetail.analysis.dates.filter(
      (date) => date.shouldShowAnalytic
    );
    return (
      <PatientAnalyticTab
        id={this.props.id}
        className={this.props.className}
        onChangeDates={this.props.onChangeDates}
        header={
          <PCRAnalyticHeader
            id={`${this.props.id}-header`}
            onChangeDates={this.props.onChangeDates}
            totals={[
              {
                label: "PCRスコア",
                value:
                  (showingDates.length !== 0
                    ? showingDates
                      .map((date) =>
                        instanceOfPolishData(date.result)
                          ? date.result.unpolish
                          : 0
                      )
                      .reduce((a, b) => a + b) / showingDates.length
                    : 0
                  ).toFixed(2).toString() + "%",
              },
            ]}
            dates={this.props.analyticDetail.analysis.dates}
            title={Strings.ANALYSIS_PCR_RESULT}
            chart={
              <PieChart
                items={[
                  {
                    name: "磨けている",
                    percents:
                      showingDates.length !== 0
                        ? parseFloat((showingDates
                          .map((date) =>
                            instanceOfPolishData(date.result)
                              ? date.result.polish
                              : 0
                          )
                          .reduce((a, b) => a + b) / showingDates.length).toFixed(2))
                        : 0,
                    color: "#02A3AF",
                  },
                  {
                    name: "磨き残し",
                    percents:
                      showingDates.length !== 0
                        ? parseFloat((showingDates
                          .map((date) =>
                            instanceOfPolishData(date.result)
                              ? parseFloat(date.result.unpolish.toFixed(2))
                              : 0
                          )
                          .reduce((a, b) => a + b) / showingDates.length).toFixed(2))
                        : 0,
                    color: "#F57F59",
                  },
                ]}
              />
            }
          />
        }
        body={
          <PCRAnalyticBody
            id={`${this.props.id}-body`}
            comprehensive={this.props.analyticDetail.comprehensive}
          />
        }
        analyticDetail={this.props.analyticDetail}
      />
    );
  }
}
