import React from "react";
import { FileTypes } from "src/helpers/enums";
import { onSelectFile } from "src/utils/utils";

interface IInputGroupProps {
  className?: string;
  inputPlaceHolder?: string;
  type?: string;
  inputClassName?: string;
  value?: any;
  name?: string;
  checked?: boolean;
  onChange: (value: any, name?: string, e?: React.ChangeEvent<HTMLInputElement>, file?: any, fileList?: any[]) => void;
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  pattern?: any;
  readonly?: boolean;
  id?: string;
  maxLength?: number;
  error?: string;
  autoFocus?: boolean;
  refTag?: React.RefObject<HTMLInputElement>;
  fileAccept?: FileTypes;
  multiple?: boolean;
}

export class Input extends React.Component<IInputGroupProps> {
  render() {
    return (
      <React.Fragment>
        <div className={`${this.props.className ?? ""}`}>
          {this.props.type === "file" ? (
            <input
              className={`${this.props.inputClassName ?? ""}`}
              type={this.props.type}
              name={this.props.name ?? ""}
              onChange={async (e) => {
                let event = Object.create(e);

                if (!this.props.multiple) {
                  onSelectFile(event.target.files[0], (value, name, file) => {
                    let encodedName = (name || "")?.replace("#", "");
                    this.props.onChange(value, encodedName, event, file);
                  });
                } else {
                  const fileList = Array.from(e.target.files!);

                  for await (const file of fileList) {
                    await onSelectFile(file, (value, name, file) => {
                      let encodedName = (name || "")?.replace("#", "");

                      file.encodedName = encodedName;
                      file.valueBase64 = value;
                    });
                  }

                  this.props.onChange(undefined, undefined, undefined, undefined, fileList);
                }
              }}
              onClick={(e) => {
                if (this.props.readonly) e.preventDefault();
              }}
              value={""}
              id={this.props.id ?? ""}
              ref={this.props.refTag}
              accept={this.props.fileAccept ?? FileTypes.ALL}
              multiple={this.props.multiple}
            />
          ) : (
            <input
              className={`${this.props.inputClassName ?? ""}`}
              type={this.props.type ?? "text"}
              value={this.props.value ?? ""}
              placeholder={this.props.inputPlaceHolder ?? ""}
              name={this.props.name ?? ""}
              checked={this.props.checked ?? false}
              onChange={(e) => {
                this.props.onChange(this.props.type === "checkbox" ? e.currentTarget.checked : e.currentTarget.value);
              }}
              id={this.props.id ?? ""}
              maxLength={this.props.maxLength ?? undefined}
              autoFocus={this.props.autoFocus ?? false}
              ref={this.props.refTag}
              pattern={this.props.pattern}
              readOnly={this.props.readonly}
              onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) =>
                this.props.onKeyUp ? this.props.onKeyUp(e) : () => {}
              }
            />
          )}
        </div>
        {this.props.error && this.props.error.length > 0 && <p className="text-error">{this.props.error}</p>}
      </React.Fragment>
    );
  }
}
