import moment from "moment";
import React from "react";

interface INoticeCardProps {
  imgSrc?: string;
  createdAt: Date;
  title: string;
  content: string;
  pdfUrl?: string;
  pdfName?: string;
}

export class NoticeCard extends React.Component<INoticeCardProps> {
  render() {
    return (
      <div className={`card ${this.props.imgSrc ? "" : "card-noimg"}`}>
        <div className={`flexbox ${this.props.imgSrc ? "card-img" : ""}`}>
          {this.props.imgSrc && this.props.imgSrc.length > 0 && (
            <div className="image">
              <img src={this.props.imgSrc} alt="" />
            </div>
          )}
          <div className="text">
            <div className="title">
              <div className="date">{moment(this.props.createdAt.toLocaleDateString()).format("YYYY/MM/DD")}</div>
              <h3>{this.props.title}</h3>
            </div>
            <div className="linewrap">
              <p>{this.props.content}</p>
            </div>
            {this.props.pdfUrl && this.props.pdfUrl.length > 0 && (
              <div className="text_right">
                <a target="_blank" rel="noreferrer" className="btn btn-default btn-pdf" href={this.props.pdfUrl}>
                  <span>{this.props.pdfName}</span>
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
