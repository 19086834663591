import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IRemoteFileManagementRepository } from "../../data/repositories/remoteFileManagementRepository";

export class CreateFolderFileManagement extends UseCase<CreateFolderFileManagementParams, void> {
  private repository: IRemoteFileManagementRepository;

  constructor(repository: IRemoteFileManagementRepository) {
    super();
    this.repository = repository;
  }

  call(params: CreateFolderFileManagementParams): Promise<Either<Failure, void>> {
    return this.repository.createFolderFileManagement(params.breadcrumb, params.name);
  }
}

export class CreateFolderFileManagementParams {
  breadcrumb: string;
  name: string;

  constructor(breadcrumb: string, name: string) {
    this.breadcrumb = breadcrumb;
    this.name = name;
  }
}
