import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IFileManagement } from "../../data/models/fileManagement";
import { IRemoteFileManagementRepository } from "../../data/repositories/remoteFileManagementRepository";

export class FetchFileManagement extends UseCase<FetchFileManagementParams, IFileManagement> {
  private repository: IRemoteFileManagementRepository;

  constructor(repository: IRemoteFileManagementRepository) {
    super();
    this.repository = repository;
  }

  call(params: FetchFileManagementParams): Promise<Either<Failure, IFileManagement>> {
    return this.repository.fetchFileManagement(params.breadcrumbPath, params.search, params.only);
  }
}

export class FetchFileManagementParams {
  breadcrumbPath: string;
  search?: string;
  only?: string;

  constructor(breadcrumbPath: string, search?: string, only?: string) {
    this.breadcrumbPath = breadcrumbPath;
    this.search = search;
    this.only = only;
  }
}
