import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { SubscriptionState } from "src/base/Enums";
import { DI, Injector } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import { Strings } from "src/constants";
import { SubscriptionContainer } from "src/features/subscription/presenter/container/subscriptionContainer";
import { Routes, history } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import { IRootState } from "src/store";

class PlanManagementPage extends React.Component<PlanManagementPageType> {
  componentDidMount() {
    DI.get().injectSubscriptionDependencies();
    this.props.onFetchAllPlans();
  }

  render() {
    let currentPlan = this.props.plans.filter((plan) => plan.current_plan)[0];

    return (
      <MainLayout
        isLoading={this.props.subscriptionState === SubscriptionState.fetchingPlans}
        id="payment"
        className="secondpage navstate_show page-planchange planchange-home"
        subClassName="p-planchange planchange-home"
      >
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">{Strings.SUBSCRIPTION_MANAGEMENT}</div>
            <div className="card-body">
              <div className="dataTable-container">
                <div className={`plan-tit ${currentPlan ? "" : "hide"}`}>
                  <span>
                    {currentPlan?.cancel_at_period_end
                      ? `${Strings.CANCEL_AT}${currentPlan?.expired_at}${Strings.ULTIL}`
                      : `${Strings.PLAN_VALID_TO}日${currentPlan?.expired_at}`}
                  </span>
                </div>
                <div className="flexbox plan-head">
                  <div className="current-plan">{Strings.CURRENT_PLAN}</div>
                  {/* <MainButton
                    className="btn btn-primary"
                    onSubmit={() => history.push(Routes.SUBSCRIPTION_PLAN_DETAIL)}
                    enabled
                    title={Strings.CHANGE_PLAN}
                  /> */}
                </div>
                <div className={`plan-body ${currentPlan ? "" : "hide"}`}>
                  {this.props.plans.filter((plan) => plan.current_plan).length !== 0 ? (
                    <div className="item">
                      <div className="plan-name">{currentPlan?.name}</div>
                      <div className="plan-info">{currentPlan?.description}</div>
                      <div className="plan-price">
                        月額<span className="cl-yell">{currentPlan.promotion_price || currentPlan?.price || 0}</span>円
                      </div>
                    </div>
                  ) : undefined}
                </div>
                {currentPlan && !currentPlan.cancel_at_period_end ? (
                  <div className="border-box">
                    <MainButton
                      className="text_underline maincl"
                      onSubmit={() => history.replace(Routes.SUBSCRIPTION_PLAN_CANCEL)}
                      enabled
                      title={Strings.CLICK_HERE_TO_CANCEL_PLAN}
                    />
                  </div>
                ) : undefined}
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ subscription }: IRootState) => {
  const { plans, subscriptionState } = subscription;
  return {
    plans,
    subscriptionState,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    onFetchAllPlans: () =>
      Injector.get().find<SubscriptionContainer>("SubscriptionContainer").onFetchAllPlans(dispatch),
  };
};

type PlanManagementPageType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(PlanManagementPage);
