import { OrthodontistState } from "src/base/Enums";
import { defaultUser } from "src/features/account/data/models/user";
import { paginationInit } from "src/shared/model/pagination";
import {
  IOrthodontistState,
  OrthodontistActions,
  OrthodontistActionType,
} from "./types";

const initialState: IOrthodontistState = {
  orthodontists: paginationInit,
  selectedOrthodontist: defaultUser,
  currentState: OrthodontistState.none,
};

export function orthodontistReducer(
  state: IOrthodontistState = initialState,
  action: OrthodontistActions
): IOrthodontistState {
  switch (action.type) {
    case OrthodontistActionType.ORTHORDONTISTS_FETCHED:
      return {
        ...state,
        orthodontists: {
          ...state.orthodontists,
          ...action.payload.orthodontists,
        },
      };
    case OrthodontistActionType.ORTHORDONTIST_UPDATED:
      let orthodontists = { ...state.orthodontists };
      let updatedIndex = orthodontists.data
        .map((orthodontist) => orthodontist.id)
        .indexOf(action.payload.orthodontist.id);
      if (updatedIndex > -1) {
        orthodontists.data[updatedIndex] = { ...action.payload.orthodontist };
      }
      return {
        ...state,
        orthodontists: {
          ...state.orthodontists,
          ...orthodontists,
        },
      };
    case OrthodontistActionType.ORTHORDONTISTS_PAGINATION_UPDATED:
      return {
        ...state,
        orthodontists: {
          ...state.orthodontists,
          [action.payload.key]: action.payload.value,
        },
      };
    case OrthodontistActionType.ORTHORDONTIST_STATE_CHANGED:
      return {
        ...state,
        currentState: action.payload.orthodontistState,
      };
    case OrthodontistActionType.SELECTED_ORTHODONTIST_UPDATED:
      return {
        ...state,
        selectedOrthodontist: {
          ...action.payload.orthodontist,
        },
      };
    case OrthodontistActionType.SELECTED_ORTHODONTIST_DATA_UPDATED:
      return {
        ...state,
        selectedOrthodontist: {
          ...state.selectedOrthodontist,
          [action.payload.key]: action.payload.value,
        },
      };
    default:
      return state;
  }
}
