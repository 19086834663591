import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IFolder } from "../../data/models/folder";
import { IRemoteFileManagementRepository } from "../../data/repositories/remoteFileManagementRepository";

export class DownloadFolderFileManagement extends UseCase<DownloadFolderFileManagementParams, string> {
  private repository: IRemoteFileManagementRepository;

  constructor(repository: IRemoteFileManagementRepository) {
    super();
    this.repository = repository;
  }

  call(params: DownloadFolderFileManagementParams): Promise<Either<Failure, string>> {
    return this.repository.downloadFolderFileManagement(params.breadcrumb, params.folder);
  }
}

export class DownloadFolderFileManagementParams {
  breadcrumb: string;
  folder: IFolder;

  constructor(breadcrumb: string, folder: IFolder) {
    this.breadcrumb = breadcrumb;
    this.folder = folder;
  }
}
