import { AccountState } from "src/base/Enums";
import { ActionType } from "typesafe-actions";
import { IUser } from "../../data/models/user";
import * as actions from "./actions";

export interface IAccountState {
  me: IUser;
  accountState: AccountState;
}

export enum AccountType {
  ACCOUNT_CHANGED = "ACCOUNT_CHANGED",
  ACCOUNT_DATA_CHANGED = "ACCOUNT_DATA_CHANGED",
  ACCOUNT_STATE_CHANGED = "ACCOUNT_STATE_CHANGED",
}

export type AccountActions = ActionType<typeof actions>;
