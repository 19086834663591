import moment from "moment";
import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { MedicalRecordState } from "src/base/Enums";
import { Injector } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import { InputGroup } from "src/components/inputGroup";
import { ConfirmDeletePopup } from "src/components/popup/confirmDeletePopup";
import { RedirectLinkPopup } from "src/components/popup/medicalRedirectLink";
import { Table } from "src/components/table";
import { Strings } from "src/constants";
import { defaultUser, IUser } from "src/features/account/data/models/user";
import { defaultRecord, IMedicalRecord } from "src/features/medicalRecord/data/models/medicalRecord";
import { MedicalRecordContainer } from "src/features/medicalRecord/presenter/container/medicalRecordContainer";
import {
  medicalRecordsPaginationUpdated,
  selectedMedicalRecordChanged,
} from "src/features/medicalRecord/presenter/store/medicalRecord";
import { changeModal } from "src/features/root/presenter/store/root";
import { history, Modals, Routes } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import { PaginationComponent } from "src/shared/components/pagination";
import { IRootState } from "src/store";

interface IMedicalManagementState {
  fromDate?: Date;
  toDate?: Date;
  code: string;
  patientName: string;
  orthodontistId?: number;
  fieldRequired?: Array<any>;
  removeRecordId: any;
  date_order?: string;
}

class MedicalManagementPage extends React.Component<MedicalManagementType, IMedicalManagementState> {
  constructor(props: any) {
    super(props);
    this.state = {
      code: "",
      patientName: "",
      removeRecordId: "",
      date_order: "desc",
    };
  }

  componentDidMount() {
    this.props.onFetchMedicalRecords(
      this.state.code,
      this.state.patientName,
      1,
      this.state.fieldRequired,
      this.state.orthodontistId,
      this.state.fromDate,
      this.state.toDate,
      this.state.date_order
    );
    this.props.onSearchOthordontists("", 10000);
  }

  componentDidUpdate(
    prevProps: Readonly<MedicalManagementType>,
    prevState: Readonly<IMedicalManagementState>,
    snapshot?: any
  ): void {
    if (prevState.date_order !== this.state.date_order) {
      this.props.onFetchMedicalRecords(
        this.state.code,
        this.state.patientName,
        1,
        this.state.fieldRequired,
        this.state.orthodontistId,
        this.state.fromDate,
        this.state.toDate,
        this.state.date_order
      );
      this.props.onSearchOthordontists("", 10000);
    }
  }

  getMedicalField(medicalRecord: IMedicalRecord) {
    const fields = [];
    if (medicalRecord.files.size > 0) {
      fields.push("STL");
    }
    if (medicalRecord.images.length > 0) {
      fields.push("口腔内画像");
    }
    if (medicalRecord.medical_attributes && medicalRecord.medical_attributes.length > 0) {
      fields.push("PCR");
    }
    return fields.join("/");
  }

  handleSortDateOrder = () => {
    this.setState({ date_order: this.state.date_order === "asc" ? "desc" : "asc" });
  };

  buildMedicalRecordTable = () => {
    return (
      <Table
        className="dataTable-container-scroll dataTable-container mt-4"
        subClassName="dataTable-table"
        isLoading={this.props.medicalRecordState === MedicalRecordState.fetchingMedicalRecord}
        headers={[
          {
            fields: [
              {
                html: (
                  <div className="fsc datepick">
                    <div className="input-group date" id="datetimepicker1">
                      <DatePicker
                        dateFormat={"yyyy/MM/dd"}
                        className="form-control"
                        selected={this.state.fromDate}
                        onChange={(date) => {
                          let fromDate = date;
                          let toDate = this.state.toDate;
                          if (fromDate && toDate && fromDate?.getTime() >= (toDate?.getTime() ?? 0)) {
                            toDate = fromDate;
                          }
                          this.setState({
                            fromDate: fromDate ?? undefined,
                            toDate: toDate,
                          });
                          this.props.onFetchMedicalRecords(
                            this.state.code,
                            this.state.patientName,
                            1,
                            this.state.fieldRequired,
                            this.state.orthodontistId,
                            fromDate ?? undefined,
                            toDate,
                            this.state.date_order
                          );
                        }}
                      />
                      <span className="input-group-addon">
                        <span className="glyphicon glyphicon-calendar"></span>
                      </span>
                    </div>
                    <span>～</span>
                    <div className="input-group date" id="datetimepicker2">
                      <DatePicker
                        dateFormat={"yyyy/MM/dd"}
                        className="form-control"
                        selected={this.state.toDate}
                        onChange={(date) => {
                          let fromDate = this.state.fromDate;
                          let toDate = date;
                          if (fromDate && toDate && fromDate?.getTime() >= (toDate?.getTime() ?? 0)) {
                            fromDate = toDate;
                          }
                          this.setState({
                            fromDate: fromDate,
                            toDate: toDate ?? undefined,
                          });
                          this.props.onFetchMedicalRecords(
                            this.state.code,
                            this.state.patientName,
                            1,
                            this.state.fieldRequired,
                            this.state.orthodontistId,
                            fromDate,
                            toDate ?? undefined,
                            this.state.date_order
                          );
                        }}
                      />
                      <span className="input-group-addon">
                        <span className="glyphicon glyphicon-calendar" />
                      </span>
                    </div>
                    {this.state.date_order === "asc" && (
                      <span style={{ cursor: "pointer" }} onClick={this.handleSortDateOrder}>
                        ▲
                      </span>
                    )}
                    {this.state.date_order === "desc" && (
                      <span style={{ cursor: "pointer" }} onClick={this.handleSortDateOrder}>
                        ▼
                      </span>
                    )}
                  </div>
                ),
              },
              {
                html: (
                  <InputGroup
                    className="iprup2 iprup fsc"
                    inputPlaceHolder={Strings.MEDICAL_NUMBER}
                    buttonClassName="btn-success"
                    buttonType="button"
                    value={this.state.code}
                    buttonText={Strings.SEARCH}
                    onValueChanged={(e) => {
                      this.setState({ ...this.state, code: e.target.value });
                      if (!e.target.value) {
                        this.props.onFetchMedicalRecords(
                          "",
                          this.state.patientName,
                          1,
                          this.state.fieldRequired,
                          this.state.orthodontistId,
                          this.state.fromDate,
                          this.state.toDate,
                          this.state.date_order
                        );
                      }
                    }}
                    onButtonClick={() => {
                      this.props.onFetchMedicalRecords(
                        this.state.code,
                        this.state.patientName,
                        1,
                        this.state.fieldRequired,
                        this.state.orthodontistId,
                        this.state.fromDate,
                        this.state.toDate,
                        this.state.date_order
                      );
                    }}
                  ></InputGroup>
                ),
              },
              {
                html: (
                  <InputGroup
                    className="iprup3 iprup fsc"
                    inputPlaceHolder={Strings.PATIENT_NAME}
                    buttonClassName="btn-success"
                    buttonType="button"
                    value={this.state.patientName}
                    onValueChanged={(e) => {
                      this.setState({ ...this.state, patientName: e.target.value });
                      if (!e.target.value) {
                        this.props.onFetchMedicalRecords(
                          this.state.code,
                          "",
                          1,
                          this.state.fieldRequired,
                          this.state.orthodontistId,
                          this.state.fromDate,
                          this.state.toDate,
                          this.state.date_order
                        );
                      }
                    }}
                    onButtonClick={() => {
                      this.props.onFetchMedicalRecords(
                        this.state.code,
                        this.state.patientName,
                        1,
                        this.state.fieldRequired,
                        this.state.orthodontistId,
                        this.state.fromDate,
                        this.state.toDate,
                        this.state.date_order
                      );
                    }}
                    buttonText={Strings.SEARCH}
                  ></InputGroup>
                ),
              },
              { html: Strings.MEDICAL_CONFIRM },
              { html: Strings.ACTION },
            ],
          },
        ]}
        rows={[
          ...this.props.medicalRecords.data.map((record: any) => {
            return this.buildMedicalRecordComponent(record);
          }),
        ]}
      ></Table>
    );
  };

  buildMedicalRecordComponent = (record: any) => {
    return {
      fields: [
        { html: moment(record.created_at).format("YYYY/MM/DD, HH:mm:ss") },
        { html: record.patient ? record.patient.special_code : "" },
        {
          html: (
            <a
              className="text-underline"
              onClick={(e) => {
                this.setState(
                  {
                    ...this.state,
                    patientName: record.patient.full_name,
                  },
                  () =>
                    this.props.onFetchMedicalRecords(
                      this.state.code,
                      record.patient.full_name,
                      1,
                      this.state.fieldRequired,
                      this.state.orthodontistId,
                      this.state.fromDate,
                      this.state.toDate,
                      this.state.date_order
                    )
                );
              }}
            >
              {record.patient.full_name}
            </a>
          ),
        },
        {
          html: (
            <div className="fcc">
              <MainButton
                title={Strings.CONFIRM}
                enabled={true}
                submitting={
                  this.props.medicalRecordState === MedicalRecordState.duplicatingMedicalRecord &&
                  this.props.selectedMedicalRecord.id === record.id
                }
                className="btn btn-white"
                type="a"
                loadingSize={20}
                onSubmit={() => {
                  history.replace({
                    pathname: Routes.MEDICAL_CONFIRM_DETAIL,
                    search: `?recordId=${record.id}`,
                  });
                }}
              />
            </div>
          ),
        },
        {
          html: (
            <div className="fcc">
              <a
                className="btn btn-primary"
                onClick={() => {
                  history.replace({
                    pathname: Routes.MEDICAL_MANAGEMENT_DETAIL,
                    search: `?recordId=${record.id}`,
                  });
                }}
              >
                {Strings.EDIT}
              </a>
              <MainButton
                title={Strings.DELETE}
                enabled={true}
                submitting={
                  this.state.removeRecordId === record.id &&
                  this.props.medicalRecordState === MedicalRecordState.deletingMedicalRecord
                }
                className="btn btn-danger"
                type="a"
                loadingSize={20}
                onSubmit={() => {
                  this.setState(
                    {
                      ...this.state,
                      removeRecordId: record.id,
                    },
                    () => this.props.changeModal(Modals.CONFIRM_REMOVE)
                  );
                }}
              />
            </div>
          ),
        },
      ],
    };
  };

  render() {
    return (
      <MainLayout
        isLoading={this.props.medicalRecordState === MedicalRecordState.fetchingMedicalRecord}
        className="secondpage navstate_show page-medical_management animate-item"
        subClassName="p-medical_management"
      >
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">{Strings.MEDICAL_MANAGEMENT}</div>
            <div className="card-body">
              <div className="btn-group2">
                <MainButton
                  className="btn btn-primary btn-main"
                  onSubmit={() => {
                    this.props.changeModal(Modals.REDIRECT_LINK);
                  }}
                  enabled
                  title={Strings.CREATE_NEW}
                />
              </div>
              {this.buildMedicalRecordTable()}
              <div
                className={
                  this.props.medicalRecords.data.length === 10 || this.props.medicalRecords.last_page > 1
                    ? "showPaginationComponent mt30"
                    : "hide"
                }
              >
                <PaginationComponent
                  className="mt10"
                  currentPage={this.props.medicalRecords.current_page}
                  totalPage={this.props.medicalRecords.last_page}
                  onClick={(page) => {
                    this.props.onFetchMedicalRecords(
                      this.state.code,
                      this.state.patientName,
                      page,
                      this.state.fieldRequired,
                      this.state.orthodontistId,
                      this.state.fromDate,
                      this.state.toDate,
                      this.state.date_order
                    );
                    this.props.paginationUpdated("current_page", page);
                  }}
                ></PaginationComponent>
              </div>
            </div>
          </div>
          <ConfirmDeletePopup
            changeModal={this.props.changeModal}
            currentModal={this.props.currentModal}
            onDelete={() => {
              this.props.onDeleteMedicalRecords(this.state.removeRecordId, () => {
                this.props.onFetchMedicalRecords(
                  this.state.code,
                  this.state.patientName,
                  this.props.medicalRecords.current_page,
                  this.state.fieldRequired,
                  this.state.orthodontistId,
                  this.state.fromDate,
                  this.state.toDate,
                  this.state.date_order
                );
              });
              this.props.changeModal(Modals.NONE);
            }}
          />
          <RedirectLinkPopup
            selectedPatient={defaultUser}
            onSelectPatient={(patient: IUser) => {
              this.props.onChangeMedicalRecord({
                ...defaultRecord(),
                patient: patient,
              });
            }}
            changeModal={this.props.changeModal}
            currentModal={this.props.currentModal}
          />
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ medicalRecord, app }: IRootState) => {
  const { medicalRecordState, medicalRecords, selectedMedicalRecord, searchedOrthodontists } = medicalRecord;
  const { currentModal } = app;
  return {
    medicalRecords,
    medicalRecordState,
    searchedOrthodontists,
    selectedMedicalRecord,
    currentModal,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    onChangeMedicalRecord: (record: IMedicalRecord) => dispatch(selectedMedicalRecordChanged(record)),
    onFetchMedicalRecords: (
      code: string,
      full_name: string,
      page: number,
      fieldRequired?: Array<any>,
      orthodontistId?: number,
      fromDate?: Date,
      toDate?: Date,
      date_order?: string
    ) =>
      Injector.get()
        .find<MedicalRecordContainer>("MedicalRecordContainer")
        .onFetchMedicalRecords(
          dispatch,
          code,
          full_name,
          page,
          fieldRequired,
          orthodontistId,
          fromDate,
          toDate,
          date_order
        ),
    onDeleteMedicalRecords: (id: string, onSuccess: () => void) =>
      Injector.get()
        .find<MedicalRecordContainer>("MedicalRecordContainer")
        .onDeleteMedicalRecord(dispatch, id, onSuccess),
    onDuplicateMedicalRecords: (id: string, onSuccess: () => void) =>
      Injector.get()
        .find<MedicalRecordContainer>("MedicalRecordContainer")
        .onDuplicateMedicalRecord(dispatch, id, onSuccess),
    onSearchOthordontists: (keyword: string, limit?: number) =>
      Injector.get()
        .find<MedicalRecordContainer>("MedicalRecordContainer")
        .onSearchOrthodontist(dispatch, keyword, limit),
    paginationUpdated: (key: string, value: any) => dispatch(medicalRecordsPaginationUpdated(key, value)),
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
  };
};

type MedicalManagementType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(MedicalManagementPage);
