import { Exporter } from "./Exporter";

export class GLB3DExporter extends Exporter {
  async exportModel(
    outputFileName: string,
    scene: any,
    onFileConverted: (convertedFile: any) => void,
    withKey?: string
  ): Promise<void> {
    outputFileName = outputFileName.replaceAll(".ply", ".glb").replaceAll(".stl", ".glb");
    const animations = this.getAnimations(scene);
    const { GLTFExporter } = await import("./GLTFExporter");

    const exporter = new GLTFExporter();
    exporter.parse(
      scene,
      function (result: any) {
        onFileConverted({
          file_type: "glb",
          blob: new Blob([result], { type: "application/octet-stream" }),
          name: outputFileName,
          isUpdate: true,
          key: withKey,
        });
      },
      (error: any) => {},
      { binary: true, animations: animations }
    );
  }

  private getAnimations(scene: any) {
    const animations: any[] = [];

    scene.traverse(function (object: any) {
      animations.push(...object.animations);
    });

    return animations;
  }
}
