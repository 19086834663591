export interface ICard {
  id: string;
  number?: string;
  cvc?: string;
  brand: string;
  expire_date?: string;
  current?: boolean;
}

var cardFromJson = (data: any) => {
  return {
    ...data,
    expire_date: data.expire_date || data.exp_date
  }
}

export const defaultCard: ICard = {
  id: "",
  number: "",
  cvc: "",
  brand: "",
  expire_date: "",
  current: false,
};
