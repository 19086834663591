import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { DI } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import { Input } from "src/components/input";
import Modal from "src/components/modal/modal";
import { IFile } from "src/features/fileManagement/data/models/file";
import { IFolder } from "src/features/fileManagement/data/models/folder";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers";
import { IRootState } from "src/store";

interface GetLinkFilePopupProps {
  itemSelected?: IFolder | IFile;
}

interface GetLinkFilePopupState {}

class GetLinkFilePopup extends React.Component<GetLinkFilePopupType, GetLinkFilePopupState> {
  componentDidMount() {
    DI.get().injectRemoteFileManagementDependencies();
  }

  render() {
    return (
      <Modal
        id="file-management-create-folder-popup"
        dialogExtraClassName="file-management-popup"
        isShow={this.props.currentModal === Modals.FILE_MANAGEMENT_GET_FILE_LINK}
        footerChildren={
          <MainButton
            className="btn btn-primary"
            enabled
            title={"クリップボードにコピー"}
            onSubmit={() => {
              navigator.clipboard.writeText((this.props.itemSelected as IFile)?.url);
              toast.success("クリップボードにコピーされました");
            }}
          />
        }
      >
        <h4 className="text_center overflow">
          <strong>リンクを取得</strong>
        </h4>
        <Input
          inputClassName="form-control"
          value={(this.props.itemSelected as IFile)?.url}
          onChange={() => {}}
          readonly
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({ app }: IRootState) => {
  const { currentModal } = app;

  return {
    currentModal,
  };
};
const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
  };
};

type GetLinkFilePopupType = GetLinkFilePopupProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(GetLinkFilePopup);
