import { OrbitControls, TransformControls } from "@react-three/drei";
import { Canvas } from "@react-three/fiber";
import React from "react";
import { Assets } from "src/utils/assets";
import * as THREE from "three";
import ThreeDSceneEditor from "./ThreeDSceneEditor";

interface ThreeDCanvasEditorProps {
  onClose: () => void;
  cameraPosition: [x: number, y: number, z: number];
  meshes: THREE.Object3D<THREE.Event>[];
  meshSelected: THREE.Mesh;
  handleSelectMesh: (mesh: THREE.Mesh) => void;
  handleUpdateScene: () => void;
}

interface ThreeDCanvasEditorState {
  mode: string;
  orbitEnabled: boolean;
}

class ThreeDCanvasEditor extends React.Component<
  ThreeDCanvasEditorProps,
  ThreeDCanvasEditorState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      mode: "translate",
      orbitEnabled: true,
    };
  }

  handleChangeMode(mode: string) {
    this.setState({ mode });
  }

  toggleControls(e?: THREE.Event) {
    if (e?.type === "mouseDown") this.setState({ orbitEnabled: false });
    if (e?.type === "mouseUp") this.setState({ orbitEnabled: true });
  }

  render(): React.ReactNode {
    return (
      <div className="jaw-viewer-editor">
        <div className="editor-nav">
          <span
            className={`nav-btn ${
              this.state.mode === "translate" ? "actived" : ""
            }`}
            onClick={() => this.handleChangeMode("translate")}
          >
            <img title="Translate" src={Assets.translate} />
          </span>
          <span
            className={`nav-btn ${
              this.state.mode === "rotate" ? "actived" : ""
            }`}
            onClick={() => this.handleChangeMode("rotate")}
          >
            <img title="Rotate" src={Assets.rotate} />
          </span>
          <span
            className={`nav-btn ${
              this.state.mode === "scale" ? "actived" : ""
            }`}
            onClick={() => this.handleChangeMode("scale")}
          >
            <img title="Scale" src={Assets.scale} />
          </span>
        </div>
        <div className="editor-nav nav-close">
          <span className={`nav-btn`} onClick={this.props.onClose}>
            <img title="Close" src={Assets.close} />
          </span>
        </div>
        <div className="editor-nav nav-save">
          <div
            className="btn-group2 threed-viewer-popup"
            onClick={this.props.handleUpdateScene}
          >
            <a className={`btn btn-primary`}>セーブ</a>
          </div>
        </div>
        <Canvas shadows camera={{ position: this.props.cameraPosition }}>
          <ThreeDSceneEditor
            meshes={this.props.meshes}
            handleSelectMesh={this.props.handleSelectMesh}
          />
          {this.props.meshSelected !== undefined ? (
            <TransformControls
              mode={this.state.mode}
              onMouseDown={(e) => this.toggleControls(e)}
              onMouseUp={(e) => this.toggleControls(e)}
              object={this.props.meshSelected}
              visible={this.props.meshSelected === undefined ? false : true}
              enabled={this.props.meshSelected === undefined ? false : true}
            />
          ) : null}
          <OrbitControls enabled={this.state.orbitEnabled} />
          <gridHelper args={[100, 100]} />
        </Canvas>
      </div>
    );
  }
}

export default ThreeDCanvasEditor;
