import { Either, RightSide, LeftSide } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { Pagination } from "src/shared/model/pagination";
import { IMedialRecordRemoteDataSource } from "../datasources/medialRecordRemoteDataSource";
import { Attribute } from "../models/attribute";
import { IMedicalRecord } from "../models/medicalRecord";

export interface IMedicalRecordRepository {
  fetchMedicalRecords(
    code: string,
    full_name: string,
    page: number,
    fieldRequired?: Array<any>,
    orthodontistId?: number,
    fromDate?: Date,
    toDate?: Date,
    date_order?: string
  ): Promise<Either<Failure, Pagination<IMedicalRecord>>>;
  addNewMedicalRecord(medicalRecord: IMedicalRecord): Promise<Either<Failure, void>>;
  updateMedicalRecord(medicalRecord: IMedicalRecord): Promise<Either<Failure, void>>;
  deleteMedicalRecord(id: string): Promise<Either<Failure, void>>;
  duplicateMedicalRecord(id: string): Promise<Either<Failure, void>>;
  fetchAttributes(): Promise<Either<Failure, Pagination<Attribute>>>;
  fetchMedicalRecordDetail(id: string): Promise<Either<Failure, IMedicalRecord>>;
}

export class MedicalRecordRepository implements IMedicalRecordRepository {
  medicalRecordRemoteDatasource: IMedialRecordRemoteDataSource;
  constructor(medicalRecordRemoteDatasource: IMedialRecordRemoteDataSource) {
    this.medicalRecordRemoteDatasource = medicalRecordRemoteDatasource;
  }

  async fetchMedicalRecords(
    code: string,
    full_name: string,
    page: number,
    fieldRequired?: Array<any>,
    orthodontistId?: number,
    fromDate?: Date,
    toDate?: Date,
    date_order?: string
  ): Promise<Either<Failure, Pagination<IMedicalRecord>>> {
    try {
      return RightSide(
        await this.medicalRecordRemoteDatasource.fetchMedicalRecords(
          code,
          full_name,
          page,
          fieldRequired,
          orthodontistId,
          fromDate,
          toDate,
          date_order
        )
      );
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async addNewMedicalRecord(medicalRecord: IMedicalRecord): Promise<Either<Failure, void>> {
    try {
      return RightSide(await this.medicalRecordRemoteDatasource.addNewMedicalRecord(medicalRecord));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async updateMedicalRecord(medicalRecord: IMedicalRecord): Promise<Either<Failure, void>> {
    try {
      return RightSide(await this.medicalRecordRemoteDatasource.updateMedicalRecord(medicalRecord));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async deleteMedicalRecord(id: string): Promise<Either<Failure, void>> {
    try {
      return RightSide(await this.medicalRecordRemoteDatasource.deleteMedicalRecord(id));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async duplicateMedicalRecord(id: string): Promise<Either<Failure, void>> {
    try {
      return RightSide(await this.medicalRecordRemoteDatasource.duplicateMedicalRecord(id));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async fetchAttributes(): Promise<Either<Failure, Pagination<Attribute>>> {
    try {
      return RightSide(await this.medicalRecordRemoteDatasource.fetchAttributes());
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async fetchMedicalRecordDetail(id: string): Promise<Either<Failure, IMedicalRecord>> {
    try {
      return RightSide(await this.medicalRecordRemoteDatasource.fetchMedicalRecordDetail(id));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }
}
