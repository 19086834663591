import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IFile } from "../../data/models/file";
import { IFolder } from "../../data/models/folder";
import { IRemoteFileManagementRepository } from "../../data/repositories/remoteFileManagementRepository";

export class RenameFileManagement extends UseCase<RenameFileManagementParams, void> {
  private repository: IRemoteFileManagementRepository;

  constructor(repository: IRemoteFileManagementRepository) {
    super();
    this.repository = repository;
  }

  call(params: RenameFileManagementParams): Promise<Either<Failure, void>> {
    return this.repository.renameFileManagement(params.itemSelected, params.rename);
  }
}

export class RenameFileManagementParams {
  itemSelected: IFolder | IFile;
  rename: string;

  constructor(itemSelected: IFolder | IFile, rename: string) {
    this.itemSelected = itemSelected;
    this.rename = rename;
  }
}
