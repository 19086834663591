import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IMedicalRecord } from "src/features/medicalRecord/data/models/medicalRecord";
import { IRemoteFileDownloadRepository } from "../../data/repositories/remoteFilesDownloadRepository";

export class LinkSelectedPatient extends UseCase<LinkSelectedPatientParams, IMedicalRecord | undefined>{
    private repository: IRemoteFileDownloadRepository;
    constructor(repository: IRemoteFileDownloadRepository) {
        super();
        this.repository = repository;
    }

    call(params: LinkSelectedPatientParams): Promise<Either<Failure, IMedicalRecord | undefined>> {
        return this.repository.linkSelectedPatient(params.patient_id)
    }

}

export class LinkSelectedPatientParams {
    patient_id: string
    constructor(patient_id: string) {
        this.patient_id = patient_id;
    }
}