import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IRemoteFileManagementRepository } from "../../data/repositories/remoteFileManagementRepository";

export class ImportFileManagement extends UseCase<ImportFileManagementParams, void> {
  private repository: IRemoteFileManagementRepository;

  constructor(repository: IRemoteFileManagementRepository) {
    super();
    this.repository = repository;
  }

  call(params: ImportFileManagementParams): Promise<Either<Failure, void>> {
    return this.repository.importFileManagement(params.breadcrumb, params.files, params.action);
  }
}

export class ImportFileManagementParams {
  breadcrumb: string;
  files: File[];
  action?: string;

  constructor(breadcrumb: string, files: File[], action?: string) {
    this.breadcrumb = breadcrumb;
    this.files = files;
    this.action = action;
  }
}
