import { Validation } from "src/helpers/validation"

export const forgetPasswordValidate = (field: string, value: any) => {
    switch(field) {
        case "email":
            if(!Validation.validateEmail(value))
                return "Email is invalid";
            break;
        case "password":
        case "passwordCF":
            if(!Validation.validatePassword(value))
                return "Password is invalid";
            break;
    }
    return "";
}