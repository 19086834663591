import React from "react";
import { Link } from "react-router-dom";
import { Strings } from "src/constants";
import { Routes } from "src/helpers";

export class Footer extends React.Component {
    render() {
        return (
          <footer className="footer">
            <div className="footer-info container"></div>
            <div className="backtop">
              <div className="backtop-icon">
                <a className="lh00" href="javascript:void(0)">
                  <img src="./common_img/backtop.png" alt="PAGE UP" />
                </a>
              </div>
            </div>
            <div className="footer-bottom">
              <p>©2022</p>
            </div>
          </footer>
        );
    }
}