import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { Pagination } from "src/shared/model/pagination";
import { IPatientAnalytic } from "../../data/models/patientAnalytic";
import { IAnalyticRepository } from "../../data/repositories/patientAnalyticRepository";

export class FetchPatientAnalytics extends UseCase<
  FetchPatientAnalyticsParams,
  Pagination<IPatientAnalytic>
> {
  private repository: IAnalyticRepository;
  constructor(repository: IAnalyticRepository) {
    super();
    this.repository = repository;
  }
  call(
    params: FetchPatientAnalyticsParams
  ): Promise<Either<Failure, Pagination<IPatientAnalytic>>> {
    return this.repository.fetchPatientAnalytics(
      params.code,
      params.fullName,
      params.limit,
      params.page
    );
  }
}

export class FetchPatientAnalyticsParams {
  code: string;
  fullName: string;
  limit: number;
  page: number;
  constructor(code: string, fullName: string, limit: number, page: number) {
    this.code = code;
    this.fullName = fullName;
    this.limit = limit;
    this.page = page;
  }
}
