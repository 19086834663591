import React from "react";
import { connect } from "react-redux";
import { IRootState } from "src/store";

interface StoragePercentComponentProps {}

interface StoragePercentComponentState {}

class StoragePercentComponent extends React.Component<StoragePercentComponentType, StoragePercentComponentState> {
  render() {
    const maximumReadable = Math.floor(this.props.maximum / Math.pow(1024, 3));
    const totalReadable = (this.props.total / Math.pow(1024, 3)).toString().slice(0, 5);
    const storageUsed = (this.props.total / this.props.maximum) * 100;

    return (
      <div className="storage-percent">
        {`${maximumReadable}GB中${totalReadable}GBを使用`}
        <div className="storage-percent-line">
          <div
            className="line"
            style={{ width: storageUsed + "%", backgroundColor: storageUsed >= 90 ? "red" : "#02a3af" }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ fileManagement }: IRootState) => {
  const { fileManagementData } = fileManagement;
  const { maximum, total } = fileManagementData;

  return {
    maximum,
    total,
  };
};
const mapDispatcherToProps = () => {
  return {};
};

type StoragePercentComponentType = StoragePercentComponentProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(StoragePercentComponent);
