import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactSVG } from "react-svg";
// import { Injector } from "src/base/Injector";
import { Strings } from "src/constants";
// import { AuthContainer } from "src/features/auth/presenter/container/authContainer";
import { Routes } from "src/helpers";
import { Assets } from "src/utils/assets";

export interface INavProps {
  isBlock: boolean;
  isStripe: boolean;
  isShowNavBar: boolean;
  unsharedFilesCount: number;
  onLogout: () => void;
}

export function Nav(props: INavProps) {
  const location = useLocation();

  const [isShowingItems, showSidebar] = useState(true);

  const isActive = (requirePath: string) => {
    return location.pathname.search(requirePath) != -1;
  };

  const shouldShowItem = () => {
    return props.isBlock || !isShowingItems;
  };

  return (
    <div id="layoutSidenav_nav" className={`${isShowingItems ? "" : "shrink"}${props.isShowNavBar ? " show" : ""}`}>
      <nav className="sb-sidenav accordion sb-sidenav-light" id="sidenavAccordion">
        <div className="sb-sidenav-menu">
          <div className={`nav ${isShowingItems ? "" : "nav-shrink"}`}>
            <Link
              className={`nav-link ${shouldShowItem() ? "hide" : ""} ${
                isActive(Routes.NOTICE_OPERATOR) ? "active" : ""
              }`}
              to={Routes.NOTICE_OPERATOR}
            >
              <img src={Assets.icon1} alt={Strings.NOTICE_OPERATOR} />
              {Strings.NOTICE_OPERATOR}
            </Link>
            <Link
              className={`nav-link ${shouldShowItem() ? "hide" : ""} ${isActive(Routes.PATIENT) ? "active" : ""}`}
              to={Routes.PATIENT_MANAGEMENT}
            >
              <img src={Assets.icon2} alt={Strings.PATIENT_MANAGEMENT} />
              {Strings.PATIENT_MANAGEMENT}
            </Link>
            <Link
              className={`nav-link ${shouldShowItem() ? "hide" : ""} ${isActive(Routes.DENTIST) ? "active" : ""}`}
              to={Routes.DENTIST_MANAGEMENT}
            >
              <img src={Assets.icon3} alt={Strings.DENTIST_MANAGEMENT} />
              {Strings.DENTIST_MANAGEMENT}
            </Link>
            <Link
              className={`nav-link ${shouldShowItem() ? "hide" : ""} ${isActive(Routes.MEDICAL) ? "active" : ""}`}
              to={Routes.MEDICAL_MANAGEMENT}
            >
              <img src={Assets.icon4} alt={Strings.MEDICAL_MANAGEMENT} />
              {Strings.MEDICAL_MANAGEMENT}
            </Link>
            <Link
              className={`nav-link ${shouldShowItem() ? "hide" : ""} ${
                isActive(Routes.PLY_DOWNLOAD_MANAGEMENT) ? "active" : ""
              }`}
              to={Routes.PLY_DOWNLOAD_MANAGEMENT}
            >
              <img src={Assets.icon11} alt={Strings.PLY_DOWNLOAD_MANAGEMENT} />
              {Strings.PLY_DOWNLOAD_MANAGEMENT}
              <span className="number">{props.unsharedFilesCount}</span>
            </Link>
            <Link
              className={`nav-link ${shouldShowItem() ? "hide" : ""} ${
                isActive(Routes.FILE_MANAGEMENT) ? "active" : ""
              }`}
              to={Routes.FILE_MANAGEMENT}
            >
              <img src={Assets.fileManagement} alt={Strings.FILE_MANAGEMENT} />
              {Strings.FILE_MANAGEMENT}
            </Link>
            <Link
              className={`nav-link ${shouldShowItem() ? "hide" : ""} ${
                isActive(Routes.PATIENT_ANALYTIC) ? "active" : ""
              } menu7`}
              to={Routes.PATIENT_ANALYTIC_MANAGEMENT}
            >
              {" "}
              <img src={Assets.icon8} alt={Strings.MEDICAL_RECORD_ANALYSIS} />
              {Strings.MEDICAL_RECORD_ANALYSIS}
            </Link>
            <Link
              className={`nav-link ${shouldShowItem() ? "hide" : ""} ${isActive(Routes.NOTIFICATION) ? "active" : ""}`}
              to={Routes.NOTIFICATION_MANAGEMENT}
            >
              <img src={Assets.icon6} alt={Strings.NOTIFICATION_MANAGEMENT} />
              {Strings.NOTIFICATION_MANAGEMENT}
            </Link>
            <Link
              className={`nav-link ${shouldShowItem() ? "hide" : ""} ${isActive(Routes.ACCOUNT) ? "active" : ""}`}
              to={Routes.ACCOUNT_MANAGEMENT}
            >
              <img src={Assets.icon5} alt={Strings.ACCOUNT_MANAGEMENT} />
              {Strings.ACCOUNT_MANAGEMENT}
            </Link>
            <Link
              className={`nav-link ${!isShowingItems || !props.isStripe ? "hide" : ""} ${
                isActive(Routes.SUBSCRIPTION) ? "active" : ""
              }`}
              to={Routes.SUBSCRIPTION_MANAGEMENT}
            >
              <img src={Assets.icon9} alt={Strings.SUBSCRIPTION_MANAGEMENT} />
              {Strings.SUBSCRIPTION_MANAGEMENT}
            </Link>
            <a
              className={`nav-link btn ${!isShowingItems ? "hide" : ""} `}
              onClick={() => {
                props.onLogout();
              }}
            >
              <img src={Assets.icon7} alt={Strings.LOGOUT} />
              {Strings.LOGOUT}
            </a>
            <ReactSVG
              className={`${isShowingItems ? "enlarge-shrink" : ""}`}
              src={isShowingItems ? Assets.hide : Assets.enlarge}
              onClick={() => {
                showSidebar((prevState) => {
                  return !prevState;
                });
              }}
            />
          </div>
        </div>
      </nav>
    </div>
  );
}
