import { action } from "typesafe-actions";
import { ThreeDViewerActionType } from "./types";

export function threeDViewerUploadMeshFiles(meshFiles: Map<string, File>) {
    return action(ThreeDViewerActionType.THREED_VIEWER_UPLOAD, { meshFiles })
}

export function threeDViewerUpdateCameraPosition(cameraPosition: [x: number, y: number, z: number], type: string) {
    return action(ThreeDViewerActionType.THREED_VIEWER_UPDATE_CAMERA_POSITION, { cameraPosition, type })
}
