import React from "react";
import { Assets } from "src/utils/assets";
import { Input } from "src/components/input";
import { PasswordInput } from "src/components/input/passwordInput";
import { Strings } from "src/constants";
import { IRootState } from "src/store";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { Injector } from "src/base/Injector";
import { AuthContainer } from "src/features/auth/presenter/container/authContainer";
import { MainButton } from "src/components/button/mainButton";
import { Link } from "react-router-dom";
import { Routes } from "src/helpers/enums";
import { Label } from "src/components/label";
import { MainLogo } from "src/shared/components/mainLogo";
import { AuthHeader } from "src/shared/components/authHeader";
import { MainBody } from "src/shared/layouts/mainBody";
import { AuthLayout } from "../layouts/authLayout";

interface ILoginState {
    id: string;
    password: string;
    shouldSaveLoginInfo: boolean
}

class LoginPage extends React.Component<LoginType, ILoginState> {
    constructor(props: any) {
        super(props);
        this.state = {
            id: '',
            password: '',
            shouldSaveLoginInfo: false
        }
    }
    componentDidMount() {
        if (localStorage.getItem("id"))
            this.setState({
                ...this.state,
                id: localStorage.getItem("id") ?? this.state.id,
                password: localStorage.getItem("password") ?? this.state.password,
                shouldSaveLoginInfo: true
            })

        $(document).ready(function () {
            if ($('input[name="role"]:checked').val() == '管理者') {
                $('.changeDrop .select').hide();
                $('.changeDrop input').show();
            } else {
                $('.changeDrop input').hide();
                $('.changeDrop .select').css('display', 'flex');
            }
            $('input[name="role"]').change(function () {
                if ($(this).val() == '販売店管理者') {
                    $('.changeDrop .select').css('display', 'flex');
                    $('.changeDrop input').hide();
                } else {
                    $('.changeDrop .select').hide();
                    $('.changeDrop input').show();
                }
            })
        });
    }

    render(): React.ReactNode {
        return (
            <AuthLayout isLoading={this.props.isSubmitting} sectionClassname="p-login">
                <div className="container-fluid">
                    <AuthHeader />
                    <div className="loginbox_c fz16">
                        <MainLogo />
                        <form className="formlogin" onSubmit={(e) => {
                            e.preventDefault()
                            this.props.onSubmit(this.state.id, this.state.password, this.state.shouldSaveLoginInfo)
                        }}>
                            <div className="formbox_e">
                                <Input
                                    inputClassName="form-control"
                                    inputPlaceHolder={Strings.ID}
                                    type="text"
                                    maxLength={50}
                                    value={this.state.id}
                                    onChange={(value) => this.setState({ ...this.state, id: value })}
                                ></Input>
                            </div>
                            <div className="formbox_e">
                                <PasswordInput
                                    className="fsc boxpassword"
                                    inputClassName="form-control pass_log_id"
                                    maxLength={20}
                                    value={this.state.password}
                                    onChange={(value) => this.setState({ ...this.state, password: value })}
                                    inputPlaceHolder={Strings.PASSWORD}
                                ></PasswordInput>
                            </div>
                            <Label text={Strings.SAVE_LOGIN_INFO} className="fsc mr30 mt10 remember-text" >
                                <Input
                                    type="checkbox"
                                    className="fsc"
                                    inputClassName="mr05"
                                    value={this.state.shouldSaveLoginInfo}
                                    checked={this.state.shouldSaveLoginInfo}
                                    name="isSmoking"
                                    onChange={(checked) => {
                                        this.setState({
                                            ...this.state,
                                            shouldSaveLoginInfo: checked
                                        })
                                    }}
                                ></Input>
                            </Label>
                            <div className="forbox_e boxbtn text-center">
                                {
                                    <MainButton
                                        title={Strings.LOGIN}
                                        submitting={this.props.isSubmitting}
                                        isSubmitButton={true}
                                        enabled={this.state.id.length != 0 && this.state.password.length != 0}
                                        className="btn btn-large btn-primary btn-primary-2"
                                        onSubmit={() => {

                                        }}
                                    />}
                            </div>
                            <div className="formbox_e mt20 text-center">
                                <Link to={Routes.FORGET_PASSWORD}>
                                    {Strings.FORGOT_PASSWORD}
                                </Link>
                            </div>
                        </form>
                    </div>
                </div>
            </AuthLayout>

        )
    }
}

const mapStateToProps = ({ login }: IRootState) => {
    const { isSubmitting } = login
    return {
        isSubmitting
    };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
    return {
        onSubmit: (id: string, password: string, shouldSaveLoginInfo: boolean) =>
            Injector.get().find<AuthContainer>("AuthContainer").normalLogin(dispatch, id, password, shouldSaveLoginInfo),
    };
};

type LoginType = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(LoginPage);