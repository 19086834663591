import { Strings } from "src/constants";
import { AnalyticFooter } from "./analyticFooter";

export class EppAnalyticFooter extends AnalyticFooter {
    title = Strings.ANALYSIS_EPP_EVAL
    content = <div className="comprehensive-box comprehensive-box2">
        <div className="comprehensive-box--ins shadow-box green mt20">
            <p>改善</p>
            <div className="line-bar">2％（3本）</div>
        </div>
        <div className="comprehensive-box--ins shadow-box purple mt20">
            <p>悪化</p>
            <div className="line-bar">1％（1本）</div>
        </div>
    </div>
}