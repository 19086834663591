export interface MFile {
  id: number;
  medical_record_id: number;
  file_type: string;
  image_type: string;
  path: string;
  base64: string;
  isUpdate: boolean;
  label: string;
  name?: string;
  title?: string;
  blob?: Blob;
  created_at?: Date;
  file?: File;
  fileUrl?: string;
}

export function mFileFromJson(json: any): MFile {
  return {
    id: json.id ?? 0,
    medical_record_id: json.medical_record_id ?? 0,
    file_type: json.file_type ?? "",
    image_type: json.image_type ?? "",
    path: json.path ?? "",
    base64: json.base64 ?? "",
    label: json.label || json.title,
    isUpdate: json.isUpdate ?? false,
    name: json.name || json.title || "",
    blob: json.blob,
    created_at: new Date(json.created_at),
    file: json.file,
  };
}

export function getFilePath(mFile?: MFile) {
  return mFile?.base64 || mFile?.path;
}

export function getFileNameFromMFile(defaultName: string, mFile?: MFile) {
  if (!mFile) return defaultName;
  if (mFile.name) {
    return mFile.name.replaceAll("\\", "/").split("/").slice(-1)[0];
  }
  if (mFile.path) {
    const firstDotIndex = mFile.path.replaceAll("\\", "/").split("/").slice(-1)[0].indexOf(".") + 1;

    return mFile.path.replaceAll("\\", "/").split("/").slice(-1)[0].slice(firstDotIndex);
  }
  return defaultName;
}

export function hasData(mFile?: MFile): boolean {
  if (!mFile) return false;
  return mFile && (mFile.base64?.length > 0 || mFile.blob !== undefined || mFile.path.length > 0);
}
