
import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IUser } from "src/features/account/data/models/user";
import { IOrthodontistRepository } from "../../data/repositories/orthodontistRepository";

export class FetchOrthodontistDetails extends UseCase<FetchOrthodontistDetailsParams, IUser | undefined>{
    private repository: IOrthodontistRepository
    constructor(repository: IOrthodontistRepository) {
        super()
        this.repository = repository
    }

    call(params: FetchOrthodontistDetailsParams): Promise<Either<Failure, IUser | undefined>> {
        return this.repository.fetchOrthodontistDetails(params.id)
    }
}

export class FetchOrthodontistDetailsParams {
    id: string
    constructor(id: string) {
        this.id = id
    }
}