import { Endpoints } from "src/base/Endpoints";
import { PatientsStatus } from "src/base/Enums";
import {
  formDataUser,
  IUser,
  urlEncodedUser,
  userFromJson,
  userJson,
} from "src/features/account/data/models/user";
import { HttpMethod } from "src/helpers";
import { IApiService } from "src/services/api/ApiService";
import { Pagination, paginationInit } from "src/shared/model/pagination";

export interface IOrthodontistRemoteDataSource {
  fetchOrthodontists(
    code: string,
    full_name: string,
    orthodontist_type: number,
    specialStatus: number,
    page: number,
    status: number,
    limit?: number,
    action?: string
  ): Promise<Pagination<IUser>>;
  fetchOrthodontistDetails(id: any): Promise<IUser | undefined>;
  addOrthodontists(user: IUser): Promise<boolean>;
  updateOrthodontist(user: IUser): Promise<boolean>;
  deleteOrthodontist(id: string): Promise<boolean>;
  updateOrthodontistStatus(id: string): Promise<boolean>;
  sendMailToOrthodontist(orthodontistId: string): Promise<void>
}

export class OrthodontistRemoteDataSource
  implements IOrthodontistRemoteDataSource {
  apiService: IApiService;
  constructor(apiService: IApiService) {
    this.apiService = apiService;
  }

  async sendMailToOrthodontist(orthodontistId: string): Promise<void> {
    await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: Endpoints.sendOrthodontistEmail(orthodontistId),
      shouldAuthorize: true,
    })
  }

  async fetchOrthodontists(
    code: string,
    full_name: string,
    orthodontist_type: number,
    specialStatus: number,
    page: number,
    status: number,
    limit: number = 20,
    action?: string
  ): Promise<Pagination<IUser>> {
    let params: any = {
      code,
      full_name,
      page,
      status: Object.keys(PatientsStatus)[status],
      limit,
    };
    if (action){
      params.action = action
    }
    if (status !== 0) {
      params.status = status === 1 ? "valid" : "invalid";
    }
    if (specialStatus !== 0) {
      params.special_status = specialStatus === 1 ? "valid" : "invalid"
    }

    if (orthodontist_type !== 0) {
      params.orthodontist_type = orthodontist_type;
    }
    let response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.getOrthodontists,
      params: params,
      shouldAuthorize: true,
    });
    if (!response.data || !response.data.data) return paginationInit;
    var data = response.data.data.data
    if (action){
        data = response.data.data
    }
    return {
      ...response.data.data,
      data: (data as Array<any>).map((user) =>
        userFromJson(user)
      ),
    };
  }

  async fetchOrthodontistDetails(id: any): Promise<IUser | undefined> {
    let response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.fetchOrthodontist(id),
      shouldAuthorize: true,
    })
    if (!response.data || !response.data.data || !response.data.data.id || response.data.data.id.toString() !== id) return undefined
    return userFromJson(response.data.data)
  }

  async addOrthodontists(user: IUser): Promise<boolean> {
    await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: Endpoints.addOrthodontist,
      shouldAuthorize: true,
      body: {
        data: formDataUser(user),
      },
      headers: { "Content-Type": "multipart/form-data" },
    });
    return true;
  }

  async updateOrthodontist(user: IUser): Promise<boolean> {
    await this.apiService.request({
      method: HttpMethod.PUT,
      endpoint: Endpoints.updateOrthodontist(user.id),
      shouldAuthorize: true,
      body: {
        data: urlEncodedUser(user),
      },
      headers: { "content-type": "application/x-www-form-urlencoded" },
    });
    return true;
  }

  async deleteOrthodontist(id: string): Promise<boolean> {
    await this.apiService.request({
      method: HttpMethod.DELETE,
      endpoint: Endpoints.deleteOrthodontist(id),
      shouldAuthorize: true,
    });
    return true;
  }

  async updateOrthodontistStatus(id: string): Promise<boolean> {
    await this.apiService.request({
      method: HttpMethod.PUT,
      endpoint: Endpoints.updateOrthodontistStatus(id),
      shouldAuthorize: true,
    });
    return true;
  }
}
