import { AnalyticDetailTab, PatientAnalyticState } from "src/base/Enums";
import {
  IPatientAnalytic,
} from "src/features/analytic/data/models/patientAnalytic";
import { Pagination } from "src/shared/model/pagination";
import { ActionType } from "typesafe-actions";
import * as actions from "./actions";

export interface IPatientAnalyticState {
  patientAnalytics: Pagination<IPatientAnalytic>;
  patientAnalyticDetail: IPatientAnalytic;
  tab: {
    analysisId: string;
    type: AnalyticDetailTab;
  };
  isNotFound?: boolean
  patientAnalyticState: PatientAnalyticState;
}

export enum PatientAnalyticType {
  PATIENT_ANALYTIC_IS_NOT_FOUND_UPDATED = "PATIENT_ANALYTIC_IS_NOT_FOUND_UPDATED",
  PATIENT_ANALYTICS_FETCHED = "PATIENT_ANALYTICS_FETCHED",
  PATIENT_ANALYTIC_DETAILS_FETCHED = "PATIENT_ANALYTIC_DETAILS_FETCHED",
  PATIENT_ANALYTIC_DETAILS_TAB_CHANGED = "PATIENT_ANALYTIC_DETAILS_TAB_CHANGED",
  PATIENT_ANALYTIC_ON_DATES_SHOWED = "PATIENT_ANALYTIC_ON_DATES_SHOWED",
  PATIENT_ANALYTICS_PAGITION_VALUE_CHANGED = "PATIENT_ANALYTICS_PAGITION_VALUE_CHANGED",
  PATIENT_ANALYTIC_STATE_CHANGED = "PATIENT_ANALYTIC_STATE_CHANGED",
}

export type PatientAnalyticActions = ActionType<typeof actions>;
