import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { FileManagementState } from "src/base/Enums";
import { DI, Injector } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import { Input } from "src/components/input";
import Modal from "src/components/modal/modal";
import { Strings } from "src/constants";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers";
import { IRootState } from "src/store";
import { RemoteFileManagementContainer } from "../../container/RemoteFileManagementContainer";

interface CreateFolderPopupProps {
  breadcrumbPath: string;
}

interface CreateFolderPopupState {
  name: string;
  error: string;
}

class CreateFolderPopup extends React.Component<CreateFolderPopupType, CreateFolderPopupState> {
  constructor(props: any) {
    super(props);

    this.state = {
      name: "",
      error: "",
    };
  }

  componentDidMount() {
    DI.get().injectRemoteFileManagementDependencies();
  }

  handleRename = (value: string) => {
    this.setState({ name: value }, () => {
      if (new RegExp(/[`!@#$%^&*+={};:\\|,.<>\/?~]/).test(value))
        this.setState({ error: "フォルダ名に特殊文字は使用できません。" });
      else this.setState({ error: "" });
    });
  };

  handleValidFolderName = (folderName: string) => {
    const validFolderName = folderName.replaceAll(" ", "_");

    return validFolderName;
  };

  handleSubmit = () => {
    const validFolderName = this.handleValidFolderName(this.state.name);

    this.props.onCreateFolder(this.props.breadcrumbPath, validFolderName).then(() => {
      this.props.changeModal(Modals.NONE);
      this.props.onFetchFileManagement(this.props.breadcrumbPath);
    });
  };

  render() {
    return (
      <Modal
        id="file-management-create-folder-popup"
        dialogExtraClassName="file-management-popup"
        isShow={this.props.currentModal === Modals.FILE_MANAGEMENT_CREATE_FOLDER}
        footerChildren={
          <div className="btn-group2">
            <MainButton
              className="btn btn-secondary"
              enabled
              title={Strings.RETURN}
              onSubmit={() => this.props.changeModal(Modals.NONE)}
            />
            <MainButton
              className="btn btn-primary"
              enabled={this.state.name.length > 0 && !this.state.error}
              title={Strings.REGISTER}
              onSubmit={this.handleSubmit}
              submitting={this.props.fileManagementState === FileManagementState.submitButton}
            />
          </div>
        }
      >
        <h4 className="text_center overflow">
          <strong>フォルダ作成</strong>
        </h4>
        <Input
          inputClassName="form-control"
          value={this.state.name}
          onChange={this.handleRename}
          error={this.state.error}
        />
      </Modal>
    );
  }
}

const mapStateToProps = ({ fileManagement, app }: IRootState) => {
  const { fileManagementState } = fileManagement;
  const { currentModal } = app;

  return {
    fileManagementState,
    currentModal,
  };
};
const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
    onCreateFolder: (breadcrumbPath: string, name: string) =>
      Injector.get()
        .find<RemoteFileManagementContainer>("RemoteFileManagementContainer")
        .onCreateFolder(dispatch, breadcrumbPath, name),
    onFetchFileManagement: (breadcrumbPath: string) =>
      Injector.get()
        .find<RemoteFileManagementContainer>("RemoteFileManagementContainer")
        .onFetchFileManagement(dispatch, breadcrumbPath),
  };
};

type CreateFolderPopupType = CreateFolderPopupProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(CreateFolderPopup);
