import moment from "moment";
import React from "react";
import ReactDatePicker from "react-datepicker";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnalyticDetailTab, PatientAnalyticState } from "src/base/Enums";
import { Injector } from "src/base/Injector";
import { Strings } from "src/constants";
import {
  defaultPatientAnalysisDetail as defaultPatientAnalysisDetail,
  IAnalyticDate,
  IPatientAnalyticDetail,
} from "src/features/analytic/data/models/patientAnalytic";
import { AnalyticContainer } from "src/features/analytic/presenter/container/AnalyticContainer";
import { patientAnalysisDetailFetched, patientAnalyticOnDateShowed } from "src/features/analytic/presenter/store/patientAnalytic";
import { history, Routes } from "src/helpers";
import { BOPAnalyticTab } from "src/layouts/analytic/tab/bopAnalyticTab";
import { EPPAnalyticTab } from "src/layouts/analytic/tab/eppAnalyticTab";
import { PCRAnalyticTab } from "src/layouts/analytic/tab/pcrAnalyticTab";
import { TotalAnalyticTab } from "src/layouts/analytic/tab/totalAnalyticTab";
import MainLayout from "src/layouts/main/mainLayout";
import { BackButton } from "src/shared/components/backButton";
import { LoadingComponent } from "src/shared/components/loadingComponent";
import { NoDataWrapperComponent } from "src/shared/components/noDataWrapperComponent";
import { IRootState } from "src/store";
import { findParamsFromSearchString, findSubRouteFromPathName } from "src/utils/utils";
import ja from "date-fns/locale/ja";

export interface AnalysisDetailPageState {
  from_date?: Date
  to_date?: Date
}

class AnalysisDetailPage extends React.Component<AnalysisDetailPageType, AnalysisDetailPageState> {
  constructor(props: any) {
    super(props)
    var fromDate = undefined;
    var toDate = undefined
    this.state = {
      from_date: fromDate,
      to_date: toDate
    }
  }

  componentDidMount() {
    let params = findParamsFromSearchString(window.location.search)
    if (params?.has("analysisId") && params.get("analysisId")) {
      let fromDate = params?.get("fromDate") ? new Date(params?.get("fromDate")!) : undefined
      let toDate = params?.get("toDate") ? new Date(params?.get("toDate")!) : undefined
      let tab: AnalyticDetailTab = AnalyticDetailTab[(params?.get("tab") || "total").toLowerCase() as keyof typeof AnalyticDetailTab] || AnalyticDetailTab.total
      this.setState({
        from_date: fromDate,
        to_date: toDate
      })
      this.props.onFetchPatientAnalyticDetails(
        params?.get("analysisId")!,
        tab,
        fromDate,
        toDate
      )
      this.changeRouteParams(params?.get("analysisId")!, tab, fromDate, toDate)
    }
  }


  buildAnalyticTab = () => {
    switch (this.props.tab.type) {
      case AnalyticDetailTab.total:
        return <TotalAnalyticTab
          id="tab1"
          className="animate-item"
          onChangeDates={(dates) => {
            this.props.onChangeDates(AnalyticDetailTab.total, dates);
          }}
          analyticDetail={
            this.props.patientAnalyticDetail.analytic_details.get(
              AnalyticDetailTab.total
            ) ?? defaultPatientAnalysisDetail
          }
        />
      case AnalyticDetailTab.pcr:
        return <PCRAnalyticTab
          id="tab2"
          className="animate-item"
          onChangeDates={(dates) => {
            this.props.onChangeDates(AnalyticDetailTab.pcr, dates);
          }}
          analyticDetail={
            this.props.patientAnalyticDetail.analytic_details.get(
              AnalyticDetailTab.pcr
            ) ?? defaultPatientAnalysisDetail
          }
        />
      case AnalyticDetailTab.epp:
        return <EPPAnalyticTab
          id="tab3"
          className="animate-item"
          onChangeDates={(dates) => {
            this.props.onChangeDates(AnalyticDetailTab.epp, dates);
          }}
          analyticDetail={
            this.props.patientAnalyticDetail.analytic_details.get(
              AnalyticDetailTab.epp
            ) ?? defaultPatientAnalysisDetail
          }
        />
      case AnalyticDetailTab.bop:
        return <BOPAnalyticTab
          id="tab4"
          onChangeDates={(dates) => {
            this.props.onChangeDates(AnalyticDetailTab.bop, dates);
          }}
          className="animate-item"
          analyticDetail={
            this.props.patientAnalyticDetail.analytic_details.get(
              AnalyticDetailTab.bop
            ) ?? defaultPatientAnalysisDetail
          }
        />
    }
  }

  changeRouteParams(analyticId: string, tab: AnalyticDetailTab, fromDate?: Date, toDate?: Date) {
    let currentRoute = findSubRouteFromPathName(window.location.pathname)
    history.replace({
      pathname: currentRoute,
      search: `?analysisId=${analyticId}&tab=${tab == AnalyticDetailTab.total ? "total" : tab.toLowerCase()}${fromDate ? `&fromDate=${moment(fromDate).format('YYYY-MM-DD')}` : ``}${toDate ? `&toDate=${moment(toDate).format('YYYY-MM-DD')}` : ""}`
    })
  }

  buildAnalyticTable = () => {
    return <div className="dataTable-container">
      <div className="analysis-head">
        <div className="analysis-head-item">
          <div className="center-box">
            <h3>患者番号</h3>
            <p className="text_bold">
              {" "}
              <span className="text_bold">{(this.props.patientAnalyticDetail.analytic_details.get(this.props.tab.type)?.analysis.patient.clinic?.medical_institution || "") + this.props.patientAnalyticDetail.analytic_details.get(this.props.tab.type)?.analysis.patient.code} </span>{this.props.patientAnalyticDetail.analytic_details.get(this.props.tab.type)?.analysis.patient.full_name}
            </p>
          </div>
        </div>
        <div className="analysis-head-item">
          <div className="flexbox fsc calendar-box datepick">
            <ReactDatePicker
              onChange={date => {
                let fromDate = date
                let toDate = this.state.to_date
                if (fromDate && toDate && fromDate?.getTime() >= (toDate?.getTime() ?? 0)) {
                  toDate = fromDate
                }
                this.setState({
                  from_date: fromDate ?? undefined,
                  to_date: toDate
                });
                this.changeRouteParams(this.props.tab.analysisId, this.props.tab.type, fromDate ?? undefined, toDate)
                this.props.onFetchPatientAnalyticDetails(
                  this.props.tab.analysisId,
                  this.props.tab.type,
                  fromDate ?? undefined,
                  toDate
                );
              }}
              locale={ja}
              selected={this.state.from_date}
              wrapperClassName="calendar-box-item"
              customInput={<div className="analytic-date-wrapper">
                <h3>集計開始日</h3>
                <div className="input-group date" id="datetimepicker1">
                  <span className="input-group-addon">
                    <span className="glyphicon glyphicon-calendar"></span>
                  </span>
                  {this.state.from_date ? <input className="form-control" type="text" value={moment(this.state.from_date).format('YYYY年MM月DD日')} /> : undefined}
                </div>
              </div>}
            />
            <ReactDatePicker
              onChange={date => {
                let fromDate = this.state.from_date
                let toDate = date
                if (fromDate && toDate && fromDate?.getTime() >= (toDate?.getTime() ?? 0)) {
                  fromDate = toDate
                }
                this.setState({
                  from_date: fromDate,
                  to_date: toDate ?? undefined
                });
                this.changeRouteParams(this.props.tab.analysisId, this.props.tab.type, fromDate, toDate ?? undefined)
                this.props.onFetchPatientAnalyticDetails(
                  this.props.tab.analysisId,
                  this.props.tab.type,
                  fromDate,
                  toDate ?? undefined
                );
              }}
              locale={ja}
              wrapperClassName="calendar-box-item"
              selected={this.state.to_date}
              customInput={<div className="analytic-date-wrapper">
                <h3>集計終了日</h3>
                <div className="input-group date" id="datetimepicker1">
                  <span className="input-group-addon">
                    <span className="glyphicon glyphicon-calendar"></span>
                  </span>
                  {this.state.to_date ? <input className="form-control" type="text" value={moment(this.state.to_date).format('YYYY年MM月DD日')} /> : undefined}
                </div>
              </div>}
            />
          </div>
        </div>
        <div className="analysis-head-item">
          <div className="btn btn-default btn-pdf" onClick={() => {
            history.replace({
              pathname: Routes.PATIENT_PDF_REVIEW_PAGE,
              search: `?analysisId=${this.props.tab.analysisId}${this.state.from_date ? `&fromDate=${moment(this.state.from_date).format('YYYY-MM-DD')}` : ``}${this.state.to_date ? `&toDate=${moment(this.state.to_date).format('YYYY-MM-DD')}` : ""}`
            })
          }}>
            <span>PDFダウンロード</span>
          </div>
        </div>
      </div>
      <div className="analysis-body" id="pdf-page">
        <ul className="tabslist analysis-tab">
          {Object.values(AnalyticDetailTab).map((tab) => {
            return (
              <li
                className={`${this.props.tab.type === tab ? "active" : ""
                  }`}
                onClick={() => {
                  this.changeRouteParams(this.props.tab.analysisId, tab, this.state.from_date, this.state.to_date)
                  this.props.onFetchPatientAnalyticDetails(
                    this.props.tab.analysisId,
                    tab,
                    this.state.from_date,
                    this.state.to_date
                  );
                }}
              >
                {tab}
              </li>
            );
          })}
        </ul>
        <LoadingComponent isLoading={this.props.patientAnalyticState ===
          PatientAnalyticState.fetchingPatientAnalyticDetail}>
          {this.buildAnalyticTab()}
        </LoadingComponent>
      </div>
    </div>
  }

  render() {

    return (
      <MainLayout
        isLoading={this.props.patientAnalyticState ===
          PatientAnalyticState.fetchingPatientAnalyticDetail}
        className="secondpage navstate_show page-analysis_management detail"
        subClassName="main_body p-analysis_management detail"
      >
        <div className="card">
          <div className="card-header flex-H">
            <BackButton onBackPressed={() => {
              history.replace(Routes.PATIENT_ANALYTIC_MANAGEMENT)
            }} />
            分析結果管理-詳細</div>
          <div className="card-body">
            <NoDataWrapperComponent isNoData={this.props.isNotFound} noDataContent={<h3>{Strings.NO_DATA}</h3>}>
              {this.buildAnalyticTable()}
            </NoDataWrapperComponent>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ patientAnalytic }: IRootState) => {
  const { patientAnalyticDetail, patientAnalyticState, tab, isNotFound } = patientAnalytic;
  return {
    patientAnalyticDetail,
    patientAnalyticState,
    tab,
    isNotFound
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    onAnalyticDetailsChanged: (type: AnalyticDetailTab, newDetails: IPatientAnalyticDetail) => dispatch(patientAnalysisDetailFetched(type, newDetails)),
    onFetchPatientAnalyticDetails: (id: string, type: AnalyticDetailTab, fromDate?: Date, toDate?: Date) =>
      Injector.get()
        .find<AnalyticContainer>("AnalyticContainer")
        .onFetchPatientAnalysisDetail(dispatch, id, type, fromDate, toDate),
    onChangeDates: (key: AnalyticDetailTab, dates: IAnalyticDate[]) =>
      dispatch(patientAnalyticOnDateShowed(key, dates)),
    onExportPdf: () => Injector.get()
      .find<AnalyticContainer>("AnalyticContainer").onExportPdf(dispatch)
  };
};

type AnalysisDetailPageType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(
  mapStateToProps,
  mapDispatcherToProps
)(AnalysisDetailPage);
