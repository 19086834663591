import { Either } from "src/base/Either"
import { Failure } from "src/base/Failure"
import { UseCase } from "src/base/UseCase"
import { IMedicalRecord } from "../../data/models/medicalRecord"
import { IMedicalRecordRepository } from "../../data/repositories/medicalRecordRepository"

export class DeleteMedicalRecord extends UseCase<DeleteMedicalRecordParams, void>{
    private repository: IMedicalRecordRepository
    constructor(repository: IMedicalRecordRepository) {
        super()
        this.repository = repository
    }

    call(params: DeleteMedicalRecordParams): Promise<Either<Failure, void>> {
        return this.repository.deleteMedicalRecord(params.id)
    }

}

export class DeleteMedicalRecordParams {
    id: string
    constructor(id: string) {
        this.id = id
    }
}