import { PatientState } from "src/base/Enums";
import { IUser } from "src/features/account/data/models/user";
import { Pagination } from "src/shared/model/pagination";
import { ActionType } from "typesafe-actions";
import * as actions from "./actions";

export interface IPatientState {
    patients: Pagination<IUser>
    selectedPatient: IUser
    patientState: PatientState
}

export enum PatientActionType {
    PATIENT_STATE_CHANGED = "PATIENT_STATE_CHANGED",
    PATIENTS_FETCHED = "PATIENTS_FETCHED",
    PATIENTS_PAGINATION_UPDATED = "PATIENTS_PAGINATION_UPDATED",
    PATIENT_UPDATED = "PATIENT_UPDATED",
    SELECTED_PATIENT_UPDATED = "SELECTED_PATIENT_UPDATED",
    SELECTED_PATIENT_DATA_UPDATED = "SELECTED_PATIENT_DATA_UPDATED",
    SELECTED_PATIENT = "SELECTED_PATIENT"
}

export type PatientActions = ActionType<typeof actions>