import React from "react";
import { MFile } from "src/features/medicalRecord/data/models/file";
import { FileTypes } from "src/helpers";
import { Assets } from "src/utils/assets";
import ImgWrapper from "./imgWrapper";

export interface ImageInterface {
  src: string;
  type: number;
}

interface IListGalerryProps {
  files?: Map<string, MFile>;
  listGalerry?: Array<any>;
  readonly?: boolean;
  onDeleteFile?: (type: string) => void;
  onItemClick?: (item: any, e?: any) => void;
  onChange: (type: string, value: string, e?: React.ChangeEvent<HTMLInputElement>, file?: any) => void;
}

export class ListGalerry3D extends React.Component<IListGalerryProps> {
  getImagePath = (item: any) => {
    const image = this.props.files?.get(item.name ?? "");
    if ((image && image.path && image.path.length > 0) || image?.blob) return Assets.stl;

    return Assets.medicalImage;
  };

  hadImage = (item: any): boolean => {
    const image = this.props.files?.get(item.name ?? "");
    return image !== undefined && (image?.blob !== undefined || image.path.length > 0);
  };

  render() {
    return (
      <div className="list-galerry d-flex">
        {(this.props.listGalerry ?? [])
          .filter((item) => {
            const image = this.props.files?.get(item.name ?? "");
            return this.props.readonly ? (image && image.path && image.path.length > 0) || image?.blob : true;
          })
          .map((item, index) => (
            <ImgWrapper
              key={index}
              className="list-galerry-item mr10"
              imgURL={this.getImagePath(item)}
              onDelete={() => {
                if (this.props.onDeleteFile) this.props.onDeleteFile(item.name);
              }}
              hadImage={this.hadImage(item)}
              label={item.label}
              onClick={(e) => {
                if (this.props.onItemClick) {
                  this.props.onItemClick(this.props.files?.get(item.name ?? ""), e);
                }
              }}
              readonly={this.props.readonly}
              onChange={(value, e, file) => {
                this.props.onChange(item.name ?? "", value, e, file);
              }}
              fileAccept={FileTypes.STL}
            />
          ))}
      </div>
    );
  }
}
