import * as actions from "./actions";
import { ActionType } from "typesafe-actions";
import { Pagination } from "src/shared/model/pagination";
import { RemoteFilesState } from "src/base/Enums";
import { MFile } from "src/features/medicalRecord/data/models/file";
import { IUser } from "src/features/account/data/models/user";

export interface IRemoteFilesState {
    remoteFiles: Pagination<MFile>
    patients: Pagination<IUser>
    selectedRemoteFiles: MFile[]
    state: RemoteFilesState
}

export enum RemoteFilesActionType {
    REMOTE_FILES_STATE_CHANGED = "REMOTE_FILES_STATE_CHANGED",
    REMOTE_FILES_FETCHED = "REMOTE_FILES_FETCHED",
    REMOTE_FILE_DELETED = "REMOTE_FILE_DELETED",
    CLEAR_ALL_SELECTED_FILES = "CLEAR_ALL_SELECTED_FILES",
    SELECT_REMOTE_FILE = "SELECT_REMOTE_FILE",
    PATIENTS_LOADED = "PATIENTS_LOADED",
    PATIENTS_PAGINATE_VALUE_CHANGED = "PATIENTS_PAGINATE_VALUE_CHANGED"
}

export type RemoteFilesActions = ActionType<typeof actions>;