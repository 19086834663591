import React from "react";
import { AttributeName, Position } from "src/base/Enums";
import { Attribute, getAttributeName, getMetaData } from "src/features/medicalRecord/data/models/attribute";
import { ToothAttribute } from "src/features/medicalRecord/data/models/medicalRecord";
import { ArrayFunction } from "src/helpers/arrayFunction";
import { MapFunction } from "src/helpers/mapFunction";
import { Table } from "../table";
import { PCRDisplay } from "./pcrDisplay";

interface IMedicalTableProps {
  children?: React.ReactNode;
  className?: string;
  text?: string;
  teeth: Map<number, Map<string, ToothAttribute>>;
  attributes: Array<Attribute>;
}

export class MedicalTable extends React.Component<IMedicalTableProps> {
  render() {
    return (
      <Table
        className="dataTable-container-scroll fixscroll"
        subClassName="table table-bordered table-custom dataTable-table"
        headers={[
          {
            fields: [
              { html: "EPP", rowSpan: 2, className: "text-bold background-blue" },
              { html: getAttributeName(AttributeName.buccal, this.props.attributes), className: "text-bold background-blue" },
              ...ArrayFunction.range(1, 16).reverse().map(tooth => {
                const bucal = MapFunction.objToMap(this.props.teeth.get(tooth)?.get(AttributeName.buccal)?.value ?? {});
                const lingual = MapFunction.objToMap(this.props.teeth.get(tooth)?.get(AttributeName.lingual)?.value ?? {});
                return {
                  className: `epp ${this.props.teeth.get(tooth)?.get(AttributeName.pattern)?.value === "4" ? "loss" : ""}`, rowSpan: 2, html: (
                    <Table
                      subClassName="table-epp"
                      rows={[
                        {
                          fields: [1, 2, 3].map(item => {
                            return {
                              html: bucal.get(item.toString()) ?? "-",
                              className: `item${item}`
                            }
                          })
                        },
                        {
                          fields: [1, 2, 3].map(item => {
                            return {
                              html: lingual.get(item.toString()) ?? "-",
                              className: `item${item}`
                            }
                          })
                        },
                      ]}
                    ></Table>
                  )
                }
              })
            ]
          },
          { fields: [{ html: getAttributeName(AttributeName.lingual, this.props.attributes), className: "text-bold background-blue" }] },
        ]}
        rows={[
          {
            fields: [
              { html: getAttributeName(AttributeName.agitation, this.props.attributes), className: "text-bold background-blue", colSpan: 2 },
              ...ArrayFunction.range(1, 16).reverse().map(item => {
                return {
                  html: (
                    this.props.teeth.get(item)?.get(AttributeName.agitation)?.value ?? 0
                  ),
                  className: `${this.props.teeth.get(item)?.get(AttributeName.pattern)?.value === "4" ? "loss" : ""}`
                }
              })
            ]
          },
          {
            fields: [
              { html: getAttributeName(AttributeName.pcr, this.props.attributes), className: "text-bold background-blue", colSpan: 2 },
              ...ArrayFunction.range(1, 16).reverse().map(item => {
                return {
                  html: (
                    <PCRDisplay
                      index={item}
                      chossedPosition={this.props.teeth.get(item)?.get(AttributeName.pcr)?.value ?? []}
                    ></PCRDisplay>
                  ),
                  className: `${this.props.teeth.get(item)?.get(AttributeName.pattern)?.value === "4" ? "loss" : ""}`
                }
              })
            ]
          },
          {
            fields: [
              { html: "", colSpan: 2, rowSpan: 2, className: "background-blue" },
              ...ArrayFunction.range(1, 8).map((ceil, index, arr) => {
                var item = this.props.teeth.get((index + 1))
                return {
                  className: `${item?.get(AttributeName.pattern)?.value === "4" ? "loss" : ""}`,
                  html:
                    <div className={`${item?.get(AttributeName.bop)?.value === "1" ? "cell-red-border" : ""}`}><span>{ceil}</span></div>
                }
              }),
              ...ArrayFunction.range(1, 8).reverse().map((ceil, index, arr) => {
                var item = this.props.teeth.get((arr.length + (index + 1)))
                return {
                  className: `${item?.get(AttributeName.pattern)?.value === "4" ? "loss" : ""}`,
                  html:
                    <div className={`${item?.get(AttributeName.bop)?.value === "1" ? "cell-red-border" : ""}`}><span>{ceil}</span></div>
                }
              })
            ]
          },
          {
            fields: [
              ...ArrayFunction.range(1, 8).map((ceil, index, arr) => {
                var item = this.props.teeth.get(arr.length * 2 + index + 1)
                return {
                  className: `${item?.get(AttributeName.pattern)?.value === "4" ? "loss" : ""}`,
                  html:
                    <div className={`${item?.get(AttributeName.bop)?.value === "1" ? "cell-red-border" : ""}`}><span>{ceil}</span></div>
                }
              }),
              ...ArrayFunction.range(1, 8).reverse().map((ceil, index, arr) => {
                var item = this.props.teeth.get((arr.length * 3) + index + 1)
                return {
                  className: `${item?.get(AttributeName.pattern)?.value === "4" ? "loss" : ""}`,
                  html:
                    <div className={`${item?.get(AttributeName.bop)?.value === "1" ? "cell-red-border" : ""}`}><span>{ceil}</span></div>
                }
              })
            ]
          },
          {
            fields: [
              { html: getAttributeName(AttributeName.pcr, this.props.attributes), className: "text-bold background-blue", colSpan: 2 },
              ...ArrayFunction.range(17, 32).reverse().map(item => {
                return {
                  className: `${this.props.teeth.get(item)?.get(AttributeName.pattern)?.value === "4" ? "loss" : ""}`,
                  html: (
                    <PCRDisplay
                      index={item}
                      chossedPosition={this.props.teeth.get(item)?.get(AttributeName.pcr)?.value ?? []}
                    ></PCRDisplay>
                  )
                }
              })
            ]
          },
          {
            fields: [
              { html: getAttributeName(AttributeName.agitation, this.props.attributes), className: "text-bold background-blue", colSpan: 2 },
              ...ArrayFunction.range(17, 32).reverse().map(item => {
                return {
                  className: `${this.props.teeth.get(item)?.get(AttributeName.pattern)?.value === "4" ? "loss" : ""}`,
                  html: (
                    this.props.teeth.get(item)?.get(AttributeName.agitation)?.value ?? 0
                  )
                }
              })
            ]
          },
        ]}
        footers={[
          {
            fields: [
              { html: "EPP", rowSpan: 2, className: "text-bold background-blue" },
              { html: getAttributeName(AttributeName.buccal, this.props.attributes), className: "text-bold background-blue" },
              ...ArrayFunction.range(17, 32).reverse().map(tooth => {
                const bucal = MapFunction.objToMap(this.props.teeth.get(tooth)?.get(AttributeName.buccal)?.value ?? {});
                const lingual = MapFunction.objToMap(this.props.teeth.get(tooth)?.get(AttributeName.lingual)?.value ?? {});
                return {
                  className: `epp ${this.props.teeth.get(tooth)?.get(AttributeName.pattern)?.value === "4" ? "loss" : ""}`, rowSpan: 2, html: (
                    <Table
                      subClassName="table-epp"
                      rows={[
                        {
                          fields: getMetaData(AttributeName.buccal, this.props.attributes).map((item, index) => {
                            return {
                              html: bucal.get((index + 1).toString()) ?? "-",
                              className: `item${index + 1}`
                            }
                          })
                        },
                        {
                          fields: [1, 2, 3].map((item, index) => {
                            return {
                              html: lingual.get((index + 1).toString()) ?? "-",
                              className: `item${index + 1}`
                            }
                          })
                        },
                      ]}
                    ></Table>
                  )
                }
              })
            ]
          },
          { fields: [{ html: getAttributeName(AttributeName.lingual, this.props.attributes), className: "text-bold background-blue" }] },
        ]}
      ></Table>
    );
  }
}