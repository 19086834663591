import { OrthodontistState } from "src/base/Enums";
import { IUser } from "src/features/account/data/models/user";
import { Pagination } from "src/shared/model/pagination";
import { ActionType } from "typesafe-actions";
import * as actions from "./actions";

export interface IOrthodontistState {
  orthodontists: Pagination<IUser>;
  selectedOrthodontist: IUser;
  currentState: OrthodontistState;
}

export enum OrthodontistActionType {
  ORTHORDONTIST_STATE_CHANGED = "ORTHORDONTIST_STATE_CHANGED",
  ORTHORDONTISTS_FETCHED = "ORTHORDONTISTS_FETCHED",
  ORTHORDONTISTS_PAGINATION_UPDATED = "ORTHORDONTISTS_PAGINATION_UPDATED",
  ORTHORDONTIST_UPDATED = "ORTHORDONTIST_UPDATED",
  SELECTED_ORTHODONTIST_UPDATED = "SELECTED_ORTHODONTIST_UPDATED",
  SELECTED_ORTHODONTIST_DATA_UPDATED = "SELECTED_ORTHODONTIST_DATA_UPDATED",
}

export type OrthodontistActions = ActionType<typeof actions>;
