import React from "react";
import { Assets } from "src/utils/assets";
import { Strings } from "src/constants";
import { Link, Router } from "react-router-dom";
import { history, Routes } from "src/helpers";
import { Dispatch } from "redux";
import { forgetPasswordFieldChange } from "src/features/auth/presenter/store/forgetPassword";
import { Injector } from "src/base/Injector";
import { AuthContainer } from "src/features/auth/presenter/container/authContainer";
import { connect } from "react-redux";
import { IRootState } from "src/store";
import { Input } from "src/components/input";
import { findParamsFromSearchString } from "src/utils/utils";
import { AuthLayout } from "../layouts/authLayout";
import { AuthHeader } from "src/shared/components/authHeader";

interface IVerifyOTPState {
    otp1: string,
    otp2: string,
    otp3: string,
    otp4: string,
}

class VerifyOTPPage extends React.Component<VerifyOTPType, IVerifyOTPState> {
    otp1Tag: React.RefObject<HTMLInputElement>;
    otp2Tag: React.RefObject<HTMLInputElement>;
    otp3Tag: React.RefObject<HTMLInputElement>;
    otp4Tag: React.RefObject<HTMLInputElement>;
    email: string = ""
    constructor(props: VerifyOTPType) {
        super(props);
        this.state = {
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: "",
        };

        this.otp1Tag = React.createRef();
        this.otp2Tag = React.createRef();
        this.otp3Tag = React.createRef();
        this.otp4Tag = React.createRef();
    }

    updateOTP = () => {
        if (this.state.otp1.length == 0) {
            this.otp1Tag.current?.focus();
            return;
        }

        if (this.state.otp2.length == 0 && this.state.otp1.length > 0) {
            this.otp2Tag.current?.focus();
            return;
        }

        if (this.state.otp3.length == 0 && this.state.otp2.length > 0) {
            this.otp3Tag.current?.focus();
            return;
        }

        if (this.state.otp4.length == 0 && this.state.otp3.length > 0) {
            this.otp4Tag.current?.focus();
            return;
        }

        const otp = this.state.otp1 + this.state.otp2 + this.state.otp3 + this.state.otp4;
        this.props.onSubmit(this.email, otp);
        this.resetOtp()
    }

    componentDidMount() {
        let params = findParamsFromSearchString(history.location.search)
        this.email = params?.get("email") || ""
    }

    resetOtp = () => {
        this.setState({
            otp1: "",
            otp2: "",
            otp3: "",
            otp4: ""
        })
        this.otp1Tag.current?.focus()
    }

    render(): React.ReactNode {
        return (
            <AuthLayout isLoading={this.props.isSubmitting} bodyClassname="p-otp" sectionClassname="p-login">
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid">
                            <AuthHeader />
                            <div className="login-bottom">
                                <form className="loginbox_c resetpass otp-block validation fz16" id="otpform">
                                    <div className="btn-left">
                                        <a onClick={() => {
                                            history.replace(Routes.FORGET_PASSWORD);
                                        }}></a>
                                    </div>
                                    <h2 className="text_center title">{Strings.INPUT_OTP}</h2>
                                    <p className="text_center text_note">{Strings.INPUT_OTP_IN_EMAIL}</p>
                                    <div className="number flexbox">
                                        <div className="number-box flexbox">
                                            <div className="item">
                                                <Input
                                                    autoFocus={this.state.otp1.length === 0}
                                                    inputClassName="otp-input"
                                                    name="email"
                                                    value={this.state.otp1}
                                                    maxLength={1}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            ...this.state,
                                                            otp1: value
                                                        }, this.updateOTP)
                                                    }}
                                                    type="tel"
                                                    refTag={this.otp1Tag}
                                                ></Input>
                                            </div>
                                            <div className="item">
                                                <Input
                                                    autoFocus={this.state.otp2.length === 0 && this.state.otp1.length > 0}
                                                    inputClassName="otp-input"
                                                    name="email"
                                                    value={this.state.otp2}
                                                    maxLength={1}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            ...this.state,
                                                            otp2: value
                                                        }, this.updateOTP)
                                                    }}
                                                    type="tel"
                                                    refTag={this.otp2Tag}
                                                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                        if (event.code == "Backspace")
                                                            this.otp1Tag.current?.focus()
                                                    }}
                                                ></Input>
                                            </div>
                                            <div className="item">
                                                <Input
                                                    autoFocus={this.state.otp3.length === 0 && this.state.otp2.length > 0}
                                                    inputClassName="otp-input"
                                                    name="email"
                                                    value={this.state.otp3}
                                                    maxLength={1}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            ...this.state,
                                                            otp3: value
                                                        }, this.updateOTP)
                                                    }}
                                                    type="tel"
                                                    refTag={this.otp3Tag}
                                                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                        if (event.code == "Backspace")
                                                            this.otp2Tag.current?.focus()
                                                    }}
                                                ></Input>
                                            </div>
                                            <div className="item">
                                                <Input
                                                    autoFocus={this.state.otp4.length === 0 && this.state.otp3.length > 0}
                                                    inputClassName="otp-input"
                                                    name="email"
                                                    value={this.state.otp4}
                                                    maxLength={1}
                                                    onChange={(value) => {
                                                        this.setState({
                                                            ...this.state,
                                                            otp4: value
                                                        }, this.updateOTP)
                                                    }}
                                                    type="tel"
                                                    refTag={this.otp4Tag}
                                                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                        if (event.code == "Backspace")
                                                            this.otp3Tag.current?.focus()
                                                    }}
                                                ></Input>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="text_center resendmail">
                                        <a
                                            className="maincl text_bold c-pointer"
                                            onClick={() => {
                                                this.props.resentOTP(this.email);
                                                this.resetOtp()
                                            }}
                                        >
                                            {Strings.RESENT_OTP}
                                        </a>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </main>
                </div>
            </AuthLayout>
        )
    }
}

const mapStateToProps = ({ forgetPassword }: IRootState) => {
    const { isSubmitting, otp } = forgetPassword
    return {
        isSubmitting,
        otp
    };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
    return {
        onChangeEmail: (email: string) => dispatch(forgetPasswordFieldChange("email", email)),
        onSubmit: (email: string, otp: string) =>
            Injector.get().find<AuthContainer>("AuthContainer").submitOTP(dispatch, email, otp),
        resentOTP: (email: string) =>
            Injector.get().find<AuthContainer>("AuthContainer").forgetPasswordHandle(dispatch, email),
    };
};

type VerifyOTPType = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(VerifyOTPPage);