import { ActionType } from "typesafe-actions";
import * as actions from "./actions";

export interface IThreeDViewerState {
    meshFiles: Map<string, File>;
    cameraPosition: [x: number, y: number, z: number];
}

export enum ThreeDViewerActionType {
    THREED_VIEWER_UPLOAD = "THREED_VIEWER_UPLOAD",
    THREED_VIEWER_UPDATE_CAMERA_POSITION = "THREED_VIEWER_UPDATE_CAMERA_POSITION",
}

export type ThreeDViewerActions = ActionType<typeof actions>