import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { NoParam } from "src/base/NoParam";
import { UseCase } from "src/base/UseCase";
import { IUser } from "../../data/models/user";
import { IAccountRepository } from "../../data/repositories/accountRepository";

export class GetMe extends UseCase<NoParam, IUser>{
    private repository: IAccountRepository
    constructor(repository: IAccountRepository) {
        super()
        this.repository = repository
    }
    call(params: NoParam): Promise<Either<Failure, IUser>> {
        return this.repository.getMe()
    }
}
