import React from "react";
import { Input } from "src/components/input";
import { Strings } from "src/constants";
import { history, Routes } from "src/helpers";
import { IRootState } from "src/store";
import { Dispatch } from "redux";
import { Injector } from "src/base/Injector";
import { connect } from "react-redux";
import { AuthContainer } from "src/features/auth/presenter/container/authContainer";
import { forgetPasswordFieldChange } from "src/features/auth/presenter/store/forgetPassword/actions";
import { MainButton } from "src/components/button/mainButton";
import { AuthLayout } from "../layouts/authLayout";
import { AuthHeader } from "src/shared/components/authHeader";

class ForgetPasswordPage extends React.Component<ForgetPasswordType> {
    componentDidMount() {
        this.props.onChangeEmail("")
    }

    render(): React.ReactNode {
        return (
            <AuthLayout isLoading={this.props.isSubmitting} bodyClassname="p-forgetpass" sectionClassname="p-login p-forgetpass">
                <div className="container-fluid">
                    <AuthHeader />
                    <div className="login-bottom">
                        <div className="resetpass loginbox_c fz16">
                            <div className="btn-left">
                                <a onClick={() => {
                                    history.replace(Routes.LOGIN);
                                }}></a>
                            </div>

                            <h2 className="text_center title">{Strings.UPDATE_PASSWORD}</h2>
                            <p className="text_center text_note">
                                パスワードをリセットします。<br />
                                Liexに登録済みのメールアドレスをご入力ください。<br />
                                再登録用の認証コードを送付いたします。
                            </p>

                            <form className="formlogin" onSubmit={(e) => {
                                e.preventDefault()
                                this.props.onSubmit(this.props.email.value)
                            }}>
                                <Input
                                    inputClassName="form-control"
                                    className="fsc"
                                    type="email"
                                    inputPlaceHolder={Strings.EMAIL_ADDRESS}
                                    value={this.props.email.value}
                                    name="email"
                                    maxLength={50}
                                    onChange={(value) => this.props.onChangeEmail(value)}
                                ></Input>

                                <div className="forbox_e boxbtn text-center">
                                    <MainButton
                                        title={Strings.SEND}
                                        submitting={this.props.isSubmitting}
                                        enabled={this.props.email.validate}
                                        className="btn btn-large btn-primary btn-primary-2"
                                        isSubmitButton={true}
                                        onSubmit={() => {
                                        }}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </AuthLayout >

        )
    }
}

const mapStateToProps = ({ forgetPassword }: IRootState) => {
    const { isSubmitting, email } = forgetPassword
    return {
        isSubmitting,
        email
    };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
    return {
        onChangeEmail: (email: string) => dispatch(forgetPasswordFieldChange("email", email)),
        onSubmit: (email: string) =>
            Injector.get().find<AuthContainer>("AuthContainer").forgetPasswordHandle(dispatch, email),
    };
};

type ForgetPasswordType = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(ForgetPasswordPage);