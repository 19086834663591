import { Either, LeftSide, match, RightSide } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { Injectable } from "src/base/Injector";
import { IUnsharedFiles } from "../datasources/unsharedFilesDatasource";

export interface IUnsharedFilesRepository {
    unsharedfiles(): Promise<Either<Failure, number>>
}

export class UnsharedFilesRepository implements IUnsharedFilesRepository {
    unsharedFiles: IUnsharedFiles
    constructor(unsharedFiles: IUnsharedFiles) {
        this.unsharedFiles = unsharedFiles
    }

    async unsharedfiles(): Promise<Either<Failure, number>> {
        try {
            return RightSide(await this.unsharedFiles.unsharedfiles());
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e })
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message })
            }
        }
        return LeftSide(new Error(""));
    }
}