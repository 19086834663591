import { Pie } from "react-chartjs-2";
import { BaseChart } from "./chart";
import "chartjs-plugin-datalabels";

export class PieChart extends BaseChart {
  render() {
    var data: any = {
      labels: this.props.items.map((item) => item.name),
      datasets: [
        {
          data: this.props.items.map((item) => item.percents as number),
          backgroundColor: this.props.items.map((item) => item.color),
          hoverBackgroundColor: this.props.items.map((item) => item.color),
          datalabels: {
            labels: {
              name: {
                align: "top",
                color: "white",
                font: function (context: any) {
                  return {
                    size: 22,
                    weight: "600",
                  };
                },
                formatter: function (value: any, ctx: any) {
                  return ctx.chart.data.labels[ctx.dataIndex];
                },
                margin: 0,
                padding: 0,
              },
              value: {
                align: "bottom",
                color: "white",
                font: function (context: any) {
                  return {
                    size: 30,
                    weight: "600",
                  };
                },
                formatter: function (value: any, ctx: any) {
                  return Math.round(value * 1000) / 1000;
                },
                margin: 0,
                padding: 0,
              },
            },
          },
        },
      ],
    };
    var options = {
      scales: {
        r: {
          angleLines: {
            display: true,
          },
          pointLabels: {
            display: true,
            font: {
              size: 20,
              style: "normal",
            },
          },
          ticks: {
            display: false,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    };
    return (
      !this.props.items.map((item) => item.percents as number).every((item) => item === 0) ? <Pie
        data={data}
        options={options}
        width={this.props.width}
        height={this.props.height}
      /> : <div></div>
    );
  }
}
