import React from "react";
import { Link } from "react-router-dom";
import { Routes } from "src/helpers";
import { Strings } from "src/constants";
import { Modals } from "src/helpers";
import Modal from "../modal/modal";

export function RedirectLinkPopup(props: any) {
  return (
    <Modal
      id="redirectLink"
      className={`${props.currentModal == Modals.REDIRECT_LINK ? "flexible" : ""}`}
      dialogExtraClassName=""
      isShow={props.currentModal == Modals.REDIRECT_LINK}
      footerChildren={
        <React.Fragment>
          <div>
            <Link
              to={Routes.MEDICAL_MANAGEMENT_CREATE}
              className="btn btn-primary close"
              id=""
              type="button"
              onClick={() => {
                if (props.selectedPatient && props.onSelectPatient) props.onSelectPatient(props.selectedPatient);
                props.changeModal(Modals.NONE);
              }}
            >
              カルテのみで登録される場合
            </Link>
          </div>
          <div>
            <Link
              to={Routes.PLY_DOWNLOAD_MANAGEMENT}
              className="btn btn-primary close"
              id=""
              type="button"
              onClick={() => {
                props.changeModal(Modals.NONE);
              }}
            >
              スキャンデータ送信済みの場合
            </Link>
          </div>
        </React.Fragment>
      }
    >
      <h4 className="text_bold mb20">{Strings.CONFIRM}</h4>
    </Modal>
  );
}
