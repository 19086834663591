import React from "react";
import { ICard } from "src/features/subscription/domain/model/card";
import { LoadingComponent } from "src/shared/components/loadingComponent";
import { Assets } from "src/utils/assets";
import { pushPopupById } from "src/utils/utils";
import { PopupComponent } from "./popup";

export interface PaymentAddPopupProps {
    onAddCard: (card: ICard) => void;
    onUpdateCard: (card: ICard) => void;
    onDeleteCard: (cardId: string) => void;
    card: ICard
    isSubmitting: boolean,
    isUpdate: boolean
    onCardPropsChanged: (key: string, value: any) => void
}

export class PaymentAddPopup extends React.Component<PaymentAddPopupProps> {
    isUpdate: boolean = false

    render() {
        const handleExpireDateInput = (e: any) => {
            let value = e.target.value
            let str = value.replace(/[^0-9//]/gi, "");
            if (value.length > 7)
                return;
            let month = str.split("/")[0] ?? ""
            let year = str.split("/")[1] ?? ""

            if (month.length == 2 && year.length === 0) {
                if (parseInt(month) > 12)
                    month = "01"
                str = month + (e.nativeEvent.data ? "/" : "")

            } else if (month.length > 2) {
                year = month.slice(-1)
                month = month.slice(0, 2)
                str = month + (e.nativeEvent.data ? "/" : "") + year
            }
            if (str.indexOf("/") === -1) {
                year = ""
                str = month
            }
            this.props.onCardPropsChanged("expire_date", str)

        }
        return <PopupComponent id="payment-infor">
            <h3>お支払情報設定</h3>
            <figure className="branch-img"><img src={Assets.cardBrand} alt="" /></figure>
            <form className="form-payment needs-validation" id="form-payment">
                <div className="col">
                    <p>カード番号</p>
                    <input className="numbertype" id="cardnumber" name="cardnumber" type="text" maxLength={16} pattern="[0-9]{16}" inputMode="numeric" value={this.props.card.number} onChange={(e) => {
                        let str = e.target.value.replace(/[^0-9//]/gi, "");
                        this.props.onCardPropsChanged("number", (this.props.card.number?.indexOf("*") ?? 0) > -1 ? "" : str)
                    }} placeholder="0000000000000000" />
                </div>
                <div className="col">
                    <p>有効期限</p>
                    <input id="paymentdate" name="paymentdate" value={this.props.card.expire_date} onChange={(e) => {
                        handleExpireDateInput(e)
                    }} type="text" maxLength={7} placeholder="mm/yyyy" />
                </div>
                <div className="col">
                    <div className="group">
                        <p>セキュリティコード</p><span>CVVとは</span>
                        <div className="clickpop note-box" data-for="note-box" onClick={() => {
                            pushPopupById("note-box")
                        }}><span>?</span></div>
                    </div>
                    <input className="numbertype numbertype" value={this.props.card.cvc} onChange={(e) => {
                        let str = e.target.value.replace(/[^0-9//]/gi, "");
                        if ((this.props.card.number?.indexOf("*") ?? 0) > -1 && this.props.isUpdate) {
                            this.props.onCardPropsChanged("number", "")
                        }
                        this.props.onCardPropsChanged("cvc", str)
                    }} id="card-cvc" name="securitycode" maxLength={3} pattern="[0-9]{3}" inputMode="numeric" type="text" placeholder="123" />
                </div>
            </form>
            <div className="buttonsubmit">
                <LoadingComponent isLoading={this.props.isSubmitting} size={100}>
                    <button className="btn-payment clickpop" disabled={!this.props.card.number || !this.props.card.expire_date || (!this.props.isUpdate && !this.props.card.cvc) || !RegExp("[0-9]{2}\/[0-9]{4}").test(this.props.card.expire_date)} type="submit" id="submit" onClick={() => {
                        if (this.props.isUpdate) {
                            this.props.onUpdateCard(this.props.card)
                        } else {
                            this.props.onAddCard(this.props.card)
                        }
                    }} >登録する</button>
                </LoadingComponent>
                <div className="text-center mt10">{(this.props.isSubmitting || !this.props.isUpdate) ? undefined : <a onClick={() => {
                    this.props.onDeleteCard(this.props.card.id)
                }} className="text-red text_bold">削除する</a>}</div>
            </div>
        </PopupComponent>
    }
}