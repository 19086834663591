import { Either, LeftSide, RightSide } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { Pagination } from "src/shared/model/pagination";
import { IAnalyticRemoteDataSource } from "../datasources/analyticDataSource";
import {
  IPatientAnalytic,
  IPatientAnalyticDetail,
} from "../models/patientAnalytic";

export interface IAnalyticRepository {
  fetchPatientAnalytics(
    code: string,
    fullName: string,
    limit: number,
    page: number
  ): Promise<Either<Failure, Pagination<IPatientAnalytic>>>;
  fetchPatientAnalyticDetail(
    id: string,
    fromDate?: Date,
    toDate?: Date,
    type?: string
  ): Promise<Either<Failure, IPatientAnalyticDetail>>;
}

export class AnalyticRepository implements IAnalyticRepository {
  analyticRemoteDatasource: IAnalyticRemoteDataSource;
  constructor(analyticRemoteDatasource: IAnalyticRemoteDataSource) {
    this.analyticRemoteDatasource = analyticRemoteDatasource;
  }

  async fetchPatientAnalytics(
    code: string,
    fullName: string,
    limit: number,
    page: number
  ): Promise<Either<Failure, Pagination<IPatientAnalytic>>> {
    try {
      return RightSide(
        await this.analyticRemoteDatasource.fetchPatientAnalytics(
          code,
          fullName,
          limit,
          page
        )
      );
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async fetchPatientAnalyticDetail(
    id: string,
    fromDate: Date,
    toDate: Date,
    type?: string
  ): Promise<Either<Failure, IPatientAnalyticDetail>> {
    try {
      return RightSide(
        await this.analyticRemoteDatasource.fetchPatientAnalyticDetail(
          id,
          fromDate,
          toDate,
          type
        )
      );
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }
}
