import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { NoParam } from "src/base/NoParam";
import { UseCase } from "src/base/UseCase";
import { ISubscriptionRepository } from "../../data/repositories/subscriptionRepository";

export class DeleteCard extends UseCase<DeleteCardParams, boolean> {
    private repository: ISubscriptionRepository;
    constructor(repository: ISubscriptionRepository) {
        super();
        this.repository = repository;
    }

    call(params: DeleteCardParams): Promise<Either<Failure, boolean>> {
        return this.repository.deleteCard(params.cardId);
    }
}

export class DeleteCardParams {
    cardId: string;
    constructor(cardId: string) {
        this.cardId = cardId;
    }
}
