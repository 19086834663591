export interface Pagination<T> {
    data: T[],
    last_page: number,
    current_page: number,
    total: number,
    isLoadingMore?: boolean
    isFetching?: boolean
}

export const paginationInit: any = {
    data: [],
    last_page: 0, current_page: 0, total: 0
}