import React from "react";
import { connect } from "react-redux";
// import { ReactSVG } from "react-svg";
import { Dispatch } from "redux";
import { SubscriptionState } from "src/base/Enums";
import { Injector } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import Modal from "src/components/modal/modal";
import { Strings } from "src/constants";
import { changeModal } from "src/features/root/presenter/store/root";
import { SubscriptionContainer } from "src/features/subscription/presenter/container/subscriptionContainer";
import { history, Modals, Routes } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import { BackButton } from "src/shared/components/backButton";
import { IRootState } from "src/store";
// import { Assets } from "src/utils/assets";

class CancelPlanPage extends React.Component<CancelPlanPageType> {
    render() {
        return <MainLayout className="secondpage navstate_show page-planchange plancancle" subClassName="p-planchange plancancle">
            <div className="container-fluid">
                <div className="card">
                    <div className="card-header flex-H">
                        <BackButton
                            onBackPressed={() => {
                                history.replace(Routes.SUBSCRIPTION_MANAGEMENT)
                            }}
                        />
                        {Strings.CANCEL_PLAN_HEADER}</div>
                    <div className="card-body">
                        <div className="dataTable-container">
                            <div className="plancancle-content">
                                <h3>{Strings.CANCEL_PLAN_TITLE}</h3>
                                <p>
                                    {Strings.CANCEL_PLAN_CONTENT}
                                </p>
                            </div>
                            <div className="flexbox planbtn">
                                <MainButton className="btn btn-primary btn-main" onSubmit={() => {
                                    history.replace(Routes.SUBSCRIPTION_MANAGEMENT)
                                }} title={Strings.RETURN} enabled={true} />
                                <MainButton className="btn btn-danger btn-main" loadingSize={30} onSubmit={() => {
                                    this.props.changeModal(Modals.CONFIRM_CANCEL);
                                }} title={Strings.CANCEL_PLAN} enabled={true} submitting={this.props.subscriptionState === SubscriptionState.cancellingSubscription} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                className={`${this.props.currentModal == Modals.CONFIRM_CANCEL ? "flexible" : ""}`}
                isShow={this.props.currentModal == Modals.CONFIRM_CANCEL}
                dialogExtraClassName="confirm-delete"
                footerChildren={
                    (
                        <React.Fragment>
                            <MainButton
                                id="confirmChange"
                                className="btn btn-primary confirmChange close"
                                type="a"
                                enabled
                                title={Strings.RETURN}
                                onSubmit={() => {
                                    this.props.changeModal(Modals.NONE);
                                }}
                            />
                            <MainButton
                                className="btn btn-danger confirmChange close"
                                id="confirmChange"
                                type="a"
                                enabled
                                title={Strings.CANCEL_PLAN}
                                onSubmit={() => {
                                    this.props.onCancelPlan()
                                    this.props.changeModal(Modals.NONE);
                                }}
                            />
                        </React.Fragment>
                    )
                }
            >
                <h4 className="text_bold mb20">{Strings.CONFIRM}</h4>
                <h5>{Strings.ARE_YOU_SURE_WANT_TO_CANCEL}</h5>
            </Modal>
        </MainLayout>
    }
}

const mapStateToProps = ({ subscription, app }: IRootState) => {
    const { plans, subscriptionState } = subscription;
    const { currentModal } = app;
    return {
        plans,
        subscriptionState,
        currentModal
    };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
    return {
        onCancelPlan: () => Injector.get()
            .find<SubscriptionContainer>("SubscriptionContainer")
            .onCancelPlan(dispatch),
        changeModal: (modal: Modals) => dispatch(changeModal(modal)),
    };
};

type CancelPlanPageType = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatcherToProps>;

export default connect(
    mapStateToProps,
    mapDispatcherToProps
)(CancelPlanPage);