import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IAuthRepository } from "../../data/repositories/authRepository";

export class VerifyOTP extends UseCase<VerifyOTPParams, boolean>{
    private repository: IAuthRepository
    constructor(repository: IAuthRepository) {
        super()
        this.repository = repository
    }
    call(params: VerifyOTPParams): Promise<Either<Failure, boolean>> {
        return this.repository.verifyOTP(params.email, params.otp)
    }
}

export class VerifyOTPParams {
    email: string;
    otp: string;
    constructor(email: string, otp: string) {
        this.email = email;
        this.otp = otp;
    }
}