import React from "react";

export interface IChartItem {
  name: string;
  percents: number | number[];
  color: string;
}

export interface ChartProps {
  items: IChartItem[];
  width?: number;
  height?: number;
  className?: string
}

export abstract class BaseChart<
  P = {},
  S = {},
  SS = any
  > extends React.Component<P & ChartProps, S, SS> { }
