import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IAuthRepository } from "../../data/repositories/authRepository";

export class Login extends UseCase<LoginParams, void>{
    private repository: IAuthRepository
    constructor(repository: IAuthRepository) {
        super()
        this.repository = repository
    }
    call(params: LoginParams): Promise<Either<Failure, void>> {
        return this.repository.login(params.id, params.password)
    }
}

export class LoginParams {
    id: string
    password: string
    constructor(id: string, password: string) {
        this.id = id
        this.password = password
    }
}