import React from "react";

interface IOptionProps {
  name?: string;
  value: any;
}

interface ISelectOptionProps {
  className?: string;
  name?: string;
  isMultiple?: boolean;
  options: Array<IOptionProps>;
  value?: any;
  readonly?: boolean;
  onChange: (value: any) => void;
  id?: string;
}

export class SelectOption extends React.Component<ISelectOptionProps> {
  render() {
    return (
      <select
        placeholder="Pick a state..."
        className={this.props.className ?? ""}
        name={this.props.name ?? ""}
        disabled={this.props.readonly}
        multiple={this.props.isMultiple ?? false}
        onChange={(e) => this.props.onChange(e.currentTarget.value)}
        id={this.props.id ?? ""}
      >
        {this.props.options.map((option, idx) => (
          <option key={idx} value={option.value} selected={this.props.value === option.value}>
            {option.name ?? option.value}
          </option>
        ))}
      </select>
    );
  }
}
