import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IPatientAnalyticDetail } from "../../data/models/patientAnalytic";
import { IAnalyticRepository } from "../../data/repositories/patientAnalyticRepository";

export class FetchPatientAnalyticDetail extends UseCase<
  FetchPatientAnalyticDetailParams,
  IPatientAnalyticDetail
> {
  private repository: IAnalyticRepository;
  constructor(repository: IAnalyticRepository) {
    super();
    this.repository = repository;
  }
  call(
    params: FetchPatientAnalyticDetailParams
  ): Promise<Either<Failure, IPatientAnalyticDetail>> {
    return this.repository.fetchPatientAnalyticDetail(
      params.id,
      params.fromDate,
      params.toDate,
      params.type
    );
  }
}

export class FetchPatientAnalyticDetailParams {
  id: string;
  fromDate?: Date;
  toDate?: Date;
  type?: string;
  constructor(id: string, fromDate?: Date, toDate?: Date, type?: string) {
    this.id = id;
    this.type = type;
    this.fromDate = fromDate;
    this.toDate = toDate;
  }
}
