import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IPatientRepository } from "../../data/repositories/patientRepository";

export class SendEmailToPatient extends UseCase<SendEmailToPatientParams, void>{
  private repository: IPatientRepository
  constructor(repository: IPatientRepository) {
    super()
    this.repository = repository
  }

  async call(params: SendEmailToPatientParams): Promise<Either<Failure, void>> {
    return this.repository.sendMailtoPatient(params.patientId)
  }
}

export class SendEmailToPatientParams {
  patientId: string
  constructor(patientId: string) {
    this.patientId = patientId
  }
}