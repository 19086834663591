import moment from "moment";
import ReactS3Client from "react-aws-s3-typescript";
import { Endpoints } from "src/base/Endpoints";
import { IAccountState } from "src/features/account/presenter/store";
import { HttpMethod } from "src/helpers";
import { handleError } from "src/helpers/errorHandler";
import { IApiService } from "src/services/api/ApiService";
import store from "src/store";
import { makeid } from "src/utils/utils";
import { IFileManagementState } from "../../presenter/store";
import { FileManagementType, IFile } from "../models/file";
import { IFileManagement } from "../models/fileManagement";
import { IFolder } from "../models/folder";

export interface IRemoteFileManagementRemoteDataSource {
  fetchFileManagement(breadcrumbPath: string, search?: string, only?: string): Promise<IFileManagement>;
  createFolderFileManagement(breadcrumbPath: string, name: string): Promise<void>;
  importFileManagement(breadcrumbPath: string, files: File[], action?: string): Promise<void>;
  downloadFilesFileManagement(breadcrumbPath: string, files: IFile[]): Promise<Blob>;
  downloadFolderFileManagement(breadcrumbPath: string, folder: IFolder): Promise<string>;
  moveFileManagement(oldPath: string, newPath: string, itemToMove?: IFolder | IFile | IFile[]): Promise<void>;
  renameFileManagement(itemSelected: IFolder | IFile, rename: string): Promise<void>;
  deleteFileManagement(breadcrumbPath: string, itemToDelete?: IFolder | IFile | IFile[]): Promise<void>;
}

export class RemoteFileManagementRemoteDataSource implements IRemoteFileManagementRemoteDataSource {
  apiService: IApiService;

  constructor(apiService: IApiService) {
    this.apiService = apiService;
  }

  async fetchFileManagement(breadcrumbPath: string, search: string = "", only: string = ""): Promise<IFileManagement> {
    let response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.fetchFileManagement(breadcrumbPath, search, only),
      shouldAuthorize: true,
    });

    return response.data.data;
  }

  async createFolderFileManagement(breadcrumbPath: string, name: string): Promise<void> {
    let response = await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: Endpoints.createFolderFileManagement,
      shouldAuthorize: true,
      body: {
        data: {
          path: breadcrumbPath,
          name,
        },
      },
    });

    return response.data.data;
  }

  async importFileManagement(breadcrumbPath: string, files: File[], action?: string): Promise<void> {
    // Get remaining data storage
    const fileManagementData: any = (store.getState().fileManagement as IFileManagementState).fileManagementData;
    const { maximum, total } = fileManagementData;
    let remaining = maximum - total;

    // Init
    const me = (store.getState().account as IAccountState).me;
    const promises: Promise<any>[] = [];
    const fileErrors: any[] = [];
    const formData = new FormData();
    const fileNameRegex = new RegExp(/[`!@#$%^&*+={};:\\|,.<>\/?~]/);

    const awsS3Config = {
      bucketName: process.env.REACT_APP_AWS_S3_BITBUCKET!,
      dirName: `${process.env.REACT_APP_AWS_S3_DIRNAME!}/clinic_${me.clinic?.id}/temp`,
      region: process.env.REACT_APP_AWS_S3_REGION!,
      accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID!,
      secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY!,
      s3Url: process.env.REACT_APP_AWS_S3_URL!,
    };
    const awsS3 = new ReactS3Client(awsS3Config);

    formData.append("path", breadcrumbPath);
    formData.append("action", action!);

    for (let i = 0; i < files.length; i++) {
      const fileName = files[i].name.slice(0, files[i].name.lastIndexOf("."));
      const filePath = files[i].webkitRelativePath;

      /** Validate file name, file path, file size */
      // Check file name
      if (fileNameRegex.test(fileName))
        return handleError(`${filePath ? filePath : fileName} ファイル名に特殊文字は使用できません。`);

      // Check file path
      const _filePath = filePath.slice(0, filePath.lastIndexOf("/")).split("/");
      for (let j = 0; j < _filePath.length; j++)
        if (fileNameRegex.test(_filePath[j]))
          return handleError(`${_filePath[j]} フォルダ名に特殊文字は使用できません。`);

      // Check file size
      if (remaining - files[i].size > maximum) return handleError(`${fileName} 空き容量がなくなりました。`);
      else remaining = remaining - files[i].size;
      // End validate

      promises.push(
        new Promise((resolve) => setTimeout(resolve, i * 500)).then(() =>
          awsS3.uploadFile(files[i], `${moment().unix() + makeid(6)}.${files[i]?.name?.split(".")[0]}`)
        )
      );
      formData.append(`files[${i}][filePath]`, window.decodeURIComponent(filePath));
    }

    await Promise.allSettled(promises).then((result) => {
      result.forEach((res: any, index: number) => {
        if (res.status === "fulfilled")
          formData.append(`files[${index}][file]`, window.decodeURIComponent(res.value.location));
        if (res.status === "rejected") fileErrors.push({ id: index });
      });
    });

    for await (const file of fileErrors) {
      await awsS3
        .uploadFile(files[file.id], `${moment().unix() + makeid(6)}.${files[file.id]?.name?.split(".")[0]}`)
        .then((result) => formData.append(`files[${file.id}][file]`, window.decodeURIComponent(result.location)));
    }

    let response = await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: Endpoints.importFileManagement,
      shouldAuthorize: true,
      body: {
        data: formData,
      },
    });

    return response.data;
  }

  async downloadFilesFileManagement(breadcrumbPath: string, files: IFile[]): Promise<Blob> {
    const body: any = {
      path: breadcrumbPath,
    };
    const listItem = [];

    for (let i = 0; i < files.length; i++) {
      listItem.push({
        name: files[i].name,
        extension: files[i].extension,
        url: files[i].url,
        nameUrl: files[i].url.split("/").pop(),
      });
    }

    body.files = listItem;

    let response = await fetch(process.env.REACT_APP_API_URL + Endpoints.downloadFilesFileManagement, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("TOKEN") ?? "",
        "Content-type": "application/json; charset=UTF-8",
      },
      body: JSON.stringify(body),
    });

    return response.blob();
  }

  async downloadFolderFileManagement(breadcrumbPath: string, folder: IFolder): Promise<string> {
    const body: any = {
      path: breadcrumbPath,
      folder: folder.name,
      url: folder.url,
      nameUrl: folder.url.split("/").pop(),
    };

    let response = await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: Endpoints.downloadFolderFileManagement,
      shouldAuthorize: true,
      body: {
        data: body,
      },
    });

    return response.data.data;
  }

  async moveFileManagement(oldPath: string, newPath: string, itemToMove?: IFolder | IFile | IFile[]): Promise<void> {
    const endpoint =
      (itemToMove as IFolder).type === FileManagementType.FOLDER
        ? Endpoints.moveFolderFileManagement
        : Endpoints.moveFileFileManagement;
    const body: any = {
      old_path: oldPath,
      new_path: newPath,
    };
    const listItem = [];

    if ((itemToMove as IFolder).type === FileManagementType.FOLDER) body.folder = (itemToMove as IFolder).name;
    if ((itemToMove as IFile).type === FileManagementType.FILE) {
      listItem.push({
        name: (itemToMove as IFile).name,
        extension: (itemToMove as IFile).extension,
        url: (itemToMove as IFile).url,
        nameUrl: (itemToMove as IFile).url.split("/").pop(),
      });

      body.files = listItem;
    }

    if ((itemToMove as IFile[]).length > 0) {
      for (let i = 0; i < (itemToMove as IFile[]).length; i++) {
        listItem.push({
          name: (itemToMove as IFile[])[i].name,
          extension: (itemToMove as IFile[])[i].extension,
          url: (itemToMove as IFile[])[i].url,
          nameUrl: (itemToMove as IFile[])[i].url.split("/").pop(),
        });
      }

      body.files = listItem;
    }

    let response = await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: endpoint,
      shouldAuthorize: true,
      body: {
        data: body,
      },
    });

    return response.data.data;
  }

  async renameFileManagement(itemSelected: IFolder | IFile, rename: string, extension?: string): Promise<void> {
    const endpoint =
      itemSelected.type === FileManagementType.FILE
        ? Endpoints.renameFileFileManagement
        : Endpoints.renameFolderFileManagement;
    const body: any =
      itemSelected.type === FileManagementType.FILE
        ? {
            path: itemSelected.path,
            file: itemSelected.name,
            name: rename,
            extension: (itemSelected as IFile).extension,
            url: (itemSelected as IFile).url,
            nameUrl: (itemSelected as IFile).url.split("/").pop(),
          }
        : {
            path: itemSelected.path,
            folder: itemSelected.name,
            name: rename,
          };

    let response = await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: endpoint,
      shouldAuthorize: true,
      body: {
        data: body,
      },
    });

    return response.data.data;
  }

  async deleteFileManagement(breadcrumbPath: string, itemToDelete?: IFolder | IFile | IFile[]): Promise<void> {
    const endpoint =
      (itemToDelete as IFolder).type === FileManagementType.FOLDER
        ? Endpoints.deleteFolderFileFileManagement
        : Endpoints.deleteFileFileFileManagement;
    const body: any = {
      path: breadcrumbPath,
    };
    const listItem = [];

    if ((itemToDelete as IFolder).type === FileManagementType.FOLDER) body.folder = (itemToDelete as IFolder).name;
    if ((itemToDelete as IFile).type === FileManagementType.FILE) {
      listItem.push({
        name: (itemToDelete as IFile).name,
        extension: (itemToDelete as IFile).extension,
        url: (itemToDelete as IFile).url,
        nameUrl: (itemToDelete as IFile).url.split("/").pop(),
      });

      body.files = listItem;
    }

    if ((itemToDelete as IFile[]).length > 0) {
      for (let i = 0; i < (itemToDelete as IFile[]).length; i++) {
        listItem.push({
          name: (itemToDelete as IFile[])[i].name,
          extension: (itemToDelete as IFile[])[i].extension,
          url: (itemToDelete as IFile[])[i].url,
          nameUrl: (itemToDelete as IFile[])[i].url.split("/").pop(),
        });

        body.files = listItem;
      }
    }

    let response = await this.apiService.request({
      method: HttpMethod.DELETE,
      endpoint: endpoint,
      shouldAuthorize: true,
      body: {
        data: body,
      },
    });

    return response.data.data;
  }
}
