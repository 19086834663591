import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IAuthRepository } from "../../data/repositories/authRepository";

export class ChangePassword extends UseCase<ChangePasswordParams, boolean>{
    private repository: IAuthRepository
    constructor(repository: IAuthRepository) {
        super()
        this.repository = repository
    }
    call(params: ChangePasswordParams): Promise<Either<Failure, boolean>> {
        return this.repository.changePassword(params.password, params.passwordCF, params.token)
    }
}

export class ChangePasswordParams {
    password: string;
    passwordCF: string;
    token: string
    constructor(password: string, passwordCF: string, token: string) {
        this.password = password;
        this.passwordCF = passwordCF;
        this.token = token
    }
}