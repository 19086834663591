import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { FileManagementState } from "src/base/Enums";
import { DI, Injector } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import { Input } from "src/components/input";
import Modal from "src/components/modal/modal";
import { Strings } from "src/constants";
import { IFile } from "src/features/fileManagement/data/models/file";
import { IFolder } from "src/features/fileManagement/data/models/folder";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers";
import { IRootState } from "src/store";
import { RemoteFileManagementContainer } from "../../container/RemoteFileManagementContainer";

interface RenamePopupProps {
  breadcrumbPath: string;
  itemSelected?: IFolder | IFile;
}

interface RenamePopupState {
  rename: string;
}

class RenamePopup extends React.Component<RenamePopupType, RenamePopupState> {
  constructor(props: any) {
    super(props);

    this.state = {
      rename: "",
    };
  }

  componentDidMount() {
    DI.get().injectRemoteFileManagementDependencies();
  }

  componentDidUpdate(
    prevProps: Readonly<RenamePopupProps>,
    prevState: Readonly<RenamePopupState>,
    snapshot?: any
  ): void {
    if (prevProps.itemSelected !== this.props.itemSelected) {
      this.setState({ rename: this.props.itemSelected?.name! });
    }
  }

  handleRename = (value: string) => {
    this.setState({ rename: value });
  };

  handleSubmit = () => {
    this.props.onRename(this.props.itemSelected!, this.state.rename).then(() => {
      this.props.changeModal(Modals.NONE);
      this.props.onFetchFileManagement(this.props.breadcrumbPath);
    });
  };

  render() {
    return (
      <Modal
        id="file-management-rename-popup"
        dialogExtraClassName="file-management-popup"
        isShow={this.props.currentModal === Modals.FILE_MANAGEMENT_RENAME}
        footerChildren={
          <div className="btn-group2">
            <MainButton
              className="btn btn-secondary"
              enabled
              title={Strings.RETURN}
              onSubmit={() => this.props.changeModal(Modals.NONE)}
            />
            <MainButton
              className="btn btn-primary"
              enabled={this.state.rename?.length > 0}
              title={Strings.REGISTER}
              onSubmit={this.handleSubmit}
              submitting={this.props.fileManagementState === FileManagementState.submitButton}
            />
          </div>
        }
      >
        <h4 className="text_center overflow">
          <strong>名前の変更</strong>
        </h4>
        <Input inputClassName="form-control" value={this.state.rename} onChange={this.handleRename} />
      </Modal>
    );
  }
}

const mapStateToProps = ({ fileManagement, app }: IRootState) => {
  const { fileManagementState } = fileManagement;
  const { currentModal } = app;

  return {
    fileManagementState,
    currentModal,
  };
};
const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
    onRename: (itemSelected: IFolder | IFile, rename: string) =>
      Injector.get()
        .find<RemoteFileManagementContainer>("RemoteFileManagementContainer")
        .onRename(dispatch, itemSelected, rename),
    onFetchFileManagement: (breadcrumbPath: string) =>
      Injector.get()
        .find<RemoteFileManagementContainer>("RemoteFileManagementContainer")
        .onFetchFileManagement(dispatch, breadcrumbPath),
  };
};

type RenamePopupType = RenamePopupProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(RenamePopup);
