import React from "react";
import * as THREE from "three";
import ThreeDCanvasEditor from "./ThreeDCanvasEditor";

interface Modal3DCanvasEditorProps {
  onClose: () => void;
  cameraPosition: [x: number, y: number, z: number];
  meshes: THREE.Object3D<THREE.Event>[];
  handleUpdateScene: (scene: THREE.Scene) => void;
}

interface Modal3DCanvasEditorState {
  meshSelected?: THREE.Mesh;
}

class Modal3DCanvasEditor extends React.Component<
  Modal3DCanvasEditorProps,
  Modal3DCanvasEditorState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      meshSelected: undefined,
    };
  }

  handleSelectMesh(mesh: THREE.Mesh) {
    this.setState({
      meshSelected: mesh,
    });
  }

  handleUpdateScene() {
    if (!this.state.meshSelected?.parent) return this.props.onClose();

    this.props.handleUpdateScene(
      this.state.meshSelected?.parent as THREE.Scene
    );
  }

  render(): React.ReactNode {
    return (
      <div className="threed-editor-modal">
        <ThreeDCanvasEditor
          onClose={this.props.onClose}
          cameraPosition={this.props.cameraPosition}
          meshes={this.props.meshes}
          meshSelected={this.state.meshSelected!}
          handleSelectMesh={(mesh: THREE.Mesh) => this.handleSelectMesh(mesh)}
          handleUpdateScene={() => this.handleUpdateScene()}
        />
      </div>
    );
  }
}

export default Modal3DCanvasEditor;
