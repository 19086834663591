import { IUnsharedFilesState, UnsharedFilesActions, UnsharedFilesActionType } from "./types";


const initialState: IUnsharedFilesState = {
  unsharedFilesCount: 0
};

export function unsharedFilesReducer(
  state: IUnsharedFilesState = initialState,
  action: UnsharedFilesActions
) {
  switch (action.type) {
    case UnsharedFilesActionType.UNSHARED_FILES_COUNT_UPDATED:
      return {
        ...state,
        unsharedFilesCount: action.payload.count
      }
    default:
      return state;
  }
}
