import { Either, LeftSide, RightSide } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { IUser } from "src/features/account/data/models/user";
import { Pagination } from "src/shared/model/pagination";
import { IPatientRemoteDatasource } from "../datasources/patientRemoteDataSource";

export interface IPatientRepository {
  fetchPatients(
    patientCode: string,
    full_name: string,
    query: string,
    page: number,
    status: number,
    action?: string
  ): Promise<Either<Failure, Pagination<IUser>>>;
  fetchListPatients(
    patientCode: string,
    full_name: string,
    query: string,
    page: number,
    status: number,
    action?: string
  ): Promise<Either<Failure, Pagination<IUser>>>;
  fetchPatientDetails(patientId: any): Promise<Either<Failure, IUser | undefined>>;
  addPatient(patient: IUser): Promise<Either<Failure, boolean>>;
  updatePatient(patient: IUser): Promise<Either<Failure, boolean>>;
  deletePatient(id: string): Promise<Either<Failure, boolean>>;
  importPatientsByCSV(csvBase64: string): Promise<Either<Failure, void>>;
  sendMailtoPatient(id: string): Promise<Either<Failure, void>>;
  downloadcsvfile(): Promise<Either<Failure, string>>;
}

export class PatientRepository implements IPatientRepository {
  patientRemoteDataSource: IPatientRemoteDatasource;
  constructor(remoteDataSource: IPatientRemoteDatasource) {
    this.patientRemoteDataSource = remoteDataSource;
  }

  async downloadcsvfile(): Promise<Either<Failure, string>> {
    try {
      return RightSide(await this.patientRemoteDataSource.downloadcsvfile());
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async sendMailtoPatient(id: string): Promise<Either<Failure, void>> {
    try {
      return RightSide(await this.patientRemoteDataSource.sendMailToPatient(id));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async fetchPatients(
    patientCode: string,
    full_name: string,
    query: string,
    page: number,
    status: number,
    action?: string
  ): Promise<Either<Failure, Pagination<IUser>>> {
    try {
      return RightSide(
        await this.patientRemoteDataSource.fetchPatients(patientCode, full_name, query, page, status, action)
      );
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async fetchListPatients(
    patientCode: string,
    full_name: string,
    query: string,
    page: number,
    status: number,
    action?: string
  ): Promise<Either<Failure, Pagination<IUser>>> {
    try {
      return RightSide(
        await this.patientRemoteDataSource.fetchListPatients(patientCode, full_name, query, page, status, action)
      );
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async fetchPatientDetails(patientId: any): Promise<Either<Failure, IUser | undefined>> {
    try {
      return RightSide(await this.patientRemoteDataSource.fetchPatientDetails(patientId));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async addPatient(patient: IUser): Promise<Either<Failure, boolean>> {
    try {
      return RightSide(await this.patientRemoteDataSource.addPatient(patient));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async updatePatient(patient: IUser): Promise<Either<Failure, boolean>> {
    try {
      return RightSide(await this.patientRemoteDataSource.updatePatient(patient));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async deletePatient(id: string): Promise<Either<Failure, boolean>> {
    try {
      return RightSide(await this.patientRemoteDataSource.deletePatient(id));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }

  async importPatientsByCSV(csvBase64: string): Promise<Either<Failure, void>> {
    try {
      return RightSide(await this.patientRemoteDataSource.importPatientsByCSV(csvBase64));
    } catch (e) {
      if (typeof e === "string") {
        return LeftSide({ message: e });
      } else if (e instanceof Error) {
        return LeftSide({ message: e.message });
      }
    }
    return LeftSide(new Error(""));
  }
}
