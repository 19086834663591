import { combineReducers, createStore } from "redux";
import { IAccountState, accountReducer } from "src/features/account/presenter/store";
import { IAdminNoticeState, adminNoticeReducer } from "src/features/adminNotice/presenter/store/adminNotice";
import { IPatientAnalyticState, patientAnalyticReducer } from "src/features/analytic/presenter/store/patientAnalytic";
import { IAuthState, authReducer } from "src/features/auth/presenter/store/auth";
import { IForgetPasswordState, forgetPasswordReducer } from "src/features/auth/presenter/store/forgetPassword";
import { ILoginState, loginReducer } from "src/features/auth/presenter/store/login";
import { IFileManagementState, fileManagementReducer } from "src/features/fileManagement/presenter/store";
import { IMedicalRecordState, medicalRecordReducer } from "src/features/medicalRecord/presenter/store/medicalRecord";
import { INotificationState, notificationReducer } from "src/features/notification/presenter/store/notification";
import { IOrthodontistState, orthodontistReducer } from "src/features/orthodontist/presenter/store/orthodontist";
import { IPatientState, patientReducer } from "src/features/patient/presenter/store";
import { IRemoteFilesState, remoteFilesDownloadReducer } from "src/features/plyDownload/presenter/store";
import { IAppState, appReducer } from "src/features/root/presenter/store/root";
import { ISubscriptionState, subscriptionReducer } from "src/features/subscription/presenter/store";
import { IThreeDViewerState, threeDViewerReducer } from "src/features/threeDViewer/presenter/store/threeDViewer";
import { IUnsharedFilesState, unsharedFilesReducer } from "src/features/unsharedFiles/presenter/store";

export interface IRootState {
  auth: IAuthState;
  login: ILoginState;
  forgetPassword: IForgetPasswordState;
  account: IAccountState;
  notification: INotificationState;
  app: IAppState;
  adminNotice: IAdminNoticeState;
  orthodontist: IOrthodontistState;
  patient: IPatientState;
  medicalRecord: IMedicalRecordState;
  patientAnalytic: IPatientAnalyticState;
  subscription: ISubscriptionState;
  remoteFilesDownload: IRemoteFilesState;
  unsharedFiles: IUnsharedFilesState;
  threeDViewer: IThreeDViewerState;
  fileManagement: IFileManagementState;
}

const rootReducer = combineReducers({
  auth: authReducer,
  login: loginReducer,
  forgetPassword: forgetPasswordReducer,
  notification: notificationReducer,
  account: accountReducer,
  adminNotice: adminNoticeReducer,
  app: appReducer,
  orthodontist: orthodontistReducer,
  patient: patientReducer,
  medicalRecord: medicalRecordReducer,
  patientAnalytic: patientAnalyticReducer,
  subscription: subscriptionReducer,
  remoteFilesDownload: remoteFilesDownloadReducer,
  unsharedFiles: unsharedFilesReducer,
  threeDViewer: threeDViewerReducer,
  fileManagement: fileManagementReducer,
});

const store = createStore<IRootState, any, any, any>(rootReducer);

export default store;
