export const TeethClipPath= [
  {
    id: "clip0_92_2",
    width: 55,
    height: 43,
    color: "white",
    transform: "translate(0 229)"
  },
  {
    id: "clip1_92_2",
    width: 58,
    height: 53,
    color: "white",
    transform: "translate(0 179)"
  },
  {
    id: "clip2_92_2",
    width: 66,
    height: 61,
    color: "white",
    transform: "translate(5 122)"
  },
  {
    id: "clip3_92_2",
    width: 58,
    height: 62,
    color: "white",
    transform: "translate(9 122)"
  },
  {
    id: "clip4_92_2",
    width: 50,
    height: 46,
    color: "white",
    transform: "translate(30 88)"
  },
  {
    id: "clip5_92_2",
    width: 46,
    height: 45,
    color: "white",
    transform: "translate(52 56)"
  },
  {
    id: "clip6_92_2",
    width: 46,
    height: 48,
    color: "white",
    transform: "translate(78 29)"
  },
  {
    id: "clip7_92_2",
    width: 38,
    height: 47,
    color: "white",
    transform: "translate(109 10)"
  },
  {
    id: "clip8_92_2",
    width: 43,
    height: 53,
    color: "white",
    transform: "translate(142)"
  },
  {
    id: "clip9_92_2",
    width: 55,
    height: 43,
    color: "white",
    transform: "matrix(-1 0 0 1 370 229)"
  },
  {
    id: "clip10_92_2",
    width: 58,
    height: 53,
    color: "white",
    transform: "matrix(-1 0 0 1 370 179)"
  },
  {
    id: "clip11_92_2",
    width: 66,
    height: 61,
    color: "white",
    transform: "matrix(-1 0 0 1 365 122)"
  },
  {
    id: "clip12_92_2",
    width: 58,
    height: 62,
    color: "white",
    transform: "matrix(-1 0 0 1 361 122)"
  },
  {
    id: "clip13_92_2",
    width: 50,
    height: 46,
    color: "white",
    transform: "matrix(-1 0 0 1 340 88)"
  },
  {
    id: "clip14_92_2",
    width: 46,
    height: 45,
    color: "white",
    transform: "matrix(-1 0 0 1 318 56)"
  },
  {
    id: "clip15_92_2",
    width: 46,
    height: 48,
    color: "white",
    transform: "matrix(-1 0 0 1 292 29)"
  },
  {
    id: "clip16_92_2",
    width: 38,
    height: 47,
    color: "white",
    transform: "matrix(-1 0 0 1 261 10)"
  },
  {
    id: "clip17_92_2",
    width: 43,
    height: 53,
    color: "white",
    transform: "matrix(-1 0 0 1 228 0)"
  },
  {
    id: "clip18_92_2",
    width: 57,
    height: 45,
    color: "white",
    transform: "translate(3 314)"
  },
  {
    id: "clip19_92_2",
    width: 58,
    height: 45,
    color: "white",
    transform: "translate(2 314)"
  },
  {
    id: "clip20_92_2",
    width: 57,
    height: 45,
    color: "white",
    transform: "matrix(-1 0 0 1 366 314)"
  },
  {
    id: "clip21_92_2",
    width: 58,
    height: 45,
    color: "white",
    transform: "matrix(-1 0 0 1 367 314)"
  },
  {
    id: "clip22_92_2",
    width: 58,
    height: 53,
    color: "white",
    transform: "translate(5 356)"
  },
  {
    id: "clip23_92_2",
    width: 58,
    height: 53,
    color: "white",
    transform: "matrix(-1 0 0 1 364 356)"
  },
  {
    id: "clip24_92_2",
    width: 62,
    height: 63,
    color: "white",
    transform: "translate(24 404)"
  },
  {
    id: "clip25_92_2",
    width: 62,
    height: 63,
    color: "white",
    transform: "matrix(-1 0 0 1 345 404)"
  },
  {
    id: "clip26_92_2",
    width: 46,
    height: 43,
    color: "white",
    transform: "translate(55 456)"
  },
  {
    id: "clip27_92_2",
    width: 46,
    height: 43,
    color: "white",
    transform: "matrix(-1 0 0 1 314 456)"
  },
  {
    id: "clip28_92_2",
    width: 43,
    height: 42,
    color: "white",
    transform: "translate(75 483)"
  },
  {
    id: "clip29_92_2",
    width: 43,
    height: 42,
    color: "white",
    transform: "matrix(-1 0 0 1 294 483)"
  },
  {
    id: "clip30_92_2",
    width: 37,
    height: 47,
    color: "white",
    transform: "translate(105 495)"
  },
  {
    id: "clip31_92_2",
    width: 37,
    height: 47,
    color: "white",
    transform: "matrix(-1 0 0 1 264 495)"
  },
  {
    id: "clip32_92_2",
    width: 33,
    height: 51,
    color: "white",
    transform: "translate(132 504)"
  },
  {
    id: "clip33_92_2",
    width: 33,
    height: 51,
    color: "white",
    transform: "matrix(-1 0 0 1 237 504)"
  },
  {
    id: "clip34_92_2",
    width: 25,
    height: 46,
    color: "white",
    transform: "translate(160 509)"
  },
  {
    id: "clip35_92_2",
    width: 25,
    height: 46,
    color: "white",
    transform: "matrix(-1 0 0 1 209 509)"
  },
]