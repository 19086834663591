import { AuthState } from "src/base/Enums";
import { IAuthState, AuthActions, AuthActionType } from "./types";

const initialState: IAuthState = {
    authState: AuthState.unauthenticated,

}

export function authReducer(state: IAuthState = initialState, action: AuthActions): IAuthState {
    switch (action.type) {
        case AuthActionType.AUTH_STATE_CHANGED:
            return {
                ...state,
                authState: action.payload.state
            }

        default:
            return state;
    }
}