import React from "react";
import { IFile } from "src/features/fileManagement/data/models/file";
import { IFolder } from "src/features/fileManagement/data/models/folder";
import { DropdownPosition } from "../pages/fileManagementPage";

interface FolderComponentProps {
  folder: IFolder;
  toggleDropdown: (dropdownPosition: DropdownPosition) => void;
  handleSelectItem: (item: IFolder | IFile) => void;
  handleMoveIntoFolder: (folder: IFolder) => void;
  isSelected: boolean;
}

interface FolderComponentState {}

class FolderComponent extends React.Component<FolderComponentProps, FolderComponentState> {
  handleToggleDropdown = (event: any) => {
    const dropdownElement = document.getElementById("dropdown-action");

    const mouseTop = event.pageY;
    const mouseBottom = document.body.offsetHeight - event.pageY;
    const mouseLeft = event.pageX;
    const mouseRight = document.body.offsetWidth - event.pageX;

    const dropdownPosition: DropdownPosition = {
      top: mouseTop,
      left: mouseLeft,
    };

    if (document.body.offsetWidth - event.clientX < dropdownElement?.offsetWidth!) {
      delete dropdownPosition.left;
      dropdownPosition.right = mouseRight;
    }
    if (document.body.offsetHeight - event.clientY < dropdownElement?.offsetHeight!) {
      delete dropdownPosition.top;
      dropdownPosition.bottom = mouseBottom;
    }

    this.props.toggleDropdown(dropdownPosition);
  };

  handleClickFolder = (event: any) => {
    if (event.detail === 1) this.props.handleSelectItem(this.props.folder);
    else this.props.handleMoveIntoFolder(this.props.folder);
  };

  render(): React.ReactNode {
    return (
      <div className={`folder-component ${this.props.isSelected ? "selected" : ""}`} onClick={this.handleClickFolder}>
        <span className="folder-name">{this.props.folder.name}</span>
        <span className="dropdown-dots" onClick={this.handleToggleDropdown} />
      </div>
    );
  }
}

export default FolderComponent;
