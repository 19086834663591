import { FileManagementState } from "src/base/Enums";
import { FileManagementActionType, FileManagementActions, IFileManagementState } from "./types";

const initialState: IFileManagementState = {
  fileManagementState: FileManagementState.none,
  fileManagementData: {
    folders: [],
    files: [],
    maximum: 0,
    total: 0,
  },
  folderToMove: [],
  filesToUpload: new Map(),
};

export function fileManagementReducer(state: IFileManagementState = initialState, action: FileManagementActions) {
  switch (action.type) {
    case FileManagementActionType.FILE_MANAGEMENT_STATE_CHANGED:
      return {
        ...state,
        fileManagementState: action.payload.fileManagementState,
      };
    case FileManagementActionType.FETCH_FILE_MANAGEMENT:
      return {
        ...state,
        fileManagementData: action.payload.fileManagementData,
      };
    case FileManagementActionType.FETCH_FOLDER_TO_MOVE_FILE_MANAGEMENT:
      return {
        ...state,
        folderToMove: action.payload.fileManagementData.folders,
      };
    case FileManagementActionType.UPDATE_FILE_TO_UPLOAD_FILE_MANAGEMENT:
      const filesToUploadModified = state.filesToUpload;

      filesToUploadModified.set(action.payload.fileToUpload.key, action.payload.fileToUpload.value!);

      return {
        ...state,
        filesToUpload: filesToUploadModified,
      };
    case FileManagementActionType.RESET_FILE_TO_UPLOAD_FILE_MANAGEMENT:
      return {
        ...state,
        filesToUpload: new Map(),
      };
    case FileManagementActionType.IMPORT_FILE_MANAGEMENT:
      return {
        ...state,
      };
    case FileManagementActionType.IMPORT_FILE_MANAGEMENT_COMPLETED:
      return {
        ...state,
        filesToUpload: new Map(),
      };
    default:
      return {
        ...state,
      };
  }
}
