import React from "react";
import ThreeDModelEditor from "./ThreeDModelEditor";

export interface ThreeDViewerSceneEditorProps {
  meshes: THREE.Object3D<THREE.Event>[];
  handleSelectMesh: (mesh: THREE.Mesh) => void;
}

class ThreeDSceneEditor extends React.Component<ThreeDViewerSceneEditorProps> {
  renderModel() {
    return this.props.meshes?.map((mesh, idx) => {
      return (
        <ThreeDModelEditor
          key={idx}
          mesh={mesh as THREE.Mesh}
          handleSelectMesh={this.props.handleSelectMesh}
        />
      );
    });
  }

  render(): React.ReactNode {
    return <scene>{this.renderModel()}</scene>;
  }
}

export default ThreeDSceneEditor;
