import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IPatientRepository } from "../../data/repositories/patientRepository";

export class ImportCsv extends UseCase<ImportCsvParams, void>{
    private repository: IPatientRepository
    constructor(repository: IPatientRepository) {
        super()
        this.repository = repository
    }

    call(params: ImportCsvParams): Promise<Either<Failure, void>> {
        return this.repository.importPatientsByCSV(params.csvBase64)
    }
}

export class ImportCsvParams {
    csvBase64: string
    constructor(csvBase64: string) {
        this.csvBase64 = csvBase64
    }
}