import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IUser } from "src/features/account/data/models/user";
import { Pagination } from "src/shared/model/pagination";
import { IPatientRepository } from "../../data/repositories/patientRepository";

export class FetchPatients extends UseCase<FetchPatientsParams, Pagination<IUser>>{
    private repository: IPatientRepository
    constructor(repository: IPatientRepository) {
        super()
        this.repository = repository
    }
    call(params: FetchPatientsParams): Promise<Either<Failure, Pagination<IUser>>> {
        return this.repository.fetchPatients(params.code, params.full_name, params.query, params.page, params.status, params.action)
    }
}

export class FetchPatientsParams {
    code: string
    full_name: string
    query: string
    page: number
    status: number
    action?: string
    constructor(code: string, full_name: string, query: string, page: number, status: number, action?: string) {
        this.code = code
        this.full_name = full_name
        this.query = query
        this.page = page
        this.status = status
        this.action = action
    }
}