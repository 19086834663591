import { ForgetPasswordActions, ForgetPasswordActionType, IForgetPasswordState } from "./types";
import { forgetPasswordValidate } from "./validation";

const initialState: IForgetPasswordState = {
    isSubmitting: false,
    email: {
        value: '',
        validate: false,
        error: ''
    },
}

export function forgetPasswordReducer(state: IForgetPasswordState = initialState, action: ForgetPasswordActions): IForgetPasswordState {
    switch (action.type) {
        case ForgetPasswordActionType.FORGET_PASSSWORD_STATE_CHANGED:
            return {
                ...state,
                isSubmitting: action.payload.isSubmitting
            }
        case ForgetPasswordActionType.FORGET_PASSSWORD_FIELD_CHANGED:
            return {
                ...state,
                [action.payload.field]: {
                    value: action.payload.value,
                    error: forgetPasswordValidate(action.payload.field, action.payload.value),
                    validate: forgetPasswordValidate(action.payload.field, action.payload.value).length == 0
                }
            }
        default:
            return state;
    }
}