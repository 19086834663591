import React from "react";

interface ITextareaProps {
  className?: string;
  placeHolder?: string;
  value?: any;
  name?: string;
  checked?: boolean;
  onChange: (value: any) => void
  cols?: number;
  rows?: 3;
  error?: string;
}

export class Textarea extends React.Component<ITextareaProps> {
    render() {
        return (
          <React.Fragment>
            <textarea 
              className={`${ this.props.className ?? '' }`}
              value={ this.props.value ?? '' }
              placeholder={ this.props.placeHolder  ?? '' }
              name={ this.props.name ?? '' }
              onChange={ (e) => this.props.onChange(e.currentTarget.value) }
              cols={ this.props.cols ?? 30 }
              rows={ this.props.rows ?? 3 }
            >
            </textarea>
            {
              this.props.error && this.props.error.length > 0 && <p className="text-error">{ this.props.error }</p>
            }
          </React.Fragment>
        );
    }
}