import { Exporter } from "./Exporter";

export class STL3DExporter extends Exporter {
    async exportModel(outputFileName: string, scene: any, onFileConverted: (convertedFile: any) => void, withKey?: string): Promise<void> {
        outputFileName = outputFileName.replaceAll(".ply", ".stl").replaceAll(".stl", ".stl")
        const { STLExporter } = await import('./STLExporter.js');

        const exporter = new STLExporter();
        let data = exporter.parse(scene, {
            binary: true
        })
        onFileConverted({
            file_type: "glb",
            blob: new Blob([data], { type: 'application/octet-stream' }),
            name: outputFileName,
            key: withKey,
            isUpdate: true
        })
    }

}