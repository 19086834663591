import { Pagination } from "src/shared/model/pagination";
import { ActionType } from "typesafe-actions";
import * as actions from './actions';

export interface IAdminNotice {
    id: number;
    activityDate: Date;
    type: string;
    clinic_id?: number;
    title: string;
    image?: string;
    content: string;
    pdf?: string;
    job?: string;
    deletedAt?: Date;
    createdAt?: Date;
    updatedAt?: Date;
}

export interface IAdminNoticeState {
    notices: Pagination<IAdminNotice>;
    state: boolean;
}

export enum AdminNoticeActionType {
    FETCH_ADMIN_NOTICE = "FETCH_ADMIN_NOTICE",
    NOTICE_STATE_CHANGED = "NOTICE_STATE_CHANGED",
}

export type AdminNoticeActions = ActionType<typeof actions>;

export const mapNotice = (notice: Pagination<any>) => {
    return {
        ...notice,
        data: notice.data.map(item => {
            return {
                ...item,
                activityDate: new Date(item.activity_date)
            }
        })
    }
}