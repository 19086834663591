import { FileManagementState } from "src/base/Enums";
import { action } from "typesafe-actions";
import { IFileToUpload } from "../../data/models/file";
import { IFileManagement } from "../../data/models/fileManagement";
import { FileManagementActionType } from "./types";

// Call this when global state changed for file management feature
export function stateChanged(fileManagementState: FileManagementState) {
  return action(FileManagementActionType.FILE_MANAGEMENT_STATE_CHANGED, {
    fileManagementState,
  });
}

// Fetch file management
export function fetchFolder(fileManagementData: IFileManagement) {
  return action(FileManagementActionType.FETCH_FILE_MANAGEMENT, {
    fileManagementData,
  });
}

// Create folder file management
export function createFolderFileManagement() {
  return action(FileManagementActionType.CREATE_FOLDER_FILE_MANAGEMENT, {});
}

// Import file management
export function importFileManagement() {
  return action(FileManagementActionType.IMPORT_FILE_MANAGEMENT, {});
}

// Import file management completed
export function importFileManagementCompleted() {
  return action(FileManagementActionType.IMPORT_FILE_MANAGEMENT_COMPLETED, {});
}

// Download folder file management
export function downloadFolderFileManagement() {
  return action(FileManagementActionType.DOWNLOAD_FOLDER_FILE_MANAGEMENT, {});
}

// Fetch folder to move file management
export function fetchFolderToMove(fileManagementData: IFileManagement) {
  return action(FileManagementActionType.FETCH_FOLDER_TO_MOVE_FILE_MANAGEMENT, {
    fileManagementData,
  });
}

// Move file management
export function moveFileManagement() {
  return action(FileManagementActionType.RENAME_FILE_MANAGEMENT, {});
}

// Rename file management
export function renameFileManagement() {
  return action(FileManagementActionType.RENAME_FILE_MANAGEMENT, {});
}

// Delete file management
export function deleteFileManagement() {
  return action(FileManagementActionType.RENAME_FILE_MANAGEMENT, {});
}

// Update file to upload
export function updateFilesToUploadFileManagement(fileToUpload: IFileToUpload) {
  return action(FileManagementActionType.UPDATE_FILE_TO_UPLOAD_FILE_MANAGEMENT, { fileToUpload });
}
// Reset file to upload
export function resetFilesToUploadFileManagement() {
  return action(FileManagementActionType.RESET_FILE_TO_UPLOAD_FILE_MANAGEMENT, {});
}
