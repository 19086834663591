import React from "react";
import { LineChart } from "src/components/chart/line_chart_component";
import { IAnalyticData } from "src/features/analytic/data/models/patientAnalytic";
import { BOPAnalyticBody } from "../body/bopAnalyticBody";
import { BOPAnalyticFooter } from "../footer/bopAnalyticFooter";
import {
  PatientAnalyticTab,
  PatientAnalyticTabProps,
} from "./patientAnalyticTab";

export class BOPAnalyticTab extends React.Component<PatientAnalyticTabProps> {
  render(): React.ReactNode {
    return (
      <PatientAnalyticTab
        id={this.props.id}
        onChangeDates={this.props.onChangeDates}
        analyticDetail={this.props.analyticDetail}
        className={this.props.className}
        body={
          <BOPAnalyticBody
            id={`${this.props.id}-body`}
            comprehensive={this.props.analyticDetail.comprehensive}
            child={<div className="tab-content percent-box-custom">
              <LineChart items={(this.props.analyticDetail.analysis.dates).map((date) => {
                return {
                  name: date.label,
                  percents: parseFloat((date.result as number).toFixed(2)),
                  color: date.color
                }
              })}
              />
              <div className="percent-box">
                {(this.props.analyticDetail.comprehensive as IAnalyticData[]).length > 0 ? <dl>
                  <dt>{(this.props.analyticDetail.comprehensive as IAnalyticData[])[0].label}</dt>
                  <dd>{parseFloat((this.props.analyticDetail.comprehensive as IAnalyticData[])[0].value.toString()).toFixed(2)}%</dd>
                </dl> : undefined}
                {(this.props.analyticDetail.comprehensive as IAnalyticData[]).length > 1 ? <dl>
                  <dt>{(this.props.analyticDetail.comprehensive as IAnalyticData[])[1].label}</dt>
                  <dd>{parseFloat((this.props.analyticDetail.comprehensive as IAnalyticData[])[1].value.toString()).toFixed(2)}%</dd>
                </dl> : undefined}
              </div>
            </div>}
          />
        }
        footer={<BOPAnalyticFooter id={`${this.props.id}-footer`} evaluation={this.props.analyticDetail.analysis.dates ? this.props.analyticDetail.evaluation : undefined} />}
      />
    );
  }
}
