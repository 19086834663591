import { Either } from "src/base/Either"
import { Failure } from "src/base/Failure"
import { UseCase } from "src/base/UseCase"
import { IMedicalRecord } from "../../data/models/medicalRecord"
import { IMedicalRecordRepository } from "../../data/repositories/medicalRecordRepository"

export class UpdateMedicalRecord extends UseCase<UpdateMedicalRecordParams, void>{
    private repository: IMedicalRecordRepository
    constructor(repository: IMedicalRecordRepository) {
        super()
        this.repository = repository
    }

    call(params: UpdateMedicalRecordParams): Promise<Either<Failure, void>> {
        return this.repository.updateMedicalRecord(params.medicalRecord)
    }

}

export class UpdateMedicalRecordParams {
    medicalRecord: IMedicalRecord
    constructor(medicalRecord: IMedicalRecord) {
        this.medicalRecord = medicalRecord
    }
}