import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Table } from "src/components/table";
import { Strings } from "src/constants";
import { Routes } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import { IRootState } from "src/store";

class AccountPage extends React.Component<AccountPageType> {
  render() {
    return (
      <MainLayout
        className="secondpage navstate_show page-account_management animate-item"
        subClassName="p-account_management"
      >
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">{Strings.ACCOUNT_MANAGEMENT}</div>
            <div className="card-body">
              <Table
                className="dataTable-container"
                subClassName="dataTable-table"
                headers={[
                  {
                    fields: [{ html: Strings.ACCOUNT_NAME }, { html: Strings.ACCOUNT_ID }, { html: Strings.ACTION }],
                  },
                ]}
                rows={[
                  {
                    fields: [
                      { html: this.props.me.full_name },
                      { html: this.props.me.username },
                      {
                        html: (
                          <div className="fcc">
                            <Link className="btn btn-primary" to={Routes.ACCOUNT_MANAGEMENT_DETAIL}>
                              編集
                            </Link>
                          </div>
                        ),
                      },
                    ],
                  },
                ]}
              />
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ account }: IRootState) => {
  const { me } = account;
  return {
    me,
  };
};

type AccountPageType = ReturnType<typeof mapStateToProps>;

export default connect(mapStateToProps)(AccountPage);
