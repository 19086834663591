import moment from "moment";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { DI, Injector } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import { ConfirmDeletePopup } from "src/components/popup/confirmDeletePopup";
import { Table } from "src/components/table";
import { Strings } from "src/constants";
import { NotificationContainer } from "src/features/notification/presenter/container/notificationContainer";
import { chooseNotification, INotification } from "src/features/notification/presenter/store/notification";
import { changeModal } from "src/features/root/presenter/store/root";
import { history, Modals, Routes } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import { PaginationComponent } from "src/shared/components/pagination";
import { IRootState } from "src/store";

export interface NotificationPageState {
  selectedNotificationId: any;
}

class NotificationPage extends React.Component<NotificationPageType, NotificationPageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      selectedNotificationId: -1,
    };
  }

  componentDidMount() {
    DI.get().provideNotificationDependencies();
    this.props.fetchNotification(1);
  }

  componentWillUnmount() {
    DI.get().removeNotificationDepenencies();
  }

  buildNotificationTable = () => {
    return (
      <Table
        className="dataTable-container mt-4"
        subClassName="dataTable-table"
        headers={[
          {
            fields: [
              { html: Strings.CREATED_AT },
              { html: Strings.NOTIFICATION_TITLE },
              { html: Strings.ATTACH_FILE },
              { html: Strings.ACTION },
            ],
          },
        ]}
        rows={[
          ...this.props.notifications.data.map((item: INotification) => {
            return {
              fields: [
                { html: moment(item.activityDate.value).format("YYYY/MM/DD, h:mm:ss a") },
                { html: item.title.value },
                {
                  html: (
                    <a className="text-underline" href={item.pdf?.value ?? ""} target="_blank" rel="noreferrer">
                      {item.pdf?.value ? item.pdf.value.split("/").slice(-1)[0] : ""}
                    </a>
                  ),
                },
                {
                  html: (
                    <div className="fcc">
                      <a
                        className="btn btn-primary"
                        onClick={() => {
                          history.replace({
                            pathname: Routes.NOTIFICATION_MANAGEMENT_DETAIL,
                            search: `?notificationId=${item.id}`,
                          });
                        }}
                      >
                        編集
                      </a>
                      <a
                        className="btn btn-danger"
                        onClick={() => {
                          this.props.changeModal(Modals.CONFIRM_REMOVE);
                          this.setState({
                            selectedNotificationId: item.id,
                          });
                        }}
                      >
                        削除
                      </a>
                    </div>
                  ),
                },
              ],
            };
          }),
        ]}
      ></Table>
    );
  };

  render() {
    return (
      <MainLayout
        isLoading={this.props.notificationState}
        className="secondpage navstate_show page-notification_management"
        subClassName="p-notification_management animate-item"
      >
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">{Strings.NOTIFICATION_MANAGEMENT}</div>
            <div className="card-body">
              <div className="btn-group2">
                <MainButton
                  className="btn btn-primary btn-main"
                  title={Strings.CREATE_NEW}
                  enabled={true}
                  onSubmit={() => {
                    history.replace(Routes.NOTIFICATION_MANAGEMENT_CREATE);
                  }}
                />
              </div>
              {this.buildNotificationTable()}
              <PaginationComponent
                className={`mt20 ${this.props.notifications.data.length >= 0 ? "hide" : ""}`}
                currentPage={this.props.notifications.current_page}
                totalPage={this.props.notifications.last_page}
                onClick={(page) => {
                  this.props.fetchNotification(page);
                }}
              ></PaginationComponent>
            </div>
          </div>
        </div>

        <ConfirmDeletePopup
          changeModal={this.props.changeModal}
          currentModal={this.props.currentModal}
          onDelete={() => {
            this.props.deleteNotification(this.state.selectedNotificationId, this.props.notifications.current_page);
          }}
        />
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ notification, app }: IRootState) => {
  const { notifications, state } = notification;
  const { currentModal } = app;
  return {
    notifications,
    currentModal,
    notificationState: state,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    fetchNotification: (page: number) =>
      Injector.get().find<NotificationContainer>("NotificationContainer").getNotification(dispatch, page),
    chooseNotification: (notification: INotification) => dispatch(chooseNotification(notification)),
    deleteNotification: (notificationId: number, page: number) =>
      Injector.get()
        .find<NotificationContainer>("NotificationContainer")
        .deleteNotificationHandle(dispatch, notificationId, page),
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
  };
};

type NotificationPageType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(NotificationPage);
