import axios from "axios";
import { Endpoints } from "src/base/Endpoints";
import { Injectable } from "src/base/Injector";
import { Endpoint, HttpMethod } from "src/helpers";
import { IApiService } from "src/services/api/ApiService";
import { Pagination } from "src/shared/model/pagination";

export interface IUnsharedFiles {
    unsharedfiles(): Promise<number>
}

export const TOKEN = "TOKEN";

export class UnsharedFiles implements IUnsharedFiles {

    apiService: IApiService
    constructor(apiService: IApiService) {
        this.apiService = apiService
    }

    async unsharedfiles(): Promise<number> {
        var response = await this.apiService.request({ method: HttpMethod.GET, endpoint: Endpoints.countUnsharedFiles })
        if (response.data.access_token) {
            localStorage.setItem(TOKEN, response.data.access_token)
        }
        if (!response.data || !response.data.data) return 0
        return response.data.data
    }
}