import React from "react";
import { Label } from "../label/label";

interface IRadio {
  label: string;
  value: any;
}

interface IMedicalRadioProps {
  options: Array<IRadio>;
  selected?: any;
  name: string;
  className?: string;
  labelClassName?: string;
  readonly?: boolean;
  onChange: (value: any) => void;
}

export class MedicalRadio extends React.Component<IMedicalRadioProps> {
  render(): React.ReactNode {
    return (
      <div className={this.props.className ?? ""}>
        {this.props.options.map((option, idx) => (
          <Label
            key={idx}
            className={this.props.labelClassName ?? ""}
            htmlFor={this.props.readonly ? undefined : this.props.name + option.value}
          >
            <input
              className="mr10"
              id={this.props.name + option.value}
              type="checkbox"
              readOnly={this.props.readonly}
              name={this.props.name}
              checked={option.value === this.props.selected}
              onChange={(value) => {
                if (this.props.selected !== option.value) this.props.onChange(option.value);
              }}
            />
            {option.label}
            <span className="checkmark"></span>
          </Label>
        ))}
      </div>
    );
  }
}
