import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { MedicalRecordState } from "src/base/Enums";
import { medicalRecordStateChanged } from "src/features/medicalRecord/presenter/store/medicalRecord";
import { FileTypes } from "src/helpers/enums";
import { Assets } from "src/utils/assets";
import { Label } from "../label";
import FileManagementPopup from "../popup/FileManagementPopup/FileManagementPopup";
import SelectFileOptionPopup from "../popup/SelectFileOptionPopup";

interface IFilePickerMultipleProps {
  className?: string;
  inputPlaceHolder?: string;
  type?: string;
  inputClassName?: string;
  value?: any;
  name?: string;
  checked?: boolean;
  onChange: (value: any, e?: React.ChangeEvent<HTMLInputElement>, file?: any, url?: string, fileList?: any) => void;
  id?: string;
  maxLength?: number;
  error?: string;
  labelClassName?: string;
  labelText?: string;
  fileAccept?: FileTypes;
  hasFile?: boolean;
  onDelete?: () => void;
  readonly?: boolean;
}

interface IFilePickerMultipleState {
  isShowSelectFilePopup: boolean;
  isShowFileManagementPopup: boolean;
}

class FilePickerMultiple extends React.Component<FilePickerMultipleType, IFilePickerMultipleState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isShowSelectFilePopup: false,
      isShowFileManagementPopup: false,
    };
  }

  handleShowSelectFilePopup = (isShow: boolean) => {
    this.setState({ isShowSelectFilePopup: isShow });
  };

  handleShowFileManagementPopup = (isShow: boolean) => {
    this.setState({ isShowFileManagementPopup: isShow });
  };

  render() {
    return (
      <>
        <div className={this.props.className ?? ""}>
          <Label
            className={this.props.labelClassName ?? ""}
            text={this.props.labelText ?? ""}
            onClick={() => !this.props.readonly && this.handleShowSelectFilePopup(true)}
          />
          {this.props.hasFile ? (
            <div
              onClick={() => {
                this.props.onDelete && this.props.onDelete();
              }}
              className="delete-btn"
            >
              <img className="close-icon" src={Assets.close} alt="" />
            </div>
          ) : undefined}
          {this.state.isShowSelectFilePopup && (
            <SelectFileOptionPopup
              isShow={this.state.isShowSelectFilePopup}
              handleShow={this.handleShowSelectFilePopup}
              handleShowFileManagementPopup={this.handleShowFileManagementPopup}
              id={this.props.id}
              inputClassName={this.props.inputClassName}
              fileAccept={this.props.fileAccept}
              onLoading={() => this.props.medicalRecordStateChanged(MedicalRecordState.convertingFiles)}
              onChange={(_, event, ___, ____, fileList) => {
                this.props.medicalRecordStateChanged(MedicalRecordState.convertingFiles);
                this.handleShowSelectFilePopup(false);

                if (fileList?.length === 1)
                  this.props.onChange(fileList[0].valueBase64, event, fileList[0], fileList[0].location);
                if (fileList?.length! > 1) {
                  this.props.onChange(undefined, event, undefined, undefined, fileList);
                }

                this.props.medicalRecordStateChanged(MedicalRecordState.none);
              }}
              multiple
            />
          )}
          {this.state.isShowFileManagementPopup && (
            <FileManagementPopup
              isShow={this.state.isShowFileManagementPopup}
              handleShow={this.handleShowFileManagementPopup}
              fileAccept={this.props.fileAccept}
              onChange={async (_: any, event: any, ___: any, ____?: string, fileList?: any[]) => {
                this.props.medicalRecordStateChanged(MedicalRecordState.convertingFiles);
                this.handleShowFileManagementPopup(false);
                this.handleShowSelectFilePopup(false);

                if (fileList?.length === 1) this.props.onChange(undefined, event, fileList[0], fileList[0].url);
                if (fileList?.length! > 1) this.props.onChange(undefined, event, undefined, undefined, fileList);

                this.props.medicalRecordStateChanged(MedicalRecordState.none);
              }}
              multiple
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = () => {};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    medicalRecordStateChanged: (state: MedicalRecordState) => dispatch(medicalRecordStateChanged(state)),
  };
};

type FilePickerMultipleType = IFilePickerMultipleProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(null, mapDispatcherToProps)(FilePickerMultiple);
