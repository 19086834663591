import React from "react";

export interface ImageCanvasProps {
  image: any;
  imageRotate: number;
  imageScaleY: number;
  imageScaleX: number;
  handleMountCanvas: (canvas: any) => void;
}

interface ImageCanvasState {}

class ImageCanvas extends React.Component<ImageCanvasProps, ImageCanvasState> {
  canvasRef;
  imageRef: any;

  constructor(props: any) {
    super(props);

    this.canvasRef = React.createRef<HTMLCanvasElement>();
    this.imageRef = React.createRef<HTMLImageElement>();
  }

  componentDidMount(): void {
    const canvas = this.canvasRef.current!;
    const context = canvas.getContext("2d")!;

    this.imageRef.current = new Image();
    const image = this.imageRef.current;

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;

      const hRatio = canvas.width / image.width;
      const vRatio = canvas.height / image.height;
      const ratio = Math.min(hRatio, vRatio);
      var centerShift_x = (canvas.width - image.width * ratio) / 2;
      var centerShift_y = (canvas.height - image.height * ratio) / 2;

      context.drawImage(
        image,
        0,
        0,
        image.width,
        image.height,
        centerShift_x,
        centerShift_y,
        image.width * ratio,
        image.height * ratio
      );
    };
    image.crossOrigin = "*";
    image.src = this.props.image.imageUrl + "?liex=anonymous";

    this.props.handleMountCanvas(canvas);
  }

  componentDidUpdate(
    prevProps: Readonly<ImageCanvasProps>,
    prevState: Readonly<ImageCanvasState>,
    snapshot?: any
  ): void {
    const image = this.imageRef.current;
    const canvas = this.canvasRef.current!;

    if (Math.abs(this.props.imageRotate).toString() === "90" || Math.abs(this.props.imageRotate).toString() === "270") {
      canvas.width = image?.height;
      canvas.height = image?.width;
    }
    if (Math.abs(this.props.imageRotate).toString() === "0" || Math.abs(this.props.imageRotate).toString() === "180") {
      canvas.width = image?.width;
      canvas.height = image?.height;
    }

    const context = canvas?.getContext("2d")!;

    const hRatio = canvas?.width / image?.width;
    const vRatio = canvas?.height / image?.height;
    const ratio = Math.min(hRatio, vRatio);

    context?.clearRect(0, 0, canvas.width, canvas.height);
    context?.save();
    context?.translate(canvas.width / 2, canvas.height / 2);
    context?.rotate((this.props.imageRotate * Math.PI) / 180);
    context?.scale(this.props.imageScaleY, this.props.imageScaleX);
    context?.drawImage(
      image,
      -(image.width * ratio) / 2,
      -(image.height * ratio) / 2,
      image.width * ratio,
      image.height * ratio
    );
    context?.restore();
  }

  render() {
    return <canvas ref={this.canvasRef} />;
  }
}

export default ImageCanvas;
