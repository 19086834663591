import React from "react";
import { Position } from "src/base/Enums";

interface IPCRDisplayProps {
  index: number;
  chossedPosition: Array<Position>;
}

export class PCRDisplay extends React.Component<IPCRDisplayProps> {
    render() {
        return (
          <svg className="pcr-display" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" id={`pcr${this.props.index}`}>
            <rect width="40" height="40" fill="#E9E8E8"></rect>
            <path className={`clleft ${ this.props.chossedPosition.indexOf(Position.left) !== -1 ? "cl-red" : ""}`} d="M0 40L20 20L0 0V40Z" fill="#E9E8E8"></path>
            <path className={`cltop ${ this.props.chossedPosition.indexOf(Position.top) !== -1 ? "cl-red" : ""}`} d="M0 -1.74846e-06L20 20L40 0L0 -1.74846e-06Z" fill="#E9E8E8"></path>
            <path className={`clright ${ this.props.chossedPosition.indexOf(Position.right) !== -1 ? "cl-red" : ""}`} d="M40 0L20 20L40 40L40 0Z" fill="#E9E8E8"></path>
            <path className={`clbottom ${ this.props.chossedPosition.indexOf(Position.bottom) !== -1 ? "cl-red" : ""}`} d="M40 40L20 20L0 40L40 40Z" fill="#E9E8E8"></path>
          </svg>
        );
    }
}