import { PatientState, SubscriptionState } from "src/base/Enums";
import { ActionType } from "typesafe-actions";
import { ICard } from "../../domain/model/card";
import { IPlan } from "../../domain/model/plan";
import * as actions from "./actions";

export interface ISubscriptionState {
  //all available plans
  plans: IPlan[];
  //all user's cards
  cards: ICard[];
  //draft card (use for add, detail popup)
  draftCard: ICard;
  isUpdate: boolean
  //selected index to determine which plan user is chosing
  selectedPlanIndex: number;
  //current function state
  subscriptionState: SubscriptionState;
}

export enum SubscriptionActionType {
  SUBSCRIPTION_ALL_PLANS_UPDATED = "SUBSCRIPTION_ALL_PLANS_UPDATED",
  SUBSCRIPTION_CURRENT_PLAN_CHANGED = "SUBSCRIPTION_CURRENT_PLAN_CHANGED",
  SUBSCRIPTION_PLAN_CANCELLED = "SUBSCRIPTION_PLAN_CANCELLED",
  SUBSCRIPTION_PLAN_SELECTED = "SUBSCRIPTION_PLAN_SELECTED",
  SUBSCRIPTION_STATE_CHANGED = "SUBSCRIPTION_STATE_CHANGED",
  SUBSCRIPTION_CARDS_FETCHED = "SUBSCRIPTION_CARDS_FETCHED",
  SUBSCRIPTION_CARD_ADDED = "SUBSCRIPTION_CARD_ADDED",
  SUBSCRIPTION_CARD_SELECTED = "SUBSCRIPTION_CARD_SELECTED",
  SUBSCRIPTION_DRAFT_CARD_PROPS_CHANGED = "SUBSCRIPTION_DRAFT_CARD_PROPS_CHANGED",
  SUBSCRIPTION_DRAFT_CARD_CHANGED = "SUBSCRIPTION_DRAFT_CARD_CHANGED",
}

export type SubscriptionActions = ActionType<typeof actions>;
