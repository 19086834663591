import { AccountState } from "src/base/Enums";
import { defaultUser } from "../../data/models/user";
import { AccountActions, AccountType as AccountActionType, IAccountState } from "./types";

const initialState: IAccountState = {
    me: defaultUser,
    accountState: AccountState.none
}

export function accountReducer(state: IAccountState = initialState, action: AccountActions): IAccountState {
    switch (action.type) {
        case AccountActionType.ACCOUNT_CHANGED:
            return {
                ...state,
                me: {
                    ...state.me,
                    ...action.payload.account
                }
            }
        case AccountActionType.ACCOUNT_DATA_CHANGED:
            return {
                ...state,
                me: {
                    ...state.me,
                    [action.payload.key]: action.payload.value
                }
            }
        case AccountActionType.ACCOUNT_STATE_CHANGED:
            return {
                ...state,
                accountState: action.payload.accountState
            }
        default:
            return state;
    }
}