export const ArrayFunction = {
  range,
  random,
  mergeUnique,
  chunk,
  findOrPush,
  removeValue
};

function range(min: number, max: number) {
  return Array.from({ length: max - min + 1 }, (_, i) => max - i);
}

function random(array: Array<any>) {
  return array[Math.floor(Math.random() * array.length)];
}

function mergeUnique(array: any[]) {
  var a = array.concat();
  for (var i = 0; i < a.length; ++i) {
    for (var j = i + 1; j < a.length; ++j) {
      if (a[i] === a[j]) a.splice(j--, 1);
    }
  }

  return a;
}

function chunk(str: string, length: number) {
  var ret = [];
  var i;
  var len;

  for (i = 0, len = str.length; i < len; i += length) {
    ret.push(str.substr(i, length))
  }

  return ret
}

function findOrPush(array: Array<any>, value: any) {
  return array.indexOf(value) == -1 ? [...array, value] : array;
}


function removeValue(array: Array<any>, value: any) {
  return array.filter(item => item != value);
}