import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { MainButton } from "src/components/button/mainButton";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers/enums";
import { IRootState } from "src/store";
import * as THREE from "three";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import Modal from "../modal/modal";
import ModelViewer from "../modelViewer/modelViewer";

export interface ThreeDViewerPopupProps {
  modelUrl: string;
  title: string;
  currentThreeDKey: string;
  threeDType: string;
  lenght: number;
  onChangeThreeDModel: (key: string) => void;
}

interface ThreeDViewerPopupState {
  isLoading: boolean;
  progressBar: string;
  progressBarColor: string;
  mesh: THREE.Mesh;
}

export class ThreeDViewerPopup extends React.Component<ThreeDViewerPopupType, ThreeDViewerPopupState> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLoading: true,
      progressBar: "0%",
      progressBarColor: "#02a3af",
      mesh: new THREE.Mesh(),
    };
  }

  componentDidUpdate(prevProps: Readonly<ThreeDViewerPopupProps>): void {
    if (prevProps.modelUrl !== this.props.modelUrl) {
      this.setState({
        isLoading: true,
        progressBar: "0%",
        progressBarColor: "#02a3af",
        mesh: new THREE.Mesh(),
      });

      const loader = new GLTFLoader();

      loader.load(
        this.props.modelUrl,
        // Load model successfully
        (gltf: any) => {
          this.setState({
            isLoading: false,
            mesh: gltf.scene.children[0],
          });
        },
        // Progress loading model
        (progressEvent) => {
          this.setState({
            progressBar: (100 * progressEvent.loaded) / progressEvent.total + "%",
          });
        },
        // Error on loading model
        () => {
          this.setState({
            progressBar: "100%",
            progressBarColor: "#e86161",
            mesh: new THREE.Mesh(),
          });
        }
      );
    }
  }

  render() {
    let threeDKeys = this.props.threeDType === "ply" ? ["ply1", "ply2", "ply3"] : ["upper", "lower", "whole"];

    return (
      <Modal
        dialogExtraClassName="max modelViewer"
        isShow={this.props.currentModal === Modals.MODEL_3D}
        onClose={() => {
          this.props.changeModal(Modals.NONE);
        }}
        disableClose={!(this.state.progressBar === "100%")}
      >
        <div className="modal-body modal-bodyViewer" data-v-2808a57a="">
          <h4 className="text_center overflow" data-v-2808a57a="" style={{ color: "rgb(2, 163, 175)" }}>
            {this.props.title}
          </h4>
          <ModelViewer
            isLoading={this.state.isLoading}
            progressBar={this.state.progressBar}
            progressBarColor={this.state.progressBarColor}
            mesh={this.state.mesh}
            meshType={this.props.threeDType}
          />
          <div className={this.props.lenght >= 3 ? "groupBtn" : "groupBtn hide"}>
            <MainButton
              title="上顎"
              enabled={this.state.progressBar === "100%"}
              className={`btn ${this.props.currentThreeDKey === threeDKeys[0] ? "btn-primary" : "btn-white"}`}
              type="a"
              onSubmit={() => {
                this.props.onChangeThreeDModel(threeDKeys[0]);
              }}
            />
            <MainButton
              title="下顎"
              enabled={this.state.progressBar === "100%"}
              className={`btn ${this.props.currentThreeDKey === threeDKeys[1] ? "btn-primary" : "btn-white"}`}
              type="a"
              onSubmit={() => {
                this.props.onChangeThreeDModel(threeDKeys[1]);
              }}
            />
            {this.props.lenght > 5 ? (
              <MainButton
                title="噛み合わせ"
                enabled={this.state.progressBar === "100%"}
                className={`btn ${this.props.currentThreeDKey === threeDKeys[2] ? "btn-primary" : "btn-white"}`}
                type="a"
                onSubmit={() => {
                  this.props.onChangeThreeDModel(threeDKeys[2]);
                }}
              />
            ) : null}
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ app }: IRootState) => {
  const { currentModal } = app;
  return {
    currentModal,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
  };
};

type ThreeDViewerPopupType = ThreeDViewerPopupProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(ThreeDViewerPopup);
