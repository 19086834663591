import React from "react";
import ReactDOM from "react-dom";
import { toast } from "react-toastify";
import { FileTypes } from "src/helpers/enums";
import { uploadFileToS3 } from "src/utils/utils";
import { MainButton } from "../button/mainButton";
import { Input } from "../input";
import { Label } from "../label";
import Modal from "../modal/modal";

const isImage = (fileExtension: string) => {
  return fileExtension.match("image.*");
};

const isVideo = (fileExtension: string) => {
  return fileExtension.match("video.*");
};

const isPDF = (fileExtension: string) => {
  return fileExtension.match("application/pdf");
};

const isPLY = (filename: string) => {
  return filename.split(".").pop()!.match("ply");
};

interface SelectFileOptionPopupProps {
  isShow: boolean;
  handleShow: (isShow: boolean) => void;
  handleShowFileManagementPopup: (isShow: boolean) => void;
  id?: string;
  inputClassName?: string;
  fileAccept?: FileTypes;
  onLoading?: () => void;
  onChange: (value: any, e?: React.ChangeEvent<HTMLInputElement>, file?: any, url?: string, fileList?: any[]) => void;
  multiple?: boolean;
}

class SelectFileOptionPopup extends React.Component<SelectFileOptionPopupProps> {
  fileValidate = (file: File) => {
    if (this.props.fileAccept === FileTypes.IMAGE && !isImage(file.type))
      return toast.warning("ファイルタイプは画像である必要があります", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    if (this.props.fileAccept === FileTypes.VIDEO && !isVideo(file.type))
      return toast.warning("ファイルタイプはビデオである必要があります", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    if (this.props.fileAccept === FileTypes.PDF && !isPDF(file.type))
      return toast.warning("ファイルの種類は PDF である必要があります", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    if (this.props.fileAccept === FileTypes.PLY && !isPLY(file.name))
      return toast.warning("ファイルタイプはPLYである必要があります", {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    if (this.props.fileAccept === FileTypes.ALL && !isImage(file.type) && !isVideo(file.type))
      return toast.warning("ファイルの種類は画像またはビデオである必要があります", {
        position: toast.POSITION.BOTTOM_LEFT,
      });

    return true;
  };

  handleUploadFileToS3 = async (value: any, event: any, file: File) => {
    this.props.onLoading && this.props.onLoading();

    if (!this.fileValidate(file)) return;

    const fileUploadResult = await uploadFileToS3(file);

    this.props.onChange(value, event, file, fileUploadResult.location);
  };

  handleUploadMultipleFileToS3 = async (event: any, fileList: any[]) => {
    this.props.onLoading && this.props.onLoading();

    if (fileList.length === 0) return;

    for await (const file of fileList) {
      if (!this.fileValidate(file)) return;

      const fileUploadResult = await uploadFileToS3(file);

      file.location = fileUploadResult.location;
    }

    this.props.onChange(undefined, event, undefined, undefined, fileList);
  };

  render() {
    return ReactDOM.createPortal(
      <Modal
        dialogExtraClassName="select-file-option-popup"
        isShow={this.props.isShow}
        onClose={() => {
          this.props.handleShow(false);
        }}
      >
        <div className="modal-body modal-bodyViewer">
          <h4 className="text_center overflow" style={{ color: "rgb(2, 163, 175)" }}>
            ファイル参照
          </h4>
          <div className="btn-group2">
            <Label
              className="btn btn-primary btn-main btn-upload-file"
              htmlFor={this.props.id ?? "select-file=option"}
              text="ローカルファイル"
            />
            <Input
              id={this.props.id ?? "select-file=option"}
              inputClassName={this.props.inputClassName ?? ""}
              type="file"
              onChange={(value, _, e, file, fileList) => {
                if (!this.props.multiple) this.handleUploadFileToS3(value, e, file);
                else this.handleUploadMultipleFileToS3(e, fileList!);
              }}
              fileAccept={this.props.fileAccept ?? FileTypes.ALL}
              multiple={this.props.multiple}
            />
            <MainButton
              className="btn btn-primary btn-main btn-upload-file"
              onSubmit={() => this.props.handleShowFileManagementPopup(true)}
              enabled
              title="ファイルアップロード"
            />
          </div>
        </div>
      </Modal>,
      document.body
    );
  }
}

export default SelectFileOptionPopup;
