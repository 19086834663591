import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { PatientAnalyticState } from "src/base/Enums";
import { Injector } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import { InputGroup } from "src/components/inputGroup";
import { Table } from "src/components/table";
import { Strings } from "src/constants";
import { AnalyticContainer } from "src/features/analytic/presenter/container/AnalyticContainer";
import { patientAnalyticsPaginationValueChanged } from "src/features/analytic/presenter/store/patientAnalytic";
import { history, Routes } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import { LoadingComponent } from "src/shared/components/loadingComponent";
import { PaginationComponent } from "src/shared/components/pagination";
import { IRootState } from "src/store";

interface TotalAnalysisPageState {
  code: string;
  name: string;
}

class TotalAnalysisPage extends React.Component<
  TotalAnalysisPageType,
  TotalAnalysisPageState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      code: "",
      name: "",
    };
  }

  componentDidMount() {
    this.props.onFetchPatientAnalytics("", "", 1);
  }

  buildAnalysisTable = () => {
    return <Table
      className="dataTable-container"
      subClassName="dataTable-table"
      isLoading={
        this.props.patientAnalyticState ===
        PatientAnalyticState.fetchingPatientAnalytics
      }
      headers={[
        {
          fields: [
            {
              html: (
                <InputGroup
                  className="iprup2 iprup fsc"
                  inputPlaceHolder={Strings.PATIENT_NUMBER}
                  buttonClassName="btn-success"
                  buttonType="button"
                  value={this.state.code}
                  buttonText={Strings.SEARCH}
                  onValueChanged={(e) => {
                    this.setState({
                      ...this.state,
                      code: e.target.value,
                    });
                    if (!e.target.value) {
                      this.props.onFetchPatientAnalytics(
                        e.target.value,
                        this.state.name,
                        1
                      );
                    }
                  }}
                  onButtonClick={() => {
                    this.props.onFetchPatientAnalytics(
                      this.state.code,
                      this.state.name,
                      1
                    );
                  }}
                ></InputGroup>
              ),
            },
            {
              html: (
                <InputGroup
                  className="iprup3 iprup"
                  inputPlaceHolder={Strings.PATIENT_NAME}
                  buttonClassName="btn-success"
                  buttonType="button"
                  value={this.state.name}
                  buttonText={Strings.SEARCH}
                  onValueChanged={(e) => {
                    this.setState({
                      ...this.state,
                      name: e.target.value,
                    });
                    if (!e.target.value) {
                      this.props.onFetchPatientAnalytics(
                        this.state.code,
                        e.target.value,
                        1
                      );
                    }
                  }}
                  onButtonClick={() => {
                    this.props.onFetchPatientAnalytics(
                      this.state.code,
                      this.state.name,
                      1
                    );
                  }}
                ></InputGroup>
              ),
            },
            { html: Strings.TOTAL_MEDICAL_RECORD },
            { html: Strings.ACTION },
          ],
        },
      ]}
      rows={[
        ...this.props.patientAnalytics.data.map((analytic) => ({
          fields: [
            // {
            //   html: ((analytic.clinic && analytic.clinic.medical_institution) ? analytic.clinic.medical_institution : "") + analytic.code,
            // },
            { html: analytic.special_code ? analytic.special_code : "" },
            {
              html: analytic.full_name,
            },
            {
              html: analytic.medical_records_for_user_count,
            },
            {
              html: (
                <div className="fcc">
                  <MainButton
                    title={Strings.CONFIRM}
                    enabled={true}
                    className="btn btn-primary"
                    type="a"
                    loadingSize={20}
                    onSubmit={() => {
                      history.replace(
                        {
                          pathname: Routes.PATIENT_ANALYTIC_MANAGEMENT_DETAIL,
                          search: `?analysisId=${analytic.id}`
                        }
                      );
                    }}
                  />
                </div>
              ),
            },
          ],
        })),
      ]}
    ></Table>
  }

  render() {
    return (
      <MainLayout
        isLoading={this.props.patientAnalyticState ===
          PatientAnalyticState.fetchingPatientAnalytics}
        className="secondpage navstate_show page-analysis_management animate-item"
        subClassName="p-analysis_management"
      >
        <div className="card">
          <div className="card-header">{Strings.MEDICAL_RECORD_ANALYSIS}</div>
          <div className="card-body">
            {this.buildAnalysisTable()}
            <div className={this.props.patientAnalytics.data.length == 20 || this.props.patientAnalytics.last_page > 1 ? 'showPaginationComponent mt30' : 'hide'}>
              <PaginationComponent
                className=""
                currentPage={this.props.patientAnalytics.current_page}
                totalPage={this.props.patientAnalytics.last_page}
                onClick={(page) => {
                  this.props.onFetchPatientAnalytics(
                    this.state.code,
                    this.state.name,
                    page
                  );
                  this.props.onPaginationUpdated("current_page", page);
                }}
              ></PaginationComponent>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ patientAnalytic }: IRootState) => {
  const { patientAnalytics, patientAnalyticState } = patientAnalytic;
  return {
    patientAnalytics,
    patientAnalyticState,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    onFetchPatientAnalytics: (code: string, fullName: string, page: number) =>
      Injector.get()
        .find<AnalyticContainer>("AnalyticContainer")
        .onFetchPatientAnalytics(dispatch, code, fullName, page),
    onPaginationUpdated: (key: string, value: any) =>
      dispatch(patientAnalyticsPaginationValueChanged(key, value)),
  };
};

type TotalAnalysisPageType = ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(
  mapStateToProps,
  mapDispatcherToProps
)(TotalAnalysisPage);
