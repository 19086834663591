import moment from "moment";
import ReactS3Client from "react-aws-s3-typescript";
import { IClinic } from "src/features/account/data/models/clinic";
import { defaultUser, IUser, userFromJson } from "src/features/account/data/models/user";
import { IAccountState } from "src/features/account/presenter/store";
import { MapFunction } from "src/helpers/mapFunction";
import store from "src/store";
import { Attribute } from "./attribute";
import { MFile, mFileFromJson } from "./file";

const awsS3Config = {
  bucketName: process.env.REACT_APP_AWS_S3_BITBUCKET!,
  dirName: process.env.REACT_APP_AWS_S3_DIRNAME!,
  region: process.env.REACT_APP_AWS_S3_REGION!,
  accessKeyId: process.env.REACT_APP_AWS_S3_ACCESS_KEY_ID!,
  secretAccessKey: process.env.REACT_APP_AWS_S3_SECRET_ACCESS_KEY!,
  s3Url: process.env.REACT_APP_AWS_S3_URL!,
};

export interface ToothAttribute {
  attribute?: Attribute;
  value: any;
}

export interface IMedicalRecord {
  id: string;
  user_id: number;
  orthodontist_id: number;
  code: string;
  result: string;
  patient: IUser;
  views: number;
  orthodontists: IUser[];
  images: MFile[];
  files: Map<string, MFile>;
  clinic?: IClinic;
  pdfs?: {
    patient: IPDF;
    orthodontist: IPDF;
  };
  pdf?: MFile;
  teeth?: Map<number, Map<string, ToothAttribute>>;
  linking_medical_record_id?: string;
  file_ids?: any[];
  created_at: Date;
  updateOnly: boolean;
  medical_attributes?: Array<any>;
  is_smoking?: number;
  isNotFound?: boolean;
  date?: Date;
}

export interface IPDF {
  file?: MFile;
  comment?: string;
}

export const defaultRecord = (): IMedicalRecord => {
  return {
    id: "",
    user_id: 0,
    orthodontist_id: 0,
    code: "",
    result: "",
    views: 0,
    patient: defaultUser,
    pdfs: {
      patient: {},
      orthodontist: {},
    },
    orthodontists: [],
    teeth: new Map(),
    images: [],
    files: new Map(),
    created_at: new Date(),
    updateOnly: false,
    pdf: mFileFromJson({}),
    is_smoking: 0,
    date: new Date(),
  };
};

export function medicalRecordFromJson(json: any): IMedicalRecord {
  var medicalRecord: IMedicalRecord = {
    id: (json.id ?? "").toString(),
    code: json.code ?? "",
    orthodontist_id: json.orthodontist_id ?? 0,
    user_id: json.user_id ?? 0,
    result: json.result ?? "",
    views: json.views ?? 0,
    patient: json.patient ?? defaultUser,
    orthodontists: json.orthodontists
      ? (json.orthodontists as Array<any>).map((orthodontist) =>
          orthodontist.orthodontist ? userFromJson(orthodontist.orthodontist) : defaultUser
        )
      : [],
    images: json.images ?? [],
    files: new Map(),
    pdfs: {
      patient: {
        file: mFileFromJson({ path: json.patient_pdf }),
        comment: json.patient_comment ?? "",
      },
      orthodontist: {
        file: mFileFromJson({ path: json.orthodontist_pdf }),
        comment: json.orthodontist_comment ?? "",
      },
    },
    created_at: json.created_at ? new Date(json.created_at) : new Date(),
    updateOnly: true,
    teeth: new Map(),
    medical_attributes: json.medical_attributes ?? [],
    pdf: mFileFromJson({ path: json.pdf }),
    is_smoking: json.is_smoking ?? 0,
  };
  var files = ((json.files as Array<any>) ?? []).map((file) => mFileFromJson(file));
  let plyFiles: MFile[] = [];
  files.forEach((file) => {
    if (file.file_type === "ply") {
      plyFiles.push(file);
      return;
    }
    medicalRecord.files.set(file.file_type, file);
  });
  plyFiles.forEach((file, index) => {
    let pathSplit = file.path.split("/");
    if (file.path && pathSplit.length > 0) {
      file.name = pathSplit[pathSplit.length - 1];
    }
    medicalRecord.files.set(file.file_type + (index + 1).toString(), file);
  });

  if (json.images && json.images.length > 0) {
    medicalRecord.images = [...json.images?.filter((image: any) => image.image_type.indexOf("dental") === -1)];

    const dentalImages = [...json.images?.filter((image: any) => image.image_type.indexOf("dental") === 0)];
    dentalImages?.forEach((image: any, imageIndex: number) => {
      medicalRecord.images.push({
        ...image,
        image_type: `dental${imageIndex === 0 ? "" : imageIndex}`,
      });
    });
  }

  var teeth = (json.teeth as Array<any>) ?? [];
  teeth.forEach((tooth: any) => {
    const { tooth_index, ...toothAttributes } = tooth;
    medicalRecord.teeth?.set(tooth_index, MapFunction.objToMap(toothAttributes));
  });

  return medicalRecord;
}

export const uploadFileToS3 = async (outputGlbFiles: Map<string, MFile | File>) => {
  let me = (store.getState().account as IAccountState).me;
  const promises: Promise<any>[] = [];

  for (const [key, file] of outputGlbFiles) {
    if ((file as MFile).isUpdate) {
      awsS3Config.dirName = `upload/clinic_${me.clinic?.id}/temp`;
      if (key.indexOf("ply") > -1) awsS3Config.dirName = `upload/clinic_${me.clinic?.id}/temp/ply`;

      const awsS3 = new ReactS3Client(awsS3Config);
      const timestamp = moment().unix();

      promises.push(
        awsS3.uploadFile(
          new File([(file as MFile).blob!], (file as MFile).file?.name!),
          `${timestamp}.${(file as MFile)?.name?.split(".")[0]}`
        )
      );
    }
  }

  if (promises.length > 0)
    try {
      await Promise.allSettled(promises).then((result: any) => {
        result.forEach((res: any) => {
          if (res.status === "fulfilled")
            for (const [key, file] of outputGlbFiles) {
              if (
                key.indexOf("ply") > -1 &&
                res.value.key.indexOf(".ply") > -1 &&
                res.value.location.indexOf((file as MFile).file?.name) > -1
              ) {
                (file as MFile).fileUrl = res.value.location;
              }
              if (
                key.indexOf("ply") === -1 &&
                res.value.key.indexOf(".stl") > -1 &&
                res.value.location.indexOf((file as MFile).file?.name) > -1
              ) {
                (file as MFile).fileUrl = res.value.location;
              }
            }
        });
      });
    } catch (exception) {}

  return outputGlbFiles;
};

export const formDataMedicalRecord = (action: string, record: IMedicalRecord): FormData => {
  var bodyFormData = new FormData();
  bodyFormData.append(`date`, record.created_at! && moment(record.created_at).format("YYYY/MM/DD HH:mm:ss"));
  bodyFormData.append(`is_smoking`, (record.is_smoking ? 1 : 0).toString());

  if (record.patient && record.patient.id !== "0") bodyFormData.append("user_id", record.patient.id);
  if (record.orthodontists) {
    record.orthodontists.forEach((orthodontist, index) => {
      if (orthodontist.id !== "") {
        bodyFormData.append(`orthodontist_ids[]`, orthodontist.id);
      }
    });
  }
  if (record.pdfs && record.pdfs.patient) {
    if (record.pdfs.patient.file && record.pdfs.patient.file.name && record.pdfs.patient.file.name.length > 0) {
      bodyFormData.append(`patient[pdf]`, window.decodeURIComponent(record.pdfs.patient.file.path));
    } else if (record.pdfs.patient.file && record.pdfs.patient.file.isUpdate && !record.pdfs.patient.file?.path) {
      bodyFormData.append(`patient[pdf]`, "delete");
    }
    if (record.pdfs.patient.comment) {
      bodyFormData.append(`patient[comment]`, record.pdfs.patient.comment);
    }
  }
  if (record.file_ids) {
    record.file_ids.forEach((fileId, index) => {
      bodyFormData.append(`file_ids[${index}]`, fileId);
    });
  }

  if (record.linking_medical_record_id) {
    bodyFormData.append("linking_record_id", record.linking_medical_record_id);
  }

  if (record.pdfs && record.pdfs.orthodontist) {
    if (
      record.pdfs.orthodontist.file &&
      record.pdfs.orthodontist.file.name &&
      record.pdfs.orthodontist.file.name.length > 0
    ) {
      bodyFormData.append(`orthodontist[pdf]`, window.decodeURIComponent(record.pdfs.orthodontist.file.path));
    } else if (
      record.pdfs.orthodontist.file &&
      record.pdfs.orthodontist.file.isUpdate &&
      !record.pdfs.orthodontist.file?.path
    ) {
      bodyFormData.append(`orthodontist[pdf]`, "delete");
    }
    if (record.pdfs.orthodontist.comment) {
      bodyFormData.append(`orthodontist[comment]`, record.pdfs.orthodontist.comment);
    }
  }

  const plyFiles: MFile[] = [];
  record.files.forEach((value, key) => {
    if (value.isUpdate) {
      if (key.indexOf("ply") > -1) {
        plyFiles.push(value);
        return;
      }
      if (!value.id && value.base64.length === 0 && !value.blob) {
        return;
      }
      bodyFormData.append(`file[3d_file_type][${key}][path]`, value.id && !value.blob ? `""` : value.fileUrl!);
      bodyFormData.append(`file[3d_file_type][${key}][title]`, value.file?.name.split(".")[0]!);
      bodyFormData.append(`file[3d_file_type][${key}][file_type]`, key);
    }
  });

  plyFiles.forEach((file, index) => {
    if (action === "update") {
      if (file.blob) {
        bodyFormData.append(`file[3d_file_type][ply][${index}][path]`, file.fileUrl!);
        bodyFormData.append(`file[3d_file_type][ply][${index}][title]`, file.file?.name.split(".")[0]!);
        bodyFormData.append(`file[3d_file_type][ply][${index}][file_type]`, file.file?.name.split(".").pop()!);
      }
      if (file.id) {
        bodyFormData.append(`file[3d_file_type][ply][${index}][id]`, file.id.toString());
      }
      return;
    } else if (file.blob) {
      bodyFormData.append(`file[3d_file_type][ply][${index}][path]`, file.fileUrl!);
      bodyFormData.append(`file[3d_file_type][ply][${index}][title]`, file.file?.name.split(".")[0]!);
      bodyFormData.append(`file[3d_file_type][ply][${index}][file_type]`, file.file?.name.split(".").pop()!);
    }
  });

  const dentalFiles: MFile[] = [];
  record.images.forEach((value) => {
    if (!value.isUpdate) return;
    if (value.image_type.indexOf("dental") === 0) return dentalFiles.push(value);

    if (action === "add" && value.path) {
      bodyFormData.append(`image[image_type][${value.image_type}][path]`, window.decodeURIComponent(value.path));
      bodyFormData.append(`image[image_type][${value.image_type}][label]`, value.title ?? value.name?.split(".")[0]!);
      bodyFormData.append(`image[image_type][${value.image_type}][file_type]`, value.file_type?.split("/").pop()!);
    }
    if (action === "update") {
      if (value.id !== 0) bodyFormData.append(`image[image_type][${value.image_type}][id]`, value.id.toString());

      bodyFormData.append(`image[image_type][${value.image_type}][path]`, window.decodeURIComponent(value.path));
      bodyFormData.append(`image[image_type][${value.image_type}][label]`, value.title ?? value.name?.split(".")[0]!);
      bodyFormData.append(`image[image_type][${value.image_type}][file_type]`, value.file_type?.split("/").pop()!);
    }
  });

  dentalFiles
    .sort((a: any, b: any) => a.image_type.localeCompare(b.image_type))
    .forEach((value, index) => {
      if (action === "add" && value.path) {
        bodyFormData.append(`image[image_type][dental][${index}][path]`, window.decodeURIComponent(value.path));
        bodyFormData.append(`image[image_type][dental][${index}][label]`, value.title ?? value.name?.split(".")[0]!);
        bodyFormData.append(`image[image_type][dental][${index}][file_type]`, value.file_type?.split("/").pop()!);
      }
      if (action === "update") {
        if (value.id !== 0) bodyFormData.append(`image[image_type][dental][${index}][id]`, value.id.toString());

        bodyFormData.append(`image[image_type][dental][${index}][path]`, window.decodeURIComponent(value.path));
        bodyFormData.append(`image[image_type][dental][${index}][label]`, value.title ?? value.name?.split(".")[0]!);
        bodyFormData.append(`image[image_type][dental][${index}][file_type]`, value.file_type?.split("/").pop()!);
      }
    });

  if (record.teeth) {
    record.teeth?.forEach((tooth, toothIndex) => {
      tooth.forEach((attribute, attributeName) => {
        if (typeof attribute.value === "object") {
          if (Array.isArray(attribute.value)) {
            attribute.value.forEach((value) => {
              bodyFormData.append(`teeth[${toothIndex}][${attributeName}][]`, value);
            });
          } else {
            for (const [key, value] of Object.entries(attribute.value)) {
              bodyFormData.append(`teeth[${toothIndex}][${attributeName}][${key}]`, value as any);
            }
          }
        } else {
          bodyFormData.append(`teeth[${toothIndex}][${attributeName}]`, attribute.value);
        }
      });
    });
  }

  if (action === "update") bodyFormData.append("_method", "PUT");

  return bodyFormData;
};
