import { PieChart } from "src/components/chart/pie_chart_component";
import { Strings } from "src/constants";
import { AnalyticHeader } from "./analyticHeader";
import {
  IAnalyticDate,
  IPolishData,
} from "src/features/analytic/data/models/patientAnalytic";

export class PCRAnalyticHeader extends AnalyticHeader {
  
}
