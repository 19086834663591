import React from "react";
import { IFile } from "src/features/fileManagement/data/models/file";
import { IFolder } from "src/features/fileManagement/data/models/folder";
import { Assets } from "src/utils/assets";

import fileBlankImage from "../../../../assets/images/file_management/file_blank_image.webp";

interface FileComponentProps {
  file: IFile;
  handleSelectItem: (item: IFolder | IFile) => void;
  handleChooseItem: (item: IFile) => void;
  handleSelectFile: (file: IFile) => void;
  isSelected: boolean;
  multiple?: boolean;
}

interface FileComponentState {
  imageError: boolean;
}

class FileComponent extends React.Component<FileComponentProps, FileComponentState> {
  constructor(props: any) {
    super(props);

    this.state = {
      imageError: false,
    };
  }

  handleLoadImageError = () => {
    this.setState({ imageError: true });
  };

  handleClickFile = (event: any) => {
    if (event.detail === 1 && !this.props.multiple) this.props.handleChooseItem(this.props.file);
    else this.props.handleSelectItem(this.props.file);
  };

  render() {
    let imageSrc = Assets.gallery;

    if (this.props.file.mime_type?.includes("image")) imageSrc = this.props.file.url;
    else imageSrc = fileBlankImage;

    return (
      <div className={`grid-item`}>
        <img
          className={`select-box`}
          src={this.props.isSelected ? Assets.boxActive : Assets.boxInActive}
          alt=""
          width={30}
          onClick={() =>
            this.props.multiple
              ? this.props.handleSelectFile(this.props.file)
              : this.props.handleChooseItem(this.props.file)
          }
        />
        <div className="grid-item-container" onClick={this.handleClickFile}>
          <div className="grid-head">
            <div className="file-image">
              {!this.state.imageError ? (
                <img
                  src={imageSrc}
                  alt=""
                  width={100}
                  style={{ alignSelf: "center" }}
                  onError={this.handleLoadImageError}
                />
              ) : (
                <img
                  src={fileBlankImage}
                  alt=""
                  width={100}
                  style={{ alignSelf: "center" }}
                  onError={this.handleLoadImageError}
                />
              )}
              {(imageSrc === fileBlankImage || this.state.imageError) && (
                <span className="file-extension">{this.props.file.extension.toUpperCase()}</span>
              )}
            </div>
          </div>
          <span className="file-name">{this.props.file.name}</span>
        </div>
      </div>
    );
  }
}

export default FileComponent;
