import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IUser } from "src/features/account/data/models/user";
import { IPatientRepository } from "../../data/repositories/patientRepository";

export class UpdatePatient extends UseCase<
    UpdatePatientParams,
    boolean
> {
    private repository: IPatientRepository;
    constructor(repository: IPatientRepository) {
        super();
        this.repository = repository;
    }

    call(params: UpdatePatientParams): Promise<Either<Failure, boolean>> {
        return this.repository.updatePatient(params.user);
    }
}

export class UpdatePatientParams {
    user: IUser;
    constructor(user: IUser) {
        this.user = user;
    }
}
