export interface IFile {
  name: string;
  extension: string;
  mime_type: string;
  type: string;
  path: string;
  url: string;
  title: string;
}

export interface IFileToUpload {
  key: string;
  value?: File | File[];
}

export enum FileManagementType {
  FILE = "file",
  FOLDER = "directory",
}
