import React from "react";
import { Link } from "react-router-dom";
import { history, Routes } from "src/helpers";
import { Assets } from "src/utils/assets";


interface MainHeaderProps {
  clinicName: string
  isShowNavBar: boolean
  onMenuIconClicked: (e:any) =>void
}

export class MainHeader extends React.Component<MainHeaderProps> {

  constructor(props: MainHeaderProps){
    super(props)
  }

  render() {
    return (
      <header className="header">
        <div className="sb-topnav navbar navbar-expand navbar-light bg-white">
          <div className="navbar-brand" >
            <img className="header-image" src={Assets.logoSide} height={30} onClick={() => {
              history.replace(Routes.NOTICE_OPERATOR)
            }} />
            <span>{this.props.clinicName}様</span>
          </div>
          {/* <button className="btn btn-link btn-sm order-1 order-lg-0 me-lg-0" id="sidebarToggle">
            <i className="fas fa-bars">123123</i>
          </button> */}
        </div>
        <div className={`hamburger${this.props.isShowNavBar? " active":""}`} onClick={this.props.onMenuIconClicked}>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </header>
    )
  }
}
