import React from "react";
import { connect } from "react-redux";
import { ReactSVG } from "react-svg";
import { Dispatch } from "redux";
import { FileManagementState } from "src/base/Enums";
import { DI, Injector } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import Modal from "src/components/modal/modal";
import { IFile } from "src/features/fileManagement/data/models/file";
import { IFolder } from "src/features/fileManagement/data/models/folder";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers";
import { IRootState } from "src/store";
import { Assets } from "src/utils/assets";
import { RemoteFileManagementContainer } from "../../container/RemoteFileManagementContainer";
import FolderToMoveComponent from "../components/FolderToMoveComponent.tsx";

interface MoveIntoFolderPopupProps {
  breadcrumbPath: string;
  itemSelected?: IFolder | IFile;
  filesSelected: IFile[];
  handleResetSelected: () => void;
}

interface MoveIntoFolderPopupState {
  breadcrumb: string[];
  folderSelected?: IFolder;
}

class MoveIntoFolderPopup extends React.Component<MoveIntoFolderPopupType, MoveIntoFolderPopupState> {
  constructor(props: any) {
    super(props);

    this.state = {
      breadcrumb: [],
      folderSelected: undefined,
    };
  }

  componentDidMount() {
    DI.get().injectRemoteFileManagementDependencies();

    this.props.onFetchFolderToMove("");
  }

  handleSelectFolder = (folder: IFolder) => {
    if (this.state.folderSelected === folder) return this.setState({ folderSelected: undefined });

    this.setState({ folderSelected: folder });
  };

  handleMoveIntoFolder = (folder: IFolder) => {
    const breadcrumbPath = folder.path.length === 0 ? [folder.name] : (folder.path + "/" + folder.name).split("/");

    this.props.onFetchFolderToMove(breadcrumbPath.join("/")).then(() => {
      this.setState({
        breadcrumb: breadcrumbPath,
        folderSelected: undefined,
      });
    });
  };

  handleGoBack = () => {
    this.setState(
      (prevState) => ({
        breadcrumb: prevState.breadcrumb.slice(0, -1),
      }),
      () => {
        const breadcrumbPath = this.state.breadcrumb.join("/");

        this.props.onFetchFolderToMove(breadcrumbPath);
      }
    );
  };

  handleSubmit = () => {
    let breadcrumbPath = this.state.breadcrumb.join("/");
    if (this.state.folderSelected)
      breadcrumbPath += breadcrumbPath === "" ? this.state.folderSelected.name : "/" + this.state.folderSelected.name;

    const itemToMove = this.props.filesSelected.length === 0 ? this.props.itemSelected : this.props.filesSelected;

    this.props.onMove(this.props.breadcrumbPath, breadcrumbPath, itemToMove).then(() => {
      this.props.handleResetSelected();
      this.props.changeModal(Modals.NONE);
      this.props.onFetchFileManagement(this.props.breadcrumbPath);
    });
  };

  render() {
    const modalTitle = this.props.itemSelected?.name
      ? this.props.itemSelected.name + "の移動"
      : this.props.filesSelected.length === 1
      ? this.props.filesSelected[0].name + "の移動"
      : this.props.filesSelected.length + "個のファイルの移動";

    return (
      <Modal
        id="file-management-move-into-folder-popup"
        dialogExtraClassName="file-management-popup file-management-move-into-folder-popup"
        isShow={this.props.currentModal === Modals.FILE_MANAGEMENT_MOVE_INTO_FOLDER}
        footerChildren={
          <div className="btn-group2">
            <MainButton
              className="btn btn-secondary"
              enabled
              title={"キャンセル"}
              onSubmit={() => this.props.changeModal(Modals.NONE)}
            />
            <MainButton
              className="btn btn-primary"
              enabled
              title={"移動する"}
              onSubmit={this.handleSubmit}
              submitting={this.props.fileManagementState === FileManagementState.submitButton}
            />
          </div>
        }
      >
        <h4 className="text_center overflow">
          <strong>{modalTitle}</strong>
        </h4>
        {this.state.breadcrumb.length > 0 ? (
          <div className="breadcrumb">
            <ReactSVG className="back-button" src={Assets.back} fill="white" onClick={this.handleGoBack} />
            {this.state.breadcrumb.at(-1)}
          </div>
        ) : null}
        <div className="grid-container mb00 pb00 pt00 mt-4">
          {this.props.folderToMove
            .filter((folder) => folder.name !== this.props.itemSelected?.name)
            .map((folder: IFolder, idx) => {
              const isSelected = this.state.folderSelected === folder;

              return (
                <FolderToMoveComponent
                  key={idx}
                  folder={folder}
                  handleSelectFolder={this.handleSelectFolder}
                  handleMoveIntoFolder={this.handleMoveIntoFolder}
                  isSelected={isSelected}
                />
              );
            })}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = ({ fileManagement, app }: IRootState) => {
  const { fileManagementState, folderToMove } = fileManagement;
  const { currentModal } = app;

  return {
    fileManagementState,
    folderToMove,
    currentModal,
  };
};
const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
    onMove: (oldPath: string, newPath: string, itemToMove?: IFolder | IFile | IFile[]) =>
      Injector.get()
        .find<RemoteFileManagementContainer>("RemoteFileManagementContainer")
        .onMove(dispatch, oldPath, newPath, itemToMove),
    onFetchFolderToMove: (breadcrumbPath: string) =>
      Injector.get()
        .find<RemoteFileManagementContainer>("RemoteFileManagementContainer")
        .onFetchFolderToMove(dispatch, breadcrumbPath, "", "all"),
    onFetchFileManagement: (breadcrumbPath: string, search?: string) =>
      Injector.get()
        .find<RemoteFileManagementContainer>("RemoteFileManagementContainer")
        .onFetchFileManagement(dispatch, breadcrumbPath, search),
  };
};

type MoveIntoFolderPopupType = MoveIntoFolderPopupProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(MoveIntoFolderPopup);
