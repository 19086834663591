import { OrthodontistState } from "src/base/Enums";
import { IUser } from "src/features/account/data/models/user";
import { Pagination } from "src/shared/model/pagination";
import { action } from "typesafe-actions";
import { OrthodontistActionType } from "./types";

export function orthodontistStateChanged(orthodontistState: OrthodontistState) {
  return action(OrthodontistActionType.ORTHORDONTIST_STATE_CHANGED, {
    orthodontistState,
  });
}

export function orthodontistsFetched(orthodontists: Pagination<IUser>) {
  return action(OrthodontistActionType.ORTHORDONTISTS_FETCHED, {
    orthodontists,
  });
}

export function orthodontistUpdated(orthodontist: IUser) {
  return action(OrthodontistActionType.ORTHORDONTIST_UPDATED, { orthodontist });
}

export function selectedOrthodontistChanged(orthodontist: IUser) {
  return action(OrthodontistActionType.SELECTED_ORTHODONTIST_UPDATED, {
    orthodontist,
  });
}

export function selectedOrthodontistDataChanged(key: string, value: any) {
  return action(OrthodontistActionType.SELECTED_ORTHODONTIST_DATA_UPDATED, {
    key,
    value,
  });
}

export function orthodontistsPaginationUpdated(key: string, value: any) {
  return action(OrthodontistActionType.ORTHORDONTISTS_PAGINATION_UPDATED, {
    key,
    value,
  });
}
