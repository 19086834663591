import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import React from "react";
import ReactDOM from "react-dom";
import domtoimage from 'dom-to-image';
import { Dispatch } from "redux";
import { isRight } from "src/base/Either";
import { AnalyticDetailTab, PatientAnalyticState } from "src/base/Enums";
import {
  FetchPatientAnalyticDetail,
  FetchPatientAnalyticDetailParams,
} from "../../domain/usecases/fetchPatientAnalyticDetail";
import {
  FetchPatientAnalytics,
  FetchPatientAnalyticsParams,
} from "../../domain/usecases/fetchPatientAnalytics";
import {
  isNotFoundUpdated,
  patientAnalysisDetailFetched,
  patientAnalyticDetailsTabChanged,
  patientAnalyticsFetched,
  patientAnalyticStateChanged,
} from "../store/patientAnalytic";

export class AnalyticContainer {
  fetchPatientAnalytics: FetchPatientAnalytics;
  fetchPatientAnalyticDetail: FetchPatientAnalyticDetail;
  constructor(
    fetchPatientAnalytics: FetchPatientAnalytics,
    fetchPatientAnalyticDetail: FetchPatientAnalyticDetail
  ) {
    this.fetchPatientAnalytics = fetchPatientAnalytics;
    this.fetchPatientAnalyticDetail = fetchPatientAnalyticDetail;
  }

  async onFetchPatientAnalytics(
    dispatch: Dispatch,
    code: string,
    fullName: string,
    page: number
  ) {
    dispatch(
      patientAnalyticStateChanged(PatientAnalyticState.fetchingPatientAnalytics)
    );
    let either = await this.fetchPatientAnalytics.call(
      new FetchPatientAnalyticsParams(code, fullName, 20, page)
    );
    if (isRight(either)) {
      dispatch(patientAnalyticsFetched(either.value));
    }
    dispatch(patientAnalyticStateChanged(PatientAnalyticState.none));
  }

  async onFetchPatientAnalysisDetail(
    dispatch: Dispatch,
    id: string,
    type: AnalyticDetailTab,
    fromDate?: Date,
    toDate?: Date
  ) {
    dispatch(patientAnalyticDetailsTabChanged(id, type));
    dispatch(
      patientAnalyticStateChanged(
        PatientAnalyticState.fetchingPatientAnalyticDetail
      )
    );

    let either = await this.fetchPatientAnalyticDetail.call(
      new FetchPatientAnalyticDetailParams(
        id,
        fromDate,
        toDate,
        type == AnalyticDetailTab.total ? undefined : type.toLowerCase()
      )
    );
    if (isRight(either)) {
      dispatch(patientAnalysisDetailFetched(type, either.value));
    }
    dispatch(patientAnalyticStateChanged(PatientAnalyticState.none));
  }

  async onExportPdf(dispatch: Dispatch) {
    try {
      dispatch(
        patientAnalyticStateChanged(PatientAnalyticState.exportingAnalyticToPDF)
      );
      const pdf = new jsPDF("portrait", "px", "a4");
      const tab1 = await this._convertElementToImage(pdf, "review-tab1");
      pdf.addImage(
        tab1[0] as string,
        "JPEG",
        pdf.internal.pageSize.getWidth() * 0.075,
        20,
        tab1[1] as number,
        tab1[2] as number
      );
      pdf.addPage();

      const tab2 = await this._convertElementToImage(pdf, "review-tab2");
      pdf.addImage(
        tab2[0] as string,
        "JPEG",
        pdf.internal.pageSize.getWidth() * 0.075,
        20,
        tab2[1] as number,
        tab2[2] as number
      );
      pdf.addPage();

      const tab3 = await this._convertElementToImage(pdf, "review-tab3");
      pdf.addImage(
        tab3[0] as string,
        "JPEG",
        pdf.internal.pageSize.getWidth() * 0.075,
        20,
        tab3[1] as number,
        tab3[2] as number
      );
      pdf.addPage();

      const tab4 = await this._convertElementToImage(pdf, "review-tab4");
      pdf.addImage(
        tab4[0] as string,
        "JPEG",
        pdf.internal.pageSize.getWidth() * 0.075,
        20,
        tab4[1] as number,
        tab4[2] as number
      );

      pdf.save("output.pdf");
      dispatch(patientAnalyticStateChanged(PatientAnalyticState.none));
    } catch (e) {
      dispatch(patientAnalyticStateChanged(PatientAnalyticState.none));
    }
  }

  async _convertElementToImage(pdf: jsPDF, id: string) {
    let element = document.getElementById(id)!
    let imageData = await domtoimage.toPng(element);
    let imgProperties = pdf.getImageProperties(imageData);
    let pdfWidth = pdf.internal.pageSize.getWidth() * 0.85;
    let pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
    return [imageData, pdfWidth, pdfHeight];
  }
}
