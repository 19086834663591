import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IUser } from "src/features/account/data/models/user";
import { IOrthodontistRepository } from "../../data/repositories/orthodontistRepository";

export class UpdateOrthodontistStatus extends UseCase<
  UpdateOrthodontistStatusParams,
  boolean
> {
  private repository: IOrthodontistRepository;
  constructor(repository: IOrthodontistRepository) {
    super();
    this.repository = repository;
  }

  call(
    params: UpdateOrthodontistStatusParams
  ): Promise<Either<Failure, boolean>> {
    return this.repository.updateOrthodontistStatus(params.id);
  }
}

export class UpdateOrthodontistStatusParams {
  id: string;
  constructor(id: string) {
    this.id = id;
  }
}
