import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { match } from "src/base/Either";
import { AuthState } from "src/base/Enums";
import { Injectable, Injector } from "src/base/Injector";
import { Strings } from "src/constants";
import { AccountContainer } from "src/features/account/presenter/container/accountContainer";
import { MedicalRecordContainer } from "src/features/medicalRecord/presenter/container/medicalRecordContainer";
import { UnsharedFilesContainer } from "src/features/unsharedFiles/presenter/container/unsharedFilesContainer";
import { history, Routes } from "src/helpers";
import { handleError } from "src/helpers/errorHandler";
import { findSubRouteFromPathName as findSubRouteFromPathName } from "src/utils/utils";
import { TOKEN } from "../../data/datasources/authRemoteDatasource";
import { ChangePassword, ChangePasswordParams } from "../../domain/usecases/changePassword";
import { ForgetPassword, ForgetPasswordParams } from "../../domain/usecases/forgetPassword";
import { Login, LoginParams } from "../../domain/usecases/login";
import { VerifyOTP, VerifyOTPParams } from "../../domain/usecases/verifyOTP";
import { authStateChanged } from "../store/auth";
import { forgetPasswordStateChanged } from "../store/forgetPassword";
import { loginStateChanged } from "../store/login";

export class AuthContainer {
  static key: string = "AuthContainer";
  login: Login;
  forgetPassword: ForgetPassword;
  verifyOTP: VerifyOTP;
  changePassword: ChangePassword;

  constructor(login: Login, forgetPassword: ForgetPassword, verifyOTP: VerifyOTP, changePassword: ChangePassword) {
    this.login = login;
    this.forgetPassword = forgetPassword;
    this.verifyOTP = verifyOTP;
    this.changePassword = changePassword;
  }

  loggedIn(dispatch: Dispatch) {
    Injector.get()
      .find<AccountContainer>("AccountContainer")
      .onGetMe(dispatch, (me) => {
        Injector.get().find<MedicalRecordContainer>("MedicalRecordContainer").onFetchAttributes(dispatch);
        Injector.get().find<UnsharedFilesContainer>("UnsharedFilesContainer").onFetchUnsharedFiles(dispatch);
        if (me.is_block == 1) {
          history.replace(
            window.location.pathname.indexOf("/home") > -1
              ? {
                  ...window.location,
                  pathname: findSubRouteFromPathName(window.location.pathname),
                }
              : Routes.SUBSCRIPTION_MANAGEMENT
          );
          return;
        }
        history.replace(
          window.location.pathname.indexOf("/home") > -1
            ? {
                ...window.location,
                pathname: findSubRouteFromPathName(window.location.pathname),
              }
            : Routes.NOTICE_OPERATOR
        );
        dispatch(authStateChanged(AuthState.authenticated));
      });
  }

  loggedOut(dispatch?: any) {
    localStorage.removeItem(TOKEN);
    let currentRoute = findSubRouteFromPathName(window.location.pathname);
    history.replace({
      pathname:
        currentRoute.indexOf("/home/") === -1 && currentRoute !== Routes.LOGIN && currentRoute !== Routes.SPLASH
          ? currentRoute
          : Routes.LOGIN,
    });
    if (dispatch) dispatch(authStateChanged(AuthState.unauthenticated));
  }

  checkForAuthStatus(dispatch: Dispatch) {
    if (localStorage.getItem(TOKEN)) {
      this.loggedIn(dispatch);
    } else {
      this.loggedOut(dispatch);
    }
  }

  async normalLogin(dispatch: Dispatch, id: string, password: string, shouldSaveLoginInfo: boolean) {
    dispatch(loginStateChanged(true));
    let either = await this.login.call(new LoginParams(id, password));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        if (shouldSaveLoginInfo) {
          localStorage.setItem("id", id);
          localStorage.setItem("password", password);
        } else {
          localStorage.removeItem("id");
          localStorage.removeItem("password");
        }
        toast.success(Strings.LOGIN_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        this.loggedIn(dispatch);
      }
    );
    dispatch(loginStateChanged(false));
  }

  async forgetPasswordHandle(dispatch: Dispatch, email: string) {
    dispatch(forgetPasswordStateChanged(true));
    let either = await this.forgetPassword.call(new ForgetPasswordParams(email));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        toast.success(Strings.SEND_OTP_SUCCESS, {
          position: toast.POSITION.BOTTOM_LEFT,
        });
        history.replace({
          pathname: Routes.VERIFY_OTP,
          search: `?email=${email}`,
        });
      }
    );
    dispatch(forgetPasswordStateChanged(false));
  }

  async submitOTP(dispatch: Dispatch, email: string, otp: string) {
    dispatch(forgetPasswordStateChanged(true));
    let either = await this.verifyOTP.call(new VerifyOTPParams(email, otp));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        if (_) {
          toast.success(Strings.VERIFY_OTP_SUCCESS, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          history.replace({
            pathname: Routes.CHANGE_PASSWORD,
            search: `?token=${localStorage.getItem("resetPassToken")}`,
          });
          localStorage.removeItem("resetPassToken");
        } else {
          toast.error(Strings.WRONG_OTP, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      }
    );
    dispatch(forgetPasswordStateChanged(false));
  }

  async submitPassword(dispatch: Dispatch, password: string, passwordCF: string, token: string) {
    dispatch(forgetPasswordStateChanged(true));
    let either = await this.changePassword.call(new ChangePasswordParams(password, passwordCF, token));
    match(
      either,
      (failure) => {
        handleError(failure.message);
      },
      (_) => {
        if (_) {
          toast.success(Strings.RESET_PASSWORD_SUCCESS, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          history.replace(Routes.LOGIN);
        } else {
          toast.error(Strings.WRONG_OTP, {
            position: toast.POSITION.BOTTOM_LEFT,
          });
        }
      }
    );
    dispatch(forgetPasswordStateChanged(false));
  }
}
