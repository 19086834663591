import React from "react";
import { LoadingComponent } from "src/shared/components/loadingComponent";

interface MainButtonProps {
  enabled?: boolean;
  id?: string;
  title: string;
  submitting?: boolean;
  className?: string;
  type?: "button" | "a";
  loadingSize?: number;
  isSubmitButton?: boolean;
  onSubmit: (e?: any) => void;
  style?: any;
}

export class MainButton extends React.Component<MainButtonProps> {
  loadingItem = () => {
    return (
      <LoadingComponent
        isLoading={this.props.submitting ?? false}
        size={this.props.loadingSize ?? 50}
        inputColor={"#FFFFFF"}
      >
        {this.props.title}
      </LoadingComponent>
    );
  };

  render(): React.ReactNode {
    return (this.props.type ?? "button") === "button" ? (
      <button
        id={this.props.id}
        type={this.props.isSubmitButton ? "submit" : "button"}
        className={`${this.props.className} ${this.props.enabled && !this.props.submitting ? "" : "disabled"}`}
        onClick={this.props.onSubmit}
        style={this.props.style}
      >
        {this.loadingItem()}
      </button>
    ) : (
      <a
        type="button"
        id={this.props.id}
        className={`${this.props.className} ${this.props.enabled && !this.props.submitting ? "" : "disabled"}`}
        onClick={this.props.onSubmit}
        style={this.props.style}
      >
        {this.loadingItem()}
      </a>
    );
  }
}
