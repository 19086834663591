import { Endpoints } from "src/base/Endpoints";
import { PatientsStatus } from "src/base/Enums";
import { formDataUser, IUser, urlEncodedUser, userFromJson } from "src/features/account/data/models/user";
import { HttpMethod } from "src/helpers";
import { IApiService } from "src/services/api/ApiService";
import { Pagination, paginationInit } from "src/shared/model/pagination";
import { dataURIToBlob } from "src/utils/utils";

export interface IPatientRemoteDatasource {
  fetchPatients(
    patientCode: string,
    full_name: string,
    query: string,
    page: number,
    status: number,
    action?: string
  ): Promise<Pagination<IUser>>;
  fetchListPatients(
    patientCode: string,
    full_name: string,
    query: string,
    page: number,
    status: number,
    action?: string
  ): Promise<Pagination<IUser>>;
  fetchPatientDetails(patientId: any): Promise<IUser | undefined>;
  addPatient(patient: IUser): Promise<boolean>;
  updatePatient(patient: IUser): Promise<boolean>;
  deletePatient(id: string): Promise<boolean>;
  importPatientsByCSV(csvBase64: string): Promise<void>;
  sendMailToPatient(patientId: string): Promise<void>;
  downloadcsvfile(): Promise<string>;
}

export class PatientRemoteDatasource implements IPatientRemoteDatasource {
  apiService: IApiService;
  constructor(apiService: IApiService) {
    this.apiService = apiService;
  }

  async downloadcsvfile(): Promise<string> {
    var response = await this.apiService.request({ method: HttpMethod.GET, endpoint: Endpoints.downloadCSV });
    if (response.data.access_token) {
      localStorage.setItem("TOKEN", response.data.access_token);
    }
    if (!response.data || !response.data.data) return "";
    return response.data.data;
  }

  async sendMailToPatient(patientId: string): Promise<void> {
    await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: Endpoints.sendPariendMail(patientId),
      shouldAuthorize: true,
    });
  }

  async fetchPatients(
    patientCode: string,
    full_name: string,
    query: string,
    page: number,
    status: number,
    action?: string
  ): Promise<Pagination<IUser>> {
    var params: any = {
      code: patientCode,
      full_name,
      query,
      page,
      status: Object.keys(PatientsStatus)[status],
    };
    if (action) {
      params.action = action;
    }
    let response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.getPatients,
      params: params,
      shouldAuthorize: true,
    });
    if (!response.data || !response.data.data) return paginationInit;
    var data = response.data.data.data;
    if (action) {
      data = response.data.data;
    }
    return {
      ...response.data.data,
      data: (data as Array<any>).map((user) => userFromJson(user)),
    };
  }

  async fetchListPatients(
    patientCode: string,
    full_name: string,
    query: string,
    page: number,
    status: number,
    action?: string
  ): Promise<Pagination<IUser>> {
    var params: any = {
      code: patientCode,
      full_name,
      query,
      page,
      status: Object.keys(PatientsStatus)[status],
    };
    if (action) {
      params.action = action;
    }
    let response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.getListPatients,
      params: params,
      shouldAuthorize: true,
    });
    if (!response.data || !response.data.data) return paginationInit;
    var data = response.data.data.data;
    if (action) {
      data = response.data.data.data;
    }
    return {
      ...response.data.data,
      data: (data as Array<any>).map((user) => userFromJson(user)),
    };
  }

  async fetchPatientDetails(patientId: any): Promise<IUser | undefined> {
    let response = await this.apiService.request({
      method: HttpMethod.GET,
      endpoint: Endpoints.patientDetails(patientId),
      shouldAuthorize: true,
    });
    if (
      !response.data ||
      !response.data.data ||
      !response.data.data.id ||
      response.data.data.id.toString() !== patientId
    )
      return undefined;
    return userFromJson(response.data.data);
  }

  async addPatient(patient: IUser): Promise<boolean> {
    await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: Endpoints.addPatient,
      shouldAuthorize: true,
      body: {
        data: formDataUser(patient),
      },
      headers: { "Content-Type": "multipart/form-data" },
    });
    return true;
  }

  async updatePatient(patient: IUser): Promise<boolean> {
    await this.apiService.request({
      method: HttpMethod.PUT,
      endpoint: Endpoints.updatePatient(patient.id),
      shouldAuthorize: true,
      body: {
        data: urlEncodedUser(patient),
      },
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    });
    return true;
  }

  async deletePatient(id: string): Promise<boolean> {
    await this.apiService.request({
      method: HttpMethod.DELETE,
      endpoint: Endpoints.deletePatient(id),
      shouldAuthorize: true,
    });
    return true;
  }

  async importPatientsByCSV(csvBase64: string): Promise<void> {
    var info = new FormData();
    info.append("csv", new File([dataURIToBlob(csvBase64)], `csv${new Date()}.csv`));
    await this.apiService.request({
      method: HttpMethod.POST,
      endpoint: Endpoints.importPatientsByCSV,
      shouldAuthorize: true,
      body: {
        data: info,
      },
      headers: { "Content-Type": "multipart/form-data" },
    });
    // return true
  }
}
