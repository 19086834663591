import { Either } from "./Either";
import { Failure } from "./Failure";

export abstract class UseCase<
    Params, T
    > {
    abstract call(params: Params): Promise<Either<Failure, T>>
}

export interface RequestParams { }
export interface ResponseValue { }

