import { Assets } from "src/utils/assets";

export function LoadingOverlayComponent(props: any) {
  return (
    <div>
      <div className={`loading-overlay${props.isLoading ? " show" : ""}`}>
        <div className="loader">
          <img src={Assets.mainLogo} height={80} />
        </div>
      </div>
      {props.children}
    </div>
  );
}
