import React from "react";
import { IFolder } from "src/features/fileManagement/data/models/folder";
import { Assets } from "src/utils/assets";

interface FolderToMoveComponentProps {
  folder: IFolder;
  handleSelectFolder: (folder: IFolder) => void;
  handleMoveIntoFolder: (folder: IFolder) => void;
  isSelected: boolean;
}

interface FolderToMoveComponentState {}

class FolderToMoveComponent extends React.Component<FolderToMoveComponentProps, FolderToMoveComponentState> {
  handleClickFolder = (event: any) => {
    if (event.detail === 1) this.props.handleSelectFolder(this.props.folder);
    else this.props.handleMoveIntoFolder(this.props.folder);
  };

  render() {
    return (
      <div className={`grid-item ${this.props.isSelected ? "selected" : ""}`} onClick={this.handleClickFolder}>
        <div className="grid-head">
          <div className="grid-head-action">
            <img
              className={`head-item select-box`}
              src={this.props.isSelected ? Assets.boxActive : Assets.boxInActive}
              width={30}
            />
          </div>
          <img src={Assets.folderMove2} width={50} style={{ alignSelf: "center" }} />
        </div>
        <span className="file-name">{this.props.folder.name}</span>
      </div>
    );
  }
}

export default FolderToMoveComponent;
