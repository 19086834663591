import React from "react";
import { BaseChart } from "src/components/chart/chart";
import { Strings } from "src/constants";
import { Assets } from "src/utils/assets";
import { IAnalyticData, IComprehensive } from "src/features/analytic/data/models/patientAnalytic"
import { LineChart } from "src/components/chart/line_chart_component";

export interface AnalyticBodyProps {
    id?: string
    comprehensive: IComprehensive | IAnalyticData[]
    child?: React.ReactNode
}

export abstract class AnalyticBody<P = {}, S = {}, SS = any> extends React.Component<P & AnalyticBodyProps, S, SS>{
    abstract title: string
    tabContent?: React.ReactNode

    render(): React.ReactNode {
        return <div className="tab-box-item bg-cover graph-style2" id={this.props.id}>
            <div className="tab-title tab-title-full mb10"><img src={Assets.analysis2} alt={this.title} /><span>{this.title}</span></div>
            {this.props.child ?? <div className="tab-content">
                <LineChart items={Object.keys(this.props.comprehensive).map((key) => {
                    return {
                        name: key,
                        percents: parseFloat(parseFloat((this.props.comprehensive as IComprehensive)[key].toString()).toFixed(2)),
                        color: Math.floor(Math.random() * 16777215).toString(16)
                    }
                })}
                />

            </div>}
        </div>
    }
}