import moment from "moment";
import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { AnalyticDetailTab } from "src/base/Enums";
import { Injector } from "src/base/Injector";
import { Strings } from "src/constants";
import {
  defaultPatientAnalysisDetail as defaultPatientAnalysisDetail,
  IAnalyticDate,
} from "src/features/analytic/data/models/patientAnalytic";
import { AnalyticContainer } from "src/features/analytic/presenter/container/AnalyticContainer";
import { patientAnalyticOnDateShowed } from "src/features/analytic/presenter/store/patientAnalytic";
import { history, Routes } from "src/helpers";
import { BOPAnalyticTab } from "src/layouts/analytic/tab/bopAnalyticTab";
import { EPPAnalyticTab } from "src/layouts/analytic/tab/eppAnalyticTab";
import { PCRAnalyticTab } from "src/layouts/analytic/tab/pcrAnalyticTab";
import { TotalAnalyticTab } from "src/layouts/analytic/tab/totalAnalyticTab";
import MainLayout from "src/layouts/main/mainLayout";
import { BackButton } from "src/shared/components/backButton";
import { NoDataWrapperComponent } from "src/shared/components/noDataWrapperComponent";
import { IRootState } from "src/store";
import { findParamsFromSearchString } from "src/utils/utils";

class AnalysisPdfViewPage extends React.Component<AnalysisPdfViewPageType> {
  constructor(props: any) {
    super(props);
  }

  componentDidMount() {
    let params = findParamsFromSearchString(window.location.search);
    if (params?.has("analysisId") && params.get("analysisId")!) {
      Object.values(AnalyticDetailTab).forEach((tab) => {
        this.props.onFetchPatientAnalyticDetails(
          params?.get("analysisId")!,
          tab,
          params?.get("fromDate") ? new Date(params?.get("fromDate")!) : undefined,
          params?.get("toDate") ? new Date(params?.get("toDate")!) : undefined
        );
      });
    }
  }

  buildAnalyticPdfBody = () => {
    return (
      <div className="card-body">
        <div className="dataTable-container">
          <div className="analysis-body" id="pdf-page">
            <TotalAnalyticTab
              id="review-tab1"
              className="animate-item"
              onChangeDates={(dates) => {
                this.props.onChangeDates(AnalyticDetailTab.total, dates);
              }}
              analyticDetail={
                this.props.patientAnalyticDetail.analytic_details.get(AnalyticDetailTab.total) ??
                defaultPatientAnalysisDetail
              }
            />
            <PCRAnalyticTab
              id="review-tab2"
              className="animate-item"
              onChangeDates={(dates) => {
                this.props.onChangeDates(AnalyticDetailTab.pcr, dates);
              }}
              analyticDetail={
                this.props.patientAnalyticDetail.analytic_details.get(AnalyticDetailTab.pcr) ??
                defaultPatientAnalysisDetail
              }
            />
            <EPPAnalyticTab
              id="review-tab3"
              className="animate-item"
              onChangeDates={(dates) => {
                this.props.onChangeDates(AnalyticDetailTab.epp, dates);
              }}
              analyticDetail={
                this.props.patientAnalyticDetail.analytic_details.get(AnalyticDetailTab.epp) ??
                defaultPatientAnalysisDetail
              }
            />
            <BOPAnalyticTab
              id="review-tab4"
              onChangeDates={(dates) => {
                this.props.onChangeDates(AnalyticDetailTab.bop, dates);
              }}
              className="animate-item"
              analyticDetail={
                this.props.patientAnalyticDetail.analytic_details.get(AnalyticDetailTab.bop) ??
                defaultPatientAnalysisDetail
              }
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <MainLayout
        className="secondpage navstate_show page-analysis_management detail"
        subClassName="main_body p-analysis_management detail"
      >
        <div className="card">
          <div className="card-header flex-H">
            <BackButton
              onBackPressed={() => {
                let params = findParamsFromSearchString(window.location.search);
                if (!this.props.isNotFound && params?.has("analysisId") && params.get("analysisId")!) {
                  let fromDate = params?.get("fromDate") ? new Date(params?.get("fromDate")!) : undefined;
                  let toDate = params?.get("toDate") ? new Date(params?.get("toDate")!) : undefined;
                  history.replace({
                    pathname: Routes.PATIENT_ANALYTIC_MANAGEMENT_DETAIL,
                    search: `?analysisId=${params.get("analysisId")}${
                      fromDate ? `&fromDate=${moment(fromDate).format("YYYY-MM-DD")}` : ``
                    }${toDate ? `&toDate=${moment(toDate).format("YYYY-MM-DD")}` : ""}`,
                  });
                } else {
                  history.replace(Routes.PATIENT_ANALYTIC_MANAGEMENT);
                }
              }}
            />
            分析結果管理-詳細
          </div>
        </div>

        <div className="analysis-head-item text-center mt20">
          <NoDataWrapperComponent isNoData={this.props.isNotFound} noDataContent={<h4>{Strings.NO_DATA}</h4>}>
            <div
              className="btn btn-default btn-pdf"
              onClick={() => {
                this.props.onExportPdf();
              }}
            >
              <span>PDFダウンロード</span>
            </div>
            {this.buildAnalyticPdfBody()}
          </NoDataWrapperComponent>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ patientAnalytic }: IRootState) => {
  const { patientAnalyticDetail, isNotFound, patientAnalyticState, tab } = patientAnalytic;
  return {
    patientAnalyticDetail,
    patientAnalyticState,
    tab,
    isNotFound,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    onFetchPatientAnalyticDetails: (id: string, type: AnalyticDetailTab, fromDate?: Date, toDate?: Date) =>
      Injector.get()
        .find<AnalyticContainer>("AnalyticContainer")
        .onFetchPatientAnalysisDetail(dispatch, id, type, fromDate, toDate),
    onChangeDates: (key: AnalyticDetailTab, dates: IAnalyticDate[]) =>
      dispatch(patientAnalyticOnDateShowed(key, dates)),
    onExportPdf: () => Injector.get().find<AnalyticContainer>("AnalyticContainer").onExportPdf(dispatch),
  };
};

type AnalysisPdfViewPageType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(AnalysisPdfViewPage);
