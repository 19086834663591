import React from "react";
import { TeethClipPath } from "src/helpers/teethClipPath";
import { ToothPath } from "./toothPath";

interface TeethSVGProps {
  teethDisabled?: Array<number>;
  openModal: (toothIndex: number) => void;
  teeths: {
    index: number;
    clipPath: string;
  }[];
  lowerJaw?: boolean;
  selectedTeethIndex?: number;
}

export class TeethSVG extends React.Component<TeethSVGProps> {
  render() {
    return (
      <div className="fcc teeth-wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="300"
          height={this.props.teeths.length === 32 ? "500" : "200"}
          viewBox={
            this.props.teeths.length === 32 ? "0 0 370 555" : this.props.lowerJaw ? "0 300 370 300" : "0 0 370 300"
          }
          fill="none"
        >
          {this.props.teeths.map((item, idx) => (
            <ToothPath
              key={idx}
              index={item.index}
              clipPath={item.clipPath}
              isSelected={item.index === this.props.selectedTeethIndex}
              isHover={this.props.teethDisabled && this.props.teethDisabled?.indexOf(item.index) !== -1}
              onClick={() => {
                this.props.openModal(item.index);
              }}
            />
          ))}
          <defs>
            {TeethClipPath.map((item, idx) => (
              <clipPath key={idx} id={item.id}>
                <rect width={item.width} height={item.height} fill={item.color} transform={item.transform}></rect>
              </clipPath>
            ))}
          </defs>
        </svg>
      </div>
    );
  }
}
