import { RemoteFilesState } from "src/base/Enums";
import { IUser } from "src/features/account/data/models/user";
import { MFile } from "src/features/medicalRecord/data/models/file";
import { Pagination } from "src/shared/model/pagination";
import { action } from "typesafe-actions";
import { RemoteFilesActionType } from "./types";

//call this when global state changed for file download feature
export function stateChanged(remoteFilesState: RemoteFilesState) {
    return action(RemoteFilesActionType.REMOTE_FILES_STATE_CHANGED, {
        remoteFilesState
    })
}

//fetch remote ply files on server
export function remoteFilesFetched(remoteFiles: Pagination<MFile>) {
    return action(RemoteFilesActionType.REMOTE_FILES_FETCHED, {
        remoteFiles
    })
}

//select or unselect remote file
export function selectRemoteFile(selectedRemoteFile: MFile) {
    return action(RemoteFilesActionType.SELECT_REMOTE_FILE, {
        selectedRemoteFile
    })
}

//call when patients is fetched from the API
export function patientsLoaded(patients: Pagination<IUser>) {
    return action(RemoteFilesActionType.PATIENTS_LOADED, { patients })
}

//change value of paginate of patients
export function patientsValueChanged(key: string, value: any) {
    return action(RemoteFilesActionType.PATIENTS_PAGINATE_VALUE_CHANGED, { key, value })
}

//call this when a remote file is deleted
export function remoteFileDeleted(id: any) {
    return action(RemoteFilesActionType.REMOTE_FILE_DELETED, { id })
}

//clear all selected files 
export function clearAllSelectedFiles() {
    return action(RemoteFilesActionType.CLEAR_ALL_SELECTED_FILES)
}