import React from "react";
import { BaseChart } from "src/components/chart/chart";
import { Strings } from "src/constants";
import {
  IAnalyticDate,
  IPatientAnalyticDetail,
} from "src/features/analytic/data/models/patientAnalytic";
import { Assets } from "src/utils/assets";
import { AnalyticBody } from "../body/analyticBody";
import { AnalyticFooter } from "../footer/analyticFooter";
import { AnalyticHeader } from "../header/analyticHeader";

export interface PatientAnalyticTabProps {
  analyticDetail: IPatientAnalyticDetail;
  id: string;
  onChangeDates: (dates: IAnalyticDate[]) => void;
  className?: string;
  header?: React.ReactElement<AnalyticHeader>;
  body?: React.ReactElement<AnalyticBody>;
  footer?: React.ReactElement<AnalyticFooter>;
}

export class PatientAnalyticTab extends React.Component<PatientAnalyticTabProps> {
  render(): React.ReactNode {
    
    return (
      <div id={this.props.id}>
        {this.props.header}
        {this.props.body}
        {this.props.footer}
      </div>
    );
  }
}
