import { Endpoints } from "src/base/Endpoints";
import { Injectable } from "src/base/Injector";
import { HttpMethod } from "src/helpers";
import { IApiService } from "src/services/api/ApiService";
import { convertUrlEncodedBody } from "src/utils/utils";
import { defaultUser, IUser, userFromJson } from "../models/user";

export interface IAccountRemoteDatasource {
    getMe(): Promise<IUser>
    updateAccount(full_name: string, username: string, password: string): Promise<void>
}

export class AccountRemoteDataSource implements IAccountRemoteDatasource {

    apiService: IApiService
    constructor(apiService: IApiService) {
        this.apiService = apiService
    }


    async getMe(): Promise<IUser> {
        var response = await this.apiService.request({ method: HttpMethod.GET, endpoint: Endpoints.me, shouldAuthorize: true })
        if (!response.data || !response.data.data) {
            return defaultUser
        }
        return userFromJson(response.data.data)
    }

    async updateAccount(full_name: string, username: string, password: string): Promise<void> {
        var body: any = {}
        if (username.length !== 0) {
            body.username = username
        }
        if (full_name.length !== 0) {
            body.full_name = full_name
        }
        if (password.length !== 0) {
            body.password = password
        }
        await this.apiService.request({
            method: HttpMethod.PUT, endpoint: Endpoints.updateMe, headers: { 'content-type': 'application/x-www-form-urlencoded' }, body: {
                data: convertUrlEncodedBody(body)
            }, shouldAuthorize: true
        })
    }
}