import { Either, LeftSide, match, RightSide } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { Injectable } from "src/base/Injector";
import { IAuthRemoteDataSource } from "../datasources/authRemoteDatasource";

export interface IAuthRepository {
    login(id: string, password: string): Promise<Either<Failure, void>>
    forgetPassword(email: string): Promise<Either<Failure, boolean>>
    verifyOTP(email: string, otp: string): Promise<Either<Failure, boolean>>
    changePassword(password: string, passwordCF: string, token: string): Promise<Either<Failure, boolean>>
}

export class AuthRepository implements IAuthRepository {
    authRemoteDataSource: IAuthRemoteDataSource
    constructor(authRemoteDataSource: IAuthRemoteDataSource) {
        this.authRemoteDataSource = authRemoteDataSource
    }

    async login(id: string, password: string): Promise<Either<Failure, void>> {
        try {
            return RightSide(await this.authRemoteDataSource.login(id, password));
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e })
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message })
            }
        }
        return LeftSide(new Error(""));
    }

    async forgetPassword(email: string): Promise<Either<Failure, boolean>> {
        try {
            return RightSide(await this.authRemoteDataSource.forgetPassword(email));
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e })
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message })
            }
        }
        return LeftSide(new Error(""));
    }

    async verifyOTP(email: string, otp: string): Promise<Either<Failure, boolean>> {
        try {
            return RightSide(await this.authRemoteDataSource.verifyOTP(email, otp));
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e })
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message })
            }
        }
        return LeftSide(new Error(""));
    }

    async changePassword(password: string, passwordCF: string, token: string): Promise<Either<Failure, boolean>> {
        try {
            return RightSide(await this.authRemoteDataSource.changePassword(password, passwordCF, token));
        } catch (e) {
            if (typeof e === "string") {
                return LeftSide({ message: e })
            } else if (e instanceof Error) {
                return LeftSide({ message: e.message })
            }
        }
        return LeftSide(new Error(""));
    }
}