import { Bar } from "react-chartjs-2";
import { BaseChart} from "./chart";

export class BarChart extends BaseChart {
  render() {
    var options: any = {
      responsive: true,

      scales: {
        y: {
          max: 100,
        },
      
      },
      bar: {
        datasets: {
          barPercentage: 10,
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        datalabels: {
          align: "end",
          anchor: "end",
          color: "#02A3AF",
          font: function (context: any) {
            var w = context.chart.width;
            return {
              size: w < 512 ? 12 : 14,
              weight: "bold",
            };
          },
          formatter: function (value: any, context: any) {
            return parseFloat(value).toFixed(2);
          },
        },
      },
    };
    var data: any = {
      labels: this.props.items.map((item) => item.name),
      datasets: [
        {
          data: this.props.items.map((item) => item.percents),
          backgroundColor: this.props.items.map((item) => item.color),
        },
      ],
    };
    return (
      <Bar
        options={options}
        data={data}
        width={this.props.width}
        height={this.props.height}
      />
    );
  }
}
