import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { INotificationRepository } from "../../data/repositories/notificationRepository";
import { INotification } from "../../presenter/store/notification/types";

export class SaveNotification extends UseCase<NotificationParams, INotification>{
    repository: INotificationRepository
    constructor(repository: INotificationRepository) {
        super()
        this.repository = repository
    }
    call(params: NotificationParams): Promise<Either<Failure, INotification>> {
        return this.repository.saveNotification(params.notification)
    }
}

class NotificationParams {
    notification: INotification
    constructor(notification: INotification) {
        this.notification = notification
    }
}