import moment from "moment";
import { paginationInit } from "src/shared/model/pagination";
import { IAdminNoticeState, AdminNoticeActions, AdminNoticeActionType } from "./types";

export const initialNotice = {
    id: -1,
    activityDate: new Date(),
    type: "admin",
    title: "",
    content: "",
}

const initialState: IAdminNoticeState = {
    notices: paginationInit,
    state: false
}

export function adminNoticeReducer(state: IAdminNoticeState = initialState, action: AdminNoticeActions): IAdminNoticeState {
    switch (action.type) {
        case AdminNoticeActionType.FETCH_ADMIN_NOTICE:
            return {
                ...state,
                notices: action.payload.notices
            }

        case AdminNoticeActionType.NOTICE_STATE_CHANGED:
            return {
                ...state,
                state: action.payload.state
            }

        default:
            return state;
    }
}