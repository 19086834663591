import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { NoParam } from "src/base/NoParam";
import { UseCase } from "src/base/UseCase";
import { IPatientRepository } from "../../data/repositories/patientRepository";

export class FetchDownloadCsvFile extends UseCase<NoParam, string>{
    private repository: IPatientRepository
    constructor(repository: IPatientRepository) {
        super()
        this.repository = repository
    }
    async call(_: NoParam = {}): Promise<Either<Failure, string>> {
        return this.repository.downloadcsvfile()
    }
}