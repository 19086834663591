import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Dispatch } from "redux";
import { PatientState } from "src/base/Enums";
import { Injector } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import FilePicker from "src/components/filePicker/filePicker";
import { Table } from "src/components/table";
import { Strings } from "src/constants";
import { PatientContainer } from "src/features/patient/presenter/container/patientContainer";
import { FileTypes, history, Routes } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import { BackButton } from "src/shared/components/backButton";
import { NoDataWrapperComponent } from "src/shared/components/noDataWrapperComponent";
import { IRootState } from "src/store";
import { Assets } from "src/utils/assets";
import { findParamsFromSearchString } from "src/utils/utils";

interface PatientCsvImportPageState {
  base64: string;
  fileName: string;
}

class PatientCsvImportPage extends React.Component<PatientCsvImportPageType, PatientCsvImportPageState> {
  constructor(props: any) {
    super(props);
    this.state = {
      base64: "",
      fileName: "",
    };
  }
  componentDidMount(): void {
    let params = findParamsFromSearchString(window.location.search);
    if (params?.has("type")) {
      let type = parseInt(params.get("type")!) || 0;
      if (type > 3) {
        type = 0;
      }
      // this.setState({
      //   ...this.state, typeofcsv: type
      // });
    }
  }

  render() {
    return (
      <MainLayout
        isLoading={this.props.patientState === PatientState.importingCsv}
        className="secondpage navstate_show page-patient_management regist animate-item"
        subClassName="p-patient_management regist"
      >
        <div className="container-fluid">
          <div className="card">
            <div className="card-header flex-H csvheader">
              <span className="texttit">
                <BackButton
                  onBackPressed={() => {
                    history.replace(Routes.PATIENT_MANAGEMENT);
                  }}
                />
                {Strings.CSV + Strings.LINE + Strings.CREATE}
              </span>
              <span className="csvdownload">
                <button
                  onClick={() => {
                    this.props.onDownloadCsv();
                  }}
                >
                  <span>
                    <img src={Assets.icocsv} alt="フォーマットダウンロード" />
                  </span>
                  フォーマットダウンロード
                </button>
              </span>
            </div>
            <NoDataWrapperComponent noDataContent={Strings.NO_DATA}>
              <div className="card-body">
                <Table
                  className="dataTable-container"
                  subClassName="table table_detail"
                  headers={[]}
                  rows={[
                    {
                      fields: [
                        { html: Strings.CSV_SELECTION },
                        {
                          html: (
                            <FilePicker
                              className="btnpdf-wrapper"
                              labelClassName="btn btn-primary btn-main"
                              id="pdf1"
                              labelText={this.state.fileName || Strings.SELECT_FILE}
                              inputClassName="w300px form-control mr10"
                              onChange={(value: any, _: any, inputfile: any) => {
                                this.setState({
                                  ...this.state,
                                  base64: value,
                                  fileName: inputfile.name ?? "",
                                });
                              }}
                              fileAccept={FileTypes.CSV}
                            />
                          ),
                        },
                      ],
                    },
                  ]}
                />
                <div className="fec mt20 btn-group-end">
                  <MainButton
                    title={Strings.REGISTER}
                    submitting={this.props.patientState === PatientState.importingCsv}
                    enabled={this.state.base64 !== ""}
                    className="btn btn-primary"
                    type="a"
                    loadingSize={20}
                    onSubmit={() => {
                      this.props.onImportCsv(this.state.base64);
                    }}
                  />
                  <Link className="btn btn-danger" to={Routes.PATIENT_MANAGEMENT} replace>
                    {Strings.RETURN}
                  </Link>
                </div>
              </div>
            </NoDataWrapperComponent>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ patient }: IRootState) => {
  const { patients, patientState } = patient;
  return {
    patients,
    patientState,
  };
};
const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    onImportCsv: (csvBase64: string) =>
      Injector.get().find<PatientContainer>("PatientContainer").onImportPatientsCsv(dispatch, csvBase64),
    onDownloadCsv: () => Injector.get().find<PatientContainer>("PatientContainer").onFetchDownloadFile(),
  };
};

type PatientCsvImportPageType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(PatientCsvImportPage);
