import React from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { ReactSVG } from "react-svg";
import { Dispatch } from "redux";
import { FileManagementState } from "src/base/Enums";
import { DI, Injector } from "src/base/Injector";
import { Strings } from "src/constants";
import { FileManagementType, IFile } from "src/features/fileManagement/data/models/file";
import { IFolder } from "src/features/fileManagement/data/models/folder";
import { RemoteFileManagementContainer } from "src/features/fileManagement/presenter/container/RemoteFileManagementContainer";
import { FileTypes } from "src/helpers/enums";
import { LoadingComponent } from "src/shared/components/loadingComponent";
import { IRootState } from "src/store";
import { Assets } from "src/utils/assets";
import Modal from "../../modal/modal";
import FileComponent from "./components/FileComponent";
import FolderComponent from "./components/FolderComponent";
import { MainButton } from "src/components/button/mainButton";

interface FileManagementPopupProps {
  isShow: boolean;
  handleShow: (isShow: boolean) => void;
  fileAccept?: FileTypes;
  onChange: (value: any, e?: React.ChangeEvent<HTMLInputElement>, file?: any, url?: string, fileList?: any[]) => void;
  multiple?: boolean;
  maxFile?: number;
}

interface FileManagementPopupState {
  isLoading: boolean;
  itemSelected?: IFolder | IFile;
  breadcrumb: string[];
  fileAccept: string;
  filesSelected: IFile[];
}

class FileManagementPopup extends React.Component<FileManagementPopupType, FileManagementPopupState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: false,
      itemSelected: undefined,
      breadcrumb: [],
      fileAccept: "",
      filesSelected: [],
    };
  }

  componentDidMount() {
    DI.get().injectRemoteFileManagementDependencies();

    let _fileAccept = "";

    switch (this.props.fileAccept) {
      case FileTypes.ALL:
        _fileAccept = "all";
        break;
      case FileTypes.IMAGE:
        _fileAccept = "image";
        break;
      case FileTypes.PLY:
        _fileAccept = "3d";
        break;
      case FileTypes.PDF:
        _fileAccept = "pdf";
        break;
      default:
        break;
    }

    this.setState(
      {
        fileAccept: _fileAccept,
      },
      () => this.props.onFetchFileManagement("", "", _fileAccept)
    );
  }

  handleSelectItem = (item: IFolder | IFile) => {
    if (item.type === FileManagementType.FOLDER) this.setState({ itemSelected: item });
    if (item.type === FileManagementType.FILE)
      this.setState({
        itemSelected: item,
        filesSelected: [item as IFile],
      });
  };

  handleMoveIntoFolder = (folder: IFolder) => {
    this.setState(
      {
        breadcrumb: folder.path.length === 0 ? [folder.name] : (folder.path + "/" + folder.name).split("/"),
      },
      () => {
        const breadcrumbPath = this.state.breadcrumb.join("/");

        this.props.onFetchFileManagement(breadcrumbPath, "", this.state.fileAccept).then(() => {
          this.handleResetSelected();
        });
      }
    );
  };

  handleChooseItem = (item: IFile) => {
    if (!this.props.multiple) this.props.onChange(item.url, undefined, item, item.url);
    else this.props.onChange(undefined, undefined, undefined, undefined, this.state.filesSelected);
  };

  handleSelectFile = (file: IFile) => {
    this.setState(
      {
        itemSelected: undefined,
      },
      () => {
        if (this.state.filesSelected.includes(file))
          return this.setState((prevState) => ({
            filesSelected: prevState.filesSelected.filter((el) => el !== file),
          }));

        if (this.props.maxFile && this.state.filesSelected.length === this.props.maxFile) {
          const shiftArray = this.state.filesSelected.slice(1);

          this.setState({ filesSelected: [...shiftArray, file] });
        } else
          this.setState((prevState) => ({
            filesSelected: [...prevState.filesSelected, file],
          }));
      }
    );
  };

  handleResetSelected = () => {
    this.setState({
      itemSelected: undefined,
    });
  };

  handleSelectBreadcrumb = (idx: number) => {
    if (idx === -1) {
      this.setState({
        breadcrumb: [],
      });
      this.handleResetSelected();
      this.props.onFetchFileManagement("", "", this.state.fileAccept);
    } else {
      const breadcrumbSlice = this.state.breadcrumb.slice(0, idx + 1);
      const breadcrumbPath = breadcrumbSlice.join("/");

      this.setState({ breadcrumb: breadcrumbSlice });
      this.props.onFetchFileManagement(breadcrumbPath, "", this.state.fileAccept).then(() => {
        this.handleResetSelected();
      });
    }
  };

  render() {
    return ReactDOM.createPortal(
      <Modal
        dialogExtraClassName="max file-management-popup"
        isShow={this.props.isShow}
        onClose={() => {
          this.props.handleShow(false);
        }}
      >
        <div className="modal-body modal-bodyViewer">
          <h4 className="text_center overflow" style={{ color: "rgb(2, 163, 175)" }}>
            {Strings.FILE_MANAGEMENT}
          </h4>
          <div className="file-management">
            <div className="breadcrumb">
              {this.state.breadcrumb.length > 0 ? (
                <div
                  className="breadcrumb-back"
                  onClick={() => this.handleSelectBreadcrumb(this.state.breadcrumb.length - 2)}
                >
                  <ReactSVG src={Assets.back} fill="white" />
                </div>
              ) : null}
              <span onClick={() => this.handleSelectBreadcrumb(-1)}>{Strings.FILE_MANAGEMENT}</span>
              {this.state.breadcrumb.map((folder, idx) => {
                return (
                  <span key={idx} onClick={() => this.handleSelectBreadcrumb(idx)}>
                    {folder}
                  </span>
                );
              })}
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <div className={`file-action-container ${this.props.multiple ? "show" : ""}`}>
                <MainButton
                  title="一括選択"
                  className="btn btn-primary"
                  onSubmit={() => {
                    this.setState({
                      filesSelected: this.state.filesSelected.length > 0 ? [] : this.props.fileManagementData.files,
                    });
                  }}
                  enabled
                />
              </div>
              <div
                className={`file-action-container ${this.state.filesSelected.length > 0 ? "d-flex" : ""}`}
                style={{ marginLeft: "1rem" }}
              >
                <img
                  src={Assets.upload2}
                  alt=""
                  width="20"
                  onClick={() =>
                    this.props.onChange(undefined, undefined, undefined, undefined, this.state.filesSelected)
                  }
                />
              </div>
            </div>
            <LoadingComponent
              isLoading={
                this.props.fileManagementState === FileManagementState.fetchFileManagement || this.state.isLoading
              }
            >
              <div className="grid-container mb00 pb00 pt00 mt-4">
                {this.props.fileManagementData.folders.map((folder, idx) => {
                  const isSelected = this.state.itemSelected === folder;

                  return (
                    <FolderComponent
                      key={idx}
                      folder={folder}
                      handleSelectItem={this.handleSelectItem}
                      handleMoveIntoFolder={this.handleMoveIntoFolder}
                      isSelected={isSelected}
                    />
                  );
                })}
                {this.props.fileManagementData.files.map((file, idx) => {
                  const isSelected = this.state.filesSelected.includes(file);

                  return (
                    <FileComponent
                      key={idx}
                      file={file}
                      handleSelectItem={this.handleSelectItem}
                      handleChooseItem={this.handleChooseItem}
                      handleSelectFile={this.handleSelectFile}
                      isSelected={isSelected}
                      multiple={this.props.multiple}
                    />
                  );
                })}
              </div>
            </LoadingComponent>
          </div>
        </div>
      </Modal>,
      document.body
    );
  }
}

const mapStateToProps = ({ fileManagement }: IRootState) => {
  const { fileManagementState, fileManagementData } = fileManagement;

  return {
    fileManagementState,
    fileManagementData,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    onFetchFileManagement: (breadcrumbPath: string, search?: string, only?: string) =>
      Injector.get()
        .find<RemoteFileManagementContainer>("RemoteFileManagementContainer")
        .onFetchFileManagement(dispatch, breadcrumbPath, search, only),
  };
};

type FileManagementPopupType = FileManagementPopupProps &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(FileManagementPopup);
