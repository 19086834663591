import ReactLoading from "react-loading";

export function LoadingComponent(props: any) {
  if (props.isLoading) {
    return (
      <ReactLoading
        color={props.inputColor ?? "#02A3AF"}
        className={`${props.className ?? " "}loading-center`}
        height={props.size}
        width={props.size}
      />
    );
  }

  if (!props.children) return <></>;

  return props.children;
}
