import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { SubscriptionState } from "src/base/Enums";
import { DI, Injector } from "src/base/Injector";
import { MainButton } from "src/components/button/mainButton";
import { PaymentAddPopup as PaymentDetailPopup } from "src/components/modal/paymentDetailPopup";
import { PaymentInfoPopup } from "src/components/modal/paymentInfoPopup";
import { PaymentManagementPopup } from "src/components/modal/paymentManagementPopup";
import { Strings } from "src/constants";
import { AccountContainer } from "src/features/account/presenter/container/accountContainer";
import { ICard, defaultCard } from "src/features/subscription/domain/model/card";
import { SubscriptionContainer } from "src/features/subscription/presenter/container/subscriptionContainer";
import {
  cardSelected,
  draftCardChanged,
  draftCardPropsChanged,
  planSelected,
} from "src/features/subscription/presenter/store";
import { Routes, history } from "src/helpers";
import MainLayout from "src/layouts/main/mainLayout";
import { BackButton } from "src/shared/components/backButton";
import { IRootState } from "src/store";
import { Assets } from "src/utils/assets";
import { showPopupById } from "src/utils/utils";

class DetailPlanPage extends React.Component<DetailPlanType> {
  componentDidMount() {
    DI.get().injectSubscriptionDependencies();
    this.props.onFetchAllPlans();
  }

  render() {
    return (
      <MainLayout
        className="secondpage navstate_show page-planchange planchoose"
        subClassName="p-planchange planchoose"
      >
        <div className="container-fluid">
          <div className="card">
            <div className="card-header flex-H">
              <BackButton
                onBackPressed={() => {
                  history.replace(Routes.SUBSCRIPTION_MANAGEMENT);
                }}
              />
              {Strings.SUBSCRIPTION_MANAGEMENT}
            </div>
            <div className="card-body">
              <div className="dataTable-container">
                <p className="text-note text_center">プラン変更が適用されるのは次回のお支払後になります</p>
                <div className="plan-body">
                  {this.props.plans.map((plan, index) => {
                    return (
                      <div
                        key={plan.id}
                        className={`item ${plan.current_plan ? "active" : ""} ${
                          !plan.current_plan && this.props.selectedPlanIndex === index ? "selected" : ""
                        }`}
                        onClick={() => {
                          if (plan.current_plan) return;
                          this.props.planSelected(index);
                        }}
                      >
                        <div className="tag">
                          <div className="ins">
                            <img src={Assets.note} alt="現在のプラン" />
                            <span>現在のプラン</span>
                          </div>
                        </div>
                        <div className="plan-name">{plan.name}</div>
                        <div className="plan-info">{plan.description}</div>
                        <div className="plan-price">
                          月額<span>{plan.price}</span>円
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="flexbox planbtn">
                  <MainButton
                    className="btn btn-primary btn-main"
                    onSubmit={() => {
                      history.replace(Routes.SUBSCRIPTION_MANAGEMENT);
                    }}
                    title={Strings.RETURN}
                    enabled={true}
                  />
                  <MainButton
                    className="btn btn-danger btn-main"
                    submitting={this.props.subscriptionState === SubscriptionState.changingSubscription}
                    loadingSize={30}
                    onSubmit={() => {
                      showPopupById("payment-method");
                      this.props.onFetchAllCards();
                    }}
                    title={Strings.CHANGE_PLAN}
                    enabled={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <PaymentManagementPopup
          onChangePlan={() => {
            if (this.props.plans.filter((plan) => plan.current_plan).length === 0) {
              this.props.onAddPlan(
                this.props.plans[this.props.selectedPlanIndex].id,
                this.props.cards.filter((card) => card.current)
                  ? this.props.cards.filter((card) => card.current)[0].id
                  : ""
              );
              return;
            }
            this.props
              .onChangePlan(
                this.props.plans[this.props.selectedPlanIndex].id,
                this.props.cards.filter((card) => card.current)
                  ? this.props.cards.filter((card) => card.current)[0].id
                  : ""
              )
              .then(() => {
                this.props.onGetMe();
              });
          }}
          isSubmitting={
            this.props.subscriptionState === SubscriptionState.changingSubscription ||
            this.props.subscriptionState === SubscriptionState.addingSubscription
          }
          onSelectCard={this.props.onCardSelected}
          cards={this.props.cards}
          isFetching={this.props.subscriptionState === SubscriptionState.fetchingCards}
          onResetDraftCard={() => {
            this.props.onChangeDraftCard(false, { ...defaultCard });
          }}
          onSeeCardDetail={(card) => {
            this.props.onChangeDraftCard(true, { ...card, cvc: "" });
          }}
        />
        <PaymentDetailPopup
          isUpdate={this.props.isUpdate}
          isSubmitting={
            this.props.subscriptionState === SubscriptionState.addingCard ||
            this.props.subscriptionState === SubscriptionState.deletingCard ||
            this.props.subscriptionState === SubscriptionState.updatingCard
          }
          onAddCard={() => {
            this.props.onAddCard(this.props.draftCard);
          }}
          onUpdateCard={(card) => {
            this.props.onUpdateCard(card);
          }}
          onDeleteCard={(cardId) => {
            this.props.onDeleteCard(cardId);
          }}
          card={this.props.draftCard}
          onCardPropsChanged={this.props.onCardPropsChanged}
        />
        <PaymentInfoPopup />
      </MainLayout>
    );
  }
}

const mapStateToProps = ({ subscription }: IRootState) => {
  const { plans, subscriptionState, selectedPlanIndex, cards, draftCard, isUpdate } = subscription;
  return {
    plans,
    cards,
    draftCard,
    isUpdate,
    subscriptionState,
    selectedPlanIndex,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    onFetchAllPlans: () =>
      Injector.get().find<SubscriptionContainer>("SubscriptionContainer").onFetchAllPlans(dispatch),
    onAddCard: (card: ICard) =>
      Injector.get().find<SubscriptionContainer>("SubscriptionContainer").onAddCard(dispatch, card),
    onUpdateCard: (card: ICard) =>
      Injector.get().find<SubscriptionContainer>("SubscriptionContainer").onUpdateCard(dispatch, card),
    onDeleteCard: (cardId: string) =>
      Injector.get().find<SubscriptionContainer>("SubscriptionContainer").onDeleteCard(dispatch, cardId),
    onFetchAllCards: () =>
      Injector.get().find<SubscriptionContainer>("SubscriptionContainer").onFetchAllCards(dispatch),
    onChangePlan: (newPlanId: string, cardId: string) =>
      Injector.get().find<SubscriptionContainer>("SubscriptionContainer").onChangePlan(dispatch, newPlanId, cardId),
    onAddPlan: (newPlanId: string, cardId: string) =>
      Injector.get().find<SubscriptionContainer>("SubscriptionContainer").onAddPlan(dispatch, newPlanId, cardId),
    planSelected: (index: number) => dispatch(planSelected(index)),
    onChangeDraftCard: (isUpdate: boolean, card: ICard) => dispatch(draftCardChanged(isUpdate, card)),
    onCardSelected: (card: ICard) => dispatch(cardSelected(card)),
    onCardPropsChanged: (key: string, value: any) => dispatch(draftCardPropsChanged(key, value)),
    onGetMe: () => Injector.get().find<AccountContainer>("AccountContainer").onGetMe(dispatch),
  };
};

type DetailPlanType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(DetailPlanPage);
