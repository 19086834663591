import { Pagination } from "src/shared/model/pagination";
import { action } from "typesafe-actions";
import { IAdminNotice, AdminNoticeActionType } from "./types";

export function adminNoticeFetchData(notices: Pagination<IAdminNotice>) {
    return action(AdminNoticeActionType.FETCH_ADMIN_NOTICE, { notices })
}

export function adminNoticeStateChanged(state: boolean) {
    return action(AdminNoticeActionType.NOTICE_STATE_CHANGED, { state })
}