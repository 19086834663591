import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IFile } from "../../data/models/file";
import { IFileManagement } from "../../data/models/fileManagement";
import { IFolder } from "../../data/models/folder";
import { IRemoteFileManagementRepository } from "../../data/repositories/remoteFileManagementRepository";

export class MoveIntoFolderFileManagement extends UseCase<MoveIntoFolderFileManagementParams, void> {
  private repository: IRemoteFileManagementRepository;

  constructor(repository: IRemoteFileManagementRepository) {
    super();
    this.repository = repository;
  }

  callFetchFolderToMove(params: FetchFolderToMoveFileManagementParams): Promise<Either<Failure, IFileManagement>> {
    return this.repository.fetchFileManagement(params.breadcrumb, params.search, params.only);
  }

  call(params: MoveIntoFolderFileManagementParams): Promise<Either<Failure, void>> {
    return this.repository.moveFileManagement(params.oldPath, params.newPath, params.itemToMove);
  }
}

export class FetchFolderToMoveFileManagementParams {
  breadcrumb: string;
  search?: string;
  only?: string;

  constructor(breadcrumb: string, search?: string, only?: string) {
    this.breadcrumb = breadcrumb;
    this.search = search;
    this.only = only;
  }
}

export class MoveIntoFolderFileManagementParams {
  oldPath: string;
  newPath: string;
  itemToMove?: IFolder | IFile | IFile[];

  constructor(oldPath: string, newPath: string, itemToMove?: IFolder | IFile | IFile[]) {
    this.oldPath = oldPath;
    this.newPath = newPath;
    this.itemToMove = itemToMove;
  }
}
