import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { Pagination } from "src/shared/model/pagination";
import { IAdminNoticeRepository } from "../../data/repositories/notificationRepository";
import { IAdminNotice } from "../../presenter/store/adminNotice";

export class FetchAdminNotice extends UseCase<NoticeParams, Pagination<IAdminNotice>>{
    repository: IAdminNoticeRepository
    constructor(repository: IAdminNoticeRepository) {
        super()
        this.repository = repository
    }
    call(params: NoticeParams): Promise<Either<Failure, Pagination<IAdminNotice>>> {
        return this.repository.fetchNotices(params.page)
    }
}

class NoticeParams {
    page: number
    constructor(page: number) {
        this.page = page
    }
}