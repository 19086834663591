import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { INotificationRepository } from "../../data/repositories/notificationRepository";

export class DeleteNotification extends UseCase<NotificationParams,boolean>{
    repository: INotificationRepository
    constructor(repository: INotificationRepository) {
        super()
        this.repository = repository
    }
    call(params: NotificationParams): Promise<Either<Failure, boolean>> {
        return this.repository.deleteNotification(params.notificationId)
    }
}

class NotificationParams {
    notificationId: number
    constructor(notificationId: number) {
        this.notificationId = notificationId
    }
}