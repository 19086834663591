import { PatientState } from "src/base/Enums";
import { IUser } from "src/features/account/data/models/user";
import { Pagination } from "src/shared/model/pagination";
import { action } from "typesafe-actions";
import { PatientActionType } from "./types";

export function patientStateChanged(patientState: PatientState) {
    return action(PatientActionType.PATIENT_STATE_CHANGED, { patientState })
}

export function patientsFetched(patients: Pagination<IUser>) {
    return action(PatientActionType.PATIENTS_FETCHED, { patients })
}

export function patientPaginationUpdated(key: string, value: any) {
    return action(PatientActionType.PATIENTS_PAGINATION_UPDATED, { key, value })
}

export function patientUpdated(patient: IUser) {
    return action(PatientActionType.PATIENT_UPDATED, { patient })
}

export function selectedPatientUpdated(patient: IUser) {
    return action(PatientActionType.SELECTED_PATIENT_UPDATED, { patient })
}

export function selectedPatientDataChanged(key: string, value: any) {
    return action(PatientActionType.SELECTED_PATIENT_DATA_UPDATED, { key, value })
}

export function selectedPatient(patient: IUser) {
    return action(PatientActionType.SELECTED_PATIENT, { patient })
}