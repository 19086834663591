import { IThreeDViewerState, ThreeDViewerActions, ThreeDViewerActionType } from "./types";

const initialState: IThreeDViewerState = {
    meshFiles: new Map(),
    cameraPosition: [0, 0, 0],
}

export function threeDViewerReducer(state: IThreeDViewerState = initialState, action: ThreeDViewerActions) {
    switch (action.type) {
        case ThreeDViewerActionType.THREED_VIEWER_UPLOAD:
            return {
                ...state,
                meshFiles: action.payload.meshFiles
            }
        case ThreeDViewerActionType.THREED_VIEWER_UPDATE_CAMERA_POSITION:
            return {
                ...state,
                cameraPosition: action.payload.cameraPosition
            }
        default:
            return {
                ...state
            }
    }
}