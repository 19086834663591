import { Dispatch } from "redux";
import { match } from "src/base/Either";
import { handleError } from "src/helpers/errorHandler";
import { FetchAdminNotice } from "../../domain/usecases/fetchAdminNotice";
import { adminNoticeFetchData, adminNoticeStateChanged } from "../store/adminNotice";

export class AdminNoticeContainer {
    fetchAdminNotice: FetchAdminNotice

    constructor(fetchAdminNotice: FetchAdminNotice) {
        this.fetchAdminNotice = fetchAdminNotice;
    }

    async getNotices(dispatch: Dispatch, page: number) {
        dispatch(adminNoticeStateChanged(true))

        let either = await this.fetchAdminNotice.repository.fetchNotices(page)
        match(either, (failure) => {
            handleError(failure.message)
        }, (data) => {
            dispatch(adminNoticeFetchData(data))
        })

        dispatch(adminNoticeStateChanged(false))
    }
}