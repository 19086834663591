import { Either } from "src/base/Either"
import { Failure } from "src/base/Failure"
import { UseCase } from "src/base/UseCase"
import { Pagination } from "src/shared/model/pagination"
import { IMedicalRecord } from "../../data/models/medicalRecord"
import { IMedicalRecordRepository } from "../../data/repositories/medicalRecordRepository"


export class FetchMedicalRecordDetail extends UseCase<FetchMedicalRecordDetailParams, IMedicalRecord>{
    private repository: IMedicalRecordRepository
    constructor(repository: IMedicalRecordRepository) {
        super()
        this.repository = repository
    }

    call(params: FetchMedicalRecordDetailParams): Promise<Either<Failure, IMedicalRecord>> {
        return this.repository.fetchMedicalRecordDetail(params.id)
    }
}

export class FetchMedicalRecordDetailParams {
    id: string
    constructor(id: string) {
        this.id = id;
    }
}