import { IClinic } from "src/features/account/data/models/clinic";
import { defaultUser, IUser } from "src/features/account/data/models/user";

export interface IPatientAnalytic {
  special_code: string;
  id: string;
  code: string;
  full_name: string;
  username: string;
  user_type: string;
  clinic?: IClinic;
  medical_records_for_user_count: string;
  analytic_details: Map<string, IPatientAnalyticDetail>;
}

export const defaultPatientAnalytic: IPatientAnalytic = {
  special_code: "",
  id: "",
  code: "",
  full_name: "",
  username: "",
  user_type: "",
  medical_records_for_user_count: "0",
  analytic_details: new Map(),
};

export interface IPatientAnalyticDetail {
  patient: IUser;
  isNotFound?: boolean,
  analysis: {
    average?: number;
    patient: IUser;
    dates: IAnalyticDate[];
    total?: {
      [key: string]: IAnalyticTotal;
    };
    chart?: {
      [key: string]: IAnalyticData[];
    };
  };
  comprehensive: IComprehensive | IAnalyticData[];
  evaluation?: IBopEvaluation;
}

export const defaultPatientAnalysisDetail: IPatientAnalyticDetail = {
  patient: defaultUser,
  analysis: {
    patient: defaultUser,
    dates: [],
    total: {},
    chart: {},
  },
  comprehensive: {},
};

export interface IAnalyticDate {
  label: string;
  value: string;
  result: number | IAnalyticData[] | IPolishData;
  shouldShowAnalytic: boolean;
  color: string;
  average?: number;
}

export interface IPolishData {
  polish: number;
  unpolish: number;
}

export interface IAnalyticData {
  label: string;
  value: number;
}

export interface IComprehensive {
  [key: string]: [value: number];
}

export interface IAnalyticTotal {
  label: string;
  value: string;
}

export interface IBopEvaluation {
  continuation: number | string;
  loss: number | string;
  occurrence: number | string;
}

export interface ITotalEvaluation {
  [key: string]: IEvaluationDetail;
}

export interface IEvaluationDetail {}

export interface IEvaluationData {
  new: number;
  old: number;
  color: string;
}
