import React from "react";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { changeModal } from "src/features/root/presenter/store/root";
import { Modals } from "src/helpers";
import { IRootState } from "src/store";

interface ModalProps {
  children?: React.ReactNode;
  isShow: boolean;
  className?: string;
  dialogExtraClassName?: string;
  id?: string;
  hiddenBody?: boolean;
  footerChildren?: React.ReactNode;
  onClose?: () => void;
  disableClose?: boolean;
}

class Modal extends React.Component<ModalType> {
  render() {
    return (
      <div
        className={`modal fade ${this.props.className ?? ""} ${this.props.isShow ? "show" : ""}`}
        id={this.props.id ?? ""}
      >
        {!this.props.hiddenBody && (
          <div className={`modal-dialog ${this.props.dialogExtraClassName}`} role="document">
            <div className="modal-content">
              <div className="modal-header">
                <button
                  className="close"
                  type="button"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    this.props.onClose ? this.props.onClose() : this.props.changeModal(Modals.NONE);
                  }}
                  disabled={this.props.disableClose}
                >
                  <span aria-hidden="true">
                    <svg
                      className="svg-inline--fa fa-times fa-w-11"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="times"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 352 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                      ></path>
                    </svg>
                  </span>
                </button>
              </div>
              <div className="modal-body">{this.props.children}</div>
              <div className="modal-footer">{this.props.footerChildren}</div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ app }: IRootState) => {
  const { currentModal } = app;
  return {
    currentModal,
  };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
  return {
    changeModal: (modal: Modals) => dispatch(changeModal(modal)),
  };
};

type ModalType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps> & ModalProps;

export default connect(mapStateToProps, mapDispatcherToProps)(Modal);
