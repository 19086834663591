import { RemoteFilesState } from "src/base/Enums";
import { paginationInit } from "src/shared/model/pagination";
import { cloneArray } from "src/utils/utils";
import { IRemoteFilesState, RemoteFilesActions, RemoteFilesActionType } from "./types";

const initialState: IRemoteFilesState = {
    remoteFiles: paginationInit,
    selectedRemoteFiles: [],
    patients: paginationInit,
    state: RemoteFilesState.none
}

export function remoteFilesDownloadReducer(state: IRemoteFilesState = initialState, action: RemoteFilesActions) {
    switch (action.type) {
        case RemoteFilesActionType.REMOTE_FILES_STATE_CHANGED:
            return {
                ...state,
                state: action.payload.remoteFilesState
            }
        case RemoteFilesActionType.REMOTE_FILES_FETCHED:
            return {
                ...state,
                remoteFiles: {
                    ...action.payload.remoteFiles
                }
            }
        case RemoteFilesActionType.SELECT_REMOTE_FILE:
            let currentSelectedFiles = cloneArray(state.selectedRemoteFiles)
            let selectedIndex = currentSelectedFiles.findIndex((file) => file.id === action.payload.selectedRemoteFile.id)
            //check if [selectedIndex] is contained in selected file list
            if (selectedIndex > -1) {
                //if does, remove contained item
                currentSelectedFiles.splice(selectedIndex, 1)
            } else {
                //if doesn't, add new file into selected file list
                currentSelectedFiles.push(action.payload.selectedRemoteFile)
            }
            return {
                ...state,
                selectedRemoteFiles: cloneArray(currentSelectedFiles)
            }
        case RemoteFilesActionType.PATIENTS_LOADED:
            let patients = {
                ...state.patients,
                ...action.payload.patients,
                data: cloneArray([...state.patients.data, ...action.payload.patients.data])
            }

            return {
                ...state,
                patients: {
                    ...patients
                }
            }
        case RemoteFilesActionType.PATIENTS_PAGINATE_VALUE_CHANGED:
            return {
                ...state,
                patients: {
                    ...state.patients,
                    [action.payload.key]: action.payload.value
                }
            }
        case RemoteFilesActionType.REMOTE_FILE_DELETED:
            let selectedFiles = cloneArray(state.selectedRemoteFiles)
            let deletedIndex = selectedFiles.findIndex((file) => file.id.toString() === action.payload.id)
            //check if deleted file is contained in selected file list
            if (deletedIndex > -1) {
                selectedFiles.splice(deletedIndex, 1)
            }
            return {
                ...state,
                selectedRemoteFiles: cloneArray(selectedFiles)
            }
        case RemoteFilesActionType.CLEAR_ALL_SELECTED_FILES:
            return {
                ...state,
                selectedRemoteFiles: []
            }
        default:
            return {
                ...state
            }
    }
}