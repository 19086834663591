import { ActionType } from "typesafe-actions";
import * as actions from './actions';

export interface ILoginState {
    isSubmitting: boolean
}

export enum LoginActionType {
    LOGIN_STATE_CHANGED = "LOGIN_STATE_CHANGED"
}

export type LoginActions = ActionType<typeof actions>;