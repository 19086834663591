import { Modals } from "src/helpers";
import { ActionType } from "typesafe-actions";
import * as actions from './actions';

export interface IAppState {
    currentModal: Modals,
    position: [x: number, y: number, z: number],
    type: string
}

export enum RootActionType {
    CHANGE_MODAL = "CHANGE_MODAL",
    CHANGE_POSITION = "CHANGE_POSITION",
}

export type RootActions = ActionType<typeof actions>;