import { action } from "typesafe-actions";
import { ForgetPasswordActionType } from "./types";

export function forgetPasswordStateChanged(isSubmitting: boolean) {
    return action(ForgetPasswordActionType.FORGET_PASSSWORD_STATE_CHANGED, { isSubmitting })
}


export function forgetPasswordFieldChange(field: string, value: any) {
    return action(ForgetPasswordActionType.FORGET_PASSSWORD_FIELD_CHANGED, { field, value })
}
