import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { UseCase } from "src/base/UseCase";
import { IFile } from "../../data/models/file";
import { IRemoteFileManagementRepository } from "../../data/repositories/remoteFileManagementRepository";

export class DownloadFilesFileManagement extends UseCase<DownloadFilesFileManagementParams, Blob> {
  private repository: IRemoteFileManagementRepository;

  constructor(repository: IRemoteFileManagementRepository) {
    super();
    this.repository = repository;
  }

  call(params: DownloadFilesFileManagementParams): Promise<Either<Failure, Blob>> {
    return this.repository.downloadFilesFileManagement(params.breadcrumb, params.files);
  }
}

export class DownloadFilesFileManagementParams {
  breadcrumb: string;
  files: IFile[];

  constructor(breadcrumb: string, files: IFile[]) {
    this.breadcrumb = breadcrumb;
    this.files = files;
  }
}
