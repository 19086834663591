import React from "react";
import { Assets } from "src/utils/assets";
import { Strings } from "src/constants";
import { PasswordInput } from "src/components/input/passwordInput";
import { IRootState } from "src/store";
import { Dispatch } from "redux";
import { forgetPasswordFieldChange } from "src/features/auth/presenter/store/forgetPassword";
import { connect } from "react-redux";
import { AuthContainer } from "src/features/auth/presenter/container/authContainer";
import { Injector } from "src/base/Injector";
import { AuthLayout } from "../layouts/authLayout";
import { history, Routes } from "src/helpers";
import { findParamsFromSearchString } from "src/utils/utils";
import { MainButton } from "src/components/button/mainButton";

class ChangePasswordPage extends React.Component<ChangePasswordType> {
    constructor(props: any) {
        super(props);
    }

    render(): React.ReactNode {
        const disabled = !this.props.password?.validate || !this.props.passwordCF?.validate || this.props.password.value != this.props.passwordCF.value;
        return (
            <AuthLayout isLoading={this.props.isSubmitting} bodyClassname="p-changepass" sectionClassname="p-login">
                <div id="layoutSidenav_content">
                    <main>
                        <div className="container-fluid">
                            <div className="login-top">
                                <div className="item logo">
                                    <img src={Assets.logo} alt="Liex" />
                                </div>
                            </div>
                            <div className="login-bottom">
                                <div className="resetpass loginbox_c fz16">
                                    <div className="btn-left">
                                        <a onClick={() => {
                                            history.replace(Routes.VERIFY_OTP)
                                        }}></a>
                                    </div>
                                    <h2 className="text_center title">パスワード再設定</h2>
                                    <p className="text_center text_note">
                                        パスワードを再設定します。<br />
                                        下記入力欄にご希望のパスワードを記入の上、<br />
                                        登録ボタンを押してください。
                                    </p>
                                    <form className="formlogin" onSubmit={(e) => {
                                        e.preventDefault()
                                        this.props.onSubmit(this.props.password?.value ?? "", this.props.passwordCF?.value ?? "", findParamsFromSearchString(window.location.search)?.get("token") ?? "")
                                    }}>
                                        <div className="formbox_e">
                                            <PasswordInput
                                                className="fsc boxpassword"
                                                inputClassName="form-control pass_log_id"
                                                value={this.props.password?.value}
                                                maxLength={15}
                                                onChange={(value) => {
                                                    this.props.onChange("password", value)
                                                }}
                                                inputPlaceHolder={Strings.PASSWORD}
                                            ></PasswordInput>
                                        </div>
                                        <div className="formbox_e">
                                            <PasswordInput
                                                className="fsc boxpassword"
                                                inputClassName="form-control pass_log_id"
                                                value={this.props.passwordCF?.value}
                                                maxLength={15}
                                                onChange={(value) => {
                                                    this.props.onChange("passwordCF", value)
                                                }}
                                                inputPlaceHolder={Strings.PASSWORD}
                                            ></PasswordInput>
                                        </div>
                                        <div className="forbox_e boxbtn text-center">
                                            <MainButton
                                                title={Strings.LOGIN}
                                                submitting={this.props.isSubmitting}
                                                isSubmitButton={true}
                                                enabled={!disabled}
                                                className="btn btn-large btn-primary btn-primary-2"
                                                onSubmit={() => {
                                                }}
                                            />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </main>
                </div >
            </AuthLayout >
        )
    }
}

const mapStateToProps = ({ forgetPassword }: IRootState) => {
    const { password, passwordCF, isSubmitting } = forgetPassword
    return {
        password,
        passwordCF,
        isSubmitting
    };
};

const mapDispatcherToProps = (dispatch: Dispatch) => {
    return {
        onChange: (field: string, email: string) => dispatch(forgetPasswordFieldChange(field, email)),
        onSubmit: (password: string, passwordCF: string, token: string) =>
            Injector.get().find<AuthContainer>("AuthContainer").submitPassword(dispatch, password, passwordCF, token)
    };
};

type ChangePasswordType = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatcherToProps>;

export default connect(mapStateToProps, mapDispatcherToProps)(ChangePasswordPage);