import React from "react";

interface IInputGroupProps {
  className?: string;
  inputPlaceHolder?: string;
  inputType?: string;
  inputClassName?: string;
  value?: any
  buttonText?: string;
  buttonClassName?: string;
  onButtonClick?: () => void;
  onValueChanged?: (e: any) => void;
  buttonType?: "button" | "reset" | "submit" | undefined;
  error?: string;
}

export class InputGroup extends React.Component<IInputGroupProps> {
  render() {
    return (
      <React.Fragment>

        <div className={`input-group ${this.props.className}`}>
          <input
            className={`form-control ${this.props.inputClassName}`}
            type={this.props.inputType ?? "text"}
            placeholder={this.props.inputPlaceHolder ?? ""}
            onChange={this.props.onValueChanged}
            value={this.props.value}
            aria-label={this.props.inputPlaceHolder ?? ""}
          />
          <button
            className={`btn ${this.props.buttonClassName}`}
            type={this.props.buttonType ?? 'button'}
            onClick={this.props.onButtonClick}
          >
            {this.props.buttonText}
          </button>
        </div>
        {
          this.props.error && this.props.error.length > 0 && <p className="text-error">{this.props.error}</p>
        }

      </React.Fragment>
    );
  }
}