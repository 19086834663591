import { Either } from "src/base/Either";
import { Failure } from "src/base/Failure";
import { NoParam } from "src/base/NoParam";
import { UseCase } from "src/base/UseCase";
import { Pagination } from "src/shared/model/pagination";
import { IUnsharedFilesRepository } from "../../data/repositories/unsharedFilesRepository";

export class FetchUnsharedFiles extends UseCase<NoParam, number>{
    repository: IUnsharedFilesRepository
    constructor(repository: IUnsharedFilesRepository) {
        super()
        this.repository = repository
    }
    async call(_: NoParam = {}): Promise<Either<Failure,number>> {
        return this.repository.unsharedfiles()
    }
}