import React from 'react'
import { colors } from 'src/constants';
import { ColorPlaceHolder } from './colorPlaceholder';

export interface ColorPickerProps {
    pickedColor?: string
    placeHolder?: React.ReactNode
    onChangeColor: (color: string) => void
}

class ColorPicker extends React.Component<ColorPickerProps> {
    state = {
        displayColorPicker: false,
    };
    wrapperRef: any

    constructor(props: any) {
        super(props)
        this.wrapperRef = React.createRef();
    }


    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker })
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false })
    };

    render() {
        return (
            <div className='color-picker'>
                <div onClick={this.handleClick}>{this.props.placeHolder || <ColorPlaceHolder color={(this.props.pickedColor !== "none" && this.props.pickedColor) || colors[0]} />}</div>

                {this.state.displayColorPicker ? <div className='pop-color grid-container'>
                    {colors.map((color) => {
                        return <ColorPlaceHolder color={color} onClick={() => {
                            this.props.onChangeColor(color)
                            this.handleClose()
                        }} />
                    })}

                </div> : null}
            </div>
        )
    }
}

export default ColorPicker